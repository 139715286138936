import { useFormik } from 'formik'
import React, { memo, useEffect, useState } from 'react'

import Select from '@/components/Select'
import Datepicker from '@/components/Datepicker'
import './FormRfqSearch.scss'
import { OrderStatusCode } from '@shared/types/order'
import Input from '@/pages/RFQPortal/components/Input'
import RfqDatepicker from '@/pages/RFQPortal/components/RfqDatepicker'
import { ReactComponent as FunnelIcon } from '@/pages/RFQPortal/assets/img/funnel-rfq-list.svg'
import { ReactComponent as ChevronUpIcon } from '@/pages/RFQPortal/assets/img/chevron-up.svg'

const isMobile = () => window.innerWidth <= 1139

let typingTimer: any
const FormRfqSearch = ({ onUpdate, filters, onRefresh, admin }: any) => {
    const [showFilters, setShowFilters] = useState(false)

    useEffect(() => {
        if (isMobile()) {
            setShowFilters(false)
        } else {
            setShowFilters(true)
        }
    }, [])

    const toggleFilters = () => {
        if (showFilters) {
            setShowFilters(false)
        } else {
            setShowFilters(true)
        }
    }

    const formik = useFormik({
        initialValues: {
            ...filters,
            user: '',
        },
        onSubmit: async (values) => {
            onUpdate(values)
        },
    })

    const showFiltersBtnClass = 'form-filters__show-btn'
    const extendedClass = showFilters ? showFiltersBtnClass + '-chevron_extended' : ''

    return (
        <div className="form-filters form-rfq-search" style={{ marginBottom: 7 }}>
            {isMobile() && (
                <div onClick={toggleFilters} className={`${showFiltersBtnClass}`}>
                    <FunnelIcon className={`${showFiltersBtnClass}-funnel`} />
                    <p className={`${showFiltersBtnClass}-title`}>Filters</p>
                    <ChevronUpIcon className={`${showFiltersBtnClass}-chevron ${extendedClass}`} />
                </div>
            )}
            {showFilters && (
                <div className="filter-controlls__wrapper">
                    {admin && (
                        <div className="form-filters__item2">
                            <Input
                                id="1"
                                name="user"
                                label="Search by User"
                                className="search white"
                                onChange={(event) => {
                                    formik.setFieldValue('user', event.target.value)
                                    clearTimeout(typingTimer)
                                    typingTimer = setTimeout(() => {
                                        formik.submitForm()
                                    }, 500)
                                }}
                                value={formik.values.user}
                            />
                        </div>
                    )}
                    <div className="form-filters__item2">
                        <RfqDatepicker
                            label="Date"
                            minInterval={1}
                            range
                            past
                            errorHide
                            placeholder=""
                            type="white"
                            dateFormat={'MM/DD/YYYY'}
                            start={formik.values.start?.toDate()}
                            end={formik.values.end?.toDate()}
                            handleChange={(value) => {
                                formik.setFieldValue('start', value.start)
                                formik.setFieldValue('end', value.end)
                                formik.submitForm()
                            }}
                        />
                    </div>
                    <div className="form-filters__item2 form-filters_span">
                        <Select
                            newVariant
                            label="Status"
                            items={[
                                { name: 'Status', value: undefined },
                                { name: 'Pending', value: OrderStatusCode.PENDING },
                                { name: 'Confirmed', value: OrderStatusCode.CONFIRMED },
                                { name: 'Booked', value: OrderStatusCode.BOOKED },
                                { name: 'Dropped', value: OrderStatusCode.DROPPED },
                                { name: 'Finished', value: OrderStatusCode.FINISHED },
                                { name: 'Cancelled', value: OrderStatusCode.LTW_CANCELED },
                            ]}
                            name="status"
                            value={formik.values.status}
                            disabled={formik.isSubmitting}
                            onAction={({ value }) => {
                                formik.setFieldValue('status', value)
                                formik.submitForm()
                            }}
                            className={`value-${formik.values.status}`}
                        />
                    </div>

                    <div className="form-filters__item2 form-filters_span">
                        <Select
                            newVariant
                            label="Payment"
                            items={[
                                { name: 'Payment', value: undefined },
                                { name: 'Paid', value: true },
                                { name: 'Unpaid', value: false },
                            ]}
                            name="payment"
                            value={formik.values.payment}
                            disabled={formik.isSubmitting}
                            onAction={({ value }) => {
                                formik.setFieldValue('payment', value)
                                formik.submitForm()
                            }}
                            className={`value-${formik.values.payment}`}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default memo(FormRfqSearch)
