import React, { FC, Dispatch, SetStateAction } from 'react'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'

import {
    FormValues,
    FormValueTypes,
    SetErrorType,
    ValidateMainBoardLocation,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import RfqTemplate from '@/pages/RFQPortal/components/RfqTemplate'

export interface SingleRfqProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    manageOpportunity: ManageOpportunityType
    step: Steps
    changeStep: (step: Steps) => void
    errorMessages?: any
    setError: SetErrorType
    setValidateMainBoard: Dispatch<SetStateAction<ValidateMainBoardLocation[]>>
}

const SingleRfq: FC<SingleRfqProps> = ({
    formData,
    changeFormData,
    manageOpportunity,
    step,
    changeStep,
    errorMessages,
    setError,
    setValidateMainBoard,
}) => {
    return (
        <RfqTemplate
            formData={formData}
            changeFormData={changeFormData}
            currentOppId={formData.project.data[0].id}
            isExpanded
            manageOpportunity={manageOpportunity}
            step={step}
            changeStep={changeStep}
            errorMessages={errorMessages}
            setError={setError}
            setValidateMainBoard={setValidateMainBoard}
        />
    )
}

export default SingleRfq
