import React, { FC, useEffect } from 'react'
import { matchPath, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Page from '@/pages/Static/Page'
import { UserActionType } from '@/store/user/user'
import { userLogout, useUserState } from '@/store/user'
import { Http } from '@shared/api/Http'
import { UserRoutes } from '@/pages/User/UserRoutes'
import { WarehouseRoutes } from './pages/Warehouse/WarehouseRoutes'
import { PublicRoutes } from './pages/PublicPages/PublicRoutes'
import { ServiceRoutes } from './pages/Service/ServiceRoutes'
import { NotFound } from './pages/NotFound/NotFound'
import SignUp from './pages/SignUp'
import ROUTES, { GUEST_ROUTES } from '@/router'
import { useLocation } from 'react-router'
import { LoginPage } from '@/pages/Login/LoginPage'
import { RfqPortalRoutes } from '@/pages/RFQPortal/RfqPortalRoutes'
import { LoadBoardRoutes } from '@/pages/LoadBoard/LoadBoardRoutes'
import { LoadBoardPostDetail } from '@/pages/LoadBoard/PostDetail'
import Home from '@/pages/Home/Home'
import { Onboarding } from '@/pages/Onboarding/Onboarding'
import { Invites } from '@/pages/Invites/Invites'
import { VerifyEmail } from '@/pages/VerifyEmail/VerifyEmail'
import { VerifyResetPassword } from '@/pages/VerifyResetPassword/VerifyResetPassword'
import { WarehousesRoutes } from '@/pages/Warehouses/WarehousesRoutes'
import { AdminRoutes } from '@/pages/Admin/AdminRoutes'
import { ServiceNotAvailable } from '@/ServiceNotAvailable'
import HowItWorksPage from '@/pages/HowItWorks'
import FaqPage from '@/pages/FAQ'
import { AccountRoutes } from '@/pages/Account/AccountRoutes'
import { getRelativePath } from '@/pages/RFQPortal/RfqForm/rfqNavigate'
import { OrderPage } from '@/pages/Order'
import { PSMRoutes } from '@/pages/PSM/PSMRoutes'

const Logout: FC = () => {
    const navigate = useNavigate()
    const { dispatch } = useUserState()
    useEffect(() => {
        const logout = async () => {
            await userLogout(dispatch!)
            localStorage.clear()
            delete Http.defaults.headers.common.Authorization
            navigate(ROUTES.index)
        }
        logout()
    }, [])
    return <></>
}

interface AppRoutesProps {
    shouldLogoReset: boolean
    setShouldLogoReset: (shouldReset: boolean) => void
}

const AppRoutes: FC<AppRoutesProps> = ({ shouldLogoReset, setShouldLogoReset }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { state, dispatch } = useUserState()
    useEffect(() => {
        dispatch?.({ type: UserActionType.UPDATE_LAST_PATH, pathname: location.pathname })
    }, [dispatch, location])

    // useEffect(() => {
    //     if (
    //         state.isLoggedIn &&
    //         state.userInfo &&
    //         location.pathname.indexOf('sign-up') < 0 &&
    //         location.pathname.indexOf('service/email') < 0 &&
    //         !(location.pathname === '' || location.pathname === '/' || location.pathname.startsWith('/search'))
    //     ) {
    //         navigate(ROUTES.signUp.index)
    //     }
    // }, [state])

    useEffect(() => {
        if (
            state.isLoggedIn &&
            GUEST_ROUTES.some((route) => matchPath(route, location.pathname)) &&
            !location.search.includes('auth_token')
        ) {
            navigate(ROUTES.index, { replace: true })
        }
    }, [location.pathname, navigate, state.isLoggedIn])

    return (
        <div className="content">
            <Routes>
                <Route path={'/504'} element={<ServiceNotAvailable />} />
                <Route path={'/'} element={<Home />} />
                <Route path={ROUTES.public.verifyEmail} element={<VerifyEmail />} />
                <Route path={ROUTES.public.resetPassword} element={<VerifyResetPassword />} />
                <Route path={`${ROUTES.signUp.index}/*`} element={<SignUp />} />
                {/*<Route path={`${ROUTES.auth.index}/*`} element={<NewSignUpPage />} />*/}
                {/*<Route path={ROUTES.index} element={<Landing />} />*/}
                <Route path={ROUTES.public.onboarding} element={<Onboarding />} />
                <Route path={ROUTES.public.invites} element={<Invites />} />
                <Route path={ROUTES.public.auth} element={<div />} />
                <Route path={ROUTES.public.login} element={<LoginPage />} />
                <Route path={ROUTES.public.logout} element={<Logout />} />
                <Route
                    path={ROUTES.public.register}
                    element={<Navigate to={{ pathname: '/sign-up', search: location.search }} />}
                />
                {/*<Route path={ROUTES.test} element={<Test />} />*/}
                {/* dashboard - entry point */}
                {/*<Route path={ROUTES.dashboard} element={<Test />} />*/}

                {/*<Route path={ROUTES.registerCarrier} element={<CarrierRegister />} />*/}
                {/*<Route path={ROUTES.registerWarehouse} element={<WarehouseRegister />} />*/}
                <Route path={`${ROUTES.public.search}/*`} element={<PublicRoutes />} />
                <Route path={`${ROUTES.rfq.old}/*`} element={<RfqPortalRoutes />} />
                <Route path={`${ROUTES.rfq.base}/*`} element={<RfqPortalRoutes />} />
                <Route path={`${ROUTES.psm.base}/*`} element={<PSMRoutes />} />
                <Route
                    path={`${ROUTES.rfq.baseNoRFQ}`}
                    element={
                        <Navigate
                            to={{
                                pathname: location.pathname.startsWith('/ms/rfq')
                                    ? '/rfq'
                                    : getRelativePath('rfq').path,
                                search: location.search,
                            }}
                            replace
                        />
                    }
                />
                <Route path={`${ROUTES.service.base}/*`} element={<ServiceRoutes />} />
                {/* <Route path={ROUTES.page.base} element={Page} /> */}
                <Route path={ROUTES.page.index} element={<Page />} />

                {/* private */}
                <Route path={`${ROUTES.user.base}/*`} element={<UserRoutes />} />
                {/*<Route path={`${ROUTES.company.base}/*`} element={<CompanyRoutes />} />*/}
                {/*<Route path={`${ROUTES.carrier.base}/*`} element={<CarrierRoutes />} />*/}
                {/* <Route path={`${ROUTES.warehouse.base}/*`} element={<WarehouseRoutes />} /> */}
                <Route path={`${ROUTES.account.base}/*`} element={<AccountRoutes />} />
                <Route path={`${ROUTES.loadboard.base}`} element={<LoadBoardRoutes />} />
                <Route path={`${ROUTES.order.base}/:orderKey`} element={<OrderPage />} />
                {/* redirect loadboard base / orderkey to order base / orderkey: */}
                <Route
                    path={`${ROUTES.loadboard.base}/:orderKey`}
                    element={
                        <Navigate
                            to={{
                                pathname: `${ROUTES.order.base}/${location.pathname.split('/')[2]}`,
                                search: location.search,
                            }}
                            replace
                            state={{
                                redirected: true,
                            }}
                        />
                    }
                />
                <Route path={`${ROUTES.warehouses.base}/*`} element={<WarehousesRoutes />} />

                <Route
                    path={`${ROUTES.admin.base}/*`}
                    element={<AdminRoutes shouldLogoReset={shouldLogoReset} setShouldLogoReset={setShouldLogoReset} />}
                />

                <Route path={ROUTES.more['how-it-works']} element={<HowItWorksPage />} />
                <Route path={ROUTES.more.faq} element={<FaqPage />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default AppRoutes
