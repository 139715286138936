import React, { useEffect, useState, useRef } from 'react'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { useUserState } from '@/store/user'
import useNavHelper from '@/hooks/useNavHelper'
import { isProdEnv } from '@/pages/RFQPortal/helpers/rfqHelper'

interface IUseGoogleAnalytics {
    ga4MeasurementId?: string
    gtmId?: string
}

let isGA4Initialized = false

export const useGoogleAnalytics = ({ ga4MeasurementId, gtmId }: IUseGoogleAnalytics) => {
    const { additionalParams } = useCustomAnalyticsEvent()

    useEffect(() => {
        if (!isGA4Initialized && ga4MeasurementId) {
            if (isProdEnv) {
                ReactGA.initialize(ga4MeasurementId)
            } else {
                ReactGA.initialize(ga4MeasurementId, {
                    gaOptions: {
                        debug_mode: true,
                    },
                    gtagOptions: {
                        debug_mode: true,
                    },
                })
            }
            isGA4Initialized = true
            console.warn('GA4 initialized')
        }

        if (!isGA4Initialized && gtmId) {
            TagManager.initialize({ gtmId: gtmId })
            console.warn('GTag initialized')
        }
    }, [ga4MeasurementId, gtmId])

    useEffect(() => {
        if (ga4MeasurementId) {
            ReactGA.set(additionalParams)
        }
    }, [additionalParams, ga4MeasurementId])

    useScrollTracking()

    return { isGA4Initialized }
}

type PageCategoryMap = {
    [key: string]: string
}

export const useCustomAnalyticsEvent = () => {
    const location = useLocation()
    const { state: data } = useUserState()
    const [additionalParams, setAdditionalParams] = useState({})
    const { currentPage } = useNavHelper()

    useEffect(() => {
        const { position, state, zip, sfAccountId, sfContactId, sfParentAccountId, role } = data?.userInfo || {}
        const correctedRole = role === 1 ? 'Supply' : 'Demand'

        const pageCategoryMap: PageCategoryMap = {
            isRfqPage: 'rfq_form',
            isOrdersPage: 'rfq_list',
            isHowItWorksPage: 'loadBoard_how_it_works',
            isFaqPage: 'loadBoard_FAQ',
            isLoadBoardPage: 'loadboard',
            isWarehousesPage: 'warehouses',
            isAdminPage: 'admin',
        }
        const category = currentPage ? pageCategoryMap[currentPage] : undefined

        const analyticsUserInfo = {
            user_role: correctedRole,
            user_position: position,
            user_state: state,
            user_zip: zip,
            sf_account_id: sfAccountId,
            sf_contact_id: sfContactId,
            sf_parent_account_id: sfParentAccountId,
            ...(category && { category }),
        }

        const params = {
            page_title: document.title,
            page_location: window.location.href,
            page: location.pathname + location.search,
            logged_in: !!data?.userInfo,
            ...(data?.userInfo && analyticsUserInfo),
        }

        setAdditionalParams(params)
    }, [location.pathname, location.search, data])

    const sendCustomEvent = (eventName = '', additionalOptions = {}) => {
        const formattedEventName = eventName
            .replace(/[\s\W]+/g, '_')
            .toLowerCase()
            .trim()

        if (!isGA4Initialized) {
            console.error('Google Analytics Measurement ID is not installed. Event not sent:', formattedEventName)
            return
        }

        ReactGA.event(formattedEventName, { ...additionalOptions, ...additionalParams })
    }

    type HandleMenuClickProps = {
        label: string
        link?: string
        callBack?: () => void
        place?: string
    }

    const sendMenuClick = ({ label, link, callBack, place }: HandleMenuClickProps) => {
        sendCustomEvent('menu_clicked', {
            menu_label: label,
            ...(link && { menu_link: link }),
            ...(place && { menu_place: place }),
        })

        if (callBack) {
            callBack()
        }
    }

    return { sendCustomEvent, additionalParams, sendMenuClick }
}

interface TrackedDepths {
    [key: string]: number[]
}

export const useScrollTracking = (): void => {
    const location = useLocation()
    const trackedDepthsRef = useRef<TrackedDepths>({})
    const appRef = useRef<HTMLElement | null>(null)

    useEffect(() => {
        appRef.current = document.getElementById('app')
    }, [])

    useEffect(() => {
        if (!isGA4Initialized) return

        const trackScrollEvent = () => {
            if (appRef.current) {
                const scrollPercent = Math.round(calculateScrolledPercentage(appRef.current))
                const currentPathDepths = trackedDepthsRef.current[location.pathname] || []
                const alreadyTracked = currentPathDepths.includes(scrollPercent)

                if ([20, 50, 75, 90].includes(scrollPercent) && !alreadyTracked) {
                    ReactGA.event({
                        category: 'scroll',
                        action: 'scroll_depth',
                        label: `${scrollPercent}% Page Scroll`,
                        value: scrollPercent,
                        nonInteraction: true,
                    })

                    trackedDepthsRef.current = {
                        ...trackedDepthsRef.current,
                        [location.pathname]: [...currentPathDepths, scrollPercent],
                    }
                }
            }
        }

        window.addEventListener('scroll', trackScrollEvent, true)

        return () => {
            window.removeEventListener('scroll', trackScrollEvent)
        }
    }, [location.pathname])
}

function calculateScrolledPercentage(element: HTMLElement): number {
    const scrollTop = element.scrollTop
    const scrollHeight = element.scrollHeight
    const clientHeight = element.clientHeight
    const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100

    return scrolledPercentage
}
