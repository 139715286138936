import React, { FC, useEffect, useState } from 'react'
import { apiVersion, getFetcher, Http, postFetcher } from '@shared/api/Http'
import { useNavigate, useParams } from 'react-router-dom'
import { showInfoModal } from '@/parts/Modals/InfoModal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import useSWR from 'swr'

interface ChangePasswordForm {
    password: string | null
    repeat_password: string | null
}

const ChangePasswordFormSchema = Yup.object().shape({
    password: Yup.string().min(6).required(),
    repeat_password: Yup.string().min(6).required(),
})

export const ServicePasswordChange: FC = () => {
    const navigate = useNavigate()
    const { token: resetToken } = useParams<{ token: string }>()
    const { data, error } = useSWR<any>(
        () => (resetToken ? `/app/${apiVersion}/service/password-reset?token=${resetToken}` : null),
        getFetcher,
    )
    const [loading, setLoading] = useState(true)

    if (error) {
        showInfoModal({
            props: {
                title: 'Invalid token',
                message: '',
                onClose: () => {
                    navigate('/')
                },
            },
        })
    }

    useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])

    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const formik = useFormik<ChangePasswordForm>({
        initialValues: { password: null, repeat_password: null },
        validationSchema: ChangePasswordFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                await Http.post(`/app/v1/service/password-reset?token=${resetToken}`, values)

                showInfoModal({
                    props: {
                        title: 'Password successfully changed',
                        message: '',
                        onClose: () => {
                            navigate('/')
                        },
                    },
                })
            } catch (errors: any) {
                formik.setSubmitting(false)
                formik.setErrors(errors)
                if (Array.isArray(errors)) {
                    setErrorMessage(JSON.stringify(errors))
                }
            }
        },
    })

    return (
        <div className="company-index wrapper page">
            {loading && <div>Loading...</div>}
            {!loading && (
                <div>
                    <div className="page-title">Change Password</div>
                    <div className="wrap">
                        {errorMessage && <p>{errorMessage}</p>}

                        <form onSubmit={formik.handleSubmit}>
                            <InputText
                                text
                                label="Password"
                                placeholder="Password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                error={formik.errors.password}
                            />

                            <InputText
                                text
                                label="Repeat password"
                                placeholder="Repeat password"
                                name="repeat_password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.repeat_password}
                                error={formik.errors.repeat_password}
                            />

                            <div className="btns">
                                <Button
                                    label="Set new password"
                                    disabled={!formik.isValid}
                                    loading={formik.isSubmitting}
                                    types={['blue', 'large']}
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}
