import React, { FC } from 'react'
import cn from 'classnames'

import { Status } from '@/pages/RFQPortal/parts/Sidebar'

interface SidebarHeaderProps {
    status: Status
    mobile?: boolean
}

export const sidebarClass = 'sidebar'
const timelineClass = sidebarClass + '__timeline'

const SidebarHeader: FC<SidebarHeaderProps> = ({ status, mobile = false }) => {
    return (
        <ul className={`${timelineClass} ${!mobile ? `${timelineClass}_desktop` : ''}`}>
            {Object.values(Status).map((statusOption) => (
                <li
                    key={statusOption}
                    className={cn(
                        `${timelineClass}__item`,
                        { [`${timelineClass}__item_active`]: status === statusOption },
                        {
                            [`${timelineClass}__item_completed`]:
                                statusOption === Status.SOLUTION && status === Status.DETAILS,

                            //     (status === Status.DETAILS || status === Status.REVIEW)) ||
                            // (statusOption === Status.DETAILS && status === Status.REVIEW),
                        },
                    )}
                >
                    {statusOption}
                </li>
            ))}
        </ul>
    )
}

export default SidebarHeader
