import React from 'react'
// check-select.svg
import ServiceOptions, { IServiceOption } from '@/pages/Warehouses/Services/ServiceOptions'

export interface ServicesProps {
    services: IServiceOption[]
    onChange: (id: string, isChecked: boolean) => void
    editMode?: boolean
}

export default function Services({ services, onChange, editMode }: ServicesProps) {
    return <ServiceOptions options={services} onChange={onChange} editMode={editMode} />
}
