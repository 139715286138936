import { ServiceMethods } from '../index'
import { DisposalBreakdownComponent } from './BreakdownComponent'
import { DisposalFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const disposalService: ServiceMethods = {
    breakdownComponent: DisposalBreakdownComponent,
    formComponent: DisposalFormComponent,

    getDefaultService: () => {
        return {
            name: RfqService.Disposal,
            type: null,
            pricings: {
                [RfqServicePricing.Disposal]: { price: '', modifier: 'pallet' },
            },
        }
    },
}
