import { useState, useEffect } from 'react'

const useIsMobile = (breakpoint = 1140) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= breakpoint)
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [breakpoint])

    return isMobile
}

export default useIsMobile
