import { useState, useEffect } from 'react'


interface useMobileScreenProps {
    screenWidth?: number
}
/**
 * Determines if the screen width is less than the specified threshold,
 * indicating that the device is considered mobile.
 *
 * @param {Object} useMobileScreenProps - An optional object containing the following properties:
 *   - {number} screenWidth - The screen width threshold. Defaults to 520.
 * @return {boolean} True if the screen width is less than the specified threshold, false otherwise.
 */
function useMobileScreen({ screenWidth = 520 }: useMobileScreenProps = {}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < screenWidth)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < screenWidth)
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [screenWidth])

    return isMobile
}

export default useMobileScreen
