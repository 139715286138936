import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InLock = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 21 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 9V6.75C3.75 3.04688 6.75 0 10.5 0C14.2031 0 17.25 3.04688 17.25 6.75V9H18C19.6406 9 21 10.3594 21 12V21C21 22.6875 19.6406 24 18 24H3C1.3125 24 0 22.6875 0 21V12C0 10.3594 1.3125 9 3 9H3.75ZM6.75 9H14.25V6.75C14.25 4.6875 12.5625 3 10.5 3C8.39062 3 6.75 4.6875 6.75 6.75V9Z" />
            </svg>
        </IconBase>
    )
}
