import { FormValues, Opportunity, FormValueTypes } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import { getInitialOpportunityData } from '@/pages/RFQPortal/RfqOrder'

export type formDataActionType =
    | { type: 'UPDATE_FORMDATA'; formData: FormValues }
    | { type: 'UPDATE_NESTED_FIELD'; path: string; value: FormValueTypes }
    | { type: 'UPDATE_PROJECT'; value: FormValues['project'] }
    | { type: 'CREATE_NEW_OPPORTUNITY'; newOppId: string; newOppName: string }
    | { type: 'DELETE_OPPORTUNITY'; id: Opportunity['id'] }

export type ManageOpportunityType = (
    action: 'CREATE_NEW_OPPORTUNITY' | 'DELETE_OPPORTUNITY',
    id?: Opportunity['id'],
) => string | void

export function rfqFormReducer(state: FormValues, action: formDataActionType): FormValues {
    switch (action.type) {
        case 'UPDATE_FORMDATA': {
            return action.formData
        }

        case 'UPDATE_NESTED_FIELD': {
            const pathParts = action.path.split(/[.[\]]+/).filter(Boolean)
            const newValue: FormValues = { ...state }

            let target: any = newValue
            for (let i = 0; i < pathParts.length - 1; i++) {
                const part: keyof FormValues = pathParts[i] as any

                if (!target[part]) {
                    if (Number.isInteger(+pathParts[i + 1])) {
                        target[part] = []
                    } else {
                        target[part] = {}
                    }
                }
                target = target[part]
            }

            const lastPart: keyof typeof target = pathParts[pathParts.length - 1] as any
            if (lastPart === 'serviceLocation' || lastPart === 'clientLocation') {
                const locationObject = target[lastPart] ?? {}
                if (typeof action.value === 'string') {
                    locationObject.formattedAddress = action.value
                } else {
                    target[lastPart] = action.value
                }
            } else {
                target[lastPart] = action.value
            }

            return newValue
        }

        case 'UPDATE_PROJECT': {
            return {
                ...state,
                project: {
                    ...action.value,
                },
            }
        }

        case 'CREATE_NEW_OPPORTUNITY': {
            const initialOpportunityData = getInitialOpportunityData(action.newOppId, action.newOppName)
            return {
                ...state,
                project: {
                    ...state.project,
                    data: [...state.project.data, initialOpportunityData],
                },
            }
        }

        case 'DELETE_OPPORTUNITY': {
            return {
                ...state,
                project: {
                    ...state.project,
                    data: state.project.data.filter((opportunity) => opportunity.id !== action.id),
                },
            }
        }

        default:
            return state
    }
}
