import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { WarehouseInfo } from './index'
import WarehouseActions from './WarehouseActions'
import { Loader } from '@/components/Loader'

type UseWarehousesColumnsProps = {
    setPrimaryWarehouse: (warehouse: WarehouseInfo) => void
    primaryUpdateLoading: false | string
    fetchWarehousesInfo?: any
    admin?: boolean
}

export interface WarehousesList extends WarehouseInfo {
    isPrimary: boolean
}

const columnHelper = createColumnHelper<WarehousesList>()

const useWarehousesColumns = ({
    setPrimaryWarehouse,
    primaryUpdateLoading,
    fetchWarehousesInfo,
    admin,
}: UseWarehousesColumnsProps) => {
    const olimpTable = 'olimp-table'

    const mainColumns = [
        columnHelper.accessor('name', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Name</div>,
            enableSorting: true,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px' },
            },
        }),
        columnHelper.accessor('location', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Location</div>,
            enableSorting: true,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px' },
            },
        }),
        columnHelper.accessor(
            (row) => {
                const firstName = row.contact?.firstName ?? ''
                const lastName = row.contact?.lastName ?? ''
                return `${firstName} ${lastName}`.trim()
            },
            {
                id: 'primaryContact',
                cell: (info) => <div>{info.getValue() || '—'}</div>,
                header: () => <div>Primary Contact</div>,
                enableSorting: true,
                meta: {
                    cellStyle: { width: '333px', maxWidth: '333px' },
                },
            },
        ),
        columnHelper.accessor((row) => row.isPrimary ?? false, {
            id: 'primaryWarehouse',
            cell: (info) => {
                const checkboxId = `checkbox-${info.row.original.id}`

                return primaryUpdateLoading === info.row.original.id ? (
                    <Loader type="small" />
                ) : (
                    <div className={`${olimpTable}__checkbox-wrapper`}>
                        <input
                            className={`${olimpTable}__checkbox-input`}
                            id={checkboxId}
                            type="checkbox"
                            disabled={!!primaryUpdateLoading}
                            checked={info.getValue() || false}
                            onChange={() => setPrimaryWarehouse(info.row.original)}
                            name="isPrimary"
                        />
                        <label htmlFor={checkboxId} className={`${olimpTable}__checkbox-label`}></label>
                    </div>
                )
            },
            header: () => <div>Primary Warehouse</div>,
            enableSorting: true,
            meta: {
                cellStyle: { width: '165px', maxWidth: '165px' },
            },
        }),
        {
            id: 'actions',
            header: () => '',
            cell: (info: any) => (
                <WarehouseActions refreshWarehouses={fetchWarehousesInfo} warehouse={info.row.original} admin={admin} />
            ),
            meta: {
                cellStyle: { width: '165px', maxWidth: '165px' },
                cellClass: 'no-text-truncate actions-cell',
            },
        },
    ].filter(Boolean)

    return mainColumns
}

export default useWarehousesColumns
