import React from 'react'
import { LoadBoardPostBodyDto } from '@OlimpDev/lib/dist/apiv2/load-board'
import { FormikProps } from 'formik'

import { setMainStyle } from './helper'
import Button from '@/components/Button'
import { SubmittedButtonNameType, submittedButtonNames } from './types'
import Datepicker from '@/components/Datepicker'

export interface ILoadBoardContainer {
    title: string
    subTitle?: string
    children?: React.ReactNode
    submittedButtonName?: SubmittedButtonNameType
    isBiddingDate?: boolean
    cancelClick: (param?: any) => void
    biddingCloseDate?: string | Date
    setBiddingCloseDate?: any
    loading?: boolean
    formik: FormikProps<LoadBoardPostBodyDto>
    counter: { start?: number; end?: number }
}

export const LoadBoardContainer = ({
    title,
    subTitle,
    children,
    submittedButtonName = submittedButtonNames.SaveAsDraft,
    isBiddingDate = false,
    cancelClick,
    biddingCloseDate,
    setBiddingCloseDate,
    loading,
    formik,
    counter,
}: ILoadBoardContainer) => {
    const checkIfNotSubmitBtn =
        submittedButtonName === SubmittedButtonNameType.SaveAsDraft ? !formik.isValid || !formik.dirty : false

    return (
        <>
            <div className={'load-board'}>
                <div className={setMainStyle(`${counter?.start !== 1 ? '_left _hidden' : '_left'}`)}>
                    {submittedButtonName === submittedButtonNames.ViewPost ? (
                        <>
                            <h2 className={setMainStyle('_title_viewPost')}>Thank you for your submission!</h2>
                            <h3 className={setMainStyle('_subtitle_viewPost')}>
                                You will receive a confirmation email with details on your submission!
                            </h3>
                        </>
                    ) : (
                        <>
                            <h2 className={setMainStyle('_title')} style={{ marginBottom: 6 }}>
                                {title}
                            </h2>
                            {title && (
                                <h3 className={setMainStyle('_title')} style={{ fontSize: 20, marginBottom: 5 }}>
                                    {subTitle}
                                </h3>
                            )}
                        </>
                    )}
                    <div>{children}</div>
                </div>
                <div className={setMainStyle('_right')} style={{ marginTop: 10 }}>
                    {isBiddingDate && submittedButtonName === submittedButtonNames.SubmitToLoadBoard && (
                        <div style={{ marginBottom: 12, fontSize: 14 }}>
                            <p style={{ marginTop: 0, marginBottom: 5 }}>Bidding Close Date</p>

                            <Datepicker
                                start={biddingCloseDate as string}
                                hasReset={false}
                                range={false}
                                type="date"
                                past={false}
                                minInterval={1}
                                onChange={({ start }: any) => setBiddingCloseDate(start.$d)}
                                errorHide
                            />
                        </div>
                    )}

                    <Button
                        disabled={!formik.values.services || checkIfNotSubmitBtn}
                        style={{ width: '100%', marginBottom: 12 }}
                        types={['blue']}
                        type="button"
                        onClick={() => formik.handleSubmit()}
                        loading={loading || formik.isSubmitting}
                    >
                        {submittedButtonName}
                    </Button>
                    <Button style={{ width: '100%' }} types={['bordered']} onClick={cancelClick}>
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    )
}
