import React, { FC, useState, useRef } from 'react'
import { v4 } from 'uuid'
import useClickOutside from '@/hooks/useClickOutside'
import cn from 'classnames'

import { ReactComponent as DotsMenuIcon } from '@/assets/images/kebab-icon.svg'

import './DotsMenu.scss'

const CLASS_NAME = 'dots-menu'

interface DotsMenuProps {
    wrapperClassName?: string
    buttons: [
        {
            name: string
            className?: string
            onClick: () => void
            disabled?: boolean
        },
    ]
}

const DotsMenu: FC<DotsMenuProps> = ({ buttons, wrapperClassName }) => {
    const menuRef = useRef<HTMLDivElement>(null)

    const [isDotsMenuOpen, setIsDotsMenuOpen] = useState(false)

    useClickOutside(menuRef, () => {
        setIsDotsMenuOpen(false)
    })

    const toggleDotsMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()

        setIsDotsMenuOpen(!isDotsMenuOpen)
    }

    const hasActiveButtons = buttons.some((button) => !button.disabled)
    if (!hasActiveButtons) {
        return null
    }

    return (
        <div className={cn(`${CLASS_NAME}__more-btn-wrapper`, wrapperClassName)} ref={menuRef}>
            <button onClick={toggleDotsMenu} className={`${CLASS_NAME}__dots-menu-btn`}>
                <DotsMenuIcon className={`${CLASS_NAME}__dots-menu-icon`} />
            </button>
            {isDotsMenuOpen && (
                <ul className={`${CLASS_NAME}__dots-menu-items`}>
                    {buttons.map((button) => {
                        if (button.disabled) {
                            return null
                        }

                        return (
                            <li
                                key={v4()}
                                className={cn(`${CLASS_NAME}__dots-menu-item`, button.className)}
                                onClick={() => {
                                    setIsDotsMenuOpen(false)
                                    button.onClick()
                                }}
                            >
                                {button.name}
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}

export default DotsMenu
