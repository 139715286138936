import React, { FC, useEffect, Dispatch, SetStateAction } from 'react'

import TemplateToRender from '@/pages/RFQPortal/components/RfqTemplate/TemplateToRender'
import ReviewToRender from '@/pages/RFQPortal/components/RfqTemplate/ReviewToRender'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'
import {
    FormValues,
    FormValueTypes,
    SetErrorType,
    ValidateMainBoardLocation,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import './RfqTemplate.scss'

export const rfqTemplateClass = 'rfq-template'
export const headerClass = rfqTemplateClass + '__header'

export interface RfqTemplateProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    currentOppId: string
    manageOpportunity: ManageOpportunityType
    isExpanded: boolean
    setExpandedId?: (id: string | null) => void
    toggleTemplateExpansion?: (id: string | null) => void
    step: Steps
    changeStep: (step: Steps) => void
    errorMessages?: any
    setError: SetErrorType
    setValidateMainBoard: Dispatch<SetStateAction<ValidateMainBoardLocation[]>>
}

const RfqTemplate: FC<RfqTemplateProps> = ({
    formData,
    changeFormData,
    currentOppId,
    manageOpportunity,
    isExpanded,
    setExpandedId,
    toggleTemplateExpansion,
    step,
    changeStep,
    errorMessages,
    setError,
    setValidateMainBoard,
}) => {
    const oppIndex = formData.project.data.findIndex((opportunity) => opportunity.id === currentOppId)

    useEffect(() => {
        if (formData.project.single === false && setExpandedId && oppIndex === 0) {
            setExpandedId(currentOppId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return step === Steps.RFQ ? (
        <TemplateToRender
            formData={formData}
            toggleTemplateExpansion={toggleTemplateExpansion}
            currentOppId={currentOppId}
            changeFormData={changeFormData}
            oppIndex={oppIndex}
            isExpanded={isExpanded}
            manageOpportunity={manageOpportunity}
            step={step}
            errorMessages={errorMessages}
            setError={setError}
            setValidateMainBoard={setValidateMainBoard}
        />
    ) : (
        <ReviewToRender formData={formData} oppIndex={oppIndex} changeStep={changeStep} />
    )
}

export default RfqTemplate
