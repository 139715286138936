import React, { useEffect, useMemo } from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { OrderQuoteSubmit, OrderQuoteSubmitted } from './ui'
import { useGetRelatedAccounts } from '@/entities/accounts/api/useGetRelatedAccounts'
import { Loader } from '@/components/Loader'
import { TabsList, TabsRoot, TabsTrigger } from '@/shared/ui/Tabs'
import { Badge } from '@/shared/ui/Badge'
import { Select } from '@/shared/ui/Select'
import { QuoteType } from '@/entities/quotes/model'
import { useAppDispatch, useAppSelector } from '@/app/store'
import {
    revertToInitial,
    setEditMode,
    setQuoteType,
    setSelectedQuote,
    setSelectedWarehouse,
    setWarehouses,
} from './model'
import { OrderStageSelect } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/ui/OrderStageSelect'
import { cn } from '@/shared/utils/cn'

export const OrderQuoteSupply = () => {
    const dispatch = useAppDispatch()

    /** Revert to initial state once the page is unloaded */
    useEffect(() => {
        return () => {
            dispatch(revertToInitial())
        }
    }, [])

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quoteType = useAppSelector((state) => state.orderQuoteSupply.quoteType)
    const isEditMode = useAppSelector((state) => state.orderQuoteSupply.isEditMode)

    /** Warehouses logic */
    /** ================ */
    const { data, error, isValidating: isLoading } = useGetRelatedAccounts()

    /** Calculate options for select */
    const warehouses = useAppSelector((state) => state.orderQuoteSupply.warehouses)
    const warehousesOptions = useMemo(() => {
        if (!warehouses) return null

        return warehouses.map((w) => ({ value: w.id, label: w.name }))
    }, [warehouses])

    /** Get selected WH data */
    const selectedWarehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse)

    /** Get quote from selected WH, if there is any */
    const quoteFromWarehouse = useAppSelector((state) => state.orderQuoteSupply.selectedQuote)
    const isStandardQuote = quoteFromWarehouse?.type === QuoteType.STANDARD

    /** Get selected WH data */
    const onWarehouseSelect = (accountId: string) => {
        dispatch(setSelectedWarehouse(accountId))
        const quote = order.bids.find((bid) => bid.warehouseId === accountId)
        dispatch(setSelectedQuote(quote || null))
        dispatch(setEditMode(false))
    }

    /** Save related accounts in state */
    useEffect(() => {
        if (!isLoading && !error && data) {
            dispatch(setWarehouses(data))

            let warehouseToSelect: string | null = null

            const primaryWH = data.find((item) => item.isPrimary)
            const companyAccount = data.find((item) => item.isCmpAcc)

            if (primaryWH) {
                warehouseToSelect = primaryWH.id
            } else if (companyAccount) {
                warehouseToSelect = companyAccount.id
            } else if (data.length > 0) {
                warehouseToSelect = data[0].id
            }

            if (warehouseToSelect) {
                onWarehouseSelect(warehouseToSelect)
            }
        }
    }, [isLoading, data, error])

    /** UI */
    /** ================ */
    if (isLoading || error || !warehouses) {
        return <Loader />
    }

    return (
        <div className="space-y-5">
            <div className={cn('flex md:flex-row gap-5', quoteFromWarehouse ? 'flex-col' : 'flex-col-reverse')}>
                {order.status !== LoadboardOrderStatus.CLOSED && !quoteFromWarehouse ? (
                    <TabsRoot value={quoteType} onValueChange={(v) => dispatch(setQuoteType(v))} className="shrink-0">
                        <TabsList className="w-full md:w-auto" variant="filled">
                            <TabsTrigger value="standard" className="w-full md:w-auto gap-2" disabled>
                                <div>
                                    Standard
                                    <span className="hidden sm:inline">&nbsp;Quote</span>
                                </div>
                                <Badge className="bg-background-secondary text-foreground-primary px-1 h-[22px] rounded-lg border border-primary hover:bg-background-secondary">
                                    {/*New*/}
                                    Soon
                                </Badge>
                            </TabsTrigger>
                            <TabsTrigger value="allIn" className="w-full md:w-auto">
                                All In
                                <span className="hidden sm:inline">&nbsp;Quote</span>
                            </TabsTrigger>
                        </TabsList>
                    </TabsRoot>
                ) : null}
                <Select
                    options={warehousesOptions ?? []}
                    label="Bidding As"
                    value={selectedWarehouse?.id ?? ''}
                    onValueChange={onWarehouseSelect}
                    wrapperClassName="w-full"
                />
                {/** Uncomment for Standard Quote */}
                {/*{order.status !== LoadboardOrderStatus.CLOSED && quoteFromWarehouse ? <OrderStageSelect /> : null}*/}
            </div>

            {!selectedWarehouse ? (
                <Loader />
            ) : (
                <>
                    {order.status === LoadboardOrderStatus.CLOSED ? null : !quoteFromWarehouse ? (
                        <div>
                            <p className="text-2xl font-semibold">Submit your pricing</p>
                            <p>Add your charges for the service(s) requested below</p>
                        </div>
                    ) : isEditMode ? (
                        <div className="text-2xl font-semibold">
                            Update your {isStandardQuote ? 'pricing' : 'quote'}
                        </div>
                    ) : null}

                    {quoteFromWarehouse ? <OrderQuoteSubmitted /> : <OrderQuoteSubmit />}
                </>
            )}
        </div>
    )
}
