import useSWR from 'swr'
import { matchPath, useParams } from 'react-router-dom'
import { useLocation } from 'react-router'

export interface MicrisiteData {
    accountId: string
    accountName: string
    aeCampaignId: string
    aeFormHandler: string
    clientName: string
    createdAt: string
    distributionMembers: string[]
    id: string
    notes: string
    olimpDistributionEmail: string
    olimpSupportPhone: string
    partnerCode: string
    partnerCodeName: string
    site: string
    updatedAt: string
}

export const useMicrosite = () => {
    const { pathname } = useLocation()
    const match = matchPath({ path: '/ms/:slug/rfq/*' }, pathname) || ({} as any)
    const { params } = match

    const resp = useSWR(
        params && params.slug ? '/api/v2/accounts/microsite/' + params.slug.toLowerCase() : null,
        async (url) => {
            const response = await fetch(url)
            return response.json()
        },
        {
            revalidateOnFocus: false,
        },
    )
    return {
        ...resp,
        slug: params && params.slug,
    }
}
