import { useState } from 'react'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { toast } from 'react-toastify'
import { PermissionLevel, ManageStaffTableRecord } from '@/parts/Modals/ManageStaffModal'

export const usePermissions = () => {
    const [isPermissionsLoading, setIsPermissionsLoading] = useState<boolean>(true)
    const [userPermissionsSending, setUserPermissionsSending] = useState<boolean>(false)

    const fetchUserPermissions = async (
        contactId: string,
        relatedWarehouses: any[],
        isAdmin?: boolean,
    ): Promise<ManageStaffTableRecord[]> => {
        setIsPermissionsLoading(true)

        try {
            const { data: permissions } = await HttpV2.get(`accounts/contacts/${contactId}/permissions`)

            const tableData = relatedWarehouses.map((prevData) => {
                const existingPermissionLevel = permissions.find(
                    (item: any) => item.accountId === prevData.id,
                )?.permissionLevel
                return {
                    ...prevData,
                    permission: existingPermissionLevel ?? undefined,
                    visibility: Boolean(existingPermissionLevel) || false,
                }
            })

            if (isAdmin) {
                return setSuperAdminPermissions(tableData)
            }
            return tableData
        } catch (error: any) {
            console.log('Error fetching permissions:', error)
            return []
        } finally {
            setIsPermissionsLoading(false)
        }
    }

    const postPermissions = async (
        contact_id: string,
        isSuperAdmin: boolean,
        mSTableData: ManageStaffTableRecord[],
    ) => {
        try {
            await HttpV2.post(`accounts/contacts/${contact_id}/permissions`, {
                superAdmin: isSuperAdmin,
                permissions: mSTableData
                    .filter((item) => Boolean(item.permission) && item.visibility)
                    .map((item: any) => ({
                        accountId: item.id,
                        permissionLevel: item.permission,
                    })),
            })
        } catch (error) {
            console.log('Error posting permissions:', error)
            throw error
        }
    }

    const updateUserPermissions = async (
        contact_id: string,
        isSuperAdmin: boolean,
        mSTableData: ManageStaffTableRecord[],
        fetchPermissionsCallback: () => void,
    ) => {
        setUserPermissionsSending(true)

        if (mSTableData) {
            try {
                await postPermissions(contact_id, isSuperAdmin, mSTableData)
                toast('User settings updated successfully', { type: 'success' })
                fetchPermissionsCallback()
            } catch (error: any) {
                toast(error?.response?.data?.message || 'Error updating permissions', { type: 'error' })
            } finally {
                setUserPermissionsSending(false)
            }
        }
    }

    const setSuperAdminPermissions = (tableData: ManageStaffTableRecord[]) => {
        const permissionKeys = Object.keys(PermissionLevel).filter((key) => isNaN(Number(key)))
        const lastPermissionKey = permissionKeys[permissionKeys.length - 1]
        const lastPermissionValue = PermissionLevel[lastPermissionKey as keyof typeof PermissionLevel]

        return tableData.map((item) => ({
            ...item,
            permission: lastPermissionValue,
            visibility: true,
        }))
    }

    return {
        isPermissionsLoading,
        userPermissionsSending,
        fetchUserPermissions,
        updateUserPermissions,
        setSuperAdminPermissions,
    }
}
