import React from 'react'
import { DialogClose, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from '@/shared/ui/Dialog'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { quotesApi } from '@/entities/quotes/api'
import { useAppDispatch } from '@/app/store'
import { orderPageActions } from '@/pages/Order/model'
import { toast } from 'react-toastify'

type AcceptModalProps = {
    open: boolean
    onOpenChange: (open: boolean) => void

    quoteRef: React.MutableRefObject<Quote | null>
    order: LoadboardOrder

    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

export const AcceptModal = ({ open, onOpenChange, quoteRef, order, isLoading, setIsLoading }: AcceptModalProps) => {
    const dispatch = useAppDispatch()

    /** Accept quote logic */
    const onAcceptQuote = async () => {
        const quote = quoteRef.current
        if (!quote || isLoading) return

        setIsLoading(true)

        try {
            await quotesApi.acceptQuote({ orderKey: order.id, quoteId: quote.id })

            dispatch(orderPageActions.acceptQuote(quote.id))

            toast('Quote has been accepted.', { type: 'success' })

            onOpenChange(false)
        } catch (e) {
            console.error(e)
            toast('We could not accept this Quote for you.', { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    if (!quoteRef.current) {
        return null
    }

    return (
        <DialogRoot
            open={open}
            onOpenChange={(newOpen) => {
                quoteRef.current = null
                onOpenChange(newOpen)
            }}
        >
            <DialogContent>
                <div className="space-y-3">
                    <DialogHeader>
                        <DialogTitle>Accept Quote?</DialogTitle>
                    </DialogHeader>
                    <div className="bg-background-tertiary px-3 py-2 rounded-lg space-y-3">
                        <div className="font-normal">
                            {quoteRef.current.type === QuoteType.STANDARD ? <>Minimum:&nbsp;</> : <>Total:&nbsp;</>}
                            {quoteRef.current.price ? (
                                <span className="font-semibold text-primary text-[20px]">{quoteRef.current.price}</span>
                            ) : (
                                <span className="text-foreground-secondary font-medium">N/A</span>
                            )}
                        </div>
                    </div>
                    <DialogFooter>
                        <DialogClose asChild>
                            <Button variant="outlined" disabled={isLoading}>
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button onClick={onAcceptQuote} disabled={isLoading}>
                            Accept {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
                        </Button>
                    </DialogFooter>
                </div>
            </DialogContent>
        </DialogRoot>
    )
}
