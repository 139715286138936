import React, { FC, useEffect, useState } from 'react'

import Button from '@/components/Button'
import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'
import { useNavigate } from 'react-router-dom'
import { navigateToWpPage } from '@/pages/RFQPortal/helpers/rfqHelper'
import rfqListPreviewLink from '@/pages/RFQPortal/assets/img/rfqListPreview.jpg'

interface StepThankYouProps {
    navigateToNewRfq: () => void
}

const StepThankYou: FC<StepThankYouProps> = ({ navigateToNewRfq }) => {
    const rfqOrderClass = 'rfq-order'
    const stepThankYouClass = rfqOrderClass + '__step-thank-you'

    const [showLogInActionBanner, setShowLogInActionBanner] = useState<boolean>()

    const navigate = useNavigate()
    const { wpContextData } = useWpData()
    const { siteData } = wpContextData || {}

    const navigateToRfqOrders = () => {
        if (siteData?.access_token) {
            navigate('/rfq/list')
        } else {
            navigateToWpPage(siteData?.CLIENT_SLUG + '-log-in')
        }
    }

    useEffect(() => {
        setShowLogInActionBanner(siteData?.showRfqListLink && !siteData?.access_token)
    }, [])

    return (
        <div className={stepThankYouClass}>
            <h3 className={`${stepThankYouClass}__title`}>Thank you for your request!</h3>
            <p className={`${stepThankYouClass}__text`}>
                Our Partner Success Team will be in contact shortly.<br className="new-line"></br> For immediate
                assistance call us at <span className={`${stepThankYouClass}__phone`}>773-570-3481</span>.
            </p>
            <div className={`${stepThankYouClass}-btns`}>
                {!showLogInActionBanner && (
                    <Button
                        types={['bordered', 'inverse']}
                        className={`${stepThankYouClass}_btn-bordered`}
                        onClick={navigateToRfqOrders}
                    >
                        View Your Orders
                    </Button>
                )}
                <Button types={['blue']} onClick={navigateToNewRfq}>
                    New RFQ
                </Button>
            </div>
            {showLogInActionBanner && (
                <div className={`${stepThankYouClass}_banner`}>
                    <div className={`${stepThankYouClass}_banner-header`}>
                        <h3 className={`${stepThankYouClass}_banner-label`}>
                            Sign in or create an account to see your order details and status
                        </h3>
                        <Button
                            types={['bordered', 'inverse']}
                            className={`${stepThankYouClass}_btn-bordered`}
                            onClick={navigateToRfqOrders}
                        >
                            Sign in
                        </Button>
                    </div>
                    <img
                        src={rfqListPreviewLink}
                        alt="Your orders list"
                        className={`${stepThankYouClass}_banner-img`}
                    />
                </div>
            )}
            <div></div>
        </div>
    )
}

export default StepThankYou
