import React from 'react'
import { flexRender, HeaderGroup } from '@tanstack/react-table'
import cn from 'classnames'

import { ReactComponent as ArrowDown } from '@/assets/images/arrow-down.svg'
import { ReactComponent as ArrowUp } from '@/assets/images/arrow-up.svg'

type ModernTableHeaderType = {
    headerGroup: HeaderGroup<any>
    headerClassName?: string
}

export const ModernTableHeader = ({ headerGroup, headerClassName = '' }: ModernTableHeaderType) => {
    return (
        <tr className={`olimp-table__header-row ${headerClassName}`} key={headerGroup.id}>
            {headerGroup.headers.map((header: any) => (
                <th
                    key={header.id}
                    className={cn('olimp-table__header-cell', {
                        'olimp-table__header-cell--sorted': header.column.getCanSort(),
                    })}
                    onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                >
                    {header.isPlaceholder ? null : (
                        <>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() &&
                                (header.column.getIsSorted() === 'asc' ? (
                                    <span className="olimp-table__header-cell-sorting">
                                        <ArrowUp />
                                    </span>
                                ) : (
                                    <span className="olimp-table__header-cell-sorting">
                                        <ArrowDown />
                                    </span>
                                ))}
                        </>
                    )}
                </th>
            ))}
        </tr>
    )
}
