import React, { FC } from 'react'
import { ReactComponent as EditIcon } from '@/pages/RFQPortal/assets/img/edit.svg'

import './EditButton.scss'

interface EditButtonProps {
    action: () => void
}

const editButtonClass = 'edit-button'

const EditButton: FC<EditButtonProps> = ({ action }) => {
    return (
        <button className={`${editButtonClass}`} onClick={action}>
            <EditIcon />
            <span className={`${editButtonClass}__text`}>Edit</span>
        </button>
    )
}

export default EditButton
