import React, { CSSProperties, memo } from 'react'
import cn from 'classnames'
import './Icon.scss'

interface IIcon {
    style?: CSSProperties
    type: string
    code?: string
    className?: string
}

const Icon: React.FC<IIcon> = memo(({ style, type, code, className }) => (
    <span className={cn('icon', `type-${type}`, code && `icon-${code}`, className)} style={style} />
))

export const ServiceIcon: React.FC<{ url: string; style?: any; title?: string; size?: number }> = ({
    url,
    style,
    size,
    title,
}) => {
    const [first, last] = title?.split(' ') ?? ['Service']
    if (!url) {
        return (
            <img
                src={
                    'https://ui-avatars.com/api/?background=ffffff&color=000&color=416283&name=S&font-size=0.6&rounded=true&length=2'
                }
                height={size ?? 16}
                alt="service-icon"
                style={style}
            />
        )
    }
    return (
        <img
            src={url}
            alt="service-icon"
            style={{
                ...(style ?? {}),
                maxHeight: size ?? 16,
                maxWidth: size ?? 16,
            }}
        />
    )
}

Icon.displayName = 'Icon'

export default Icon
