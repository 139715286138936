import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import './SubHeader.scss'

const subHeaderClass = 'sub-header'

export interface LinkSettings {
    label: string
    path: string
    className: string
}

interface SubHeaderProps {
    title?: string
    wrapperClass?: string
    links: LinkSettings[]
}

const SubHeader: FC<SubHeaderProps> = ({ title, links, wrapperClass = '', ...rest }) => {
    return (
        <div className={`app-page-container app-page-container_wrapper ${subHeaderClass} ${wrapperClass}`}>
            <h2 className={`${subHeaderClass}__title navigation__entity-label-text`}>{title}</h2>
            <nav className={`${subHeaderClass}__menu`}>
                <ul className={`${subHeaderClass}__menu-list`}>
                    {links.map((item, index) => (
                        <li key={index} className={`${subHeaderClass}__menu-item ${item.className}`} {...rest}>
                            <Link to={item.path}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )
}

export default SubHeader
