import React, { FC } from 'react'
import { Routes, Navigate, Route } from 'react-router-dom'
import ROUTES from '@/router'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import Account from './index'

export const AccountRoutes: FC = () => {
    return (
        <div className="app-page-container app-page-container_wrapper">
            <LoggedInRoute />
            <Routes>
                <Route path="/settings/*" element={<Account />} />
                <Route path="/" element={<Navigate to={`${ROUTES.account.base}${ROUTES.account.settings}`} />} />
            </Routes>
        </div>
    )
}
