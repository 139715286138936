import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { getSWRFetcher } from '@shared/apiv2/api'
import { Navigate, useParams } from 'react-router-dom'
import WarehouseSchedule from '@/pages/Warehouses/General/WarehouseSchedule'
import Sidebar from '../Sidebar'
import General from './General'
import PrimaryContact from './PrimaryContact'

export default function WarehousesGeneral() {
    const [isManager, setIsManager] = useState(false)
    const { id: warehouseId } = useParams()

    const { data, error, revalidate, isValidating } = useSWR(
        warehouseId ? `/accounts/${warehouseId}/warehouse` : null,
        getSWRFetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: true,
        },
    )

    useEffect(() => {
        if (data) {
            setIsManager(data.permission >= 2)
        }
    }, [data])

    if (!warehouseId) {
        return <Navigate to={'/warehouses'} />
    }

    return (
        <div className="app-page-container app-page-container_wrapper app-page-container_wrapper--no-margin">
            <div className="app-page-container_flex">
                <div className="app-page">
                    <section className="app-card">
                        <General
                            warehouseId={warehouseId}
                            revalidate={revalidate}
                            data={data}
                            loading={isValidating}
                            manager={isManager}
                        />
                    </section>
                    <section className="app-card">
                        <PrimaryContact
                            warehouseId={warehouseId!}
                            contact={data?.contact}
                            reload={revalidate}
                            manager={isManager}
                            loading={isValidating}
                        />
                    </section>
                    <section className="app-card">
                        <WarehouseSchedule loading={isValidating} warehouseId={warehouseId} manager={isManager} />
                    </section>
                </div>
                <div className="app-sidebar">
                    <Sidebar manager={isManager} />
                </div>
            </div>
        </div>
    )
}
