import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { createQuickSession, resend, SessionRequestDto, verify, VerifyRequestDto } from '@shared/apiv2/quick-session'
import { useInfo } from '@shared/api/useDataHooks'
import { useCountdown } from '@/hooks/useCountdown'
import Checkbox from '@/components/Checkbox'
import { stripeAgreementLabel } from '@/parts/Forms/Register/RegisterForm'
import { useOneTimeSessionData } from '@/hooks/useOneTimeSessionData'
import './bookNoRegisterModal.scss'
import { getOLIMPLandingURL } from '@/utils/url'

interface BookNoRegisterModalForm {
    firstName?: string
    lastName?: string
    phone?: string
    email?: string
    usdot?: string
    agreement: boolean
    receiveSms: boolean
    company?: string
    warehouseId: number
    filters?: string
}

const BookNoRegisterModalSchema = Yup.object().shape({
    agreement: Yup.bool().oneOf([true], 'Field must be checked'),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email('Invalid email').required('Required'),
    usdot: Yup.string().min(5, 'USDOT has to be at least 5 digits'),
    company: Yup.string(),
    receiveSms: Yup.boolean(),
})

interface BookNoRegisterModalProps {
    filters: string | undefined
    warehouseId: number
    distance: number
    onBookNoRegisterModal?: (msg: string) => Promise<void> | void
}

const CodeVerifyModalSchema = Yup.object().shape({
    code: Yup.string().required(),
})

const BookNoRegisterModal: FC<BookNoRegisterModalProps> = ({ onBookNoRegisterModal, warehouseId, filters }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { update } = useOneTimeSessionData('signupValues')
    const {
        state: { userInfo },
    } = useUserState()
    const { data: info, isLoading } = useInfo()
    const [hash, setHash] = useState()
    const redirectBooking = () => {
        ModalRegistry.get().close(Modals.ModalsBookNoRegisterModal)
        navigate(`/booking/${hash}`)
    }
    const { start, counter } = useCountdown({ seconds: 5, onComplete: redirectBooking })
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [step, setStep] = useState<number>(0)
    const [verifyId, setVerifyId] = useState<number>(0)
    const formikRegistration = useFormik<BookNoRegisterModalForm>({
        initialValues: {
            agreement: false,
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            phone: userInfo?.phone ? String(userInfo?.phone) : '',
            email: userInfo?.email,
            company: '',
            usdot: '',
            receiveSms: false,
            warehouseId,
            filters,
        },
        validationSchema: BookNoRegisterModalSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')
            try {
                formikRegistration.setSubmitting(true)
                const quickSession = await createQuickSession({
                    ...values,
                    phone: `+${values.phone}`,
                } as SessionRequestDto)
                formikRegistration.setSubmitting(false)
                const { id } = quickSession.data
                setStep(1)
                setVerifyId(id)
            } catch (errors: any) {
                formikRegistration.setSubmitting(false)

                const errorData = errors?.response?.data ?? null

                if (errorData.error === 'email_already_registered') {
                    navigate(
                        `/login?email=${values.email}&message=You already have an account, please sign in to proceed with booking&booking=${values.filters}&bookingWh=${values.warehouseId}`,
                    )
                    ModalRegistry.get().close(Modals.ModalsBookNoRegisterModal)
                } else if (errorData.errors) {
                    for (const errField of Object.keys(errorData.errors)) {
                        formikRegistration.setFieldError(errField, errorData.errors[errField])
                    }
                } else if (errorData.message) {
                    setErrorMessage(errorData.message)
                }
            }
        },
    })
    const formikCode = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: CodeVerifyModalSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')
            try {
                formikCode.setSubmitting(true)
                const { data } = await verify(verifyId, values as VerifyRequestDto)
                formikCode.setSubmitting(false)
                setHash(data.hash)
                setStep(2)
            } catch (errors: any) {
                formikCode.setSubmitting(false)
                if (errors?.message) {
                    setErrorMessage(errors?.message)
                }
            }
        },
    })

    useEffect(() => {
        if (hash) {
            start()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])

    const regValues = formikRegistration.values
    const goAndSignUp = useCallback(() => {
        update({
            email: regValues.email ?? '',
            phone: (regValues.phone ?? '').substring(1),
            firstName: regValues.firstName ?? '',
            lastName: regValues.lastName ?? '',
            usdot: regValues.usdot ?? '',
            company: regValues.company ?? '',
        })
        ModalRegistry.get().close(Modals.ModalsBookNoRegisterModal)
        navigate('/sign-up')
    }, [
        navigate,
        regValues.company,
        regValues.email,
        regValues.firstName,
        regValues.lastName,
        regValues.phone,
        regValues.usdot,
        update,
    ])

    const resendCode = useCallback(async () => {
        setErrorMessage('')
        try {
            formikCode.setSubmitting(true)
            await resend(verifyId)
            formikCode.setSubmitting(false)
        } catch (errors: any) {
            formikCode.setSubmitting(false)
            if (errors?.message) {
                setErrorMessage(errors?.message)
            }
        }
    }, [verifyId, formikCode])

    const firstStep = (
        <form key={'firstStep'} onSubmit={formikRegistration.handleSubmit}>
            <InputText
                text
                required
                label="First name"
                name="firstName"
                onChange={formikRegistration.handleChange}
                value={regValues.firstName}
                error={formikRegistration.errors.firstName}
            />
            <InputText
                text
                required
                label="Last name"
                name="lastName"
                onChange={formikRegistration.handleChange}
                value={regValues.lastName}
                error={formikRegistration.errors.lastName}
            />
            <InputText
                required
                label="Phone number"
                name="phone"
                onChange={formikRegistration.handleChange}
                value={regValues.phone}
                error={formikRegistration.errors.phone}
                format="+# (###) ### ####"
            />

            <label htmlFor="receiveSms" className="receiveSms">
                <Checkbox
                    onChange={formikRegistration.handleChange}
                    name="receiveSms"
                    className={'receiveSms-box'}
                    value={regValues.receiveSms}
                    label={'Receive SMS notifications?'}
                />
            </label>
            <InputText
                text
                label="E-mail"
                name="email"
                required
                onChange={formikRegistration.handleChange}
                value={regValues.email}
                error={formikRegistration.errors.email}
            />
            <InputText
                required={Boolean(info?.public_booking_usdot_required)}
                name="usdot"
                isNumericString
                onChange={formikRegistration.handleChange}
                value={regValues.usdot}
                error={formikRegistration.errors.usdot}
                errorHide={false}
                textError
                placeholder="USDOT or MC/MX#"
                label="USDOT or MC/MX#"
            />
            <InputText
                text
                label="Company"
                name="company"
                onChange={formikRegistration.handleChange}
                value={regValues.company}
                error={formikRegistration.errors.company}
            />

            <Checkbox
                name="agreement"
                onChange={formikRegistration.handleChange}
                error={formikRegistration.errors.agreement}
                value={regValues.agreement}
                label={
                    <>
                        By creating this booking you agree to{' '}
                        <a rel="noreferrer" href={getOLIMPLandingURL('terms-and-conditions')} target="_blank">
                            OLIMP&apos;s Terms of Use
                        </a>
                        {stripeAgreementLabel}
                    </>
                }
            />
            {/*{(info?.public_fee ?? 0) > 0 && (*/}
            {/*    <div className="info">*/}
            {/*        Registered users receive {info.public_fee}% better rates. Continue to receive standard rates.*/}
            {/*    </div>*/}
            {/*)}*/}
            {errorMessage && <p className="error">{errorMessage}</p>}
            <div className="btns">
                <Button
                    label="Continue"
                    disabled={!formikRegistration.isValid}
                    loading={formikRegistration.isSubmitting}
                    types={['blue', 'large']}
                    type="submit"
                />
                {!formikRegistration.isSubmitting && (
                    <Button label="Sign Up" types={['plain', 'bordered', 'large']} onClick={goAndSignUp} />
                )}
            </div>
        </form>
    )

    const secondStep = (
        <form key={'secondStep'} onSubmit={formikCode.handleSubmit}>
            <div style={{ width: '150px' }}>
                <InputText
                    isNumericString
                    label="Code"
                    name="code"
                    onChange={formikCode.handleChange}
                    value={formikCode.values.code}
                    error={formikCode.errors.code}
                    mask="######"
                    maxLength={6}
                />
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <div>
                Didn&apos;t receive a code?{' '}
                <span className={'link link-blue'} onClick={resendCode}>
                    Resend
                </span>
            </div>
            <div className="btns">
                <Button
                    label="Send code"
                    disabled={!formikCode.isValid}
                    loading={formikCode.isSubmitting}
                    types={['blue', 'large']}
                    type="submit"
                />
            </div>
        </form>
    )

    return (
        <div>
            {step === 0 && firstStep}
            {step === 1 && secondStep}
            {step === 2 && (
                <div>
                    <p>Booking access link was sent to your email</p>
                    {hash && <small>You will be redirected to booking page in {counter} seconds ...</small>}
                </div>
            )}
        </div>
    )
}

ModalRegistry.get().register<BookNoRegisterModalProps>(Modals.ModalsBookNoRegisterModal, {
    id: 'BookNoRegisterModalProps',
    className: 'modal-book-request',
    size: 'medium',
    Component: memo(BookNoRegisterModal),
    title: 'Book Now',
})

export const showBookNoRegisterModal = (props: ModalPartialProps<BookNoRegisterModalProps>): void =>
    ModalRegistry.get().show<BookNoRegisterModalProps>(Modals.ModalsBookNoRegisterModal, props)

export default memo(BookNoRegisterModal)
