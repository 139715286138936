import React, { FC } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { defaultSWRConfig, getSWRFetcher } from '@shared/apiv2/api'
import { Loader } from '@/components/Loader'
import { useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'

export const Invites: FC = () => {
    const { state, dispatch } = useUserState()
    const { key } = useParams<{ key?: string }>()
    const { data, error } = useSWR(key ? `/invites/${key}` : null, getSWRFetcher, defaultSWRConfig)
    const loading = !data && !error
    if (!key) {
        return <Navigate to={'/'} />
    }

    if (loading) {
        return <Loader type="list" />
    }

    if (data) {
        localStorage.setItem('targetAppUrl', data.targetAppUrl)
        localStorage.setItem('inviteKey', key)
        // should be removed on sign up or login
        if (data.needsSignup) {
            dispatch!({ type: UserActionType.LOGOUT })
            return <Navigate to={'/sign-up?email=' + data.email + '&lb_invite=' + key} />
        }
        if (data.targetAppUrl && state.isLoggedIn) {
            return <Navigate to="/" />
        }
        return <Navigate to={'/login?email=' + data.email} />
    }

    return <Navigate to={'/'} />
}
