import React, { memo } from 'react'
import cn from 'classnames'
import { SortingState } from '@tanstack/react-table'
import { LBPostStatus, LoadBoardListFilters, LoadBoardListResponse } from '@shared/apiv2/load-board'

import { InputText } from '@/components/InputText'
import { ReactComponent as SearchIcon } from '@/assets/images/search.svg'
import { ReactComponent as FilterIcon } from '@/assets/images/filter.svg'

import Select from '@/components/Select'
import { LoadBoardList } from './LoadBoardList'
import InputLabel from '@/components/InputLabel'
import Search from '@/components/Search'

type LoadBoardTableType = {
    CLASS_NAME: string
    search: string
    handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    isCarrier: boolean
    sorting: SortingState
    handleSortChange: (param: SortingState) => void
    data: LoadBoardListResponse | undefined
    filters: LoadBoardListFilters
    pagination: { pageIndex: number; pageSize: number }
    setPagination: any
    setFilters: (params: any) => any
    error: any
    tabValue: string | number | undefined
    setShowFilter: (params: boolean) => any
    showFilter: boolean
}

export const LoadBoardTable = memo(
    ({
        CLASS_NAME,
        search,
        handleSearchChange,
        isCarrier,
        sorting,
        handleSortChange,
        data,
        filters,
        pagination,
        setPagination,
        setFilters,
        error,
        tabValue,
        setShowFilter,
        showFilter,
    }: LoadBoardTableType) => {

        return (
            <div className={`${CLASS_NAME}__data ${showFilter ? 'active' : ''}`}>
                <div className={`${CLASS_NAME}__inputs`}>
                    <InputText
                        text
                        iconNode={<SearchIcon />}
                        value={search}
                        onChange={handleSearchChange}
                        name="search"
                        placeholder="Search"
                        errorHide
                        className={`${CLASS_NAME}__inputDescktop`}
                    />
                    <div className={`${CLASS_NAME}__mobileWrap`}>
                        <InputLabel
                            name="search"
                            label="Search"
                            iconNode={<SearchIcon />}
                            className={`${CLASS_NAME}__inputMobile`}
                            value={search}
                            onChange={handleSearchChange}
                            // errorMessage={formik.errors.name}
                        />
                        <FilterIcon onClick={() => setShowFilter(!showFilter)} />
                    </div>
                </div>
                <LoadBoardList
                    sorting={sorting}
                    onSortingChange={handleSortChange}
                    data={data?.data}
                    total={data?.count}
                    showDistance={filters.searchAroundMe || !!(filters.address && filters.address !== '')}
                    pagination={pagination}
                    onPaginationChange={(pag) => {
                        setPagination(pag)
                        return pag
                    }}
                    error={error}
                    tabValue={tabValue}
                />
            </div>
        )
    },
)
