import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SignUpRegisterStep } from '@/pages/SignUp/steps/SignUpRegisterStep'
import { useCompanyState } from '@/store/company'
import { useUserState } from '@/store/user'
import { ManagerDetailsStep } from '@/pages/SignUp/steps/ManagerDetailsStep'
import { useLocation, useNavigate } from 'react-router'
import { CompanyInfoStep } from '@/pages/SignUp/steps/CompanyInfoStep'
import { VerificationStep } from '@/pages/SignUp/steps/VerificationStep'
import { RegistrationReadyStep } from './steps/RegistrationReadyStep'
import { SignUpCarrierStep } from '@/pages/SignUp/steps/carrier/SignUpCarrierStep'
import { SignUpWarehouseStep } from './steps/warehouse/SignUpWarehouseStep'
import { SignUpWarehouseAddressStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseAddressStep'
import { SignUpWarehouseWorkingHoursStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseWorkingHoursStep'
import { SignUpWarehouseLocalDeliveryStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseLocalDeliveryStep'
import { SignUpWarehouseUSDOTStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseUSDOTStep'
import { SignUpWarehouseTaxNumberStep } from './steps/warehouse/SignUpWarehouseTaxNumberStep'
import { SignUpWarehouseDoneStep } from '@/pages/SignUp/steps/warehouse/SignUpWarehouseDoneStep'
import { SignUpCarrierDoneStep } from './steps/carrier/SignUpCarrierDoneStep'
import { entityTypes } from '@shared/types'
import { utils } from '@shared/common/utils'
import { UserActionType } from '@/store/user/user'
import { ssoUrl } from '@/router'

// eslint-disable-next-line complexity
const SignUp: React.FC = () => {
    const { state: companyState } = useCompanyState()
    const { state: userState, dispatch } = useUserState()
    const { signUp } = userState
    const location = useLocation()
    if (!userState.isLoggedIn || !userState.userInfo?.verified) {
        window.location.href = `${ssoUrl}/${window.location.search}`
        return null
    }

    if (userState.isLoggedIn && userState.userInfo?.verified) {
        return <Navigate to="/" />
    }
    return null
}

export default SignUp
