import React from 'react'
import { Attachment, LoadboardOrder } from '@/entities/loadboard-orders/model'
import { mediaDocumentIcons, mediaImageExtensions } from '@/shared/lib/media'
import { Button } from '@/shared/ui/Button'
import {
    DialogRoot,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/shared/ui/Dialog'
import { InDownload2 } from '@/shared/ui/Icons/InDownload2'

type OrderDetailsFilesProps = {
    order: LoadboardOrder
}

export const OrderDetailsFiles = ({ order }: OrderDetailsFilesProps) => {
    return (
        <div>
            <div className="font-semibold">Uploaded Files</div>
            {order.attachments.length > 0 ? (
                <div className="mt-1 grid grid-cols-2 md:grid-cols-4 gap-3">
                    {order.attachments.map((attachment, index) => {
                        const splittedAttachment = attachment.filename.split('.')
                        const extension = splittedAttachment[splittedAttachment.length - 1]

                        return mediaImageExtensions.includes(extension) ? (
                            <ImageFile key={index} attachment={attachment} />
                        ) : (
                            <DocumentFile key={index} attachment={attachment} extension={extension} />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

type DocumentFileProps = {
    attachment: Attachment
    extension: string
}

export const DocumentFile = ({ attachment, extension }: DocumentFileProps) => {
    const onDocumentClick = () => {
        window.open(attachment.link, '_blank')
    }

    return (
        <Button
            variant="plain"
            className="border border-border rounded-lg overflow-hidden flex flex-col items-start"
            onClick={onDocumentClick}
        >
            <div className="h-28 w-full flex items-center justify-center">
                <img
                    src={mediaDocumentIcons[extension] as any}
                    alt={attachment.filename}
                    className="h-16 w-auto object-cover rounded-t-lg"
                />
            </div>
            <div className="px-2 py-1 text-[12px]">{attachment.filename}</div>
        </Button>
    )
}

type ImageFileProps = {
    attachment: Attachment
}

export const ImageFile = ({ attachment }: ImageFileProps) => {
    const onDownloadClick = () => {
        window.open(attachment.link, '_blank')
    }

    return (
        <DialogRoot>
            <DialogTrigger asChild>
                <Button
                    variant="plain"
                    className="border border-border rounded-lg overflow-hidden flex flex-col items-start"
                >
                    <div className="h-28 w-full">
                        <img src={attachment.link} alt="adsa" className="h-full w-full object-cover rounded-t-lg" />
                    </div>
                    <div className="px-2 py-1 text-[12px]">{attachment.filename}</div>
                </Button>
            </DialogTrigger>
            <DialogContent className="flex flex-col">
                <DialogHeader>
                    <DialogTitle>{attachment.filename}</DialogTitle>
                </DialogHeader>
                <img
                    src={attachment.link}
                    alt={attachment.filename}
                    className="h-full w-full object-cover rounded-lg"
                    style={{ maxHeight: 'calc(100vh - 200px)' }}
                />
                <DialogFooter>
                    <Button onClick={onDownloadClick} variant="outlined" className="gap-2">
                        Download
                        <InDownload2 size={16} />
                    </Button>
                    <DialogClose asChild>
                        <Button>Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    )
}
