import React, { FC, useState, useEffect } from 'react'

import Input from '@/pages/RFQPortal/components/Input'
import './CalculateSpaceModal.scss'

import { useRfqModal, ModalId } from '@/pages/RFQPortal/contexts/RfqModalProvider'
import Modal from '@/pages/RFQPortal/components/Modal/Modal'

const modalClass = 'calculateSpaceModalContent'

interface CalculateSpaceModalProps {}

function calculateSquareFootage(palletsHigh: number, palletLength: number, palletWidth: number, totalPallets: number) {
    const areaPerPallet = palletLength * palletWidth
    const groundLevelPallets = totalPallets / palletsHigh
    const totalSquareFootage = groundLevelPallets * areaPerPallet
    return totalSquareFootage
}

const CalculateSpaceModal: FC<CalculateSpaceModalProps> = () => {
    const { currentModal, closeModal } = useRfqModal()

    const [totalSpace, setTotalSpace] = useState<number>()
    const [palletsHigh, setPalletsHigh] = useState<number>(0)
    const [palletLength, setPalletLength] = useState<number>(0)
    const [palletWidth, setPalletWidth] = useState<number>(0)
    const [totalPallets, setTotalPallets] = useState<number>(0)

    useEffect(() => {
        if (palletsHigh && palletLength && palletWidth && totalPallets) {
            setTotalSpace(
                Number(calculateSquareFootage(palletsHigh, palletLength, palletWidth, totalPallets).toFixed(2)),
            )
        } else {
            setTotalSpace(0)
        }
    }, [palletsHigh, palletLength, palletWidth, totalPallets])

    const handleCloseModal = () => {
        setTotalSpace(undefined)
        setPalletsHigh(0)
        setPalletLength(0)
        setPalletWidth(0)
        setTotalPallets(0)

        closeModal()
    }

    return (
        <Modal
            title="Space Needed Calculator"
            show={currentModal === ModalId.CalculateSpace}
            onClose={handleCloseModal}
        >
            <div className={`${modalClass}`}>
                <div className={`${modalClass}__row`}>
                    <p className={`${modalClass}__title`}>Can your pallets be stacked?</p>
                    <Input
                        id=""
                        label="How many Pallets High?"
                        type="number"
                        min="0"
                        onChange={(e) => setPalletsHigh(Number(e.target.value))}
                    />
                </div>
                <div className={`${modalClass}__row`}>
                    <p className={`${modalClass}__title`}>Pallet Size (Standard pallet size: 48” x 40”)</p>
                    <div className={`${modalClass}__size`}>
                        <Input
                            id=""
                            label="L”"
                            type="number"
                            min="0"
                            onChange={(e) => setPalletLength(Number(e.target.value))}
                        />
                        <Input
                            id=""
                            label="W”"
                            type="number"
                            min="0"
                            onChange={(e) => setPalletWidth(Number(e.target.value))}
                        />
                    </div>
                </div>
                <div className={`${modalClass}__row`}>
                    <p className={`${modalClass}__title`}>Maximum amount of pallets to be stored</p>
                    <Input
                        id=""
                        label="Pallets #"
                        type="number"
                        min="0"
                        onChange={(e) => setTotalPallets(Number(e.target.value))}
                    />
                </div>
                <div className={`${modalClass}__footer`}>
                    <p className={`${modalClass}__title`}>Based on your information, you will need:</p>
                    <p className={`${modalClass}__result`}>{totalSpace || '-'} sq. ft.</p>
                </div>
            </div>
        </Modal>
    )
}

export default CalculateSpaceModal
