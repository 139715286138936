import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Separator } from '@/shared/ui/Separator'

const NON_ACCESSORIAL_PRICINGS = [RfqServicePricing.Drayage, RfqServicePricing.LineHaul, RfqServicePricing.Chassis]

export const DrayageBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        return (
            <div className="flex gap-3 items-center">
                {service.pricings[RfqServicePricing.Drayage]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.Drayage]!.price).toLocaleString('en')}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3 w-full">
            <div>
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.LineHaul]}
                </div>
                {service.pricings[RfqServicePricing.LineHaul]?.price ? (
                    <div className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.LineHaul]!.price).toLocaleString('en')}
                    </div>
                ) : (
                    <div className="text-foreground-secondary font-medium">N/A</div>
                )}
            </div>
            <div>
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.Chassis]}
                </div>
                {service.pricings[RfqServicePricing.Chassis]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <span className="text-xl leading-6 text-primary font-semibold">
                            ${Number(service.pricings[RfqServicePricing.Chassis]!.price).toLocaleString('en')}
                        </span>
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            Day
                        </span>
                    </div>
                ) : (
                    <div className="text-foreground-secondary font-medium">N/A</div>
                )}
            </div>

            <Separator />

            {Object.entries(service.pricings)
                .filter(([key]) => !NON_ACCESSORIAL_PRICINGS.includes(key as RfqServicePricing))
                .map(([key, value], index) => {
                    const pricingName = key as RfqServicePricing
                    return (
                        <div key={index} className="flex gap-2 h-7 items-center">
                            <div className="w-[154px] text-sm leading-[18px] text-foreground-secondary font-medium">
                                {RfqServicePricingLabel[pricingName]}
                            </div>
                            <div>
                                {value.price ? (
                                    <div className="text-xl leading-6 text-primary font-semibold">
                                        ${Number(value.price).toLocaleString('en')}
                                    </div>
                                ) : (
                                    <div className="text-foreground-secondary font-medium">N/A</div>
                                )}
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
