// CheckBox.tsx
import React from 'react'
import cn from 'classnames'

import './CheckboxNew.scss'

interface CheckBoxProps {
    label?: string
    checked: boolean
    onChange: (checked: boolean) => void
    disabled?: boolean
    name?: string
    className?: string
    id?: string
    readOnly?: boolean
    roundLabel?: boolean
    lightStyle?: boolean
}

const CheckBoxNew: React.FC<CheckBoxProps> = ({
    label,
    checked,
    onChange,
    readOnly = false,
    disabled = false,
    lightStyle = false,
    name,
    className,
    roundLabel = false,
    ...rest
}) => {
    if (readOnly) {
        return <label className={cn('checkbox-new', className, { round: roundLabel, readOnly })}>{label}</label>
    }
    return (
        <label className={cn('checkbox-new', className, { round: roundLabel })}>
            <input
                type="checkbox"
                className={'checkbox-new__input'}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
                name={name}
                {...rest}
            />
            <span
                className={cn('checkbox-new__label', {
                    'checkbox-new__label--light': lightStyle,
                })}
            >
                {label}
            </span>
        </label>
    )
}

export default CheckBoxNew
