/* eslint-disable no-unused-vars */
import React, { FC } from 'react'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { FormValues, FormValueTypes, SubmitRfqResponse, SetErrorType } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'

import SidebarHeader from '@/pages/RFQPortal/parts/Sidebar/SidebarHeader'
import FormToRender from '@/pages/RFQPortal/parts/Sidebar/FormToRender'
import SidebarButtons from '@/pages/RFQPortal/parts/Sidebar/SidebarButtons'
import ReviewToRender from '@/pages/RFQPortal/parts/Sidebar/ReviewToRender'
import MobileFixedSection from '@/pages/RFQPortal/parts/Sidebar/MobileFixedSection'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'
import './Sidebar.scss'
import '@/pages/RFQPortal/components/RfqTemplate/RfqTemplate.scss'

interface SidebarProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    step: Steps
    changeStep: (type: 'increment' | 'decrement') => void
    setCurrentStep: (step: Steps) => void
    errorMessages: any
    setError: SetErrorType
    submissionError: string
    isSubmiting: boolean
    setValidateSidebarFunc: any
    validateForm: () => Promise<boolean>
}

export enum Status {
    SOLUTION = 'Solution',
    DETAILS = 'Details',
    // REVIEW = 'Review',
}

const Sidebar: FC<SidebarProps> = ({
    formData,
    changeFormData,
    step,
    changeStep,
    setCurrentStep,
    errorMessages,
    setError,
    submissionError,
    isSubmiting,
    setValidateSidebarFunc,
    validateForm,
}) => {
    const { wpContextData } = useWpData()
    const { siteData } = wpContextData || {}
    const {
        CLIENT_NAME: clientName,
        CLIENT_SLUG: clientSlug,
        SITES_WITH_REGISTRATION: sitesWithRegistration,
        access_token: accessToken,
        CLIENT_NAMES_WITH_REGISTRATION: clientNamesWithRegistration,
        publicSites,
    } = siteData || {}

    let status: Status
    switch (step) {
        case Steps.CHOOSE_SOLUTION:
            status = Status.SOLUTION
            break
        // case Steps.REVIEW:
        //     status = Status.REVIEW
        //     break
        default:
            status = Status.DETAILS
    }

    return (
        // return status !== Status.REVIEW ? (
        <FormToRender
            formData={formData}
            changeFormData={changeFormData}
            errorMessages={errorMessages}
            setError={setError}
            sitesWithRegistration={sitesWithRegistration}
            clientSlug={clientSlug}
            accessToken={accessToken}
            publicSites={publicSites}
            clientName={clientName}
            clientNamesWithRegistration={clientNamesWithRegistration}
            setValidateSidebarFunc={setValidateSidebarFunc}
        >
            {{
                header: <SidebarHeader status={status} />,
                buttons: (
                    <>
                        <SidebarButtons
                            step={step}
                            changeStep={changeStep}
                            setCurrentStep={setCurrentStep}
                            submissionError={submissionError}
                            validateForm={validateForm}
                            errorMessages={errorMessages}
                            isSubmiting={isSubmiting}
                        />
                        <MobileFixedSection
                            setCurrentStep={setCurrentStep}
                            step={step}
                            changeStep={changeStep}
                            submissionError={submissionError}
                            status={status}
                            validateForm={validateForm}
                            errorMessages={errorMessages}
                            isSubmiting={isSubmiting}
                        />
                    </>
                ),
            }}
        </FormToRender>

        // ) : (
        // <ReviewToRender formData={formData} changeStep={changeStep} submissionError={submissionError}>
        //     {{
        //         header: <SidebarHeader status={status} />,
        //         buttons: (
        //             <>
        //                 <SidebarButtons
        //                     setCurrentStep={setCurrentStep}
        //                     step={step}
        //                     changeStep={changeStep}
        //                     submissionError={submissionError}
        //                     // isSubmiting={isSubmiting}
        //                     errorMessages={errorMessages}
        //                 />
        //                 <MobileFixedSection
        //                     setCurrentStep={setCurrentStep}
        //                     step={step}
        //                     changeStep={changeStep}
        //                     submissionError={submissionError}
        //                     // isSubmiting={isSubmiting}
        //                     status={status}
        //                 />
        //             </>
        //         ),
        //     }}
        // </ReviewToRender>
    )
}

export default Sidebar
