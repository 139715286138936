import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InRework = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.8802 7.09902C19.7972 6.82253 19.3585 6.73013 19.1072 6.9312L16.9188 8.68213L14.5432 8.68212V6.78163L16.7316 5.03071C16.9829 4.8297 16.8674 4.47863 16.5219 4.4123C15.8446 4.28211 15.1114 4.27255 14.3515 4.41798C12.5948 4.75417 11.2241 5.94798 10.9439 7.37507C10.7951 7.96808 10.8666 8.56041 11.0896 9.09557L5.99816 13.1687C5.19429 13.8118 5.19429 14.854 5.99816 15.4969C6.37437 15.8193 6.90038 16 7.40181 16C7.90323 16 8.45497 15.8392 8.85719 15.5177L13.9486 11.4445C14.6193 11.6215 15.3583 11.6787 16.1261 11.5818C17.9102 11.3568 19.4022 10.26 19.8225 8.85575C20.0546 8.22651 20.0432 7.64104 19.8802 7.09902ZM7.65623 14.7193C7.27745 14.7193 6.97014 14.4734 6.97014 14.1704C6.97014 13.8672 7.27745 13.6215 7.65623 13.6215C8.03502 13.6215 8.34233 13.8672 8.34233 14.1704C8.34233 14.4746 8.03502 14.7193 7.65623 14.7193Z" />
                <path d="M0 0.616284C0 0.27592 0.3449 0 0.770355 0H2.31107C2.73652 0 3.08142 0.27592 3.08142 0.616284V1.06449C3.08142 1.40486 3.42632 1.68078 3.85178 1.68078C4.27723 1.68078 4.62213 1.40486 4.62213 1.06449V0.616284C4.62213 0.27592 4.96703 0 5.39249 0H6.9332C7.35865 0 7.70355 0.27592 7.70355 0.616284V5.54656C7.70355 5.88692 7.35865 6.16284 6.9332 6.16284H0.770356C0.3449 6.16284 0 5.88692 0 5.54656V0.616284Z" />
                <path d="M9.24426 0.616284C9.24426 0.27592 9.58916 0 10.0146 0H11.5553C11.9808 0 12.3257 0.27592 12.3257 0.616284V1.06449C12.3257 1.40486 12.6706 1.68078 13.096 1.68078C13.5215 1.68078 13.8664 1.40486 13.8664 1.06449V0.616284C13.8664 0.27592 14.2113 0 14.6368 0H16.1775C16.6029 0 16.9478 0.27592 16.9478 0.616284V3.2274C16.9261 3.22264 16.9041 3.21812 16.8819 3.21385C15.9711 3.03882 14.9912 3.02819 13.993 3.21925C12.0735 3.58661 10.5094 4.70378 9.78546 6.13512C9.47191 6.05707 9.24426 5.82308 9.24426 5.54656V0.616284Z" />
                <path d="M7.70355 10.0613V8.01169C7.70355 7.67133 7.35865 7.39541 6.9332 7.39541H5.39249C4.96703 7.39541 4.62213 7.67133 4.62213 8.01169V8.4599C4.62213 8.80027 4.27723 9.07619 3.85178 9.07619C3.42632 9.07619 3.08142 8.80027 3.08142 8.4599V8.01169C3.08142 7.67133 2.73652 7.39541 2.31107 7.39541H0.770355C0.3449 7.39541 0 7.67133 0 8.01169V12.942C0 13.2823 0.3449 13.5583 0.770356 13.5583H3.98664C4.14735 13.0964 4.45473 12.6603 4.90872 12.2971L7.70355 10.0613Z" />
            </svg>
        </IconBase>
    )
}
