/* eslint-disable no-unused-vars */
// U.S. states
export enum usStates {
    AL = 'Alabama',
    AK = 'Alaska',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    FL = 'Florida',
    GA = 'Georgia',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PA = 'Pennsylvania',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming',
}

// Canadian provinces and territories
export enum canadaProvinces {
    AB = 'Alberta',
    BC = 'British Columbia',
    MB = 'Manitoba',
    NB = 'New Brunswick',
    NL = 'Newfoundland and Labrador',
    NS = 'Nova Scotia',
    NT = 'Northwest Territories',
    NU = 'Nunavut',
    ON = 'Ontario',
    PE = 'Prince Edward Island',
    QC = 'Quebec',
    SK = 'Saskatchewan',
    YT = 'Yukon',
}

export function getFullStateName(abbreviation: string): string | null {
    const stateName = usStates[abbreviation as keyof typeof usStates]
    if (stateName) return stateName

    return canadaProvinces[abbreviation as keyof typeof canadaProvinces] || null
}

export function getShortStateName(name: string): string | null {
    const allStates = { ...usStates, ...canadaProvinces }
    return Object.keys(allStates).find((key) => allStates[key as keyof typeof allStates] === name) || null
}
