import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { cn } from '@/shared/utils/cn'
import { InCheckmark2 } from '@/shared/ui/Icons/InCheckmark2'

export type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    indicator?: React.ReactNode
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
    ({ className, indicator, ...props }, ref) => (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                'peer h-5 w-5 p-0 shrink-0 rounded border border-solid border-border ring-offset-background text-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-colors duration-75 data-[state=checked]:bg-primary data-[state=checked]:text-white',
                className,
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current" forceMount>
                {indicator !== undefined ? indicator : <InCheckmark2 size={12} />}
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    ),
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
