import * as React from 'react'

export type IconContext = {
    color?: string
    size?: string
    className?: string
    style?: React.CSSProperties
    attr?: React.SVGAttributes<SVGElement>
}

export const DefaultContext: IconContext = {
    color: undefined,
    size: undefined,
    className: undefined,
    style: undefined,
    attr: undefined,
}

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
export const IconContext: React.Context<IconContext> = React.createContext && React.createContext(DefaultContext)

export type IconProps = React.SVGAttributes<SVGElement> & {
    size?: string | number
    color?: string
    title?: string
}

export type IconBaseProps = IconProps & {
    children: React.ReactElement
}

export function IconBase({ children, ...props }: IconBaseProps) {
    const elem = (conf: IconContext) => {
        const attr = children.props
        const { size = 24, title, ...svgProps } = props
        const computedSize = size || conf.size || '1em'
        let className
        if (conf.className) className = conf.className
        if (props.className) className = (className ? className + ' ' : '') + props.className

        return (
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                {...conf.attr}
                {...attr}
                {...svgProps}
                className={className}
                style={{
                    color: props.color || conf.color,
                    ...conf.style,
                    ...props.style,
                }}
                height={computedSize}
                width={computedSize}
                xmlns="http://www.w3.org/2000/svg"
            >
                {title && <title>{title}</title>}
                {children.props.children}
            </svg>
        )
    }

    return IconContext !== undefined ? (
        <IconContext.Consumer>{(conf: IconContext) => elem(conf)}</IconContext.Consumer>
    ) : (
        elem(DefaultContext)
    )
}
