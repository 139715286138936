import React, { FC, useEffect, useState } from 'react'
import './VerifyEmail.scss'
import { Loader } from '@/components/Loader'
import { updateLoggedState, useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'
import Button from '@/components/Button'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { InputText } from '@/components/InputText'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const VerifyEmail: FC = () => {
    // token is url query param
    const token = new URLSearchParams(window.location.search).get('token')
    const navigate = useNavigate()
    const { state, dispatch } = useUserState()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const resendVerificationLink = () => {}

    const initiateVerification = async () => {
        if (!token) {
            setError('Verification Code is missing.')
            return
        }
        try {
            setLoading(true)
            const { data: verificationData } = await HttpV2.post<{ token: string; user: any }>(
                '/auth/email-verification',
                {
                    verificationCode: token,
                },
            )

            if (verificationData) {
                setHttpV2Token(verificationData.token)
                updateLoggedState(verificationData.user, dispatch)
                toast('Email verified successfully.', { type: 'success' })
                navigate('/')
            }
        } catch (e: any) {
            setError(e.response?.data?.verificationCode || 'Something went wrong.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (state.isLoggedIn) {
            dispatch!({ type: UserActionType.LOGOUT })
        }
        initiateVerification()
    }, [])
    const signin = () => {
        navigate('/login')
    }
    return (
        <div className="verify-email-page">
            <div className="verify-box">
                <div className="verify-box__title">Verifying your email</div>
                <div className="verify-box__description">
                    {loading ? (
                        <>
                            <div className="verify-box__loader">
                                <Loader type="medium" />
                            </div>
                            <div className="verify-box__description__text">Please wait...</div>
                            <div className="verify-box__description__text">We are verifying your email address.</div>
                        </>
                    ) : error ? (
                        <>
                            <div className="verify-box__description__text">
                                We couldn&apos;t verify your email address due to
                            </div>
                            <div className="verify-box__description__error">{error}</div>
                            <div className="verify-box__description__text">
                                Please Sign In and resend verification link.
                            </div>
                            <Button types={['blue']} className="verify-box__description__button" onClick={signin}>
                                Sign In
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}
