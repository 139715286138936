import React, { useEffect } from 'react'

import Datepicker from '@/components/Datepicker'
import { setMainStyle } from '../helper'
import { ILoadBoardItemsProps } from '../types'
import SelectTime from '@/components/SelectTime'
import { InputText } from '@/components/InputText'

export const Drayage = ({ setDisabled, formik }: ILoadBoardItemsProps) => {
    useEffect(() => {
        const { inDate, outDate, timeFrom, timeTo } = formik.values.drayage || {}
        if (inDate && outDate && timeFrom && timeTo) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [setDisabled, formik.values.drayage])

    return (
        <div style={{ width: 808 }} className="drayage">
            <span style={{ fontSize: 16, fontWeight: 500 }}>Estimated Arrival Time (ETA)</span>
            {/* from to */}
            <div className="drayage_wrap">
                <div className="drayage_row">
                    <SelectTime
                        value={formik.values.drayage?.timeFrom}
                        onAction={(event) => formik.setFieldValue('drayage.timeFrom', event.value)}
                        name="timeFrom"
                        label="From"
                    />
                    <span className="drayage_row_span">Central Daylight Time (UTC-5)</span>
                </div>
                <div style={{ flex: 1 }}>
                    <SelectTime
                        // disabled={requestInProgress}
                        value={formik.values.drayage?.timeTo}
                        onAction={(event) => {
                            formik.setFieldValue('drayage.timeTo', event.value)
                            console.log(event)
                        }}
                        name="timeFrom"
                        label="To"
                    />
                </div>
            </div>

            {/* Port Terminal */}
            <div className="drayage_wrap">
                <div className="drayage_row">
                    <InputText
                        text
                        className={setMainStyle('_input')}
                        // style={{ width: '808px' }}
                        label="Port"
                        value={formik.values.drayage?.port}
                        name={'port'}
                        placeholder="placeholder"
                        onChange={(event) => formik.setFieldValue('drayage.port', event.target.value)}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <InputText
                        text
                        className={setMainStyle('_input')}
                        // style={{ width: '808px' }}
                        label="Terminal"
                        value={formik.values.drayage?.terminal}
                        name={'port'}
                        placeholder="placeholder"
                        onChange={(event) => formik.setFieldValue('drayage.terminal', event.target.value)}
                    />
                </div>
            </div>

            <div className={setMainStyle('_warehousing_dates')}>
                <div style={{ flex: 1, flexDirection: 'row', marginRight: 12, maxWidth: 400 }}>
                    <div className={setMainStyle('_title_wh')} style={{ marginBottom: 0 }}>
                        In/Out Dates
                    </div>
                    <div className={setMainStyle('_title_wh')} style={{ fontSize: 14 }}>
                        Start Date
                    </div>
                    <Datepicker
                        start={formik.values.drayage?.inDate as any}
                        hasReset={false}
                        range={false}
                        type="date"
                        past={false}
                        minInterval={1}
                        onChange={({ start }: any) => formik.setFieldValue('drayage.inDate', new Date(start.$d))}
                        errorHide
                    />
                </div>
                <div style={{ flex: 1, maxWidth: 400 }}>
                    <div className={setMainStyle('_title_wh')}>End Date</div>
                    <Datepicker
                        start={formik.values.drayage?.outDate as any}
                        hasReset={false}
                        range={false}
                        type="date"
                        past={false}
                        minInterval={1}
                        onChange={({ start }: any) => formik.setFieldValue('drayage.outDate', new Date(start.$d))}
                        errorHide
                    />
                </div>
            </div>
        </div>
    )
}
