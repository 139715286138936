import React, { FC, useState, useEffect, Dispatch, SetStateAction, useRef } from 'react'
import cn from 'classnames'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'
import {
    FormValues,
    FormValueTypes,
    SetErrorType,
    ValidateMainBoardLocation,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import { toast } from 'react-toastify'

import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import RfqDatepicker from '@/pages/RFQPortal/components/RfqDatepicker'
import Input from '@/pages/RFQPortal/components/Input'
import Textarea from '@/pages/RFQPortal/components/Textarea'
import Select from '@/pages/RFQPortal/components/Select'
import Checkbox from '@/pages/RFQPortal/components/Checkbox'
import RadioInput from '@/pages/RFQPortal/components/RadioInput'
import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'
import { useRfqModal, ModalId } from '@/pages/RFQPortal/contexts/RfqModalProvider'

import { ReactComponent as DeleteButton } from '@/pages/RFQPortal/assets/img/delete-btn.svg'
import { ReactComponent as CollapseBtn } from '@/pages/RFQPortal/assets/img/chevron-up.svg'

import useValidationRules from '@/pages/RFQPortal/hooks/useValidationRules'
import { isProdEnv } from '@/pages/RFQPortal/helpers/rfqHelper'

import { rfqTemplateClass, headerClass } from '@/pages/RFQPortal/components/RfqTemplate'
import TemplateTitle from '@/pages/RFQPortal/components/RfqTemplate/TemplateTitle'
import { useMobileWidth } from '@shared/common/hooks'

interface TemplateToRenderProps {
    formData: FormValues
    toggleTemplateExpansion?: (id: string | null) => void
    currentOppId: string
    changeFormData: (path: string, value: FormValueTypes) => void
    oppIndex: number
    isExpanded: boolean
    manageOpportunity: ManageOpportunityType
    step: Steps
    errorMessages?: any
    setError: SetErrorType
    setValidateMainBoard: Dispatch<SetStateAction<ValidateMainBoardLocation[]>>
}

export const freightEnvironment: string[] = ['Dry', 'Temp Controlled', 'Freeze']
const certifications: string[] = ['Bonded', 'Hazmat', 'Food Grade']
const unitTypes: string[] = [
    'Pallet - Standard',
    'Floor Loaded',
    'Crate',
    'Paper Roll',
    'Non-Standard Freight',
    'Slip Sheet',
    'Carpet Roll',
    'Carton',
    'Drum/Barrel',
    'Floor Loaded Unit',
    'Freight Bale',
    'Freight Weight (lb)',
    'Gaylord',
    'Load',
    'Square Feet',
    'Super Sack',
    'Tires',
    'Pallet - Oversized',
]
const spaceNeeded: string[] = [
    'I am not sure',
    '< 5,000 sq. ft.',
    '5,000 - 10,000 sq. ft.',
    '10,000 - 20,000 sq. ft.',
    '20,000 - 40,000 sq. ft.',
    '40,000 + sq. ft.',
]

const TemplateToRender: FC<TemplateToRenderProps> = ({
    formData,
    toggleTemplateExpansion,
    currentOppId,
    changeFormData,
    oppIndex,
    isExpanded,
    manageOpportunity,
    step,
    errorMessages,
    setError,
    setValidateMainBoard,
}) => {
    const servicesNeededWrapperRef = useRef<HTMLDivElement>(null)
    const freightEnvWrapperRef = useRef<HTMLDivElement>(null)
    const certificationsWrapperRef = useRef<HTMLDivElement>(null)
    const serviceLocationWrapperRef = useRef<HTMLDivElement>(null)
    const serviceDateStartWrapperRef = useRef<HTMLDivElement>(null)
    const serviceDateEndWrapperRef = useRef<HTMLDivElement>(null)
    const commodityWrapperRef = useRef<HTMLDivElement>(null)
    const loadContainerNumberWrapperRef = useRef<HTMLDivElement>(null)
    const unitTypeWrapperRef = useRef<HTMLDivElement>(null)
    const numberOfUnitsWrapperRef = useRef<HTMLDivElement>(null)
    const spaceNeededWrapperRef = useRef<HTMLDivElement>(null)
    const notesWrapperRef = useRef<HTMLDivElement>(null)

    const isMobile = useMobileWidth()

    const { openModal } = useRfqModal()

    const [durationErrorModalShown, setDurationErrorModalShown] = useState<boolean>(false)
    const [showDurationErrorModal, setShowDurationErrorModal] = useState<boolean>(false)

    const {
        validateRequiredInput,
        validateRequiredCheckbox,
        validateMaxLength,
        validateGoogleLocation,
        validateNumberInput,
        validateDateInput,
    } = useValidationRules()

    const [projectLocationNumber, setLocationNumber] = useState<string>(
        !formData.project.single ? (oppIndex + 1).toString() : '',
    )

    useEffect(() => {
        const newLocationNumber = !formData.project.single ? (oppIndex + 1).toString() : ''
        setLocationNumber(newLocationNumber)
    }, [formData.project.single, oppIndex])

    const { wpContextData } = useWpData()
    const { siteData } = wpContextData || {}
    const { CLIENT_SLUG: clientSlug } = siteData || {}

    const [servicesNeeded, setServicesNeeded] = useState([
        'Cross-Docking',
        'Rework / Restack',
        'Storage',
        'Transloading',
        'Final Mile / Local Delivery',
        'Drayage',
        'Disposal',
        'Yard Storage / Trailer Parking',
    ])

    const [disabledServices, setDisabledServices] = useState<string[]>([])
    const [servicesTooltips, setServicesTooltips] = useState<any>()

    useEffect(() => {
        if (clientSlug === 'arrive') {
            setServicesNeeded([
                'Cross-Docking',
                'Rework / Restack',
                'Storage',
                'Transloading',
                'Disposal',
                'Yard Storage / Trailer Parking',
            ])
        }
    }, [clientSlug])

    const projectData = formData.project.data[oppIndex]
    const currentOppError: any = errorMessages?.opportunities[currentOppId]

    const handleHeaderClick = () => {
        if (formData.project.single === false && toggleTemplateExpansion) {
            toggleTemplateExpansion(currentOppId)
        }
    }

    const handleServicesChange = (isChecked: boolean, inputValue: string) => {
        const opportunityFieldName = 'servicesNeeded'

        // if (inputValue === 'Cross-Docking') {
        //     const allowedOptions = ['Final Mile / Local Delivery']

        //     const currentAllowedOptions = projectData[opportunityFieldName].filter((currentOption) =>
        //         allowedOptions.includes(currentOption),
        //     )

        //     changeFormData(`project.data[${oppIndex}].${opportunityFieldName}`, currentAllowedOptions)
        // }

        changeFormData(
            `project.data[${oppIndex}].${opportunityFieldName}`,
            isChecked
                ? [...projectData[opportunityFieldName], inputValue]
                : projectData[opportunityFieldName].filter((element) => element !== inputValue),
        )
    }

    const handleCertificationsChange = (isChecked: boolean, inputValue: string) => {
        const opportunityFieldName = 'certifications'

        changeFormData(
            `project.data[${oppIndex}].${opportunityFieldName}`,
            isChecked
                ? [...projectData[opportunityFieldName], inputValue]
                : projectData[opportunityFieldName].filter((element) => element !== inputValue),
        )
    }

    const handleFreightEnvChange = (item: string) => {
        const opportunityFieldName = 'freightEnvironment'

        changeFormData(`project.data[${oppIndex}].${opportunityFieldName}`, item)
    }

    const handleFreightEnvInputClick = (item: string) => {
        const opportunityFieldName = 'freightEnvironment'

        // Allow to uncheck input
        if (projectData[opportunityFieldName] === item) {
            changeFormData(`project.data[${oppIndex}].${opportunityFieldName}`, '')
        }
    }

    const validateServicesNeeded = (): boolean => {
        const fieldLabel = 'Services Needed*'
        const path = 'servicesNeeded'
        const value = projectData.servicesNeeded

        if (
            !validateRequiredCheckbox(
                fieldLabel,
                path,
                value,
                setError,
                servicesNeededWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                currentOppId,
            )
        ) {
            return false
        }

        return true
    }

    const validateServiceLocation = (): boolean => {
        const serviceLocationObject = formData.project.data[oppIndex].serviceLocation

        if (serviceLocationObject) {
            const fieldLabel = 'Service Location*'
            const path = 'serviceLocation'

            if (
                !validateGoogleLocation(
                    fieldLabel,
                    path,
                    serviceLocationObject,
                    setError,
                    serviceLocationWrapperRef.current as HTMLDivElement,
                    projectLocationNumber,
                    currentOppId,
                )
            ) {
                return false
            }
        } else {
            return false
        }

        return true
    }

    // eslint-disable-next-line complexity
    const validateServiceDate = (type = 'both'): boolean => {
        if (type === 'both' || type === 'start') {
            if (
                !validateRequiredInput(
                    'Start Date*',
                    'serviceDate.start',
                    projectData.serviceDate?.start || '',
                    setError,
                    serviceDateStartWrapperRef.current as HTMLDivElement,
                    projectLocationNumber,
                    currentOppId,
                )
            ) {
                return false
            }
        }

        const fieldLabel = 'End Date*'
        const path = 'serviceDate.end'
        const dateStart = projectData.serviceDate?.start || ''
        const dateEnd = projectData.serviceDate?.end || ''

        if (type === 'both' || type === 'end') {
            if (
                !validateRequiredInput(
                    fieldLabel,
                    path,
                    dateEnd,
                    setError,
                    serviceDateEndWrapperRef.current as HTMLDivElement,
                    projectLocationNumber,
                    currentOppId,
                )
            ) {
                return false
            }
        }

        if (
            !validateDateInput(
                fieldLabel,
                path,
                dateStart,
                setError,
                serviceDateEndWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                currentOppId,
                dateEnd,
            )
        ) {
            return false
        }

        const serviceDuration = calculateDifference(dateStart, dateEnd)

        if (serviceDuration >= 32 - 1 && formData.project.single) {
            setError(
                'serviceDate.end',
                {
                    message: 'Duration Exceeded. Use Project.',
                    wrapperEl: serviceDateEndWrapperRef.current as HTMLDivElement,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: 'End Date*',
                },
                currentOppId,
            )
            setShowDurationErrorModal(true)
            return false
        }

        return true
    }

    useEffect(() => {
        const serviceDuration = calculateDifference(
            projectData.serviceDate.start || '',
            projectData.serviceDate.start || '',
        )

        if (
            showDurationErrorModal &&
            !durationErrorModalShown &&
            serviceDuration >= 32 - 1 &&
            formData.project.single
        ) {
            setDurationErrorModalShown(true)

            toast(
                <div>
                    <span>This is a Project Order as the service dates are ≥ 32 days. </span>
                    <a
                        style={{ color: '#fff', textDecoration: 'underline' }}
                        href="/"
                        onClick={(e) => {
                            e.preventDefault()
                            transferDataToProject()
                        }}
                    >
                        Click
                    </a>
                    <span> to update in the Project Form.</span>
                </div>,
                {
                    type: 'error',
                    autoClose: false,
                    onClose: () => {
                        setDurationErrorModalShown(false)
                        setShowDurationErrorModal(false)
                    },
                    closeButton: <span style={{ fontSize: '18px', color: 'white' }}>X</span>,
                },
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDurationErrorModal])

    const transferDataToProject = () => {
        changeFormData('project.single', false)

        toast('Your data has been successfully transferred to the Project RFQ', {
            type: 'success',
            autoClose: 7000,
        })

        setTimeout(() => {
            validateMainBoardForm()
        }, 50)
    }

    const validateNumberOfUnits = (): boolean => {
        const fieldLabel = '# of Units*'
        const path = 'numberOfUnits'
        const value = projectData.numberOfUnits

        if (
            !validateRequiredInput(
                fieldLabel,
                path,
                value,
                setError,
                numberOfUnitsWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                currentOppId,
            )
        ) {
            return false
        }

        if (
            !validateNumberInput(
                fieldLabel,
                path,
                value,
                setError,
                numberOfUnitsWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                1,
                currentOppId,
            )
        ) {
            return false
        }

        return true
    }

    const validateCommodity = (): boolean => {
        const fieldLabel = 'Commodity'
        const path = 'commodity'
        const value = projectData.commodity

        if (
            !validateMaxLength(
                fieldLabel,
                path,
                value,
                setError,
                commodityWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                undefined,
                currentOppId,
            )
        ) {
            return false
        }

        return true
    }

    const validateLoadContainerNumber = (): boolean => {
        const fieldLabel = 'Load/Container #'
        const path = 'loadNumber'
        const value = projectData.loadNumber

        if (
            !validateMaxLength(
                fieldLabel,
                path,
                value,
                setError,
                loadContainerNumberWrapperRef.current as HTMLDivElement,
                projectLocationNumber,
                undefined,
                currentOppId,
            )
        ) {
            return false
        }

        return true
    }

    const validateMainBoardForm = (): boolean => {
        const mainBoardValidationResults = [
            validateServicesNeeded(),
            validateServiceLocation(),
            validateServiceDate(),
            validateCommodity(),
            validateLoadContainerNumber(),
            validateNumberOfUnits(),
        ]

        return mainBoardValidationResults.every((result) => result === true)
    }

    useEffect(() => {
        setValidateMainBoard((prev) => [
            ...prev,
            {
                oppId: currentOppId,
                func: validateMainBoardForm,
            },
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValidateMainBoard, clientSlug])

    useEffect(() => {
        const handleSetDisabledServices = () => {
            const otherServicesSelected = projectData.servicesNeeded.some(
                (service) => service !== 'Final Mile / Local Delivery' && service !== 'Drayage',
            )

            setDisabledServices(otherServicesSelected ? [] : ['Final Mile / Local Delivery', 'Drayage'])
        }

        handleSetDisabledServices()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData.servicesNeeded])

    useEffect(() => {
        const handleSetServicesTooltips = () => {
            const servicesHints: any = {
                'Cross-Docking': 'Includes handling in/out within 24 hours of service (without storage)',
                Storage: formData.project.single
                    ? 'Includes handling in/out for more than 24 hours up to 31 Days'
                    : 'Includes handling in/out for more than 31+ Days',
            }

            if (disabledServices.includes('Final Mile / Local Delivery') || disabledServices.includes('Drayage')) {
                servicesHints['Final Mile / Local Delivery'] =
                    // eslint-disable-next-line quotes
                    "Select another service to enable 'Final Mile / Local Delivery' and 'Drayage' options"
                servicesHints.Drayage =
                    // eslint-disable-next-line quotes
                    "Select another service to enable 'Final Mile / Local Delivery' and 'Drayage' options"
            }

            setServicesTooltips(servicesHints)
        }
        handleSetServicesTooltips()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData.servicesNeeded, disabledServices])

    return (
        <div
            className={`${rfqTemplateClass}-wrapper ${cn({
                [`${rfqTemplateClass}-wrapper_collapsed`]: !isExpanded,
            })}`}
        >
            <div
                className={cn(headerClass, {
                    [`${headerClass}_cursor-pointer`]: formData.project.single === false,
                })}
                onClick={handleHeaderClick}
            >
                <TemplateTitle
                    isProjectSingle={formData.project.single}
                    projectData={projectData}
                    oppIndex={oppIndex}
                />
                {!formData.project.single && (
                    <div className={`${headerClass}-buttons`}>
                        <DeleteButton
                            className={`${headerClass}-btn-delete`}
                            onClick={() => manageOpportunity('DELETE_OPPORTUNITY', currentOppId)}
                        />
                        <CollapseBtn className={`${headerClass}-btn-collapse`} />
                    </div>
                )}
            </div>
            <div className={`${rfqTemplateClass}`}>
                <section className={`${rfqTemplateClass}__section ${rfqTemplateClass}__section_with-border`}>
                    <h3 className={`${rfqTemplateClass}__section-title`}>
                        Services Needed* <span>(Select all applicable)</span>
                    </h3>
                    <Checkbox
                        currentOppId={currentOppId}
                        fieldName="servicesNeeded"
                        avaliableOptions={servicesNeeded}
                        disabledOptions={disabledServices}
                        tooltipMessages={servicesTooltips}
                        stateData={projectData.servicesNeeded}
                        handleChange={handleServicesChange}
                        errorMessage={currentOppError?.servicesNeeded?.message || ''}
                        isFlex
                        onClick={() => setTimeout(validateServicesNeeded, 50)} //Need timeout to wait till state updates
                        wrapperRef={servicesNeededWrapperRef}
                    />
                </section>
                <section
                    className={`${rfqTemplateClass}__section ${rfqTemplateClass}__section_with-border flex-section`}
                >
                    <div className={`${rfqTemplateClass}__section-item`}>
                        <h3 className={`${rfqTemplateClass}__section-title`}>Freight Environment*</h3>
                        <RadioInput
                            isFlex
                            currentOppId={currentOppId}
                            fieldName="freightEnvironment"
                            avaliableOptions={freightEnvironment}
                            value={projectData.freightEnvironment}
                            handleChange={handleFreightEnvChange}
                            handleClick={handleFreightEnvInputClick}
                            initialValue="Dry"
                            wrapperRef={freightEnvWrapperRef}
                        />
                    </div>
                    <div className={`${rfqTemplateClass}__section-item`}>
                        <h3 className={`${rfqTemplateClass}__section-title`}>Certifications</h3>
                        <Checkbox
                            currentOppId={currentOppId}
                            fieldName="certifications"
                            avaliableOptions={certifications}
                            stateData={projectData.certifications}
                            handleChange={handleCertificationsChange}
                            wrapperRef={certificationsWrapperRef}
                        />
                    </div>
                </section>
                <section className={`${rfqTemplateClass}__section`}>
                    <h3 className={`${rfqTemplateClass}__section-title ${rfqTemplateClass}__section-title_details`}>
                        Details
                    </h3>
                    <div className={`${rfqTemplateClass}__container`}>
                        <div className="flex-inputs">
                            <GoogleAutocompleteInput
                                type="text"
                                id={`serviceLocation-${currentOppId}`}
                                name={`serviceLocation-${currentOppId}`}
                                shouldResetOnBlur
                                data-name="serviceLocation"
                                value={projectData.serviceLocation.formattedAddress}
                                changePlaceFull={(path, value) => {
                                    changeFormData(path, value)
                                }}
                                onChangeText={changeFormData}
                                inputPath={`project.data[${oppIndex}].serviceLocation`}
                                errorMessage={currentOppError?.serviceLocation?.message || ''}
                                label="Service Location*"
                                placeholder=""
                                wrapperClassName="serviceLocation_input-wrapper"
                                wrapperRef={serviceLocationWrapperRef}
                                autoComplete="off"
                                validate={validateServiceLocation}
                            />
                            <RfqDatepicker
                                label="Start Date*"
                                start={projectData.serviceDate.start}
                                handleChange={({ start }) => {
                                    changeFormData(
                                        `project.data[${oppIndex}].serviceDate.start`,
                                        start?.format('MM/DD/YYYY'),
                                    )
                                    setTimeout(() => {
                                        validateServiceDate('start')
                                    }, 50)
                                }}
                                hasReset
                                range={false}
                                past={false}
                                errorHide
                                placeholder=""
                                errorMessage={errorMessages?.opportunities[currentOppId]?.serviceDate?.start?.message}
                                wrapperRef={serviceDateStartWrapperRef}
                            />
                            <RfqDatepicker
                                wrapperClassName={`${rfqTemplateClass}__service-end-date`}
                                label="End Date*"
                                start={projectData.serviceDate.end}
                                handleChange={({ start }) => {
                                    changeFormData(
                                        `project.data[${oppIndex}].serviceDate.end`,
                                        start?.format('MM/DD/YYYY'),
                                    )

                                    setTimeout(() => {
                                        validateServiceDate('end')
                                    }, 50)
                                }}
                                hasReset
                                range={false}
                                past={false}
                                errorHide
                                placeholder=""
                                errorMessage={errorMessages?.opportunities[currentOppId]?.serviceDate?.end?.message}
                                wrapperRef={serviceDateEndWrapperRef}
                            />
                        </div>
                        <div className="flex-inputs">
                            <Input
                                label="Commodity"
                                id={`commodity-${currentOppId}`}
                                type="text"
                                name={`commodity-${currentOppId}`}
                                data-name="commodity"
                                errorMessage={currentOppError?.commodity?.message || ''}
                                value={projectData.commodity}
                                onChange={(e: any) =>
                                    changeFormData(`project.data[${oppIndex}].commodity`, e.target.value)
                                }
                                onBlur={validateCommodity}
                                wrapperRef={commodityWrapperRef}
                            />
                            <Input
                                label="Load/Container #"
                                id={`loadNumber-${currentOppId}`}
                                type="text"
                                name={`loadNumber-${currentOppId}`}
                                data-name="loadNumber"
                                errorMessage={currentOppError?.loadNumber?.message || ''}
                                value={projectData.loadNumber}
                                onChange={(e: any) =>
                                    changeFormData(`project.data[${oppIndex}].loadNumber`, e.target.value)
                                }
                                onBlur={validateLoadContainerNumber}
                                wrapperRef={loadContainerNumberWrapperRef}
                            />
                        </div>
                        <div className="flex-inputs">
                            <Select
                                label="Unit Type"
                                id={`unitType-${currentOppId}`}
                                name={`unitType-${currentOppId}`}
                                value={projectData.unitType}
                                onChange={(e: any) =>
                                    changeFormData(`project.data[${oppIndex}].unitType`, e.target.value)
                                }
                                options={unitTypes}
                                isInitEmpty={false}
                                wrapperRef={unitTypeWrapperRef}
                            />
                            <Input
                                label="# of Units*"
                                id={`numberOfUnits-${currentOppId}`}
                                type="number"
                                name={`numberOfUnits-${currentOppId}`}
                                data-name="numberOfUnits"
                                value={projectData.numberOfUnits}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    changeFormData(`project.data[${oppIndex}].numberOfUnits`, e.target.value)
                                }
                                min="1"
                                onBlur={validateNumberOfUnits}
                                errorMessage={errorMessages?.opportunities[currentOppId]?.numberOfUnits?.message}
                                wrapperRef={numberOfUnitsWrapperRef}
                            />
                        </div>
                        {!formData.project.single && !isProdEnv && (
                            <div className={`flex-inputs ${rfqTemplateClass}__spaceNeeded-wrapper`}>
                                <Select
                                    label="Space Needed"
                                    id={`spaceNeeded-${currentOppId}`}
                                    name={`spaceNeeded-${currentOppId}`}
                                    value={projectData.spaceNeeded}
                                    onChange={(e: any) =>
                                        changeFormData(`project.data[${oppIndex}].spaceNeeded`, e.target.value)
                                    }
                                    options={spaceNeeded}
                                    isInitEmpty
                                    wrapperRef={spaceNeededWrapperRef}
                                />
                                <p
                                    className={`${rfqTemplateClass}__sqft-btnOpen input-wrapper`}
                                    onClick={() => openModal(ModalId.CalculateSpace)}
                                >
                                    Calculate Sq. Ft. Needed
                                </p>
                            </div>
                        )}
                        <Textarea
                            label="Notes"
                            id={`notes-${currentOppId}`}
                            name={`notes-${currentOppId}`}
                            value={projectData.notes}
                            onChange={(e) => changeFormData(`project.data[${oppIndex}].notes`, e.target.value)}
                            placeholder="Copy and paste Scope of Work, details, etc."
                            wrapperRef={notesWrapperRef}
                        />
                    </div>
                </section>
                <section className={`${rfqTemplateClass}__section`}>
                    <h3 className={`${rfqTemplateClass}__section-title`}>Images/Files</h3>
                    <DropZoneArea
                        name={`files-${currentOppId}`}
                        changeFormData={changeFormData}
                        inputPath={`project.data[${oppIndex}].files`}
                        filesCollection={projectData.files}
                    />
                </section>
            </div>
        </div>
    )
}

export default TemplateToRender

const calculateDifference = (dateStart_: string, dateEnd_: string): number => {
    const start = new Date(dateStart_)
    const end = new Date(dateEnd_)

    const utcStart = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
    const utcEnd = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())

    const differenceInMilliseconds = utcEnd - utcStart
    return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24))
}
