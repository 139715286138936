import React, { FC, useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { navigateToWpPage } from '@/pages/RFQPortal/helpers/rfqHelper'
import useNavHelper from '@/hooks/useNavHelper'
import cn from 'classnames'

import './RfqHeader.scss'

const parentClass = 'rfq-header'

interface RfqHeaderProps {
    showList?: boolean
    listLinkTo?: string
    clickListLink?: () => void
}

const RfqHeader: FC<RfqHeaderProps> = ({ showList = true, listLinkTo = '/rfq/list', clickListLink }) => {
    const { slug } = useParams()
    const { pages } = useNavHelper()

    return (
        <>
            {!pages.isRfqPage && (
                <div className={parentClass}>
                    <h1 className={`${parentClass}__title`}>Request For Quote</h1>
                    <div className={cn(`${parentClass}__info`)}>
                        <div className={`${parentClass}__tools`} id="rfq-header-tools"></div>
                    </div>
                </div>
            )}
            <Outlet />
        </>
    )
}

interface IframedRfqHeaderProps {
    siteData: any
}

export default RfqHeader

export const IframedRfqHeader: FC<IframedRfqHeaderProps> = ({ siteData }) => {
    // useContentSecurityPolicy()
    const location = useLocation()
    const navigate = useNavigate()
    // usePardotCampaign(siteData?.CLIENT_SLUG)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const rfqListLink = getRfqListLink()

        if (urlParams.has('after-log-in') && siteData?.CLIENT_SLUG) {
            if (rfqListLink !== '') {
                navigate(rfqListLink)
                window.parent.postMessage({ type: 'deleteAfterLogInParamFromUrl' }, '*')
            } else {
                navigateToWpPage(siteData?.CLIENT_SLUG + '-log-in/')
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData?.CLIENT_SLUG])

    const getRfqListLink = () => {
        if (siteData?.access_token) {
            return '/rfq/list?token=' + siteData.access_token
        } else {
            return ''
        }
    }

    return (
        <RfqHeader
            // partnerCode={partnerCode}
            showList={!!siteData?.showRfqListLink}
            clickListLink={() => (!siteData?.access_token ? navigateToWpPage(siteData?.CLIENT_SLUG + '-log-in') : null)}
            listLinkTo={getRfqListLink()}
        />
    )
}
