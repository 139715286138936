import React from 'react'
import { tcn } from '@/utils/tcn'

const Section = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
    <section
        ref={ref}
        className={tcn(
            'bg-background-secondary shadow-shadow rounded-xl w-full xl:w-auto p-3 xl:p-5',
            className,
        )}
        {...props}
    />
))
Section.displayName = 'Section'

export { Section }
