import React from 'react'

import { setMainStyle } from '../helper'
import Checkbox from '@/components/Checkbox'

export const CertificationsCheckboxes = ({
    isCertificationsChecked,
    handleCertificationsChange,
}: {
    isCertificationsChecked: (param: any) => any
    handleCertificationsChange: (param: any) => void
}) => {
    return (
        <div>
            <p className={setMainStyle('_label')} style={{ paddingBottom: 16 }}>
                Certifications
            </p>
            <div className={setMainStyle('_wrap')}>
                <Checkbox
                    name="bonded"
                    label="Bonded"
                    className={setMainStyle('_checkbox')}
                    value={isCertificationsChecked('bonded')}
                    onChange={handleCertificationsChange}
                />

                <Checkbox
                    name="hazmat"
                    label="Hazmat"
                    className={setMainStyle('_checkbox')}
                    value={isCertificationsChecked('hazmat')}
                    onChange={handleCertificationsChange}
                />

                <Checkbox
                    name="fda"
                    label="FDA"
                    className={setMainStyle('_checkbox')}
                    value={isCertificationsChecked('fda')}
                    onChange={handleCertificationsChange}
                />
            </div>
        </div>
    )
}
