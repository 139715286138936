import * as React from 'react'

import { cn } from '@/shared/utils/cn'

export type LoaderProps = React.HTMLAttributes<HTMLDivElement> & {
    className?: string
    wrapperClassName?: string
    wrapperProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>
}

const Loader = ({ className, wrapperClassName, wrapperProps, ...props }: LoaderProps) => (
    <div
        aria-label="loader"
        className={cn('flex items-center justify-center w-full h-full', wrapperClassName)}
        {...wrapperProps}
    >
        <div
            aria-label="spinner"
            className={cn(
                'w-20 h-20 border-4 border-accent-medium border-t-primary rounded-full animate-spin',
                className,
            )}
            {...props}
        ></div>
    </div>
)
Loader.displayName = 'Loader'

export { Loader }
