import React, { FC, RefObject, useState } from 'react'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'
import cn from 'classnames'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    id?: string
    label: string
    errorMessage?: string | false
    wrapperRef?: RefObject<HTMLDivElement>
    wrapperClassName?: string
}

const Textarea: FC<TextareaProps> = ({ id, label, errorMessage, wrapperRef, wrapperClassName, ...rest }) => {
    const [inputId] = useState(id || generateRandomId())
    const checkIfError = errorMessage ? 'input-error' : ''

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        if (typeof rest.onBlur === 'function') {
            rest.onBlur(e)
        }
    }

    return (
        <div className={cn(`textarea-wrapper ${wrapperClassName} `)} ref={wrapperRef}>
            <label htmlFor={inputId}>{label}</label>
            <textarea id={id} className={checkIfError} {...rest} onBlur={handleBlur} />
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default Textarea
