import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'

export const HandlingBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
                <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                </span>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.HandlingTotal]!.price).toLocaleString('en')}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        </div>
    )
}
