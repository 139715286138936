import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InDisposal = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 14.5C16 15.3281 15.3281 16 14.5 16H5.5C4.67188 16 4 15.3281 4 14.5V3.99995H16V14.5ZM7.5 6.49995C7.5 6.22182 7.27812 5.99995 7 5.99995C6.72188 5.99995 6.5 6.22261 6.5 6.49995V13.5C6.5 13.7773 6.72266 14 7 14C7.27734 14 7.5 13.7773 7.5 13.5V6.49995ZM10.5 6.49995C10.5 6.22182 10.2781 5.99995 10 5.99995C9.72188 5.99995 9.5 6.22261 9.5 6.49995V13.5C9.5 13.7773 9.72266 14 10 14C10.2773 14 10.5 13.7773 10.5 13.5V6.49995ZM13.5 6.49995C13.5 6.22182 13.2781 5.99995 13 5.99995C12.7219 5.99995 12.5 6.22261 12.5 6.49995V13.5C12.5 13.7781 12.7219 14 13 14C13.2781 14 13.5 13.7773 13.5 13.5V6.49995ZM3.5 0.999943H7L7.36187 0.276192C7.44653 0.106817 7.61969 -0.000183105 7.80906 -0.000183105H12.1906C12.38 -0.000183105 12.5531 0.106817 12.6378 0.276192L13 0.999943H16.5C16.7761 0.999943 17 1.22376 17 1.49994V2.49994C17 2.77607 16.7761 2.99995 16.5 2.99995H3.5C3.22381 2.99995 3 2.77607 3 2.49994V1.49994C3 1.22369 3.225 0.999943 3.5 0.999943Z" />
            </svg>
        </IconBase>
    )
}
