import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InFileInvoice = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6H12V0L18 6ZM12 7.5H18V21.75C18 23.0156 16.9688 24 15.75 24H2.25C0.984375 24 0 23.0156 0 21.75V2.25C0 1.03125 0.984375 0 2.25 0H10.5V6C10.5 6.84375 11.1562 7.5 12 7.5ZM3 4.125C3 4.35938 3.14062 4.5 3.375 4.5H7.125C7.3125 4.5 7.5 4.35938 7.5 4.125V3.375C7.5 3.1875 7.3125 3 7.125 3H3.375C3.14062 3 3 3.1875 3 3.375V4.125ZM3.375 7.5H7.125C7.3125 7.5 7.5 7.35938 7.5 7.125V6.375C7.5 6.1875 7.3125 6 7.125 6H3.375C3.14062 6 3 6.1875 3 6.375V7.125C3 7.35938 3.14062 7.5 3.375 7.5ZM9.23438 14.8594L8.95312 14.8125C7.875 14.4844 7.875 14.2969 7.92188 14.1094C7.96875 13.7344 8.71875 13.6406 9.32812 13.7344C9.60938 13.7812 9.89062 13.875 10.1719 13.9688C10.6406 14.1562 11.2031 13.875 11.3438 13.4062C11.5312 12.8906 11.2969 12.375 10.7812 12.1875C10.4531 12.0938 10.1719 12 9.9375 11.9531V11.625C9.9375 11.1094 9.51562 10.6875 9 10.6875C8.4375 10.6875 8.0625 11.1094 8.0625 11.625V11.9062C6.98438 12.1406 6.23438 12.7969 6.09375 13.7812C5.71875 15.7969 7.64062 16.3594 8.4375 16.5938L8.71875 16.6875C10.0781 17.0625 10.0781 17.2031 10.0312 17.4375C9.98438 17.8125 9.23438 17.9062 8.625 17.8125C8.29688 17.7656 7.875 17.5781 7.5 17.4844L7.3125 17.3906C6.79688 17.2031 6.28125 17.4844 6.09375 17.9531C5.90625 18.4688 6.1875 18.9844 6.65625 19.1719L6.84375 19.2188C7.21875 19.3594 7.64062 19.5 8.0625 19.5938V19.875C8.0625 20.4375 8.4375 20.8125 9 20.8125C9.51562 20.8125 9.9375 20.4375 9.9375 19.875V19.5938C10.9688 19.3594 11.7188 18.75 11.8594 17.7656C12.2344 15.7031 10.2656 15.1875 9.23438 14.8594Z" />
            </svg>
        </IconBase>
    )
}
