import React, { FC, RefObject } from 'react'
import Datepicker, { IDatepicker, IOnDatepickerChangeArgs } from '@/components/Datepicker'
import cn from 'classnames'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'

interface RfqDatepickerProps extends IDatepicker {
    label: string
    handleChange: (dateObj: IOnDatepickerChangeArgs) => void
    errorMessage?: string | undefined | false
    wrapperRef?: RefObject<HTMLDivElement>
    wrapperClassName?: string
}

const RfqDatepicker: FC<RfqDatepickerProps> = ({
    label,
    handleChange,
    errorMessage,
    wrapperRef,
    wrapperClassName,
    ...rest
}) => {
    return (
        <div className={`${cn('input-wrapper', { 'input-error': errorMessage }, wrapperClassName)}`} ref={wrapperRef}>
            <label className="select-label">{label}</label>
            <Datepicker onChange={(dateObj) => handleChange(dateObj)} {...rest} />
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default RfqDatepicker
