import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { StaffInfo } from './index'
import StaffActions from './staffActions'

const columnHelper = createColumnHelper<StaffInfo>()

const useStaffColumns = (triggerUpdateStaffList: () => void) => {
    const olimpTable = 'olimp-table'

    const mainColumns = [
        columnHelper.accessor(
            (row) => {
                const firstName = row.firstName ?? ''
                const lastName = row.lastName ?? ''
                return `${firstName} ${lastName}`.trim()
            },
            {
                id: 'name',
                cell: (info) => <div>{info.getValue() || '—'}</div>,
                header: () => <div>Name</div>,
                enableSorting: false,
                meta: {
                    cellStyle: { width: '333px', maxWidth: '333px', order: 1 },
                    cellClass: `${olimpTable}__column-name`,
                },
            },
        ),
        columnHelper.accessor('title', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Title</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px', order: 2 },
                cellClass: `${olimpTable}__column-title`,
            },
        }),
        columnHelper.accessor('phone', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Phone #</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px', order: 4 },
                cellClass: `${olimpTable}__column-phone`,
            },
        }),
        columnHelper.accessor('email', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Email</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px', order: 3 },
            },
        }),
        {
            id: 'actions',
            header: () => '',
            cell: (info: any) => {
                return (
                    <div>
                        {(
                            <StaffActions
                                staffInfo={info.row.original}
                                triggerUpdateStaffList={triggerUpdateStaffList}
                            />
                        ) || '—'}
                    </div>
                )
            },
            meta: {
                cellStyle: { width: '165px', maxWidth: '165px' },
                cellClass: 'no-text-truncate actions-cell',
            },
        },
    ]

    return mainColumns
}

export default useStaffColumns
