import React from 'react'
import { Quote, QuoteStatus, QuoteType } from '@/entities/quotes/model'
import { OrderQuoteAllIn } from './OrderQuoteAllIn'
import { OrderQuoteStandard } from './OrderQuoteStandard'
import { OrderQuoteBreakdown } from '../../OrderQuoteBreakdown'
import { Button } from '@/shared/ui/Button'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { setEditMode } from '../model'
import { OrderQuoteInvoice } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/ui/OrderQuoteInvoice'
import { OrderQuoteNotification } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/ui/OrderQuoteNotification'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { WarehouseOrderStatus } from '@/entities/warehouse-orders/model'

export const OrderQuoteSubmitted = () => {
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const quote = useAppSelector((state) => state.orderQuoteSupply.selectedQuote) as Quote
    const isEditMode = useAppSelector((state) => state.orderQuoteSupply.isEditMode)

    /** Temporarily use this for partial deployment */
    const isUpdateAllowed = quote.status === QuoteStatus.CREATED

    /** Use this for Standard Quote later */
    // const isUpdateAllowed =
    //     quote.status !== QuoteStatus.DECLINED && (order.rfqStatus || 0) < WarehouseOrderStatus.Finished

    const isStandardQuote = quote.type === QuoteType.STANDARD

    return (
        <div className="space-y-5">
            <OrderQuoteInvoice />
            <OrderQuoteNotification />

            {!isEditMode ? (
                <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3 space-y-3">
                    <div className="flex items-start sm:items-center justify-between">
                        <div className="flex flex-col">
                            <div className="text-2xl font-semibold">
                                Submitted {isStandardQuote ? 'Prices' : 'Quote'}
                            </div>
                            {quote.submittedBy ? (
                                <div className="text-sm text-foreground-secondary">
                                    By <span className="font-semibold">{quote.submittedBy}</span>
                                </div>
                            ) : null}
                        </div>
                        {isUpdateAllowed && (
                            <div className="flex items-center md:w-fit">
                                <Button variant="outlined" onClick={() => dispatch(setEditMode(true))}>
                                    Update{' '}
                                    <span className="hidden sm:inline">
                                        &nbsp;{isStandardQuote ? 'Prices' : 'Quote'}
                                    </span>
                                </Button>
                            </div>
                        )}
                    </div>
                    <OrderQuoteBreakdown quote={quote} />
                </div>
            ) : isStandardQuote ? null : (
                // <OrderQuoteStandard mode="edit" submittedQuote={quote} />
                <OrderQuoteAllIn mode="edit" submittedQuote={quote} />
            )}
        </div>
    )
}
