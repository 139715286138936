import React from 'react'
import { GeoLocation } from '@shared/types'
import { UserInfo, UserType } from '@shared/api/types'

export enum UserActionType {
    SET_LOGGED_STATE = 'SET_LOGGED_STATE',
    LOGOUT = 'LOGOUT',
    UPDATE_GEO = 'UPDATE_GEO',
    UPDATE_LAST_PATH = 'UPDATE_LAST_PATH',
    SIGN_UP_VERIFY_LATER = 'SIGN_UP_VERIFY_LATER',
    SIGN_UP_LOCAL_DELIVERY_ANSWER = 'SIGN_UP_LOCAL_DELIVERY_ANSWER',
    SIGN_UP_CHOOSE_ENTITY_TYPE = 'SIGN_UP_CHOOSE_ENTITY_TYPE',
}

export enum UserRole {
    UNKNOWN,
    WAREHOUSE_MANAGER,
    FREIGHT_BROKER_MANAGER,
    PSM,
}

export interface UserData {
    id: number
    role: UserRole
    type: UserType
    sfAccountId: string
    sfParentAccountId: string
    sfContactId: string
    firstName: string
    lastName: string
    email: string
    phone: string
    position: string
    state: string
    zip: string
    companyName: string
    parentCompanyName: string
    companySlug?: string
    companySite: {
        aeCampaignId: string
        aeFormHandler: string
        clientName: string
        distributionMembers: string
        notes: string
        olimpDistributionEmail: string
        olimpSupportPhone: string
        partnerCode: string
        partnerCodeName: string
        site: string
    }
    address: string
    phoneExtention: string
    verified: boolean
    banned: boolean
    active: boolean
    admin: boolean
}

export type UserAction =
    | { type: UserActionType.SET_LOGGED_STATE; userInfo?: UserData }
    | { type: UserActionType.UPDATE_GEO; geolocation?: GeoLocation }
    | { type: UserActionType.SIGN_UP_VERIFY_LATER }
    | { type: UserActionType.SIGN_UP_LOCAL_DELIVERY_ANSWER; answer: boolean }
    | { type: UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE; entityType: 'c' | 'w' }
    | { type: UserActionType.UPDATE_LAST_PATH; pathname: string }
    | { type: UserActionType.LOGOUT }

export type SignUpInfo = {
    verificationSkipped?: boolean
    localDeliverySkipped?: boolean
    chosenEntityType?: 'w' | 'c'
}

export interface UserState {
    userType?: UserType
    isLoggedIn: boolean
    userInfo?: UserData
    geolocation?: GeoLocation
    signUp: SignUpInfo
    lastPathname?: string
    pathname?: string
}

export interface UserStore {
    state: UserState
    dispatch?: React.Dispatch<UserAction>
}
