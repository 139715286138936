import React, { FC, createContext, useState, ReactNode, useContext } from 'react'

export enum ModalId {
    CalculateSpace = 'calculateSpace',
}

interface ProviderProps {
    children: ReactNode
}

interface ProviderValue {
    currentModal: CurrentModalType
    openModal: (modalId: ModalId) => void
    closeModal: () => void
}

type CurrentModalType = ModalId | undefined

// Define outside of provider to prevent recreating the context every time the component re-renders
export const RfqModalContext = createContext<ProviderValue | undefined>(undefined)

const RfqModalProvider: FC<ProviderProps> = ({ children }) => {
    const [currentModal, setCurrentModal] = useState<CurrentModalType>(undefined)

    const RfqModalContextValue: ProviderValue = {
        currentModal,
        openModal: (modalId: ModalId) => setCurrentModal(modalId),
        closeModal: () => setCurrentModal(undefined),
    }

    return <RfqModalContext.Provider value={RfqModalContextValue}>{children}</RfqModalContext.Provider>
}

export const useRfqModal = (): ProviderValue => {
    const context = useContext(RfqModalContext)

    if (context === undefined) {
        throw new Error('useRfqModal must be used within a RfqModalProvider')
    }

    return context
}

export default RfqModalProvider
