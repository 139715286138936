import { ServiceMethods } from '../index'
import { ReworkBreakdownComponent } from './BreakdownComponent'
import { ReworkFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const reworkService: ServiceMethods = {
    breakdownComponent: ReworkBreakdownComponent,
    formComponent: ReworkFormComponent,

    getDefaultService: () => {
        return {
            name: RfqService.Rework,
            type: null,
            pricings: {
                [RfqServicePricing.Rework]: { price: '' },
            },
        }
    },
}
