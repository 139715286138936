import React, { FC, memo, useCallback } from 'react'
import { IWarehouseData } from '@shared/types'
import LocationLink from '@/components/LocationLink'
import Currency from '@/components/Currency'
import { miles } from '@shared/common/format'
import { RatingItem } from '@/parts/Rating/Item'
import Button from '@/components/Button'
import { StatusDot } from '@/components/Status'
import { PhotosView } from '@/parts/PhotosView'

export type OnBookInfo = {
    id: number
    distribution: string
    distance: number
}

interface ICarrierWarehouseTableLine extends IWarehouseData {
    onBook: (info: OnBookInfo) => void
    onQuote: (info: OnBookInfo) => void
    fullSearch?: boolean
}

const getStatus = (status: number) => {
    const LABELS: any = {
        1: 'Inactive',
        3: 'Archived',
        5: 'Active',
        6: 'Rejected',
        7: 'Banned',
    }

    return { color: status === 5 ? '#57e08c' : '#ff5656', label: LABELS[status] }
}

export const CarrierWarehouseTableLine: FC<ICarrierWarehouseTableLine> = memo(
    ({
        entity,
        address,
        zip_code,
        distance,
        total,
        rating,
        distribution,
        onBook,
        onQuote,
        id,
        status,
        manager,
        fullSearch,
        photos,
    }) => {
        const handleBook = useCallback(() => {
            onBook({ id, distribution, distance })
        }, [id, distribution, distance, onBook])

        const handleQuote = useCallback(() => {
            onQuote({ id, distribution, distance })
        }, [distance, distribution, id, onQuote])
        const statusDetails = status && getStatus(status)
        return (
            <div className="row">
                <div className="cell cell-photo">
                    <PhotosView photos={photos} carouselOnHover autoplay autoplaySpeed={2000} />
                </div>

                <div className="cell cell-entity">
                    <div className="label">Warehouse Name</div>
                    {statusDetails && (
                        <StatusDot
                            title={<div className="name">{entity || '-'}</div>}
                            color={statusDetails.color}
                            msg={
                                <div style={{ textAlign: 'left' }}>
                                    <b>Status </b> {statusDetails.label}
                                    <br />
                                    <b>Phone </b> {manager?.phone?.number}
                                    <br />
                                    <b>Email </b> {manager?.email?.email}
                                    <br />
                                </div>
                            }
                        />
                    )}
                    {!fullSearch && <div className="name">{entity || '**'}</div>}
                </div>

                <div className="cell cell-address">
                    {address && (
                        <LocationLink
                            id={id}
                            lat={address.lat}
                            lng={address.long}
                            address={address.address!}
                            zip={zip_code}
                            maxZoom={fullSearch ? 17 : 11}
                        />
                    )}
                </div>

                <div className="cell cell-distance">
                    <div className="label">Distance</div>
                    {distance ? `${miles(distance)} mls` : ''}
                </div>

                <div className="cell cell-total">
                    <div className="label">Total Cost</div>
                    <Currency value={total} />
                </div>

                <div className="cell cell-rating">
                    <div className="label">Rating</div>
                    <RatingItem value={rating} />
                </div>

                <div
                    className="cell cell-button"
                    style={
                        fullSearch
                            ? {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  backgroundColor: 'white',
                                  padding: '16px 0',
                              }
                            : {}
                    }
                >
                    {(!status || status === 5) && (
                        <Button types={['inverse', 'small', 'bold']} onClick={handleBook}>
                            Book
                        </Button>
                    )}

                    {fullSearch && (
                        <Button types={['inverse', 'small', 'bold']} onClick={handleQuote}>
                            Quote
                        </Button>
                    )}
                </div>
            </div>
        )
    },
)

CarrierWarehouseTableLine.displayName = 'CarrierWarehouseTableLine'
