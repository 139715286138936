import React, { memo, useEffect } from 'react'
import { FormikValues } from 'formik'

import { InputText } from '@/components/InputText'
import Select from '@/components/Select'
import InputLocation from '@/components/InputLocation'
import { checkedBoxes, setMainStyle } from './helper'
import { ServicesCheckboxes } from './Checkboxes/ServicesCheckboxes'
import { EquipmentCheckboxes } from './Checkboxes/EquipmentCheckboxes'
import { FeaturesCheckboxes } from './Checkboxes/FeaturesCheckboxes'
import { CertificationsCheckboxes } from './Checkboxes/CertificationsCheckboxes'
import { showLoadBoardModal } from '.'
import { FreightEnvironmentValue } from './types'

interface ILoadBoardItemProps {
    handleServicesChange: (param: any) => void
    handleCertificationsChange: (param: any) => void
    handleEquipmentChange: (param: any) => void
    handleFeaturesChange: (param: any) => void
    formik: FormikValues
    setDisabled: any
}

export const LoadBoardItem = memo(
    ({
        handleServicesChange,
        handleCertificationsChange,
        handleEquipmentChange,
        handleFeaturesChange,
        formik,
        setDisabled,
    }: ILoadBoardItemProps) => {
        useEffect(() => {
            setDisabled(!formik.values.address)
        }, [setDisabled, formik.values.address])

        const isServicesChecked = (featureName: string) => checkedBoxes(featureName, 'services', formik)
        const isFeatureChecked = (featureName: string) => checkedBoxes(featureName, 'features', formik)
        const isEquipmentChecked = (featureName: string) => checkedBoxes(featureName, 'equipment', formik)
        const isCertificationsChecked = (featureName: string) => checkedBoxes(featureName, 'certifications', formik)

        return (
            <>
                <InputLocation
                    allowLocationSelect
                    label="Service Location"
                    value={formik.values.address}
                    placeholder="Service location"
                    // hiddenAddress={physical_address.address}
                    name="address"
                    onChange={(e: any) => {
                        showLoadBoardModal({
                            props: {
                                data: { ...formik.values, address: e.target.value },
                            },
                        })
                        formik.setFieldValue('address', e.target.value)
                    }}
                    errorOnFocus
                    required={formik.values?.address}
                    error={Object.keys(formik.errors)?.length && formik.errors}
                />
                <ServicesCheckboxes isServicesChecked={isServicesChecked} handleServicesChange={handleServicesChange} />
                <InputText
                    text
                    className={setMainStyle('_input')}
                    style={{ width: '808px' }}
                    label="Commodity"
                    value={formik.values.commodity}
                    name={'commodity'}
                    placeholder="Commodity"
                    onChange={formik.handleChange}
                />
                <InputText
                    text
                    className={setMainStyle('_input')}
                    style={{ width: '808px' }}
                    label={'Volume'}
                    value={formik.values.volume}
                    name="volume"
                    placeholder="Volume"
                    onChange={formik.handleChange}
                />
                <div className={setMainStyle('_freight')} style={{ marginBottom: 26 }}>
                    <Select
                        label="Freight Environment"
                        placeholder="Select"
                        items={[
                            { name: 'Dry', value: FreightEnvironmentValue.DRY },
                            { name: 'Temperature Controlled', value: FreightEnvironmentValue.TEMPERATURE_CONTROLLED },
                            { name: 'Freeze', value: FreightEnvironmentValue.FREEZE },
                            {
                                name: 'Temperature',
                                value: FreightEnvironmentValue.TEMPERATURE,
                            },
                        ]}
                        name="status"
                        className={setMainStyle('_freightSelect')}
                        value={formik.values.freightEnvironment}
                        onAction={({ value }) => formik.setFieldValue('freightEnvironment', value)}
                    />

                    <CertificationsCheckboxes
                        isCertificationsChecked={isCertificationsChecked}
                        handleCertificationsChange={handleCertificationsChange}
                    />
                </div>

                <EquipmentCheckboxes
                    isEquipmentChecked={isEquipmentChecked}
                    handleEquipmentChange={handleEquipmentChange}
                />

                <FeaturesCheckboxes isFeatureChecked={isFeatureChecked} handleFeaturesChange={handleFeaturesChange} />
            </>
        )
    },
)
