import React from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

type OrderDetailsTeamMembersProps = {
    order: LoadboardOrder
}

export const OrderDetailsTeamMembers = ({ order }: OrderDetailsTeamMembersProps) => {
    if (!order.teamMembers) {
        return null
    }

    return (
        <div className="space-y-5">
            {order.teamMembers.map((member, index) => (
                <div key={index} className="text-ellipsis overflow-hidden">
                    {member.email}
                </div>
            ))}
        </div>
    )
}
