import React, { FC } from 'react'
import Button from '@/components/Button'
import HelpLinks from '@/parts/Cards/HelpLinks'

interface ServicesSidebarProps {
    editMode: boolean
    onCancel: () => void
    loading?: boolean
    onSave: () => void
}

export const ServicesSidebar: FC<ServicesSidebarProps> = ({ editMode, onCancel, onSave, loading }) => {
    return (
        <div className="space-y-5">
            {editMode && (
                <div className="warehouses-services-page__controls">
                    <Button
                        label={'Cancel'}
                        types={['bordered', 'transparent']}
                        onClick={onCancel}
                        disabled={loading}
                    />
                    <Button
                        label={'Save Changes'}
                        types={['blue']}
                        onClick={onSave}
                        disabled={loading}
                        loading={loading}
                    />
                </div>
            )}
            <HelpLinks />
        </div>
    )
}
