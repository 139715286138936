import React from 'react'
import cn from 'classnames'

const TablePagination = ({ table, total }: any) => {
    const currentPageIndex = table.getState().pagination.pageIndex
    const pageCount = table.getPageCount()
    const pageSize = table.getState().pagination.pageSize

    const showAllPages = pageCount <= 5
    const maxVisiblePages = 10

    const getPageNumbers = () => {
        if (showAllPages) {
            return Array.from({ length: pageCount }, (_, i) => i + 1)
        }

        const pageNumbers = []
        const leftOffset = Math.max(0, Math.min(currentPageIndex - 2, pageCount - maxVisiblePages + 1))
        const rightOffset = Math.min(pageCount - 1, leftOffset + maxVisiblePages - 1)

        const firstPage = 1
        const lastPage = pageCount

        // Always include the first page
        if (leftOffset >= 1) {
            pageNumbers.push(firstPage)
        }

        // Add ellipsis if there's a gap between the first page and the current range
        if (leftOffset > 1) {
            pageNumbers.push('...')
        }

        for (let i = leftOffset + 1; i <= rightOffset; i++) {
            pageNumbers.push(i)
        }

        // Add ellipsis if there's a gap between the current range and the last page
        if (rightOffset < pageCount - 1) {
            pageNumbers.push('...')
        }

        // Always include the last page if it's not already in the range
        if (rightOffset < pageCount) {
            pageNumbers.push(lastPage)
        }

        return pageNumbers
    }

    return (
        <>
            <div className="olimp-table__pagination">
                <div className="olimp-table__pagination__controls">
                    <div className="pagination-shortcuts">
                        {getPageNumbers().map((pageNumber, index) => (
                            <React.Fragment key={index}>
                                {!(typeof pageNumber === 'number') ? (
                                    <span>{pageNumber}</span>
                                ) : (
                                    <button
                                        className={cn('pagination-shortcut', {
                                            active: currentPageIndex === pageNumber - 1, // Subtract 1 to match the array index
                                        })}
                                        onClick={() => table.setPageIndex(pageNumber - 1)}
                                    >
                                        {pageNumber}
                                    </button>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="olimp-table__pagination__info">
                        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                            Previous
                        </button>
                        <span className="olimp-table__pagination__info-pages">
                            Page <strong>{currentPageIndex + 1}</strong> of {pageCount} (<strong>{total}</strong> items)
                        </span>
                        <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                            Next
                        </button>
                    </div>
                    <select
                        className="olimp-table__page-size"
                        value={pageSize}
                        onChange={(e) => table.setPageSize(Number(e.target.value))}
                    >
                        {[10, 20, 30, 40, 50].map((pageSizeOption) => (
                            <option key={pageSizeOption} value={pageSizeOption}>
                                Show {pageSizeOption}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    )
}

export default TablePagination
