import React, { FC } from 'react'

import CalculateSpaceModal from '@/pages/RFQPortal/modals/CalculateSpaceModal'

interface RfqModalsProps {}

const RfqModals: FC<RfqModalsProps> = () => {
    return (
        <>
            <CalculateSpaceModal />
        </>
    )
}

export default RfqModals
