import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import cn from 'classnames'
import { useUserState } from '@/store/user'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useGeoLocation } from '@shared/api/useDataHooks'
import EventBus from '@shared/common/eventBus'
import { Events } from '@shared/common/events'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals, GeoLocationRequest } from '@shared/types'
import './ModalSelectLocation.scss'
import { Loader } from '@/components/Loader'

interface ILocationSelectModalProps {
    address?: string
    pin?: 'pin' | 'round'
    onSelect: (data: GeoLocationRequest) => void
}

const LocationSelectModal: FC<ILocationSelectModalProps> = ({ address, onSelect, pin = 'pin' }) => {
    const [selectLoading, setSelectLoading] = useState<any>(false)
    const [map, setMap] = useState<any>(null)
    const { state } = useUserState()
    const [[latitude, longitude], setCurrentLangLat] = useState([0, 0])
    const [searchValue, setSearchValue] = useState(address)
    const { data, error, setLocation, isLoading } = useGeoLocation({
        address: searchValue,
    })

    const handleSearchChange = useCallback((e) => {
        setSearchValue(e.target.value)
    }, [])

    const handleGoogleApiLoaded = useCallback(({ maps, map: m }) => {
        setMap(m)
    }, [])

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault()
            setLocation({ address: searchValue })
        },
        [setLocation, searchValue],
    )
    const handleDragEnd = useCallback((m) => {
        const { lat, lng } = m.getCenter()
        setCurrentLangLat([lat(), lng()])
    }, [])

    const handleSelect = useCallback(() => {
        setSelectLoading(true)
        setLocation({ lat: latitude, long: longitude })
    }, [latitude, longitude, setLocation])

    useEffect(() => {
        if (data && map) {
            map.setCenter({ lat: data.lat, lng: data.long })
            setCurrentLangLat([data.lat!, data.long!])
            if (selectLoading) {
                EventBus.bus.emit(Events.CLOSE_MODAL, Modals.LocationSelectModal)
                setSelectLoading(false)

                if (onSelect) onSelect(data)
            }
        }
    }, [data, map, onSelect, selectLoading, setLocation])
    console.log(process.env.REACT_APP_GMAP_API_KEY)
    return (
        <>
            <form onSubmit={handleSubmit} className="form-search-address">
                <div className="field">
                    <InputText
                        text
                        autoFocus
                        name="address"
                        value={searchValue}
                        onChange={handleSearchChange}
                        placeholder="Enter address..."
                    />
                </div>
                <Button
                    type="submit"
                    types={['blue', 'small']}
                    loading={isLoading && !selectLoading}
                    disabled={(searchValue?.length ?? 0) < 2 || selectLoading}
                    label="Search"
                />
            </form>
            <div className="map-wrapper">
                <div className="map">
                    <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        onDragEnd={handleDragEnd}
                        onGoogleApiLoaded={handleGoogleApiLoaded}
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY! }}
                        center={state.geolocation && { lat: state.geolocation.lat, lng: state.geolocation.long }}
                        defaultZoom={12}
                    />

                    <div className="marker-center">
                        {!map && <Loader />}
                        {map && <span className={cn({ 'icon-pin': pin === 'pin', 'icon-round': pin === 'round' })} />}
                    </div>
                </div>
            </div>

            <div className="btns">
                <Button
                    type="button"
                    onClick={handleSelect}
                    types={['blue']}
                    loading={selectLoading}
                    disabled={false}
                    label="Select"
                />
            </div>
        </>
    )
}

ModalRegistry.get().register<ILocationSelectModalProps>(Modals.LocationSelectModal, {
    id: 'LocationSelectModal',
    className: 'modal-select-location',
    size: 'large',
    title: 'Choose a place',
    Component: memo(LocationSelectModal),
})

export const showLocationSelectModal = (props: ModalPartialProps<ILocationSelectModalProps>): void =>
    ModalRegistry.get().show<ILocationSelectModalProps>(Modals.LocationSelectModal, props)

export default memo(LocationSelectModal)
