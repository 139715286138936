import React, { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { WarehouseStaff } from '@/pages/Warehouses/WarehousesStaff'
import { PermissionLevel } from '@/parts/Modals/ManageStaffModal'
import { getEnumKeyByValue } from '@/utils/helper'
import Select from '@/pages/RFQPortal/components/Select'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { toast } from 'react-toastify'

const olimpTable = 'olimp-table'
const columnHelper = createColumnHelper<WarehouseStaff>()

const useWarehousesStaffColumns = (
    loggedInUserInfo: WarehouseStaff | undefined,
    warehouseAcc: string,
    triggerUpdateWHStaffList: () => void,
    isAdmin?: boolean,
) => {
    const mainColumns = [
        columnHelper.accessor(
            (row) => {
                const firstName = row.firstName ?? ''
                const lastName = row.lastName ?? ''
                return `${firstName} ${lastName}`.trim()
            },
            {
                id: 'name',
                cell: (info) => <div>{info.getValue() || '—'}</div>,
                header: () => <div>Name</div>,
                enableSorting: false,
                meta: {
                    cellStyle: { width: '184px', maxWidth: '184px', order: 1 },
                    cellClass: `${olimpTable}__column-name`,
                },
            },
        ),
        columnHelper.accessor('title', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Title</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '184px', maxWidth: '184px', order: 2 },
                cellClass: `${olimpTable}__column-title`,
            },
        }),
        columnHelper.accessor('phone', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Phone #</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '184px', maxWidth: '184px', order: 4 },
                cellClass: `${olimpTable}__column-phone`,
            },
        }),
        columnHelper.accessor('email', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Email</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '184px', maxWidth: '184px', order: 3 },
            },
        }),
        columnHelper.accessor('permission', {
            cell: (info) => {
                console.log('isAdmin', isAdmin)
                console.log('loggedInUserInfo', loggedInUserInfo)

                const row = info.row.original

                const isAdminPermission = info.getValue() === PermissionLevel.Admin
                const isManagerPermission = info.getValue() === PermissionLevel.Manager

                const managerLabel = getEnumKeyByValue(PermissionLevel, PermissionLevel.Manager)
                const userLabel = getEnumKeyByValue(PermissionLevel, PermissionLevel.User)

                let userTypeLabel
                if (isAdminPermission || isManagerPermission) {
                    userTypeLabel = managerLabel
                } else {
                    userTypeLabel = userLabel
                }

                const enableEditPermissions =
                    isAdmin ||
                    (loggedInUserInfo &&
                        loggedInUserInfo.id !== row.id &&
                        loggedInUserInfo.permission > PermissionLevel.User &&
                        loggedInUserInfo.permission >= info.getValue())

                const submitPermission = async (
                    accontId: string,
                    permissionId: number,
                    permissionLevel: PermissionLevel,
                ) => {
                    try {
                        await HttpV2.post(
                            `accounts/${accontId}/contacts/permissions/${permissionId}/${permissionLevel}`,
                        )

                        toast('User type has been updated successfully!', {
                            type: 'success',
                        })
                        triggerUpdateWHStaffList()
                    } catch (e: any) {
                        toast(e.response?.data?.message, {
                            type: 'error',
                        })
                    }
                }

                if (
                    isAdmin ||
                    (loggedInUserInfo &&
                        loggedInUserInfo.permission &&
                        loggedInUserInfo.permission > PermissionLevel.User)
                ) {
                    return (
                        <Select
                            id={row.id}
                            name={`permission__${row.id}`}
                            value={userTypeLabel}
                            options={Object.keys(PermissionLevel).filter(
                                (key) => isNaN(Number(key)) && key !== 'Admin',
                            )}
                            onChange={(e) => {
                                const isPermissionLevel = (value: string): value is keyof typeof PermissionLevel => {
                                    return value in PermissionLevel
                                }

                                const newPermission = e.target.value
                                if (isPermissionLevel(newPermission)) {
                                    submitPermission(warehouseAcc, row.permissionId, PermissionLevel[newPermission])
                                } else {
                                    toast('Invalid permission level', { type: 'error' })
                                }
                            }}
                            isInitEmpty={false}
                            disabled={!enableEditPermissions}
                        />
                    )
                } else {
                    return <div>{userTypeLabel}</div>
                }
            },
            header: () => <div>User Type</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '120px', maxWidth: '120px', order: 3 },
            },
        }),
    ]

    return mainColumns
}

export default useWarehousesStaffColumns
