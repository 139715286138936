import React, { FC } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { UserAccountSettings } from './index'
import ROUTES, { DEFAULT_ROUTES } from '@/router'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import { UserTermsOfService } from '@/pages/User/UserTermsOfService/UserTermsOfService'

export const UserRoutes: FC = () => {
    return (
        <>
            <LoggedInRoute />
            <Routes>
                {/*<Route path={ROUTES.user.settings} element={<UserAccountSettings />} />*/}
                <Route path={ROUTES.user.tos} element={<UserTermsOfService />} />
                <Route path={ROUTES.user.base} element={<Navigate to={DEFAULT_ROUTES.user} />} />
            </Routes>
        </>
    )
}
