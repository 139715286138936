import React, { memo, useCallback } from 'react'
import cn from 'classnames'
import { EntityType } from '@shared/api/types'

import './Tabs.scss'

interface ITabsItem {
    name: string
    value: string | number
    totalValue?: number
}

export enum TabsVariant {
    default = 'default',
    primary = 'primary',
}

interface ITabsProps {
    list: ITabsItem[]
    value: string | number
    name: string
    onChange: (value: string | number) => void
    variant?: TabsVariant
    size?: number
    Item?: React.FC<{ name: string; total?: number }>
    className?: string
}

const Tabs: React.FC<ITabsProps> = ({
    name,
    value,
    onChange,
    list,
    variant = TabsVariant.default,
    size = 16,
    Item,
    className,
}) => {
    const renderItem = useCallback(
        (item) => (
            <button
                // style={{ width: `${(100 / list.length).toFixed(2)}%` }}
                key={item.value}
                type="button"
                className={cn('item', { active: item.value === value }, className)}
                onClick={() => onChange(item.value)}
            >
                <span style={{ fontSize: size }}>
                    {Item
                        ? Item({
                              name: item.name,
                              total: item.totalValue,
                          })
                        : item.name}
                </span>
            </button>
        ),
        [Item, list.length, onChange, size, value],
    )

    return (
        <>
            <div
                className={cn('tabs', {
                    toggle: variant === TabsVariant.default,
                    toggle2: variant === TabsVariant.primary,
                })}
            >
                <input type="hidden" name={name} value={value} />
                <div className="list">{list.map(renderItem)}</div>
            </div>
        </>
    )
}

export default memo(Tabs)
