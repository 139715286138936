import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InInfoCircle = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM10.875 7.125C10.875 6.50625 11.3813 6 12 6C12.6187 6 13.125 6.50391 13.125 7.125V13.125C13.125 13.7461 12.6211 14.25 12 14.25C11.3789 14.25 10.875 13.7484 10.875 13.125V7.125ZM12 18.75C11.1862 18.75 10.5262 18.09 10.5262 17.2762C10.5262 16.4625 11.1858 15.8025 12 15.8025C12.8142 15.8025 13.4738 16.4625 13.4738 17.2762C13.4719 18.0891 12.8156 18.75 12 18.75Z" />
            </svg>
        </IconBase>
    )
}
