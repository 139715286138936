import React from 'react'
import HelpLinks from '@/parts/Cards/HelpLinks'

export const OrdersSidebar = () => {
    return (
        <aside className="shrink-0 xl:w-[380px]">
            <HelpLinks />
        </aside>
    )
}
export default OrdersSidebar
