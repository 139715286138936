import React, { FC, useState } from 'react'
import Button from '@/components/Button'
import { showDeleteStaffModal } from '@/parts/Modals/DeleteStaffModal'
import { showManageStaffModal } from '@/parts/Modals/ManageStaffModal'
import { PermissionLevel } from '@/parts/Modals/ManageStaffModal'
import DotsMenu from '@/components/DotsMenu'
import { getEnumKeyByValue } from '@/utils/helper'

import { StaffInfo } from './index'

interface StaffActionsProps {
    staffInfo: StaffInfo
    triggerUpdateStaffList?: () => void
}

const StaffActions: FC<StaffActionsProps> = ({ staffInfo, triggerUpdateStaffList }) => {
    const handleDelete = () => {
        //Todo: implement delete API

        showDeleteStaffModal({
            props: {
                deleteApiLink: `accounts/contacts/${staffInfo.id}`,
                refreshWarehouses: triggerUpdateStaffList,
                modalTitle: `Confirm Delete ${staffInfo.firstName || ''} ${staffInfo.lastName || ''} `,
                displayData: {
                    name: `${staffInfo.firstName} ${staffInfo.lastName}`,
                    title: staffInfo.title,
                    phone: staffInfo.phone,
                    email: staffInfo.email,
                },
            },
        })
    }

    const olimpTable = 'olimp-table'
    const warehousesPage = 'warehouses'

    return (
        <div className={`${olimpTable}__btns-column`}>
            {triggerUpdateStaffList && (
                <Button
                    className={`${warehousesPage}__details-btn`}
                    type="button"
                    types={['blue']}
                    label="Manage"
                    onClick={() => {
                        const isAdminPermission =
                            staffInfo.userType?.toLowerCase() ===
                            getEnumKeyByValue(PermissionLevel, PermissionLevel.Admin)?.toLowerCase()

                        showManageStaffModal({
                            props: {
                                modalTitle: `Manage ${staffInfo.firstName || ''} ${staffInfo.lastName || ''} `,
                                contactId: staffInfo.id,
                                isAdmin: isAdminPermission,
                                triggerUpdateStaffList: triggerUpdateStaffList,
                            },
                        })
                    }}
                />
            )}
            <DotsMenu
                buttons={[
                    {
                        name: 'Delete',
                        onClick: handleDelete,
                        className: `${warehousesPage}__dots-menu-item ${warehousesPage}__dots-menu-item_delete`,
                    },
                ]}
            />
        </div>
    )
}

export default StaffActions
