import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import {
    PopoverRoot,
    PopoverContent,
    PopoverTrigger,
    PopoverTriggerLabel,
    PopoverTriggerWrapper,
    PopoverTriggerIcon,
    PopoverArrow,
} from '@/shared/ui/Popover'
import { Calendar } from '@/shared/ui/Calendar'
import { DateRange, DayPickerMultipleProps, DayPickerRangeProps, DayPickerSingleProps, Matcher } from 'react-day-picker'
import { format } from 'date-fns'
import { InCalendar } from '@/shared/ui/Icons/InCalendar'
import { isArray } from 'lodash'

export type DatePickerProps = (DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps) & {
    disabled?: boolean
    datesDisabled?: Matcher | Matcher[] | undefined

    popoverProps?: React.ComponentProps<typeof PopoverRoot>

    placeholder?: React.ReactNode
    renderValue?: (selected: Date | Date[] | DateRange | undefined) => React.ReactNode

    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof PopoverTriggerWrapper>, 'className'>

    triggerClassName?: string
    triggerProps?: Omit<React.ComponentProps<typeof PopoverTrigger>, 'className' | 'disabled'>
    renderTrigger?: (selected: Date | Date[] | DateRange | undefined) => React.ReactNode

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof PopoverTriggerLabel>, 'className'>

    icon?: React.ReactNode
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof PopoverTriggerIcon>, 'className'>

    popoverContentClassName?: string
    popoverContentProps?: Omit<React.ComponentProps<typeof PopoverContent>, 'className'>

    hidePopoverArrow?: boolean
    popoverArrowClassName?: string
    popoverArrowProps?: Omit<React.ComponentProps<typeof PopoverArrow>, 'className'>
}

export function DatePicker({
    disabled,
    datesDisabled,

    popoverProps,

    placeholder,
    renderValue,

    wrapperClassName,
    wrapperProps,

    triggerClassName,
    triggerProps,
    renderTrigger,

    label,
    labelClassName,
    labelProps,

    icon,
    iconWrapperClassName,
    iconWrapperProps,

    popoverContentClassName,
    popoverContentProps,

    hidePopoverArrow,
    popoverArrowClassName,
    popoverArrowProps,

    ...calendarProps
}: DatePickerProps) {
    const Comp = React.useMemo(() => {
        switch (calendarProps.mode) {
            case 'single':
                return DatePickerSingleValue
            case 'multiple':
                return DatePickerMultipleValue
            case 'range':
                return DatePickerRangeValue
        }
    }, [calendarProps.mode])

    return (
        <PopoverRoot {...popoverProps}>
            <PopoverTriggerWrapper className={wrapperClassName} {...wrapperProps}>
                {renderTrigger ? (
                    <PopoverTrigger asChild>{renderTrigger(calendarProps.selected)}</PopoverTrigger>
                ) : (
                    <PopoverTrigger
                        className={cn(
                            'justify-start text-left !font-normal data-[state=open]:border-primary',
                            !calendarProps.selected && 'text-foreground-primary/70',
                            isArray(calendarProps.selected) &&
                                !calendarProps.selected.length &&
                                'text-foreground-primary/70',
                            triggerClassName,
                        )}
                        disabled={disabled}
                        {...triggerProps}
                    >
                        {renderValue ? (
                            renderValue(calendarProps.selected)
                        ) : (
                            <Comp date={calendarProps.selected as any} placeholder={placeholder} />
                        )}
                    </PopoverTrigger>
                )}

                {label !== undefined ? (
                    <PopoverTriggerLabel className={labelClassName} {...labelProps}>
                        {label}
                    </PopoverTriggerLabel>
                ) : null}

                <PopoverTriggerIcon className={iconWrapperClassName} {...iconWrapperProps}>
                    {icon !== undefined ? icon : <InCalendar size={16} className="mr-2 text-foreground-secondary/70" />}
                </PopoverTriggerIcon>
            </PopoverTriggerWrapper>
            <PopoverContent className={cn('w-auto p-0', popoverContentClassName)} {...popoverContentProps}>
                {hidePopoverArrow ? null : <PopoverArrow className={popoverArrowClassName} {...popoverArrowProps} />}
                <Calendar disabled={datesDisabled} {...calendarProps} />
            </PopoverContent>
        </PopoverRoot>
    )
}

const DatePickerSingleValue = ({ date, placeholder }: { date?: Date; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow justify-start overflow-hidden">
            {date ? format(date, 'PPP') : placeholder ?? 'Pick a date'}
        </span>
    </div>
)

const DatePickerMultipleValue = ({ date, placeholder }: { date?: Date[]; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow">
            {date && date.length > 0
                ? `${date.length} date${date.length > 1 ? 's' : ''} selected`
                : placeholder ?? 'Pick dates'}
        </span>
    </div>
)

const DatePickerRangeValue = ({ date, placeholder }: { date?: DateRange; placeholder?: React.ReactNode }) => (
    <div className="flex w-full items-center text-sm">
        <span className="flex-grow justify-start overflow-hidden">
            {date?.from ? (
                date.to ? (
                    <>
                        {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                    </>
                ) : (
                    format(date.from, 'LLL dd, y')
                )
            ) : (
                placeholder ?? 'Pick a range'
            )}
        </span>
    </div>
)
