import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InStorage = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 15.25V5.35313C0 4.5375 0.497813 3.8 1.25719 3.49688L9.62813 0.148534C9.86563 0.0531904 10.1344 0.0531904 10.3719 0.148534L18.7437 3.49688C19.5031 3.8 20 4.5375 20 5.35313V15.25C20 15.6656 19.6656 16 19.25 16H17.75C17.3344 16 17 15.6656 17 15.25V6.97188C17 6.44688 16.5531 5.97188 16 5.97188H4C3.44688 5.97188 3 6.44688 3 6.97188V15.25C3 15.6656 2.66406 16 2.25 16H0.75C0.335938 16 0 15.6656 0 15.25ZM4.75 16C4.33437 16 4 15.6656 4 15.25V13.5H16V15.25C16 15.6656 15.6656 16 15.25 16H4.75ZM4 10.5H16V12.5H4V10.5ZM4 7H16V9.5H4V7Z" />
            </svg>
        </IconBase>
    )
}
