import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InChevronDown = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.66586 13C7.40998 13 7.15398 12.9023 6.95898 12.707L0.958984 6.70703C0.568359 6.31641 0.568359 5.68359 0.958984 5.29297C1.34961 4.90234 1.98242 4.90234 2.37305 5.29297L7.66586 10.5875L12.9596 5.29375C13.3502 4.90312 13.983 4.90312 14.3737 5.29375C14.7643 5.68437 14.7643 6.31719 14.3737 6.70781L8.37367 12.7078C8.17836 12.9031 7.92211 13 7.66586 13Z" />
            </svg>
        </IconBase>
    )
}
