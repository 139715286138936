import React, { ChangeEvent, FC, memo, useCallback } from 'react'
import cn from 'classnames'
import { InputText } from '@/components/InputText'
import { showLocationSelectModal } from '@/parts/Modals/LocationSelectModal'
import { GeoLocationRequest } from '@shared/types'

interface InputLocationProps {
    placeholder?: string
    name: string
    disabled?: boolean
    errorOnFocus?: boolean
    value: string | undefined
    label?: string
    error?: string
    errorHide?: boolean
    autoFocus?: boolean
    textError?: boolean
    hiddenAddress?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    pin?: 'round' | 'pin'
    required?: boolean
    allowLocationSelect?: boolean
    className?: string
}

const InputLocation: FC<InputLocationProps> = ({
    placeholder = '',
    name,
    disabled,
    value,
    label,
    error,
    errorHide,
    autoFocus,
    textError = true,
    errorOnFocus,
    hiddenAddress,
    pin = 'pin',
    onChange,
    required = false,
    allowLocationSelect = false,
    className,
}) => {
    const handleSelect = useCallback(
        (data: GeoLocationRequest) => {
            if (onChange) {
                onChange({ target: { name, value: data.address || '' } } as ChangeEvent<HTMLInputElement>)
            }
        },
        [onChange, name],
    )

    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                onChange(e)
            }
        },
        [onChange],
    )

    const handleOpenLocation = useCallback(() => {
        showLocationSelectModal({
            props: {
                address: value || hiddenAddress,
                pin,
                onSelect: handleSelect,
            },
        })
    }, [pin, value, hiddenAddress, handleSelect])

    return (
        <div className={cn('input-location', { disabled })}>
            <InputText
                className={className}
                text
                autoFocus={autoFocus}
                label={label}
                placeholder={placeholder}
                error={error}
                onIconClick={allowLocationSelect ? handleOpenLocation : undefined}
                onChange={handleChange}
                disabled={disabled}
                errorHide={errorHide}
                required={required}
                textError={textError}
                errorOnFocus={errorOnFocus}
                name={name}
                value={value}
                icon={allowLocationSelect ? 'pin-small' : undefined}
            />
        </div>
    )
}

export default memo(InputLocation)
