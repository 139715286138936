/* eslint-disable complexity */
import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import '../MenuItem.scss'
import SubMenu from '@/parts/Navigation/Submenu'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import cn from 'classnames'

import './LandingMenu.scss'
const CLASSNAME = 'landing-menu'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    closeMenu: () => void
}

const LandingMenu: FC<IMenu> = ({ closeMenu }) => {
    const baseLandingLink = window.location.origin.replace('app.', '')
    const { sendMenuClick } = useCustomAnalyticsEvent()

    return (
        <>
            <div className="menu-item_active">
                <Link to="" className="menu-item menu-item_main" onClick={(e) => e.preventDefault()}>
                    <Button label="Who We Serve" />
                </Link>
                <SubMenu
                    links={[
                        {
                            label: 'Freight Brokers',
                            href: `${baseLandingLink}/freight-brokers`,
                        },
                        {
                            label: 'Carriers',
                            href: `${baseLandingLink}/carriers`,
                        },
                        {
                            label: 'Freight Forwarders',
                            href: `${baseLandingLink}/freight-forwarders`,
                        },
                        {
                            label: 'Shippers',
                            href: `${baseLandingLink}/shippers`,
                        },
                        {
                            label: 'Retailers',
                            href: `${baseLandingLink}/retailers`,
                        },
                        {
                            label: 'Warehouses',
                            href: `${baseLandingLink}/warehouses`,
                        },
                    ]}
                    onLinkClick={(link) => {
                        sendMenuClick({
                            label: link.label,
                            link: link.href,
                            place: 'header',
                            callBack: closeMenu,
                        })
                    }}
                />
            </div>
            <div className="menu-item_active">
                <Link to="" className="menu-item menu-item_main" onClick={(e) => e.preventDefault()}>
                    <Button label="Services" />
                </Link>
                <SubMenu
                    links={[
                        {
                            label: 'Warehousing',
                            href: `${baseLandingLink}/warehousing`,
                        },
                        {
                            label: 'Cross-docking',
                            href: `${baseLandingLink}/cross-docking`,
                        },
                        {
                            label: 'Pallet-rework',
                            href: `${baseLandingLink}/pallet-reworks`,
                        },
                        {
                            label: 'Transloading and drayage',
                            href: `${baseLandingLink}/transloading-and-drayage`,
                        },
                    ]}
                    onLinkClick={(link) => {
                        sendMenuClick({
                            label: link.label,
                            link: link.href,
                            place: 'header',
                            callBack: closeMenu,
                        })
                    }}
                />
            </div>
            <div className="menu-item_active">
                <Link to="" className="menu-item menu-item_main" onClick={(e) => e.preventDefault()}>
                    <Button label="Company" />
                </Link>
                <SubMenu
                    links={[
                        {
                            label: 'Freight Brokers',
                            href: `${baseLandingLink}/freight-brokers`,
                        },
                        {
                            label: 'Carriers',
                            href: `${baseLandingLink}/carriers`,
                        },
                        {
                            label: 'Freight Forwarders',
                            href: `${baseLandingLink}/freight-forwarders`,
                        },
                        {
                            label: 'Shippers',
                            href: `${baseLandingLink}/shippers`,
                        },
                        {
                            label: 'Retailers',
                            href: `${baseLandingLink}/retailers`,
                        },
                        {
                            label: 'Warehouses',
                            href: `${baseLandingLink}/warehouses`,
                        },
                    ]}
                    onLinkClick={(link) => {
                        sendMenuClick({
                            label: link.label,
                            link: link.href,
                            place: 'header',
                            callBack: closeMenu,
                        })
                    }}
                />
            </div>
        </>
    )
}

export default LandingMenu
