import React, { memo } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { Modals } from '@shared/types'
import Button from '@/components/Button'

const InsuranceRequirementsModal = () => {
    return (
        <div className="space-y-5">
            <p>
                (a) Property and Casualty Insurance: sufficient to cover the replacement of the warehouse and the
                contents stored therein
            </p>
            <p>
                (b) Comprehensive Commercial General Liability Insurance with minimum limits of not less than the value
                of the Goods and $1,000,000 USD per occurrence and $1,000,000 in the aggregate
            </p>
            <p>
                (c) Commercial Automobile Liability Insurance, with a combined single limit of not less than $1,000,000
                USD per occurrence (if offering pickup, redelivery, drayage)
            </p>
            <p>(d) Errors and Omissions Insurance in limits of not less than $1,000,000 USD per occurrence</p>
            <p>(e) Employer’s Liability Insurance in limits not less than $1,000,000 USD per employee per accident</p>
            <p>(f) Umbrella/Excess Liability Insurance</p>
            <div className="flex justify-end">
                <Button
                    types={['bordered']}
                    onClick={() => ModalRegistry.get().close(Modals.InsuranceRequirementsModal)}
                >
                    Okay
                </Button>
            </div>
        </div>
    )
}

ModalRegistry.get().register(Modals.InsuranceRequirementsModal, {
    id: 'InsuranceRequirementsModal',
    size: 'medium',
    Component: memo(InsuranceRequirementsModal),
    title: 'Minimum Insurance Requirements',
})

export const showInsuranceRequirementsModal = () => ModalRegistry.get().show(Modals.InsuranceRequirementsModal)

export default memo(InsuranceRequirementsModal)
