import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InDownload2 = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0002 9.50001V12H2.00016V9.50001H0.333496V12C0.333496 12.9167 1.0835 13.6667 2.00016 13.6667H12.0002C12.9168 13.6667 13.6668 12.9167 13.6668 12V9.50001H12.0002ZM11.1668 6.16668L9.99183 4.99168L7.8335 7.14168V0.333344H6.16683V7.14168L4.0085 4.99168L2.8335 6.16668L7.00016 10.3333L11.1668 6.16668Z" />
            </svg>
        </IconBase>
    )
}
