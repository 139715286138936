import React, { FC, useEffect, useState } from 'react'
import { ReactComponent as CloseBtnIcon } from '@/pages/RFQPortal/assets/img/closeRfqToast.svg'
import cn from 'classnames'

import './validationPopup.scss'

const CLASS_NAME = 'error-toaster'

interface ValidationPopupProps {
    errors: any
    setShowPopup: (showPopup: boolean) => void
}

interface PopupData {}

const ValidationPopup: FC<ValidationPopupProps> = ({ setShowPopup, errors }) => {
    return (
        <div className={cn(`${CLASS_NAME}-wrapper`)}>
            <div className={CLASS_NAME}>
                <div className={`${CLASS_NAME}-header`}>
                    <h3 className={`${CLASS_NAME}-header__title`}>Please complete all required fields</h3>
                    <CloseBtnIcon className={`${CLASS_NAME}-header__btn-close`} onClick={() => setShowPopup(false)} />
                </div>
                {/* <ul className={`${CLASS_NAME}__errors-list`}> */}
                {/* {errors.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={`${CLASS_NAME}__error`}
                                onClick={() => scrollToInput(item.wrapperEl, item.oppId)}
                            >
                                {item.errorLabel}
                            </li>
                        )
                    })} */}
                {/* </ul> */}
            </div>
        </div>
    )
}

export default ValidationPopup
