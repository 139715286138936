import React, { FC } from 'react'
import cn from 'classnames'

const CLASS_NAME = 'how-it-works__steps'

const HowItWorksSteps: FC = () => {
    const stepsData = [
        {
            iconLink: require('../../assets/images/hiw-acc.png'),
            label: 'Create an Account',
            description:
                'Log in and enter your warehouse name and address, additional staff, certificate of insurance, and company logo.',
        },
        {
            iconLink: require('../../assets/images/hiw-set-up.png'),
            label: 'Set Up Your Warehouses',
            description:
                'If you have more than one location, enter each location and set a primary contact for each location. The primary contact will receive email notifications when opportunities are posted within 100 miles of your warehouse address.',
        },
        {
            iconLink: require('../../assets/images/hiw-enter.png'),
            label: 'Enter Your Warehouse Details',
            description:
                'Enter your hours of operation, after hours (if available), services offered, features, certifications, equipment, and vehicle types available for local re-deliveries.',
        },
        {
            iconLink: require('../../assets/images/hiw-search.png'),
            label: 'Search For & Bid on Opportunities',
            description:
                'Search for opportunities by distance, service dates, services needed, etc. Select the opportunity to see additional information and place your bid for the warehousing services needed.',
        },
        {
            iconLink: require('../../assets/images/hiw-accept.png'),
            label: 'Bid Accepted & Accept the Freight',
            description:
                'OLIMP’s Partner Success Manager will present your bid to our client and if your bid is accepted, OLIMP’s Partner Success Manager will be in touch to arrange the details of the opportunity.',
        },
        {
            iconLink: require('../../assets/images/hiw-pay.png'),
            label: 'We Pay You',
            description: 'Once the order is complete, OLIMP will pay you via Comcheck, Credit Card or ACH transfer.',
        },
    ]

    return (
        <ul className={CLASS_NAME}>
            {stepsData.map((step, index) => (
                <li key={index} className={cn(`${CLASS_NAME}__step`)}>
                    <img src={step.iconLink} alt="step" className={cn(`${CLASS_NAME}__icon`)} />
                    <div className={cn(`${CLASS_NAME}__label`)}>{step.label}</div>
                    <div className={cn(`${CLASS_NAME}__desc`)}>{step.description}</div>
                </li>
            ))}
        </ul>
    )
}

export default HowItWorksSteps
