import React from 'react'
import { Modals } from '@shared/types'
import { ModalRegistry } from '@shared/common/modals'

import Button from '@/components/Button'
import { setMainStyle, initialValuesLoadBoard } from './helper'

export const LoadBoarCancel = ({
    setIsCancel,
    isCancel,
    resetForm,
}: {
    setIsCancel: any
    isCancel: boolean
    resetForm: any
}) => {
    return (
        <div className={setMainStyle('_cancel')}>
            <div className={setMainStyle('_cancel_container')}>
                <div>
                    <h4 style={{ fontWeight: 400, fontSize: 20 }}>Are you sure ? Changes will not be saved</h4>
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{ margin: 'auto' }}
                            label="Cancel"
                            types={['bordered', 'small']}
                            onClick={() => setIsCancel(!isCancel)}
                        />
                        <Button
                            style={{ margin: 'auto' }}
                            label="Close"
                            types={['bordered', 'small', 'blue']}
                            onClick={() => {
                                resetForm({ values: initialValuesLoadBoard })
                                ModalRegistry.get().close(Modals.LoadBoardPostFormModal)
                                setIsCancel(!isCancel)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
