import { ZendeskAPI } from 'react-zendesk'
import { Http } from '@shared/api/Http'
import { Callback } from 'yup/lib/types'

export const useZendeskJWT = () => {
    const handleJwtToken = async function () {
        let hostType = ''

        switch (window.location.origin) {
            case process.env.REACT_APP_PROD_HOST:
                hostType = 'prod'
                break
            case process.env.REACT_APP_DEV_HOST:
                hostType = 'dev'
                break
            case process.env.REACT_APP_STAGE_HOST:
                hostType = 'stage'
                break
            case process.env.REACT_APP_DEMO_HOST:
                hostType = 'demo'
                break
            default:
                hostType = 'else'
        }

        const jwtToken: Error = await Http.get('app/v1/zendesk?host-type=' + hostType)

        ZendeskAPI('messenger', 'loginUser', function (callback: Callback) {
            callback(jwtToken)
        })
    }

    return {
        handleJwtToken,
    }
}
