import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Input } from '@/shared/ui/Input'

export const CrossDockingFormComponent = ({ isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string, field: RfqServicePricing) => {
        if (service.pricings[field]) {
            service.pricings[field]!.price = value
        }
    }

    if (isFloorLoaded) {
        return (
            <div className="flex flex-col gap-5 w-full">
                <div className="font-medium">Floor Loaded</div>
                <div>
                    <Input
                        label={RfqServicePricingLabel[RfqServicePricing.CrossDocking]}
                        wrapperClassName="w-full md:w-[200px]"
                        defaultValue={service.pricings.crossDocking?.price}
                        onChange={(e) => onChange(e.target.value, RfqServicePricing.CrossDocking)}
                    />
                    {errors?.[RfqServicePricing.CrossDocking] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.CrossDocking]}</div>
                    ) : null}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="font-medium">Palletized</div>
            <div>
                <Input
                    label={RfqServicePricingLabel[RfqServicePricing.HandlingIn]}
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.handlingIn?.price}
                    onChange={(e) => onChange(e.target.value, RfqServicePricing.HandlingIn)}
                />
                {errors?.[RfqServicePricing.HandlingIn] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingIn]}</div>
                ) : null}
            </div>
            <div>
                <Input
                    label={RfqServicePricingLabel[RfqServicePricing.HandlingOut]}
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.handlingOut?.price}
                    onChange={(e) => onChange(e.target.value, RfqServicePricing.HandlingOut)}
                />
                {errors?.[RfqServicePricing.HandlingOut] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingOut]}</div>
                ) : null}
            </div>
        </div>
    )
}
