import React, { FC, memo } from 'react'
import CheckBoxNew from '@/components/CheckboxNew'
import { IServiceOption } from '@/pages/Warehouses/Services/ServiceOptions'
import { BsInfoCircleFill } from '@react-icons/all-files/bs/BsInfoCircleFill'
import Tooltip from 'react-tooltip-lite'
import cn from 'classnames'

const DEFAULT_HAZMAT_TOOLTIPS: { [key: string]: string } = {
    'hazmat-class-1-explosives':
        'Division 1.1:      Explosives which have a mass explosion hazard\n' +
        'Division 1.2:      Explosives which have a projection hazard but not a mass explosion hazard\n' +
        'Division 1.3:      Explosives which have a fire hazard and either a minor blast hazard or a minor projection hazard or both, but not a mass explosion hazard\n' +
        'Division 1.4:      Explosives which present no significant blast hazard\n' +
        'Division 1.5:      Very insensitive explosives with a mass explosion hazard\n' +
        'Division 1.6:      Extremely insensitive articles which do not have a mass explosion hazard',
    'hazmat-class-2-gasses':
        'Division 2.1:      Flammable gases\n' +
        'Division 2.2:      Non-flammable, non-toxic gases\n' +
        'Division 2.3:      Toxic gases',
    'hazmat-class-3-flammable-liquids': 'Flammable liquids (and Combustible liquids [U.S.])',
    'hazmat-class-4-flammable-solids':
        'Division 4.1:      Flammable solids, self-reactive substances and solid desensitized explosives\n' +
        'Division 4.2:      Substances liable to spontaneous combustion\n' +
        'Division 4.3:      Substances which in contact with water emit flammable gases',
    'hazmat-class-5-oxidizing-substances':
        'Division 5.1:      Oxidizing substances\n' + 'Division 5.2:      Organic peroxides',
    'hazmat-class-6-toxic-and-infectious-substances':
        'Division 6.1:      Toxic* substances\n' + 'Division 6.2:      Infectious substances',
    'hazmat-class-7-radioactive-materials': 'Radioactive materials',
    'hazmat-class-8-corrosive-substances': 'Corrosive substances',
    'hazmat-class-9-miscellaneous-dangerous-goods': 'Miscellaneous dangerous goods/hazardous materials and articles',
}

interface HazmatTooltipProps {
    id: string
}

const HazmatTooltip = memo(({ id }: HazmatTooltipProps) => {
    const tooltipText: string = DEFAULT_HAZMAT_TOOLTIPS[id]

    const texts = tooltipText.split('\n').map((item, index) => {
        return (
            <React.Fragment key={index}>
                <div style={{ marginTop: index === 0 ? '0' : '8px' }}>{item}</div>
            </React.Fragment>
        )
    })

    return (
        <Tooltip
            content={texts}
            className={cn('warehouses-services-page__hazmat-class__tooltip')}
            distance={15}
            background="white"
            tipContentHover
        >
            <BsInfoCircleFill size={16} />
        </Tooltip>
    )
})

interface HazmatChecklistProps {
    options: IServiceOption[]
    onChange: (id: string, isChecked: boolean) => void
    editMode?: boolean
}

const HazmatChecklist: FC<HazmatChecklistProps> = ({ options, onChange, editMode }) => {
    return (
        <div className="warehouses-services-page__checkboxes">
            {options
                .filter((opt) => editMode || opt.isSelected)
                .map((option) => (
                    <div className="warehouses-services-page__hazmat-class" key={option.id}>
                        <CheckBoxNew
                            readOnly={!editMode}
                            lightStyle
                            name={option.id}
                            checked={!!option.isSelected}
                            onChange={() => onChange(option.id, !option.isSelected)}
                            label={option.name}
                        />
                        <HazmatTooltip id={option.id} />
                    </div>
                ))}
        </div>
    )
}

export default HazmatChecklist
