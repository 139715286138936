import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import cn from 'classnames'
import { Loader } from '@/components/Loader'
import Dropdown from '@/components/Dropdown'

import Icon from '../Icon/Icon'
import SelectItem, { ISelectItem } from './SelectItem'
import './Select.scss'

export interface ISelect {
    items?: any[]
    className?: string
    value: string | boolean | number | undefined
    label?: string
    placeholder?: string
    name: string
    max?: number
    type?: string
    validate?: boolean
    editable?: boolean
    disabled?: boolean
    sort?: boolean
    loading?: boolean
    error?: string
    textError?: boolean
    errorHide?: boolean
    newVariant?: boolean
    canEdit?: (item: ISelectItem) => boolean
    onAction?: ISelectItem['onAction']
}

const getStyleValue = (el: HTMLElement | null, prop: string): number =>
    Number(window.getComputedStyle(el!).getPropertyValue(prop).replace('px', ''))

const Select: React.FC<ISelect> = ({
    type = 'right',
    className,
    label,
    error,
    placeholder,
    max = 200,
    sort,
    editable,
    disabled,
    loading,
    items = [],
    value,
    canEdit,
    onAction,
    newVariant = false,
}) => {
    const [hasScroll, setHasScroll] = useState(false)
    const [forceHide, setForceHide] = useState(false)
    const scrollbarRef = useRef<any>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const optionsRef = useRef<HTMLDivElement>(null)
    const selected = useMemo(() => items.find((i) => i.value === value), [items, value])

    const handleToggle = useCallback(
        (opened) => {
            const option = scrollbarRef.current?.el.getElementsByClassName('first')[0]
            const content = optionsRef.current
            const wrap = wrapperRef.current
            if (option && content && opened) {
                const itemHeight =
                    option.clientHeight + getStyleValue(option, 'margin-bottom') + getStyleValue(option, 'margin-top')
                const containerPadding = getStyleValue(wrap, 'padding-bottom') + getStyleValue(wrap, 'padding-top')
                const maxHeight = itemHeight * max + containerPadding

                const height = items.length * itemHeight + containerPadding
                const scroll = height > maxHeight
                setHasScroll(scroll)
                setForceHide(false)

                scrollbarRef.current.el.style.height = scroll ? `${maxHeight}px` : 'auto'
                scrollbarRef.current.recalculate()
            }
        },
        [items, max, scrollbarRef, optionsRef, wrapperRef],
    )

    const handleAction: ISelectItem['onAction'] = useCallback(
        (opts) => {
            if (onAction) {
                onAction(opts)
            }
            setForceHide(true)
        },
        [onAction],
    )
    return (
        <div className={cn('select', className)}>
            <Dropdown
                type="select"
                forceHide={forceHide}
                onToggle={handleToggle}
                className={cn(type, {
                    empty: !selected,
                    'has-scroll': hasScroll,
                    editable,
                    disabled: !items.length || disabled,
                })}
                toggleClass="current"
                toggleElement={
                    <>
                        <div className="select-wrap">
                            {label && (
                                <div className={newVariant ? 'select-label ffBeVietnamPro' : 'label'}>{label}</div>
                            )}
                        </div>

                        <div className="text-wrapper">
                            {loading && <Loader type="select" />}

                            <div className={cn('text', { placeholder: !selected, 'has-error': error })}>
                                {selected?.icon && <Icon code={selected.icon} type="cards" />}
                                <span className="name">{selected ? selected.name : placeholder}</span>
                            </div>
                            {!loading && <div className="icon-arrow" />}
                        </div>
                    </>
                }
            >
                <div className="options" ref={optionsRef}>
                    <SimpleBar className="scrollbar" ref={scrollbarRef} scrollbarMinSize={30}>
                        <div className="s-content" ref={wrapperRef}>
                            {items.map((item, i) => {
                                const key = item.name + i
                                return (
                                    <SelectItem
                                        key={key}
                                        editable={editable && (!canEdit || canEdit(item))}
                                        sort={sort}
                                        index={i}
                                        icon={item.icon}
                                        name={item.name || item.title}
                                        value={item.value}
                                        selected={selected?.value}
                                        onAction={handleAction}
                                    />
                                )
                            })}
                        </div>
                    </SimpleBar>
                </div>
            </Dropdown>
        </div>
    )
}

export default memo(Select)
