import React, { FC } from 'react'

import { headerClass } from '@/pages/RFQPortal/components/RfqTemplate'
import { Opportunity } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

interface TemplateTitleProps {
    isProjectSingle: boolean | null
    projectData: Opportunity
    oppIndex: number
}

const TemplateTitle: FC<TemplateTitleProps> = ({ isProjectSingle, projectData, oppIndex }) => {
    return (
        <h2 className={`${headerClass}-title`}>
            {isProjectSingle
                ? 'On Demand'
                : projectData.serviceLocation?.formattedAddress && projectData.id
                ? `Location ${oppIndex + 1} - "${projectData.serviceLocation?.formattedAddress}"`
                : `Location ${oppIndex + 1}`}
        </h2>
    )
}

export default TemplateTitle
