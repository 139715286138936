import React, { FC, memo, useEffect, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import './UnableDeleteAccount.scss'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import { getUnableDeleteAccountModalColumns } from './getUnableDeleteAccountModalColumns'
import { WarehouseOrdersListResponse, WHOrdersListOptions } from '@shared/apiv2/wh-orders'
import { HttpV2 } from '@shared/apiv2/api'
import { objectToQueryString, Pagination } from '@shared/apiv2/list-query.utils'
import { Loader } from '@/components/Loader'

const fetchUnfinishedOrders = async (warehouseID: string, pagination: Pagination, signal: AbortSignal) => {
    const params: WHOrdersListOptions = {
        filters: { search: '' },
        sorting: { id: '', desc: true },
        pagination,
    }

    const { data } = await HttpV2.get<WarehouseOrdersListResponse>(
        `accounts/${warehouseID}/warehouse/orders/unfinished?${objectToQueryString(params)}`,
        { signal: signal },
    )
    return data
}

interface UnableDeleteAccountModalProps {
    accountId: string
    accountName: string
}

const UnableDeleteAccountModal: FC<UnableDeleteAccountModalProps> = ({
    accountId,
    accountName,
}: UnableDeleteAccountModalProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const [ordersData, setOrdersData] = useState<WarehouseOrdersListResponse | null>(null)

    useEffect(() => {
        const controller = new AbortController()
        setIsLoading(true)
        fetchUnfinishedOrders(accountId, pagination, controller.signal)
            .then((data) => {
                setOrdersData(data)
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => setIsLoading(false))
        return () => {
            controller.abort()
        }
    }, [pagination])

    const columns = getUnableDeleteAccountModalColumns()

    return (
        <div className="space-y-5">
            <h2 className="text-2xl">Cannot Delete &quot;{accountName}&quot;</h2>
            <div>This warehouse has open orders</div>
            {isLoading || !ordersData ? (
                <Loader />
            ) : (
                <ModernTable
                    columns={columns}
                    data={ordersData.data}
                    total={ordersData.count}
                    pagination={pagination}
                    onPaginationChange={(updater) => setPagination(updater)}
                />
            )}
            <Button
                types={['blue']}
                className="ml-auto py-0 px-3"
                onClick={() => ModalRegistry.get().close(Modals.UnableDeleteAccountModal)}
            >
                Okay
            </Button>
        </div>
    )
}

ModalRegistry.get().register<UnableDeleteAccountModalProps>(Modals.UnableDeleteAccountModal, {
    id: 'UnableDeleteAccountModalProps',
    className: 'modal-unable-delete-account',
    size: 'large',
    Component: memo(UnableDeleteAccountModal),
})

export const showUnableDeleteAccountModal = (props: ModalPartialProps<UnableDeleteAccountModalProps>): void =>
    ModalRegistry.get().show<UnableDeleteAccountModalProps>(Modals.UnableDeleteAccountModal, props)

export default memo(UnableDeleteAccountModal)
