import React, { FC, RefObject, useState } from 'react'
import '@/pages/RFQPortal/assets/style/rfq-form.scss'
import { tcn } from '@/utils/tcn'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'

interface TextareaProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    id?: string
    label?: string
    errorMessage?: string | false
    isInitEmpty?: boolean
    options: object
    wrapperRef?: RefObject<HTMLDivElement>
    wrapperClassName?: string
}

const Select: FC<TextareaProps> = ({
    id,
    label,
    errorMessage,
    wrapperRef,
    isInitEmpty = true,
    options,
    value,
    wrapperClassName,
    ...rest
}) => {
    const checkIfError = errorMessage ? 'input-error' : ''
    const [inputId] = useState(id || generateRandomId())

    return (
        <div className={tcn('select-wrapper', wrapperClassName)} ref={wrapperRef}>
            {label && <label htmlFor={inputId}>{label}</label>}
            <select id={inputId} {...rest} className={checkIfError} value={value}>
                {isInitEmpty && <option key="undefined"></option>}
                {Object.entries(options).map(([item, name]) => {
                    return (
                        <option key={`${item}-${inputId}`} value={name}>
                            {name}
                        </option>
                    )
                })}
            </select>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default Select
