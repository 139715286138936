/* eslint-disable complexity */
import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { LoadBoardPostBodyDto, createLBPost, submitLBPost, updateLBPost } from '@shared/apiv2/load-board'
import { useNavigate } from 'react-router-dom'
import { ModalPartialProps, Modals } from '@shared/types'
import { ModalRegistry } from '@shared/common/modals'
import { toast } from 'react-toastify'

// styles
import './loadBoardStyles.scss'

// components
import {
    DEFAULT_COUNTER_LENGTH,
    EXTRA_COUNTER_LENGTH,
    LoadBoardModalProps,
    LoadBoardServicesType,
    START_COUNTER,
    SubmittedButtonNameType,
    nextButtonTitle,
    submittedButtonNames,
    NextButtonNameType,
    LOADBOARD_CHECKBOXES,
} from './types'
import { checkIsServices, handleEntityChange, initialValuesLoadBoard, setOptionsForLoadBoardSteps } from './helper'
import { ProgressBar } from '@/components/ProgressBar'
import { BottomNavigation } from './BottomNavigation'
import { LoadBoarCancel } from './LoadBoarCancel'
import { StepsList } from './Steps/StepsList'

const LoadBoardModal: FC<LoadBoardModalProps> = ({ data, revalidate }) => {
    const [counter, setCounter] = useState<{ start: number; end?: number }>({
        start: START_COUNTER,
        end: DEFAULT_COUNTER_LENGTH,
    })
    const [disabled, setDisabled] = useState(false)
    const [nextButtonName, setNextButtonName] = useState<NextButtonNameType>(nextButtonTitle.Next)
    const [isCounter, setIsCounter] = useState(false)
    const [submittedButtonName, setSubmittedButtonName] = useState<SubmittedButtonNameType>(
        submittedButtonNames.SaveAsDraft,
    )
    const [isBiddingDate, setIsBiddingDate] = useState(false)
    const [isCancel, setIsCancel] = useState(false)
    const [loadBoardDraftId, setLoadBoardDraftId] = useState<number>()
    const [biddingCloseDate, setBiddingCloseDate] = useState(new Date())
    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            setLoadBoardDraftId(data?.id)
        }
    }, [data])

    const formik = useFormik<LoadBoardPostBodyDto>({
        initialValues: data ? data : initialValuesLoadBoard,
        onSubmit: async (values) => {
            try {
                setDisabled(false)
                // save draft
                if (submittedButtonName === submittedButtonNames.SaveAsDraft && values.services && !loadBoardDraftId) {
                    await saveDraft(values)
                }

                // update draft
                if (submittedButtonName === submittedButtonNames.SaveAsDraft && values.services && loadBoardDraftId) {
                    await updateDraft(values)
                }

                // submitted post
                if (
                    submittedButtonName === submittedButtonNames.SubmitToLoadBoard &&
                    loadBoardDraftId &&
                    nextButtonName !== nextButtonTitle.Review
                ) {
                    console.info('submitted post')

                    // submit to load board
                    await submittedPost(values)
                }

                // create and submitted post
                if (submittedButtonName === submittedButtonNames.SubmitToLoadBoard && !loadBoardDraftId) {
                    console.info('create and submitted post')
                    // create post
                    await createAndSubmitPost(values)
                }
                // ViewPost
                if (submittedButtonName === submittedButtonNames.ViewPost && !data?.id) {
                    ModalRegistry.get().close(Modals.LoadBoardPostFormModal)
                    navigate(`/loadboard/${loadBoardDraftId}`)
                }
            } catch (errors: any) {
                const { message } = errors.response.data
                if (errors) {
                    console.log('LoadBoard submit errors', errors)
                    if (Array.isArray(message)) {
                        message?.forEach((elem: any) => {
                            setDisabled(true)
                            formik.setErrors(elem)
                            toast(elem, { type: 'error' })
                        })
                    }

                    if (typeof message === 'string' && message === 'Internal server error') {
                        setDisabled(true)
                        formik.setErrors('Wrong address' as any)
                        toast('Wrong address', { type: 'error' })
                    }
                }
            }
        },
    })

    const saveDraft = async (values: LoadBoardPostBodyDto) => {
        const createdLbPost = await createLBPost(values)
        await setLoadBoardDraftId(createdLbPost?.data?.id) // set id
        console.log('createdLbPost', createdLbPost)
        toast('Your draft has been saved', { type: 'success' })
        formik.resetForm({ values: { ...values, ...createdLbPost } })
        revalidate()
        setDisabled(false)
    }
    const updateDraft = async (values: LoadBoardPostBodyDto) => {
        const updatedLbPost = await updateLBPost(loadBoardDraftId!, values)
        console.log('updatedLbPost', updatedLbPost)
        toast('Your draft has been saved', { type: 'success' })
        formik.resetForm({ values: { ...values, ...updatedLbPost } })
        revalidate()
    }
    const submittedPost = async (values: LoadBoardPostBodyDto) => {
        await updateLBPost(loadBoardDraftId!, values)
        await submitLBPost(loadBoardDraftId!, biddingCloseDate)
        toast('Post has been created', { type: 'success' })

        // if resume post don't show View Post, just close modal
        if (data) {
            ModalRegistry.get().close(Modals.LoadBoardPostFormModal)
        }
        revalidate()
        setSubmittedButtonName(submittedButtonNames.ViewPost)
    }
    const createAndSubmitPost = async (values: LoadBoardPostBodyDto) => {
        const createdLbPost = await createLBPost(values)
        setLoadBoardDraftId(createdLbPost?.data?.id)
        // submit to load board
        await submitLBPost(createdLbPost?.data?.id, biddingCloseDate)

        setSubmittedButtonName(submittedButtonNames.ViewPost)
        toast('Your post is submitted and available now', { type: 'success' })
    }

    // checkboxes
    const handleServicesChange = (e: any) =>
        handleEntityChange({ entityName: LOADBOARD_CHECKBOXES.services, e, formik })
    const handleEquipmentChange = (e: any) =>
        handleEntityChange({ entityName: LOADBOARD_CHECKBOXES.equipment, e, formik })
    const handleCertificationsChange = (e: any) =>
        handleEntityChange({ entityName: LOADBOARD_CHECKBOXES.certifications, e, formik })
    const handleFeaturesChange = (e: any) =>
        handleEntityChange({ entityName: LOADBOARD_CHECKBOXES.features, e, formik })

    const setPercentage = (percent: number) => percent * (100 / counter.end!)

    const {
        isWarehousingSteps,
        isTransloadingSteps,
        isDrayageSteps,
        isWarehousingAndTransloadingSteps,
        isWarehousingAndDrayageSteps,
        isTransloadingAndDrayageSteps,
        isAllServicesSteps,
    } = checkIsServices({ loadBoardServices: formik.values.services })

    // set extra counter for multiple services
    useMemo(() => {
        if (isWarehousingAndTransloadingSteps || isWarehousingAndDrayageSteps || isTransloadingAndDrayageSteps) {
            setCounter({ start: START_COUNTER, end: DEFAULT_COUNTER_LENGTH + START_COUNTER })
        } else if (isAllServicesSteps) {
            setCounter({ start: START_COUNTER, end: DEFAULT_COUNTER_LENGTH + 2 })
        } else {
            setCounter({ start: START_COUNTER, end: DEFAULT_COUNTER_LENGTH })
        }
    }, [
        isAllServicesSteps,
        isTransloadingAndDrayageSteps,
        isWarehousingAndDrayageSteps,
        isWarehousingAndTransloadingSteps,
    ])

    // set Review for btn
    useMemo(() => {
        if (isWarehousingAndTransloadingSteps && counter.start === EXTRA_COUNTER_LENGTH) {
            setNextButtonName(nextButtonTitle.Review)
        }
        if (isWarehousingAndDrayageSteps && counter.start === EXTRA_COUNTER_LENGTH) {
            setNextButtonName(nextButtonTitle.Review)
        }
        if (isTransloadingAndDrayageSteps && counter.start === EXTRA_COUNTER_LENGTH) {
            setNextButtonName(nextButtonTitle.Review)
        }
        if (isAllServicesSteps && counter.start === EXTRA_COUNTER_LENGTH + 1) {
            setNextButtonName(nextButtonTitle.Review)
        }
        if (counter.start === counter.end) {
            setSubmittedButtonName(submittedButtonNames.SaveAsDraft)
        }
    }, [
        isWarehousingAndTransloadingSteps,
        counter.start,
        counter.end,
        isWarehousingAndDrayageSteps,
        isTransloadingAndDrayageSteps,
        isAllServicesSteps,
    ])

    const nextClick = () => {
        if (nextButtonName === nextButtonTitle.Review) {
            setNextButtonName(nextButtonTitle.ReviewPage)
            setSubmittedButtonName(submittedButtonNames.SubmitToLoadBoard)
            setIsBiddingDate(true)
            return
        }
        if (counter.start <= counter.end!) {
            setCounter({ start: ++counter.start, end: counter.end })

            const services = formik.values.services?.toLowerCase()

            if (
                (services === LoadBoardServicesType.Warehousing.toLowerCase() ||
                    services === LoadBoardServicesType.Transloading.toLowerCase() ||
                    services === LoadBoardServicesType.Drayage.toLowerCase()) &&
                counter.start === counter.end
            ) {
                setNextButtonName(nextButtonTitle.Review)
                setCounter({ start: DEFAULT_COUNTER_LENGTH, end: DEFAULT_COUNTER_LENGTH })
                return
            }
            setIsCounter(false)
            setNextButtonName(nextButtonTitle.Next)
        } else {
            setCounter({ start: counter.end!, end: counter.end! })
        }
    }

    const prevClick = () => {
        setCounter({ start: --counter.start, end: counter.end })

        // disabled next button on 1 step
        if (counter.start === START_COUNTER && formik.values.services) {
            setDisabled(false)
        }

        // if review btn
        if (nextButtonName === nextButtonTitle.Review && counter.start < counter.end!) {
            setNextButtonName(nextButtonTitle.Next)
        }
        // if ReviewPage
        if (nextButtonName === nextButtonTitle.ReviewPage && counter.start < counter.end!) {
            setNextButtonName(nextButtonTitle.Review)
            setCounter({ start: counter.end!, end: counter.end })
            setIsBiddingDate(true)
        }

        // from submit post go back to review page
        if (nextButtonName === nextButtonTitle.ReviewPage) {
            setSubmittedButtonName(submittedButtonNames.SaveAsDraft)
        }
    }

    const cancelClick = () => {
        setIsCancel(!isCancel)
        if (revalidate) {
            revalidate()
        }
    }

    const {
        stepOptionMain,
        stepOptionWarehouse,
        stepOptionAdditionalDetails,
        stepOptionReview,
        stepOptionDrayage,
        stepOptionTransloading,
    } = setOptionsForLoadBoardSteps({
        formik,
        cancelClick,
        submittedButtonName,
        isBiddingDate,
        biddingCloseDate,
        setBiddingCloseDate,
    })

    const stepListProps = {
        counter,
        formik,
        handleServicesChange,
        handleCertificationsChange,
        handleEquipmentChange,
        handleFeaturesChange,
        cancelClick,
        setDisabled,
        isWarehousingSteps,
        stepOptionMain,
        stepOptionWarehouse,
        nextButtonName,
        stepOptionAdditionalDetails,
        stepOptionReview,
        isTransloadingSteps,
        isDrayageSteps,
        stepOptionDrayage,
        isWarehousingAndTransloadingSteps,
        stepOptionTransloading,
        isWarehousingAndDrayageSteps,
        isTransloadingAndDrayageSteps,
        isAllServicesSteps,
    }

    return (
        <>
            {isCancel && <LoadBoarCancel setIsCancel={setIsCancel} isCancel={isCancel} resetForm={formik.resetForm} />}

            <StepsList {...stepListProps} />

            <div style={{ position: 'relative' }}>
                {/* next, back buttons, +  counter */}
                <div style={{ height: 40, marginBottom: 16 }}>
                    {formik.values.services && (
                        <BottomNavigation
                            disabled={disabled}
                            counter={counter}
                            prevClick={prevClick}
                            nextClick={nextClick}
                            nextButtonName={nextButtonName}
                            isCounter={isCounter}
                        />
                    )}
                </div>

                {/* Progress bar */}
                <div style={{ width: 800 }}>
                    <ProgressBar percent={setPercentage(counter.start)} />
                </div>
            </div>
        </>
    )
}

ModalRegistry.get().register<LoadBoardModalProps>(Modals.LoadBoardPostFormModal, {
    id: 'LoadBoardModalProps',
    className: 'load-board',
    size: 'large',
    Component: memo(LoadBoardModal),
})

export const showLoadBoardModal = (props: ModalPartialProps<LoadBoardModalProps>): void =>
    ModalRegistry.get().show<LoadBoardModalProps>(Modals.LoadBoardPostFormModal, props)

export const showLoadBoardModalAsync = (props: ModalPartialProps<LoadBoardModalProps>): Promise<void> =>
    ModalRegistry.get().showAsync<LoadBoardModalProps>(Modals.LoadBoardPostFormModal, props)

export default memo(LoadBoardModal)
