import { useState, useEffect } from 'react'

export interface PaginationInfo {
    pageIndex: number
    pageSize: number
}

const useCustomPagination = <T>(items: T[], pagination: PaginationInfo) => {
    const { pageIndex, pageSize } = pagination

    const [paginatedItems, setPaginatedItems] = useState<T[]>([])

    useEffect(() => {
        const start = pageIndex * pageSize
        const end = start + pageSize

        setPaginatedItems(items.slice(start, end))
    }, [items, pageIndex, pageSize])

    return {
        paginatedItems,
    }
}

export default useCustomPagination
