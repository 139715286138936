import React, { useRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import cn from 'classnames'
import Dropdown from '@/components/Dropdown'
import { computeMonthWeeks, IDayObject } from '@/components/Datepicker/utils'
import Button from '@/components/Button'
import './Datepicker.scss'
import { ReactComponent as Calendar } from '@/assets/images/datepicker-calendar.svg'

export interface IOnDatepickerChangeArgs {
    start?: dayjs.Dayjs
    end?: dayjs.Dayjs
}

export interface IDatepicker {
    start?: string
    end?: string
    placeholder?: string
    lang?: 'en' | 'ru'
    type?: string
    dateFormat?: string
    range?: boolean
    past?: boolean
    minInterval?: number
    hasReset?: boolean
    label?: string
    newVariant?: boolean
    defaultDate?: string

    error?: boolean
    errorHide?: boolean

    max?: string
    min?: string
    required?: boolean

    onChange?: (obj: IOnDatepickerChangeArgs) => void
}

const TODAY = dayjs().startOf('day')
const MONTH_FORMAT = 'MMMM YYYY'
const WEEK_DAYS = {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    ru: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
}

const DATE_FORMAT = 'MM.DD.YYYY'

interface IDay extends IDayObject {
    selectedStart?: dayjs.Dayjs
    selectedEnd?: dayjs.Dayjs
    onSelect: (day: IDayObject) => void
}

const Day: React.FC<IDay> = ({
    date,
    num,
    onSelect,
    selectedEnd,
    selectedStart,
    disabled,
    otherMonth,
    today,
    limitDate,
}) => {
    const handleClick = useCallback(() => {
        onSelect({
            date,
            num,
            disabled,
            otherMonth,
            today,
            limitDate,
        })
    }, [date, disabled, limitDate, num, onSelect, otherMonth, today])

    const selected = useMemo(() => {
        if (selectedStart && selectedEnd) {
            return dayjs(date) >= selectedStart && dayjs(date) <= selectedEnd
        }
        if (!selectedEnd) {
            return selectedStart && dayjs(date).isSame(selectedStart)
        }
        return false
    }, [date, selectedEnd, selectedStart])

    return (
        <div
            role="button"
            tabIndex={-1}
            className={cn('col', {
                disabled,
                selected,
                'other-month': otherMonth,
                today,
                'limit-date': limitDate,
                'start-date': date === selectedStart?.format(),
                'end-date': date === selectedEnd?.format(),
            })}
            onClick={handleClick}
        >
            {num}
            <div className="active" />
        </div>
    )
}

/**
 * @description Datepicker - date picker
 * @param {boolean} newVariant - switched to a new view label variant, don't forget to write label=""
 */

// eslint-disable-next-line complexity
const Datepicker: React.FC<IDatepicker> = ({
    start,
    end,
    min,
    max,
    defaultDate,
    range = true,
    lang = 'en',
    required,
    label,
    type,
    error,
    past,
    onChange,
    minInterval,
    dateFormat = DATE_FORMAT,
    placeholder,
    hasReset = true,
    newVariant = false,
}) => {
    const datePickerRef = useRef<HTMLInputElement | null>(null)

    const [isOpen, setIsOpen] = useState(false)
    const [month, setMonth] = useState(dayjs(defaultDate || start).startOf('month'))
    const [selectedStart, setSelectedStart] = useState<dayjs.Dayjs | undefined>(
        dayjs(defaultDate || start).startOf('day'),
    )
    const [selectedEnd, setSelectedEnd] = useState<dayjs.Dayjs | undefined>(
        range ? dayjs(end).startOf('day') : selectedStart,
    )
    const months = useMemo(
        () =>
            computeMonthWeeks(month, {
                selectedDateEnd: selectedEnd,
                selectedDateStart: selectedStart,
                today: TODAY,
                min,
                max,
                lang,
                past,
            }),
        [month, selectedEnd, selectedStart, min, max, lang, past],
    )
    const selected = useMemo(() => {
        if (start) {
            const s = dayjs(start)
            const e = dayjs(end)

            return !range || s.isSame(e) ? s.format(dateFormat) : `${s.format(dateFormat)} — ${e.format(dateFormat)}`
        }
        return null
    }, [dateFormat, end, range, start])

    useEffect(() => {
        setSelectedStart(dayjs(start).startOf('day'))
        setSelectedEnd(dayjs(range ? end : start).startOf('day'))
    }, [end, range, start])

    const weekDays = useMemo(
        () =>
            (WEEK_DAYS[lang] || WEEK_DAYS.en).map((item) => (
                <div key={`day-${item}`} className="col">
                    {item}
                </div>
            )),
        [lang],
    )

    const handleSelect = useCallback(
        (day: IDayObject) => {
            const dayDate = dayjs(day.date)

            if (range) {
                if ((selectedStart && selectedEnd) || !selectedStart) {
                    setSelectedStart(dayDate)
                    setSelectedEnd(undefined)
                } else if (!selectedEnd) {
                    if (dayDate < selectedStart) {
                        setSelectedEnd(selectedStart)
                        setSelectedStart(dayDate)
                    } else {
                        setSelectedEnd(dayDate)
                    }
                }
            } else {
                setSelectedStart(dayDate)
                setSelectedEnd(dayDate)
            }
        },
        [range, selectedEnd, selectedStart],
    )

    const handlePrevMonth = useCallback(() => {
        setMonth(month.add(-1, 'month').startOf('month'))
    }, [month])

    const handleNextMonth = useCallback(() => {
        setMonth(month.add(1, 'month').startOf('month'))
    }, [month])

    const handlePrevYear = useCallback(() => {
        setMonth(month.add(-1, 'year').startOf('year'))
    }, [month])

    const handleNextYear = useCallback(() => {
        setMonth(month.add(1, 'year').startOf('year'))
    }, [month])

    const handleApply = useCallback(() => {
        let calcEnd = selectedEnd || selectedStart

        if (range) {
            if (minInterval && calcEnd && selectedStart && calcEnd.diff(selectedStart) < minInterval) {
                calcEnd = selectedStart.add(minInterval, 'days')
            }
            if (calcEnd) {
                setSelectedEnd(calcEnd)
            }
        }
        if (onChange) {
            onChange({
                start: selectedStart,
                end: calcEnd,
            })
            setIsOpen(false)
        }
    }, [minInterval, onChange, range, selectedEnd, selectedStart])

    const handleReset = useCallback(() => {
        setSelectedEnd(undefined)
        setSelectedStart(undefined)
        if (onChange) {
            onChange({
                start: undefined,
                end: undefined,
            })
            setIsOpen(false)
        }
    }, [onChange])

    const handleToggle = useCallback((state) => {
        setIsOpen(state)
    }, [])

    const handleFocusOut = (event: React.FocusEvent<HTMLDivElement>) => {
        const target = event.relatedTarget as Node
        if (datePickerRef.current && !datePickerRef.current.contains(target)) {
            handleApply()
        }
    }

    useEffect(() => {
        if (range) {
            setSelectedEnd(undefined)
            setSelectedStart(undefined)
        }
    }, [range])

    return (
        <div className="datepicker" ref={datePickerRef} onBlur={handleFocusOut}>
            <div className="datepicker-wrapper">
                {label && <div className={newVariant ? 'datepicker-label ffBeVietnamPro' : 'label'}>{label}</div>}
            </div>

            <Dropdown
                type="right"
                open={isOpen}
                onToggle={handleToggle}
                toggleElement={
                    <>
                        <div
                            className={cn('result-picker', type, {
                                'has-error': error,
                                placeholder: Boolean(placeholder),
                            })}
                        >
                            {newVariant ? (
                                <span>{selected || (placeholder ?? '')}</span>
                            ) : (
                                <span>{selected || (placeholder ?? 'Select...')}</span>
                            )}

                            {/* <span className="icon-calendar" /> */}
                            <Calendar className="icon-calendar" />
                        </div>
                    </>
                }
            >
                <div className="date-picker">
                    <div className="datepicker-header">
                        <div className="month-header">
                            <div
                                role="button"
                                tabIndex={0}
                                className="arrow double arrow-left"
                                onClick={handlePrevYear}
                            />
                            <div role="button" tabIndex={-1} className="arrow arrow-left" onClick={handlePrevMonth} />
                            <span className="current-month">{month.format(MONTH_FORMAT)}</span>
                            <div role="button" tabIndex={-2} className="arrow arrow-right" onClick={handleNextMonth} />
                            <div
                                role="button"
                                tabIndex={-3}
                                className="arrow double arrow-right"
                                onClick={handleNextYear}
                            />
                        </div>

                        {range && (
                            <div className="days-info">
                                <p>
                                    <span className="days-info-label">Start: </span>
                                    {selectedStart ? dayjs(selectedStart).format('YYYY-MM-DD') : undefined}
                                </p>
                                <p>
                                    <span className="days-info-label">End: </span>
                                    {selectedEnd ? dayjs(selectedEnd).format('YYYY-MM-DD') : undefined}
                                </p>

                                {!selectedStart && <p className="days-info-message">Choose START date</p>}
                                {selectedStart && !selectedEnd && <p className="days-info-message">Choose END date</p>}
                            </div>
                        )}

                        <div className="days-header">{weekDays}</div>
                    </div>

                    <div className="body">
                        {months.map((week, i) => (
                            /* eslint-disable-next-line react/no-array-index-key */
                            <div key={`week-${i}`} className="row row-body">
                                {week.map((day, ii) => (
                                    <Day
                                        key={`day-${(i + 1) * (ii + 1)}`}
                                        {...day}
                                        selectedEnd={selectedEnd}
                                        selectedStart={selectedStart}
                                        onSelect={handleSelect}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>

                    <div className={cn('datepicker-footer', { 'has-reset': hasReset })}>
                        {hasReset && (
                            <Button
                                label="Reset"
                                types={['bordered', 'small']}
                                disabled={!selectedStart}
                                onClick={handleReset}
                            />
                        )}
                        <Button
                            label="Select"
                            types={['blue', 'small']}
                            onClick={handleApply}
                            disabled={!selectedStart}
                        />
                    </div>
                </div>
            </Dropdown>

            {/* </dropdown> */}

            {/*    <div className="w-error" v-if="! errorHide"> */}
            {/*        <div className="error" v-if="!! error"> */}
            {/*            {{ error }} */}
            {/*        </div> */}
            {/*    </div> */}
        </div>
    )
}

export default memo(Datepicker)
