import React from 'react'
import './WarehouseGeneral.scss'
import { HttpV2 } from '@shared/apiv2/api'
import Button from '@/components/Button'
import { showPrimaryContactModal } from '@/parts/Modals/PrimaryContactModal'
import { toast } from 'react-toastify'
import { Loader } from '@/components/Loader'
import useMobileScreen from '@/hooks/useMobileScreen'

type PrimaryContactProps = {
    contact: any
    warehouseId: string
    loading: boolean
    manager: boolean
    reload: () => void
}

export default function PrimaryContact({ contact, warehouseId, reload, loading, manager }: PrimaryContactProps) {
    const isMobile = useMobileScreen({ screenWidth: 1140 })

    const setMainContact = async (id: string, accountId: string) => {
        try {
            const resp = await HttpV2.put(`/accounts/${warehouseId}/warehouse/primary-contact/${id}`)
            if (resp.status === 200) {
                reload()
                toast('Success', { type: 'success' })
            }
        } catch (error: any) {
            console.log('Error: ', error?.response?.data?.message)
            toast(error?.response?.data?.message, { type: 'error' })
        }
    }

    const changePrimContactLabel = isMobile ? 'Change' : 'Change Contact'

    return (
        <div className="warehouses-general__primary-contact">
            <div className="app-card__header bordered-block">
                <h2 className="app-card__title">Primary Contact</h2>
                {manager && (
                    <Button
                        type="button"
                        types={['bordered']}
                        label={changePrimContactLabel}
                        onClick={() =>
                            showPrimaryContactModal({
                                props: {
                                    title: 'Primary Contact',
                                    onSelect: setMainContact,
                                },
                            })
                        }
                    />
                )}
            </div>
            {loading ? (
                <Loader type="large" />
            ) : (
                <div className="primary-contact">
                    <div className="primary-contact__left">
                        <ul className="primary-contact__list">
                            <li className="primary-contact__item">
                                <p className="primary-contact__label">First Name</p>
                                <p className="primary-contact__text">{contact?.firstName}</p>
                            </li>
                            <li className="primary-contact__item">
                                <p className="primary-contact__label">Last Name</p>
                                <p className="primary-contact__text">{contact?.lastName}</p>
                            </li>
                        </ul>
                    </div>
                    <div className="primary-contact__right">
                        <ul className="primary-contact__list">
                            <li className="primary-contact__item">
                                <p className="primary-contact__label">Email</p>
                                <p className="primary-contact__text">{contact?.email}</p>
                            </li>
                            <li className="primary-contact__item">
                                <p className="primary-contact__label">Phone</p>
                                <p className="primary-contact__text">{contact?.phone}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}
