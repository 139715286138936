import React, { FC, useRef, useEffect, useState } from 'react'
import './DropZoneArea.scss'

import Dropzone from 'dropzone'
import setDropZoneConfig from '@/pages/RFQPortal/components/DropZoneArea/setDropzoneConfig'
import { ReactComponent as AddImage } from '@/assets/images/addImage.svg'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'
import { toast } from 'react-toastify'

import docIcon from '@/pages/RFQPortal/assets/img/DOC.png'
import docxIcon from '@/pages/RFQPortal/assets/img/DOCX.png'
import pdf from '@/pages/RFQPortal/assets/img/PDF.png'
import ppt from '@/pages/RFQPortal/assets/img/PPT.png'
import pptx from '@/pages/RFQPortal/assets/img/PPTX.png'
import xls from '@/pages/RFQPortal/assets/img/XLS.png'
import xlsx from '@/pages/RFQPortal/assets/img/XLSX.png'

interface DropZoneAreaProps {
    filesCollection: File[]
    changeFormData: (path: string, files: File[], filesdata?: any) => void
    name?: string // Unique identifier for the drop zone
    inputPath?: string //used for RFQ
    allowedFormats?: string[]
    // singleFile?: boolean
    wrapperClassName?: string
    maxFiles?: number
    maxFileSize?: number
}

export const notImageTypeIcons: { [key: string]: any } = {
    'application/msword': docIcon,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
    'application/pdf': pdf,
    'application/vnd.ms-powerpoint': ppt,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptx,
    'application/vnd.ms-excel': xls,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsx,
}

const DropZoneArea: FC<DropZoneAreaProps> = ({
    name,
    changeFormData,
    inputPath = '',
    filesCollection,
    allowedFormats = ['.jpg', '.png', '.jpeg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.pptx', '.ppt'],
    wrapperClassName,
    maxFiles = 4,
    maxFileSize = 15,
}) => {
    const [dzName] = useState(name || generateRandomId())
    const dropZoneRef = useRef<HTMLDivElement>(null)
    const previewsContainerId = `previews-container-${dzName}`

    useEffect(() => {
        const dropZoneElement = dropZoneRef.current

        if (dropZoneElement) {
            // Check if Dropzone is already attached to the element
            if (!dropZoneElement.classList.contains('dz-clickable')) {
                const dropZoneConfig = setDropZoneConfig(
                    dropZoneElement,
                    '#' + previewsContainerId,
                    allowedFormats,
                    maxFiles,
                    maxFileSize,
                )

                const myDropzone = new Dropzone(dropZoneElement, dropZoneConfig)

                myDropzone.on('addedfile', function (file) {
                    for (const fileType in notImageTypeIcons) {
                        if (file.type === fileType) {
                            const thumbnailElement = file.previewElement.querySelector(
                                '[data-dz-thumbnail]',
                            ) as HTMLImageElement
                            if (thumbnailElement) {
                                thumbnailElement.alt = file.name
                                thumbnailElement.src = notImageTypeIcons[fileType]

                                thumbnailElement.style.width = 'auto'
                                thumbnailElement.style.height = '90%'
                                thumbnailElement.style.margin = 'auto'
                                thumbnailElement.style.paddingTop = '20px'
                            }
                        }
                    }

                    const otherFiles = myDropzone.files.filter((dzFile) => dzFile !== file)

                    otherFiles.forEach((dzFile) => {
                        if (dzFile.name === file.name || dzFile.size === file.size) {
                            myDropzone.removeFile(file)
                            toast(`${file.name} has already been selected for upload`, {
                                type: 'warning',
                                autoClose: 3000,
                            })
                        }
                    })

                    // After a file is added, move the previews above the rfq-form__dropzone div
                    const previewsContainer = dropZoneElement.parentNode as ParentNode
                    const lastContainerChild = previewsContainer.lastElementChild as ParentNode

                    if (previewsContainer && lastContainerChild) {
                        previewsContainer.insertBefore(lastContainerChild, dropZoneElement)
                    }

                    // Add files to formData
                    changeFormData(inputPath, [...myDropzone.files], {
                        file_names: myDropzone.files.map((fileName) => fileName.name),
                        file_sizes: myDropzone.files.map((fileName) => fileName.size),
                        file_types: myDropzone.files.map((fileName) => fileName.type),
                        total_files: myDropzone.files.length,
                    })
                })
                myDropzone.on('removedfile', function (file) {
                    // Handle the file removal here
                    const updatedFiles = myDropzone.files.filter((f) => f !== file)
                    changeFormData(inputPath, [...updatedFiles])
                })

                if (filesCollection) {
                    filesCollection.forEach((file: any) => {
                        myDropzone.addFile(file)
                    })
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxFiles, maxFileSize, allowedFormats])

    const dropZoneClass = 'drop-zone'
    return (
        <div className={`${dropZoneClass}__wrapper ${wrapperClassName}`} id={previewsContainerId}>
            <div className={`${dropZoneClass} ${dropZoneClass}_active`} ref={dropZoneRef}>
                <AddImage className={`${dropZoneClass}__icon`} />
                <p className={`${dropZoneClass}__text`}>
                    Drop
                    <br />
                    or Browse
                </p>
            </div>
        </div>
    )
}

export default DropZoneArea
