import React from 'react'
import { LoadBoardPostBodyDto } from '@OlimpDev/lib/dist/apiv2/load-board'
import { FormikProps } from 'formik'

export type FreightEnvironmentType = 'dry' | 'temperature' | 'controlled' | 'freeze'
export type FreightFormatType = 'Palletized' | 'Floor Loaded'
export type NextButtonNameType = 'Next' | 'Review' | 'ReviewPage' | 'SubmissionPage'

export enum FreightEnvironmentValue {
    DRY = 'dry',
    TEMPERATURE_CONTROLLED = 'temperature controlled',
    FREEZE = 'freeze',
    TEMPERATURE = 'temperature',
}

export interface LoadBoardModalProps {
    title?: string
    pending?: boolean
    error?: boolean
    noButtons?: boolean
    data?: any
    onClose?: () => void
    revalidate?: any
}

export let freightEnvironment: FreightEnvironmentType
export let freightFormat: FreightFormatType
export let nextButtonType: NextButtonNameType

export enum LoadBoardServicesType {
    OrderDetails = 'Order Details',
    Transloading = 'transloading',
    Warehousing = 'warehousing',
    Drayage = 'drayage',
    AdditionalDetails = 'Additional Details',
    Review = 'Review',
}

export enum LoadBoardNewServicesType {
    Storage = 'Storage',
    Transloading = 'Transloading',
    'Cross-Docking' = 'Cross-Docking',
    'Rework / Restack' = 'Rework / Restack',
    Drayage = 'Drayage',
    'Final Mile / Local Delivery' = 'Final Mile / Local Delivery',
    Disposal = 'Disposal',
    'Yard Storage / Trailer Parking' = 'Yard Storage / Trailer Parking',
}

export enum NextButton {
    Next = 'Next',
    Review = 'Review',
    ReviewPage = 'ReviewPage',
}

export enum SubmittedButtonNameType {
    SaveAsDraft = 'Save as Draft',
    SubmitToLoadBoard = 'Submit to Load Board',
    ViewPost = 'View Post',
}

export const LOADBOARD_CHECKBOXES = {
    services: 'services',
    certifications: 'certifications',
    equipment: 'equipment',
    features: 'features',
}

export const loadBoardServicesTitle = LoadBoardServicesType
export const loadBoardNewServicesTitle = LoadBoardNewServicesType
export const nextButtonTitle = NextButton
export const submittedButtonNames = SubmittedButtonNameType

export const START_COUNTER = 1
export const DEFAULT_COUNTER_LENGTH = 3
export const EXTRA_COUNTER_LENGTH = 4

export interface IAdditionalDetails {
    formik: FormikProps<LoadBoardPostBodyDto>
    serviceName?: LoadBoardServicesType
}

export interface ILoadBoardItemsProps {
    formik: FormikProps<LoadBoardPostBodyDto>
    setDisabled: any
}
