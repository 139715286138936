import React from 'react'
import { OrderQuoteInsurance } from './OrderQuoteInsurance'
import { OrderQuoteAllIn } from './OrderQuoteAllIn'
import { OrderQuoteStandard } from './OrderQuoteStandard'
import { useAppSelector } from '@/app/store'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'

export const OrderQuoteSubmit = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const selectedWarehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse)
    const quoteType = useAppSelector((state) => state.orderQuoteSupply.quoteType)

    if (order.status === LoadboardOrderStatus.CLOSED) {
        return (
            <div className="border border-border bg-background-tertiary rounded-lg flex items-center justify-center py-3">
                The order is closed for quote submissions.
            </div>
        )
    }

    return (
        <div>
            {selectedWarehouse?.coiStatus !== 'Confirmed' ? (
                <OrderQuoteInsurance />
            ) : quoteType === 'standard' ? (
                null
                // <OrderQuoteStandard mode="submit" />
            ) : (
                <OrderQuoteAllIn mode="submit" />
            )}
        </div>
    )
}
