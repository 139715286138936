import React from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

type OrderDetailsNotesProps = {
    order: LoadboardOrder
}

export const OrderDetailsNotes = ({ order }: OrderDetailsNotesProps) => {
    return (
        <div>
            <div className="font-semibold">Customer Notes -</div>
            {order.notes ? (
                <div className="mt-1 border border-border rounded-lg p-3 leading-6">
                    {order.notes?.split('\n').map((s, index) => (
                        <p key={index} className="min-h-[1.5rem] text-base">
                            {s}
                        </p>
                    ))}
                </div>
            ) : null}
        </div>
    )
}
