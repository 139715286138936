import React from 'react'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { OrderStatusCode } from '@shared/types/order'
import { utils } from '@shared/common/utils'
import { HttpV2 } from '@shared/apiv2/api'

import { ReactComponent as RFQFileIcon } from '@/assets/images/file.svg'
import { ReactComponent as ArrowDown } from '@/assets/images/arrow-down.svg'
import { ReactComponent as ArrowUp } from '@/assets/images/arrow-up.svg'
import { DropdownDocuments } from '@/components/DropdownDocuments'
import { GoogleLocationLink } from '@/components/GoogleLocationLink/GoogleLocationLink'
export const getRFQFileUrl = (key: string): string => `${HttpV2.defaults.baseURL!}/rfq/attachments/${key}`

export const RFQ_CLASS = 'rfq-portal'

export const expandedColumns = [
    {
        cellName: 'freightCommodity',
        cellHeader: 'Commodity',
        // cellStyle: { width: 220 },
        cell: (row: any) => {
            return (
                <div style={{ overflowY: 'hidden' }} className="rfq-portal__rfq-sub" title={row.freightCommodity}>
                    {row.freightCommodity}
                </div>
            )
        },
    },
    {
        cellName: 'services',
        cellHeader: 'Services Needed',
        // cellStyle: { maxWidth: '460px', width: '460px' },
        cell: (row: any) => {
            return (
                <div className="rfq-portal__rfq-sub" title={row.services}>
                    {row.services}
                </div>
            )
        },
    },
    {
        cellName: 'freightEnvironment',
        cellHeader: 'Freight Environment',
        // cellStyle: { width: 200 },
        cell: (row: any) => {
            return <div className={`${RFQ_CLASS}__rfq-sub`}>{row.freightEnvironment}</div>
        },
    },
    {
        cellName: 'freightCertification',
        cellHeader: 'Certifications',
        // cellStyle: { width: 240 },
        cell: (row: any) => {
            return <div className={`${RFQ_CLASS}__rfq-sub`}>{row.freightCertification}</div>
        },
    },
]
const mainColumns = [
    {
        cellName: 'key',
        cellHeader: 'RFQ #',
        cell: ({ getValue, row }: any) => {
            return (
                <div>
                    <div className="rfq-portal__rfq">{getValue()}</div>
                    <div className="rfq-portal__rfq_date">{new Date(row.original.createdAt).toLocaleDateString()}</div>
                </div>
            )
        },
        enableSorting: true,
    },
    {
        cellName: 'load',
        cellHeader: <span>Load #</span>,
        cell: ({ getValue, row }: any) => {
            return (
                <div
                    style={{
                        fontSize: 16,
                        fontWeight: 400,
                    }}
                >
                    {getValue()}
                </div>
            )
        },
    },
    {
        cellName: 'status',
        cellHeader: 'Status',
        cell: ({ getValue }: any) => {
            const setColor = () => {
                switch (parseInt(getValue())) {
                    case OrderStatusCode.BOOKED:
                        return ['status_booked', 'Booked']
                    case OrderStatusCode.CONFIRMED:
                        return ['status_booked', 'Confirmed']
                    case OrderStatusCode.DROPPED:
                        return ['status_booked', 'Dropped']
                    case OrderStatusCode.LTW_CANCELED:
                        return ['status_cancel', 'Cancelled']
                    case OrderStatusCode.FINISHED:
                        return ['status_finished', 'Finished']
                    case OrderStatusCode.PENDING:
                        return ['status_pending', 'Pending']
                    case OrderStatusCode.COMPLETED:
                        return ['status_complete', 'Complete']
                    default:
                        return ['status_booked', 'Unknown']
                }
            }
            const [color, text] = setColor()
            return (
                <span className={`${RFQ_CLASS}__status ${RFQ_CLASS}__${color}`} style={{ width: 150 }}>
                    {' '}
                    {text}
                </span>
            )
        },
    },
    {
        cellName: 'address',
        cellHeader: 'Location',
        cell: ({ getValue, row }: any) => {
            return (
                <span
                    className={`${RFQ_CLASS}__text ${RFQ_CLASS}__shortened`}
                    style={{ fontSize: 16, fontWeight: 400 }}
                >
                    {row.original.zipCode ? row.original.zipCode : getValue()}
                </span>
            )
        },
    },
    {
        cellName: 'firstName',
        cellHeader: 'User',
        cell: (cell: any) => {
            const { getValue, row } = cell
            return (
                <div className={`${RFQ_CLASS}__text`} style={{ fontSize: 16, fontWeight: 400 }}>
                    {row.original.firstName} {row.original.lastName}
                </div>
            )
        },
    },
    {
        cellName: 'invoice',
        cellHeader: 'Invoice/Files',
        meta: {
            cellClass: 'no-text-truncate',
        },
        header: () => <span style={{ width: '100%', display: 'inline-block', textAlign: 'left' }}>Files</span>,
        cell: ({ row }: { row: Row<any> }) => {
            const attachments = row.original.attachments

            return (
                <div className={`${RFQ_CLASS}__invoice`} onClick={(e) => e.stopPropagation()}>
                    {attachments && attachments.length > 0 ? (
                        <DropdownDocuments
                            newVariant
                            list={attachments.map((att: any) => ({
                                id: att.key,
                                title: att.name,
                                file_name: att.name,
                                ...att,
                            }))}
                            toggleEl={
                                <div style={{ cursor: 'pointer' }}>
                                    <span className={`${RFQ_CLASS}__invoice_number`}>{attachments.length}</span>{' '}
                                    <span className={`${RFQ_CLASS}__invoice_arrow`}>
                                        <ArrowDown />
                                    </span>
                                    <RFQFileIcon style={{ fontSize: 20 }} />
                                </div>
                            }
                            type="right"
                            getUrlFunc={({ id, title, file_name }) => getRFQFileUrl(id.toString())}
                            title={utils.pluralize(attachments.length, 'File')}
                        />
                    ) : (
                        <div style={{ cursor: 'default' }}>-</div>
                    )}
                </div>
            )
        },
    },
    {
        cellName: 'paid',
        cellHeader: 'Payment',
        header: () => <span style={{ textAlign: 'left', width: '100%', display: 'inline-block' }}>Payment</span>,
        cell: ({ getValue }: any) => {
            const value = getValue() ? 'Paid' : 'Unpaid'
            const setColor = () => {
                switch (value) {
                    case 'Paid':
                        return 'payment_success'
                    case 'Unpaid':
                        return 'payment_error'
                }
            }
            return (
                <span
                    className={`${RFQ_CLASS}__${setColor()} `}
                    style={{
                        display: 'block',
                        width: '100%',
                        textAlign: 'left',
                    }}
                >
                    {value}
                </span>
            )
        },
    },
]
export const rfqColumns: any = {
    columns: [],
    expandedColumns,
}
const columnHelper = createColumnHelper<any>()

for (const rfq of mainColumns) {
    rfqColumns.columns.push(
        columnHelper.accessor(rfq.cellName, {
            header: () => rfq.cellHeader,
            ...rfq,
        }),
    )
}
