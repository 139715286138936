import React, { FC, InputHTMLAttributes, memo } from 'react'

import './InputLabel.scss'
import classNames from 'classnames'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    key?: string
    label?: string
    errorMessage?: string
    value: any
    onChange: (param?: any) => void
    style?: React.CSSProperties
    inputStyle?: React.CSSProperties
    classNames?: string
    inputClassName?: string
    name?: string
    type?: string
    disabled?: boolean
    iconNode?: React.ReactNode
}

const InputLabel: FC<InputProps> = ({
    key,
    label,
    errorMessage,
    onChange,
    value,
    style,
    inputStyle,
    inputClassName,
    className,
    name,
    type = 'text',
    disabled = false,
    iconNode,
    ...rest
}) => {
    const id: string | number = key ?? '1'

    return (
        <div className={`input-label ${className}`} key={key} style={{ ...style, opacity: disabled ? 0.8 : 1 }}>
            <label className="input-label__label" htmlFor={id}>
                {label}
            </label>
            <input
                name={name}
                className={`input-label__input ${inputClassName} ${errorMessage && 'input-label__inputError'}`}
                value={value}
                onChange={onChange}
                style={inputStyle}
                type={type}
                disabled={disabled}
                {...rest}
            />
            {iconNode && <div className="input-label__icon">{iconNode}</div>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    )
}

export default memo(InputLabel)
