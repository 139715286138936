import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InSend = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8036 8.45957C15.9874 8.38079 16.1066 8.20002 16.1066 8C16.1066 7.79999 15.9874 7.61922 15.8036 7.54043L1.40265 1.36861L1.40121 1.368L0.954313 1.17647C0.78189 1.10257 0.58261 1.13141 0.438204 1.25115C0.293799 1.37089 0.228566 1.56138 0.26926 1.74451L0.374586 2.21847L0.375071 2.22067L1.65937 8L0.375071 13.7793L0.374462 13.7821L0.26926 14.2555C0.228566 14.4386 0.293799 14.6291 0.438205 14.7489C0.58261 14.8686 0.78189 14.8974 0.954313 14.8235L15.8036 8.45957ZM13.1707 8.49999L2.57266 8.49999L2.65966 8.10847C2.67554 8.03703 2.67554 7.96298 2.65966 7.89154L2.57265 7.49999L13.1706 7.49999L14.3373 8L13.1707 8.49999Z" />
            </svg>
        </IconBase>
    )
}
