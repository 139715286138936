import React, { FC, memo, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { InputText } from '@/components/InputText'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { useCompanyState } from '@/store/company'
import { useCarrier } from '@shared/api/carrier'
import './RequestSolutionModal.scss'
import Textarea from '@/components/Textarea'
import { Http } from '@OlimpDev/lib/dist/api/Http'

interface RequestSolutionModalForm {
    request_local_delivery: string | null
    request_delivery_type: string | null
    request_address: string | null
    request_pallets_number: string | null
    request_days: string | null
    request_services: string | null
    request_type: string | null
    request_features: string | null
    company_name: string | null
    company_state: string | null
    name: string | null
    last_name: string | null
    phone: string | null
    email: string | null
    message: string
    lead_source: string | null
}

const RequestSolutionModalSchema = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email('Invalid email').required('Required'),
    message: Yup.string().required(),
})

interface RequestSolutionModalProps {
    onRequestSolutionModal?: (msg: string) => Promise<void> | void
    values?: any
    unities?: any
    serviceItems?: any
    features?: any
    deliveryItems?: any
}

function getStateFromAddress(address: string | null): string {
    if (address) {
        const addressArray = address.split(', ')
        const state = addressArray[addressArray.length - 2].slice(0, 2)
        return state
    } else {
        return ''
    }
}

const RequestSolutionModal: FC<RequestSolutionModalProps> = (props) => {
    const { state: companyState } = useCompanyState()

    const {
        state: { userInfo },
    } = useUserState()

    const { data } = useCarrier()

    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [RFQFormData, setRFQFormData] = useState<RequestSolutionModalForm>({
        request_local_delivery: '',
        request_delivery_type: '',
        request_address: '',
        request_pallets_number: '',
        request_days: '',
        request_services: '',
        request_type: '',
        request_features: '',
        company_name: '',
        company_state: '',
        name: '',
        last_name: '',
        phone: '',
        email: '',
        message: '',
        lead_source: '',
    })

    const servisesInfo = props?.unities || null
    const typesInfo = props?.serviceItems || null
    const featuresInfo = props?.features || null
    const deliveryInfo = props?.deliveryItems || null

    /* eslint-disable complexity*/
    /* eslint-disable react-hooks/exhaustive-deps*/
    useEffect(() => {
        if (props) {
            setRFQFormData({
                request_local_delivery: changeBoolToYesString(props?.values.localDelivery),
                request_delivery_type: changeCodesToNames(props?.values.delivery, deliveryInfo) || '',
                request_address: props?.values.address || '',
                request_pallets_number: props?.values.number_of_units || '',
                request_days: props?.values.number_of_days || '',
                request_services: changeCodesToNames(props?.values.unity, servisesInfo) || '',
                request_type: changeCodesToNames(props?.values.service, typesInfo) || '',
                request_features: changeCodesToNames(props?.values.features, featuresInfo) || '',
                company_name: companyState?.companyInfo?.entity || '',
                company_state: getStateFromAddress(data?.legal_address?.address || '') || '',
                name: userInfo?.firstName || '',
                last_name: userInfo?.lastName || '',
                phone: userInfo?.phone ? String(userInfo?.phone) : '',
                email: userInfo?.email || '',
                message: '',
                lead_source: window.location.href,
            })

            if (props?.values.unity !== 1) {
                setRFQFormData((prevState) => ({
                    ...prevState,
                    request_days: '',
                }))
            }

            if (props?.values.unity !== 1 && props?.values.unity !== 3) {
                setRFQFormData((prevState) => ({
                    ...prevState,
                    request_local_delivery: '',
                    request_delivery_type: '',
                }))
            }
        }
    }, [])

    function changeBoolToYesString(bool: boolean): string {
        switch (bool) {
            case true:
                return 'Yes'
            case false:
                return 'No'
            default:
                return ''
        }
    }

    function changeCodesToNames(propertyWithCodes: any, mapArray: Array<any>) {
        if (Array.isArray(propertyWithCodes)) {
            const propertyWithNames = []

            for (let i = 0; i < propertyWithCodes.length; i++) {
                for (let k = 0; k < mapArray.length; k++) {
                    if (propertyWithCodes[i] === mapArray[k].value || propertyWithCodes[i] === mapArray[k].code) {
                        propertyWithNames.push(mapArray[k].name)
                        break
                    }
                }
            }
            return propertyWithNames.join(', ')
        } else {
            for (let k = 0; k < mapArray.length; k++) {
                if (propertyWithCodes === mapArray[k].value) {
                    propertyWithCodes = mapArray[k].name
                    return propertyWithCodes
                }
            }
        }
    }

    const formik = useFormik<RequestSolutionModalForm>({
        initialValues: RFQFormData,
        validationSchema: RequestSolutionModalSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                await Http.post('/app/v1/support/offline-create', values)
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.RequestSolutionModal)
                await props.onRequestSolutionModal?.('Your message has been sent successfully to the administrator')
            } catch (errors) {
                formik.setSubmitting(false)
                if (Array.isArray(errors)) {
                    setErrorMessage(JSON.stringify(errors))
                }
            }
        },
    })

    return (
        <div>
            {errorMessage && <p>{errorMessage}</p>}
            <form onSubmit={formik.handleSubmit}>
                <div className="RS_form-group RS_form-group_order">
                    <h3>Request for Quote (RFQ)</h3>
                    <div className="requestSolLine requestSolLine_order">
                        <InputText
                            text
                            label="Address"
                            name="request_address"
                            onChange={formik.handleChange}
                            value={formik.values.request_address}
                            error={formik.errors.request_address}
                        />
                        <InputText
                            text
                            label="Number of Pallets"
                            name="request_pallets_number"
                            onChange={formik.handleChange}
                            value={formik.values.request_pallets_number}
                            error={formik.errors.request_pallets_number}
                        />
                    </div>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="Days"
                            name="request_days"
                            onChange={formik.handleChange}
                            value={formik.values.request_days}
                            error={formik.errors.request_days}
                        />
                        <InputText
                            text
                            label="Services"
                            name="request_services"
                            onChange={formik.handleChange}
                            value={formik.values.request_services}
                            error={formik.errors.request_services}
                        />
                    </div>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="Type"
                            name="request_type"
                            onChange={formik.handleChange}
                            value={formik.values.request_type}
                            error={formik.errors.request_type}
                        />
                        <InputText
                            text
                            label="Features"
                            name="request_features"
                            onChange={formik.handleChange}
                            value={formik.values.request_features}
                            error={formik.errors.request_features}
                        />
                    </div>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="Local delivery"
                            name="request_local_delivery"
                            onChange={formik.handleChange}
                            value={formik.values.request_local_delivery}
                            error={formik.errors.request_local_delivery}
                        />
                        <InputText
                            text
                            label="Delivery type"
                            name="request_delivery_type"
                            onChange={formik.handleChange}
                            value={formik.values.request_delivery_type}
                            error={formik.errors.request_delivery_type}
                        />
                    </div>
                </div>
                <div className="RS_form-group">
                    <p className="RS-attantion">Complete the below and receive additional options in minutes.</p>
                    <h3>Requested by</h3>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="Company name"
                            name="company_name"
                            onChange={formik.handleChange}
                            value={formik.values.company_name}
                            error={formik.errors.company_name}
                        />
                        <InputText
                            text
                            label="Company state"
                            name="company_state"
                            onChange={formik.handleChange}
                            value={formik.values.company_state}
                            error={formik.errors.company_state}
                        />
                    </div>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="First name"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.errors.name}
                        />
                        <InputText
                            text
                            label="Last name"
                            name="last_name"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            error={formik.errors.last_name}
                        />
                    </div>
                    <div className="requestSolLine">
                        <InputText
                            text
                            label="Phone"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            error={formik.errors.phone}
                        />
                        <InputText
                            text
                            label="E-mail"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={formik.errors.email}
                        />
                    </div>
                    <Textarea
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={formik.errors.message}
                        placeholder="Message"
                        label="Leave message"
                        name="message"
                    />
                    <p className="RS-attantion">Our emergency response team will have a solution in minutes.</p>
                    <div className="btns">
                        <Button
                            label="Send"
                            disabled={!formik.isValid}
                            loading={formik.isSubmitting}
                            types={['blue', 'large']}
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

ModalRegistry.get().register<RequestSolutionModalProps>(Modals.RequestSolutionModal, {
    id: 'RequestSolutionModalProps',
    className: 'request_solution-modal',
    size: 'medium',
    Component: memo(RequestSolutionModal),
    title: 'Request A Solution',
})

export const showRequestSolutionModal = (props: ModalPartialProps<RequestSolutionModalProps>): void =>
    ModalRegistry.get().show<RequestSolutionModalProps>(Modals.RequestSolutionModal, props)

export default memo(RequestSolutionModal)
