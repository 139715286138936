import React from 'react'
import { useAppSelector } from '@/app/store'
import { Quote, QuoteStatus, RequoteReason, RequoteReasonText } from '@/entities/quotes/model'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { WarehouseOrderStatus } from '@/entities/warehouse-orders/model'
import { InLock } from '@/shared/ui/Icons/InLock'
import { InClose } from '@/shared/ui/Icons/InClose'
import { InInfoCircle } from '@/shared/ui/Icons/InInfoCircle'

export const OrderQuoteNotification = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const quote = useAppSelector((state) => state.orderQuoteSupply.selectedQuote) as Quote

    if (quote.status === QuoteStatus.REQUOTE) {
        if (quote.rejectReason === RequoteReason.Price) return null
        return (
            <div className="bg-background-tertiary border border-warning rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-warning">
                    <InInfoCircle size={20} />
                    <p className="font-medium">Requote Requested</p>
                </div>
                <p>
                    Customer has requested requote
                    {!quote.rejectReason ? (
                        '.'
                    ) : (
                        <span>
                            &nbsp;with the reason: <b>{RequoteReasonText[quote.rejectReason as RequoteReason]}</b>
                        </span>
                    )}
                </p>
                {quote.rejectReasonComment && <p>{quote.rejectReasonComment}</p>}
            </div>
        )
    }

    if (quote.status === QuoteStatus.DECLINED) {
        return (
            <div className="bg-background-tertiary border border-danger rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-danger">
                    <InClose size={16} />
                    <p className="font-medium">Quote Rejected</p>
                </div>
                <p>
                    This quote has been rejected
                    {!quote.rejectReason ? (
                        '.'
                    ) : (
                        <span>
                            &nbsp;with the reason: <b>{quote.rejectReason}</b>
                        </span>
                    )}
                </p>
                {quote.rejectReasonComment && <p>{quote.rejectReasonComment}</p>}
            </div>
        )
    }

    const showHoldNotification = order.rfqStatus === WarehouseOrderStatus.Finished && !order.paid

    if (showHoldNotification) {
        return (
            <div className="bg-background-tertiary border border-warning rounded-lg p-3 space-y-3">
                <div className="flex items-center gap-3 text-warning">
                    <InLock />
                    <p className="font-medium">Customer Hold</p>
                </div>
                <p>The load is not to be released until the hold is lifted</p>
            </div>
        )
    }

    return null
}
