import React, { FC, useState, Dispatch, SetStateAction } from 'react'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'
import {
    FormValues,
    FormValueTypes,
    SetErrorType,
    ValidateMainBoardLocation,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import RfqTemplate from '@/pages/RFQPortal/components/RfqTemplate'

import { ReactComponent as CreateNewOppButton } from '@/pages/RFQPortal/assets/img/plus.svg'
import { useExpandedOppContext } from '@/pages/RFQPortal/contexts/ExpandedOppProvider'

interface ProjectRfqProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    manageOpportunity: ManageOpportunityType
    step: Steps
    changeStep: (step: Steps) => void
    errorMessages?: any
    setError: SetErrorType
    setValidateMainBoard: Dispatch<SetStateAction<ValidateMainBoardLocation[]>>
}

const mainBoardClass = 'main-board'
const projectClass = mainBoardClass + '__project'

const rfqTemplateClass = 'rfq-template'
const headerClass = rfqTemplateClass + '__header'

const ProjectRfq: FC<ProjectRfqProps> = ({
    formData,
    changeFormData,
    manageOpportunity,
    step,
    changeStep,
    errorMessages,
    setError,
    setValidateMainBoard,
}) => {
    const { expandedId, setExpandedId } = useExpandedOppContext()

    const toggleTemplateExpansion = (id: string | null): void => {
        if (expandedId === id) {
            setExpandedId(null)
        } else {
            setExpandedId(id)
        }
    }

    const handleNewOpportunity = () => {
        const id = manageOpportunity('CREATE_NEW_OPPORTUNITY')
        if (typeof id === 'string') {
            setExpandedId(id)
        }
    }

    return (
        <div className={`${projectClass}`}>
            {formData?.project?.data.map((opportunity, index) => (
                <RfqTemplate
                    key={opportunity.id}
                    currentOppId={opportunity.id}
                    isExpanded={expandedId === opportunity.id}
                    toggleTemplateExpansion={toggleTemplateExpansion}
                    formData={formData}
                    changeFormData={changeFormData}
                    manageOpportunity={manageOpportunity}
                    setExpandedId={setExpandedId}
                    step={step}
                    changeStep={changeStep}
                    errorMessages={errorMessages}
                    setError={setError}
                    setValidateMainBoard={setValidateMainBoard}
                />
            ))}

            {/* {step !== Steps.REVIEW && ( */}
            {
                <div className={`${headerClass} ${projectClass}__btn-add`} onClick={handleNewOpportunity}>
                    <h2 className={`${headerClass}-title`}>Add Service Location</h2>
                    <div className={`${headerClass}-buttons`}>
                        <CreateNewOppButton className={`${projectClass}__add`} />
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectRfq
