import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Input } from '@/shared/ui/Input'
import { Select, SelectOption } from '@/shared/ui/Select'

const SELECT_OPTIONS: SelectOption[] = [
    { value: 'total', label: 'Total' },
    { value: 'pallet', label: 'Pallet' },
    { value: 'unit', label: 'Unit' },
]

export const DisposalFormComponent = ({ service, errors }: ServiceFormComponentProps) => {
    const onPriceChange = (value: string) => {
        if (service.pricings?.disposal) {
            service.pricings.disposal.price = value
        }
    }

    const onModifierChange = (value: string) => {
        if (service.pricings?.disposal) {
            service.pricings.disposal.modifier = value
        }
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div>
                <div className="flex gap-2 items-center w-full">
                    <Input
                        label={RfqServicePricingLabel[RfqServicePricing.Disposal]}
                        wrapperClassName="w-full md:w-[200px]"
                        defaultValue={service.pricings.disposal?.price}
                        onChange={(e) => onPriceChange(e.target.value)}
                    />
                    <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                    <Select
                        defaultValue={service.pricings.disposal?.modifier ?? ''}
                        options={SELECT_OPTIONS}
                        onValueChange={onModifierChange}
                        wrapperClassName="w-[100px] sm:w-[170px] shrink-0"
                    />
                </div>
                {errors?.[RfqServicePricing.Disposal] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.Disposal]}</div>
                ) : null}
            </div>
        </div>
    )
}
