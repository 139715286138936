import React, { useCallback, useEffect, useState } from 'react'
import { Section } from '@/shared/ui/Section'
import { ScrollArea } from '@/shared/ui/ScrollArea'
import { Button } from '@/shared/ui/Button'
import { InSend } from '@/shared/ui/Icons/InSend'
import { useChat } from '@/entities/chat/api/useChat'
import { ChatErrorMessage } from '@/pages/Order/ui/OrderChat/ui/ChatErrorMessage'
import { Loader } from '@/shared/ui/Loader'
import { ChatInfoMessage } from '@/pages/Order/ui/OrderChat/ui/ChatInfoMessage'
import { Textarea } from '@/shared/ui/Textarea'
import { ChatMessage } from '@/pages/Order/ui/OrderChat/ui/ChatMessage'
import { useUserState } from '@/store/user'
import { tcn } from '@/utils/tcn'
import { orderChatSlice } from '@/pages/Order/ui/OrderChat/model/slice'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { UserRole } from '@/store/user/user'
import { toast } from 'react-toastify'
import { cn } from '@/shared/utils/cn'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const {
    addChatMessage,
    selectChat,
    setChatMessages,
    setCurrentMessage,
    setChatLoading,
    setCurrentChat,
    setAvailableChats,
} = orderChatSlice.actions

export const OrderChat = ({
    reference,
    className,
    hide,
    psmMode,
}: {
    reference: string
    className?: string
    hide?: boolean
    psmMode?: boolean
}) => {
    const vpRef = React.useRef<HTMLDivElement>(null)
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    const scrollBottom = () => {
        vpRef.current?.scrollTo({
            top: vpRef.current.scrollHeight,
            behavior: 'smooth',
        })
    }

    const { state: userState } = useUserState()
    const isPSM = userState.userInfo?.role === UserRole.PSM
    const dispatch = useAppDispatch()
    const chatMessages = useAppSelector((state) => state.orderChat.chatMessages)
    const currentMessage = useAppSelector((state) => state.orderChat.currentMessage)
    const chatLoading = useAppSelector((state) => state.orderChat.chatLoading)
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const currentChat = useAppSelector((state) => state.orderChat.currentChat)

    const selectedChatRef = React.useRef<any>(null)
    useEffect(() => {
        selectedChatRef.current = selectedChat
    }, [selectedChat])

    const { getChat, getChats, sendMessage, connected, error } = useChat({
        callbacks: {
            onMessageReceived: (message) => {
                if (message.chatId !== selectedChatRef.current) return
                dispatch(addChatMessage(message))
                scrollBottom()
            },
            onChatReceived: ({ chat, messages }) => {
                dispatch(setChatLoading(false))
                dispatch(setCurrentChat(chat))
                if (chat && !selectedChatRef.current) {
                    dispatch(selectChat(chat.id))
                }
                dispatch(setChatMessages(messages))
                scrollBottom()
            },
            onChatsReceived: (chats) => {
                dispatch(setChatLoading(false))
                dispatch(setAvailableChats(chats))
            },
        },
    })

    useEffect(() => {
        if (typeof selectedChat === 'number' && isPSM) {
            getChat(selectedChat)
        }
    }, [selectedChat, isPSM])
    useEffect(() => {
        if (connected && userState.userInfo) {
            dispatch(setChatLoading(true))

            dispatch(setChatMessages([]))
            if (isPSM) {
                getChats(reference)
            } else {
                getChat(reference)
            }
        }
    }, [connected, userState.userInfo, reference])

    const handleMessageChange = (e: any) => {
        dispatch(setCurrentMessage(e.target.value))
    }
    const handleSendMessage = () => {
        sendCustomEvent('new_message_sent')
        if (!currentMessage?.trim?.()) {
            toast('Message cannot be empty', {
                type: 'error',
            })
            return
        }
        const messageBody: any = {
            message: currentMessage,
            reference,
        }
        if (typeof selectedChat === 'number') {
            messageBody.chatId = selectedChat
        }
        sendMessage(messageBody)
        dispatch(setCurrentMessage(''))
        dispatch(
            setChatMessages([
                ...chatMessages,
                {
                    message: currentMessage,
                    name: `${userState.userInfo!.firstName} ${userState.userInfo!.lastName}`,
                    nameShort: `${userState.userInfo!.firstName[0]}${userState.userInfo!.lastName[0]}`,
                    createdAt: new Date().toISOString(),
                    chatId: typeof selectedChat === 'number' && selectedChat,
                    me: true,
                },
            ]),
        )
        setTimeout(() => {
            scrollBottom()
        })
    }

    if (hide) {
        return null
    }

    return (
        <Section className={tcn('w-[350px] 2xl:w-[510px]', className)}>
            <ScrollArea
                viewportProps={{
                    ref: vpRef,
                }}
                viewportClassName={cn('w-full border border-border rounded-xl', {
                    'h-auto': error,
                    'h-[525px]': !error && !psmMode,
                    'h-[400px]': psmMode,
                })}
            >
                <div className="flex flex-col gap-3 p-3">
                    {error && <ChatErrorMessage error={error} />}
                    {chatLoading && !error && (
                        <Loader className="h-12 w-12 border-2 border-accent-medium border-t-primary" />
                    )}
                    {!chatLoading && chatMessages.length === 0 && (
                        <ChatInfoMessage message={'There are no messages yet.'} />
                    )}
                    {!chatLoading &&
                        chatMessages?.length > 0 &&
                        chatMessages.map((message, index) => {
                            return <ChatMessage key={index} {...message} />
                        })}
                </div>
            </ScrollArea>

            {!error && !chatLoading && (
                <div className="mt-5 flex w-full gap-2">
                    <Textarea
                        className="max-h-24 w-full rounded-xl px-3 py-1.5 transition-colors duration-75 ring-none outline-none bg-background-secondary text-sm placeholder:text-foreground-secondary/70 disabled:cursor-not-allowed disabled:opacity-50"
                        value={currentMessage}
                        placeholder={'Type a message...'}
                        onChange={handleMessageChange}
                    />
                    <Button className="p-0 h-9 w-9 shrink-0" onClick={handleSendMessage}>
                        <InSend size={24} />
                    </Button>
                </div>
            )}
        </Section>
    )
}
