import { RFQDocumentType } from './rfqTypes'
const RfqStatusType = {
    Booked: 'Booked',
    Pending: 'Pending',
    Complete: 'Complete',
}

const RfqPaymentType = {
    Unpaid: 'Unpaid',
    Paid: 'Paid',
}

export const fakeData = [
    {
        rfq: {
            rfq: 1234,
            date: '2023-01-01T00:00:01.997Z',
        },
        load: 12345,
        status: RfqStatusType.Booked,
        zipCode: 60089,
        user: 'John Doe',
        invoice: '',
        payment: RfqPaymentType.Paid,
        commodity: 'Commodity Name',
        services: 'Storage, Cross-Docking, Rework/Restack, Transloading',
        freightEnvironment: 'Dry',
        certifications: 'bonded, hazmat, food grade',
        subRows: [
            {
                rfq: {
                    commodity: 'Commodity Name',
                },
                load: {
                    services: 'Storage, Cross-Docking, Rework/Restack, Transloading',
                },
                user: {
                    freightEnvironment: 'Dry',
                },
                payment: {
                    certification: 'Bonded, Hazmat, Food Grade',
                },
            },
        ],
    },
    {
        rfq: {
            rfq: 1234,
            date: '2023-01-01T00:00:01.997Z',
        },
        load: 12345,
        status: RfqStatusType.Complete,
        zipCode: 60089,
        user: 'John Doe',
        invoice: '',
        payment: RfqPaymentType.Unpaid,
        commodity: 'Commodity Name',
        services: 'Storage, Cross-Docking, Rework/Restack, Transloading',
        freightEnvironment: 'Dry',
        certifications: 'Bonded, Hazmat, Food Grade',
    },
    {
        rfq: 1234,
        date: '2023-01-01',
        load: 12345,
        status: RfqStatusType.Pending,
        zipCode: 60089,
        user: 'John Doe',
        invoice: '',
        payment: RfqPaymentType.Unpaid,
        subRows: [
            {
                services: 'Storage, Cross-Docking, Rework/Restack, Transloading',
                freightCommodity: 'Commodity Name',
                freightEnvironment: 'Dry',
                freightCertification: 'Bonded, Hazmat, Food Grade',
            },
        ],
    },
]

export const RFQDocument: { [key: string]: string } = {
    WHINV: 'WHINV',
    BOL: 'BOL',
    RC: 'RC',
    POD: 'POD',
    GINOUT: 'GINOUT',
    CUSTOMER: 'Customer Files',
    QB_INVOICE: 'Invoice',
    INVOICE: 'Invoice',
    Other: 'Other',
}
