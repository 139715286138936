import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'

import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/shared/utils/cn'

const buttonVariants = cva(
    'h-9 px-3 border-0 border-solid inline-flex items-center justify-center whitespace-nowrap rounded-lg !font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-primary text-white hover:bg-primary/90 focus-visible:ring-primary-foreground !font-semibold',
                outlined: 'border border-solid border-primary text-primary !font-semibold hover:bg-primary/10',
                link: 'text-primary underline-offset-2 underline !font-normal hover:text-primary/80 rounded-none h-auto px-0',
                ghost: 'text-foreground-primary hover:bg-accent-light/50',
                danger: 'bg-danger text-white hover:bg-danger/90',
                plain: 'rounded-none h-auto px-0',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
        asChild?: boolean
    }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button'
        return <Comp className={cn(buttonVariants({ variant, className }))} ref={ref} {...props} />
    },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
