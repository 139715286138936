import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InFinalMile = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0002 9.375e-06V12.5C20.0002 14.4344 18.4346 16 16.5002 16C14.594 16 13.0471 14.4781 13.0002 12.5844L1.51304 15.7156C0.980229 15.8594 0.430541 15.5469 0.285416 15.0125C0.140229 14.4812 0.454604 13.9313 0.987416 13.7844L11.0002 11.0562V2C11.0002 0.895313 11.8971 0 13.0002 0L20.0002 9.375e-06ZM16.5002 11C15.6721 11 15.0002 11.6719 15.0002 12.5C15.0002 13.3281 15.6721 14 16.5002 14C17.3284 14 18.0002 13.3281 18.0002 12.5C18.0002 11.6719 17.3284 11 16.5002 11ZM0.722416 6.49062C0.579604 5.95625 0.896167 5.40938 1.4296 5.26562L2.87835 4.87813L3.52523 7.29375C3.5971 7.55937 3.8721 7.69062 4.13773 7.64687L5.10335 7.3875C5.36898 7.31562 5.52836 7.04063 5.45648 6.775L4.8096 4.35938L6.25961 3.97187C6.79398 3.82812 7.34086 4.14687 7.48461 4.67812L8.77836 9.50937C8.92211 10.0437 8.60336 10.5906 8.07211 10.7344L3.24085 12.0281C2.70773 12.1719 2.1596 11.8531 2.01648 11.3219L0.722416 6.49062Z" />
            </svg>
        </IconBase>
    )
}
