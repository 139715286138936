import React from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import useSWR from 'swr'
import '../Static.scss'
import { apiVersion, getFetcher, swrConfiguration } from '@shared/api/Http'
import { useUserState } from '@/store/user'

const TITLES: Record<string, string> = {
    'cookie-policy': 'Cookie Policy',
    'term-of-use': 'Terms of use',
}

const Page: React.FC = () => {
    const { alias } = useParams<{ alias?: string }>()
    const {
        state: { userType },
    } = useUserState()
    const { data: html } = useSWR(
        () => (alias ? `app/${apiVersion}/service/landing-chunk?slug=${alias}` : null),
        getFetcher,
        swrConfiguration,
    )
    const pageTitle = TITLES[alias!] || ''

    return (
        <div className="wrapper section">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageTitle} />
            </Helmet>
            <div className="static" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default Page
