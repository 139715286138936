import React, { useEffect } from 'react'
import './UploadFile.scss'
import FileUpload from '@/components/FileUpload'
import Tooltip from 'react-tooltip-lite'
import { Hint } from '@/components/Hint'
import Button from '@/components/Button'
import { showInsuranceUploadModal } from '@/parts/Modals/FileUploadModal'

interface UploadFileProps {
    title: string
    accept?: string
    children: React.ReactNode
    disabled?: boolean
    manager?: boolean
    multiple?: boolean
    hintDescription?: string
    loading?: boolean
    handleFileSelect: (file: FileList | null, expirationDate?: string) => void
}

export const UploadFile = ({
    title,
    children,
    handleFileSelect,
    disabled,
    loading,
    manager = true,
    accept,
    multiple,
    hintDescription,
}: UploadFileProps) => {
    const handleUploadSelect = (file: FileList | null) => {
        handleFileSelect(file)
    }

    return (
        <div className="upload-file">
            <div className={'app-card__header bordered-block'}>
                <div className="upload-file__title">
                    {title}
                    {hintDescription && (
                        <Hint type="right" toggle={<span className="icon">?</span>}>
                            {hintDescription}
                        </Hint>
                    )}
                </div>
                {title === 'Insurance' && manager && (
                    <Button
                        types={['bordered']}
                        label="Upload File"
                        onClick={() =>
                            showInsuranceUploadModal({
                                props: { loading, onSubmit: handleFileSelect },
                            })
                        }
                    />
                )}
                {title !== 'Insurance' && manager && (
                    <FileUpload
                        accept={accept}
                        disabled={disabled}
                        loading={loading}
                        multiple={multiple}
                        onFileSelect={handleUploadSelect}
                    />
                )}
            </div>
            <div>{children}</div>
        </div>
    )
}
