import React, { FC, memo, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import { useFormik } from 'formik'
import { useUserState } from '@/store/user'
import { FeedbackPayload, sendFeedbackPassword } from '@shared/api/user'
import './FeedbackModal.scss'
import { HttpV2 } from '@shared/apiv2/api'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import Input from '@/pages/RFQPortal/components/Input'
import Textarea from '@/pages/RFQPortal/components/Textarea'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'
import PhoneInput from '@/pages/RFQPortal/components/IntlTelInput'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

interface FeedbackForm {
    name: string | undefined
    phone: string | undefined
    email: string | undefined
    message: string
}

interface FeedbackModalProps {
    onFeedback?: (msg: string) => Promise<void> | void
}
export const sendFeedback = async (payload: FeedbackPayload): Promise<AxiosResponse<any>> => {
    return HttpV2.post('/support', payload)
}

const FeedbackFormSchema = Yup.object().shape({
    name: Yup.string().required('Your name is required'),
    email: Yup.string().email('Invalid email').required('Required'),
    message: Yup.string().required('Message is required'),
})

const FeedbackModal: FC<FeedbackModalProps> = ({ onFeedback }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    const {
        state: { userInfo },
    } = useUserState()

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const [phoneItiObject, setPhoneItiObject] = useState<any>(null)
    const [phoneError, setPhoneError] = useState<string>('')

    const formik = useFormik<FeedbackForm>({
        initialValues: {
            name: userInfo?.firstName || '',
            phone: userInfo?.phone ? String(userInfo?.phone) : '',
            email: userInfo?.email || '',
            message: '',
        },
        validateOnMount: false,
        validateOnChange: validateOnChange,
        validationSchema: FeedbackFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')

            const phoneValidation = validatePhone()
            if (!phoneValidation) {
                return
            }

            try {
                formik.setSubmitting(true)
                await sendFeedback(values as FeedbackPayload)
                formik.setSubmitting(false)
                ModalRegistry.get().close(Modals.FeedbackModal)
                toast('Your message has been successfully sent to our team. We will contact you shortly.', {
                    type: 'success',
                })
            } catch (errors: any) {
                formik.setSubmitting(false)

                if (errors && errors?.response) {
                    setErrorMessage(errors?.response?.data?.message)
                }
            }
        },
    })

    const validatePhone = (): boolean => {
        if (!phoneItiObject) {
            setPhoneError('Please reload the page or contact support.')
            return false
        }

        const number = phoneItiObject.getNumber().trim()
        if (number === '') {
            setPhoneError('Phone number is required.')
            return false
        }

        if (!phoneItiObject.isValidNumber()) {
            setPhoneError('Please enter a valid phone number.')
            return false
        }

        setPhoneError('')
        return true
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="modal-feedback-inputs">
                    <Input
                        label="Name*"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        errorMessage={formik.errors.name}
                    />
                    <PhoneInput
                        label="Phone number*"
                        clientPhoneItiObject={phoneItiObject}
                        setClientPhoneItiObject={setPhoneItiObject}
                        value={formik.values.phone}
                        changeValue={(newValue) => formik.setFieldValue('phone', newValue)}
                        errorMessage={phoneError}
                        validatePhone={validatePhone}
                        validateOnChange={validateOnChange}
                    />
                    <Input
                        label="E-mail*"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        errorMessage={formik.errors.email}
                    />
                    <Textarea
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        placeholder="Message"
                        label="How can we help?"
                        name="message"
                        errorMessage={formik.errors.message}
                    />
                </div>

                <CancelDeleteButtons
                    handleCancel={() => ModalRegistry.get().close(Modals.FeedbackModal)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    errorMessage={errorMessage}
                    wrapperClassName="modal-feedback__actions"
                    textSubmit="Send"
                    stylesSubmit={{ width: '80px' }}
                    buttonsPosition="right"
                    onSubmitClick={() => {
                        setValidateOnChange(true)

                        setTimeout(() => {
                            validatePhone()
                        }, 50)

                        sendCustomEvent('contact_support_sent', {
                            action: 'User sent the message',
                        })
                    }}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<FeedbackModalProps>(Modals.FeedbackModal, {
    id: 'FeedbackModalProps',
    className: 'modal-feedback',
    size: 'medium',
    Component: memo(FeedbackModal),
    title: 'Contact support',
})

export const showFeedbackModal = (props: ModalPartialProps<FeedbackModalProps>): void =>
    ModalRegistry.get().show<FeedbackModalProps>(Modals.FeedbackModal, props)

export default memo(FeedbackModal)
