import React, { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

type subMenuLink = {
    label: string
    to?: string
    href?: string
    active?: boolean
}

interface SubMenuProps {
    links: subMenuLink[]
    onLinkClick?: (link: subMenuLink) => void
}

const SubMenu: FC<SubMenuProps> = ({ links, onLinkClick }) => {
    return (
        <div className="menu-item__submenu">
            <div className="wrapper">
                <div>
                    <ul className="menu-item__list">
                        {links.map((link, index) => (
                            <li className="menu-item__item" key={index}>
                                {link.to ? (
                                    <Link
                                        to={link.to}
                                        className={cn('menu-item', 'menu-item__link', { active: link.active })}
                                        onClick={() => {
                                            if (onLinkClick) {
                                                onLinkClick(link)
                                            }
                                        }}
                                    >
                                        {link.label}
                                    </Link>
                                ) : (
                                    <a
                                        href={link.href}
                                        className={cn('menu-item', 'menu-item__link', { active: link.active })}
                                        onClick={() => {
                                            if (onLinkClick) {
                                                onLinkClick(link)
                                            }
                                        }}
                                    >
                                        {link.label}
                                    </a>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SubMenu
