import React from 'react'
import { cn } from '@/shared/utils/cn'

const Container = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                'container relative z-[1] w-full sm:w-[90%] md:w-[720px] lg:w-[900px] xl:w-[1140px] 2xl:w-[1400px]',
                className,
            )}
            {...props}
        />
    ),
)
Container.displayName = 'Container'

export { Container }
