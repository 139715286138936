import React from 'react'

import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'
import { isCorporateEmail } from '@/pages/RFQPortal/helpers/publicEmailDomains'
import { SetErrorType } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

const useValidationRules = () => {
    const { wpContextData } = useWpData()
    const { siteData } = wpContextData || {}
    const { CLIENT_SLUG: clientSlug, publicSites } = siteData || {}

    const validateRequiredInput = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        oppId = '',
    ): boolean => {
        if (value.trim() === '') {
            setError(
                path,
                {
                    message: 'Field is required',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        } else {
            setError(
                path,
                {
                    message: '',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return true
        }
    }

    const validateEmail = async (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        corporate = false,
        required = false,
        oppId = '',
    ): Promise<boolean> => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (required) {
            if (!validateRequiredInput(fieldLabel, path, value, setError, wrapperEl, projectLocationNumber, oppId))
                return false
        }

        if (!emailRegex.test(value) && value) {
            setError(
                path,
                {
                    message: 'Please enter a valid email address',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        if (corporate) {
            try {
                const isCorporate = await isCorporateEmail(value)
                if (!isCorporate && !publicSites?.includes(clientSlug)) {
                    setError(
                        path,
                        {
                            // message:
                            //     'Please use your corporate email or visit our <a id="general-rfq-link" style="color: #2887FA">General RFQ</a>',
                            message: 'Please use your corporate email',
                            wrapperEl: wrapperEl,
                            projectLocationNumber: projectLocationNumber,
                            fieldLabel: fieldLabel,
                        },
                        oppId,
                    )
                    return false
                }
            } catch (error) {
                console.error('Error checking email:', error)
                return false
            }
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )
        return true
    }

    const validatePhone = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        iti: any,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        required = false,
        oppId = '',
    ): boolean => {
        if (!iti) {
            setError(
                path,
                {
                    message: 'Error! Please contact support.',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        if (required) {
            if (!validateRequiredInput(fieldLabel, path, value, setError, wrapperEl, projectLocationNumber, oppId))
                return false
        }

        if (iti && !iti.isValidNumber() && iti.getNumber().trim() !== '') {
            setError(
                path,
                {
                    message: 'Please enter a valid phone number',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )
        return true
    }

    const validatePhoneExtention = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        required = false,
        oppId = '',
    ): boolean => {
        if (required) {
            if (!validateRequiredInput(fieldLabel, path, value, setError, wrapperEl, projectLocationNumber, oppId))
                return false
        }

        if (value.trim().length > 4) {
            setError(
                path,
                {
                    message: 'Max 4 characters',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        if (isNaN(Number(value))) {
            setError(
                path,
                {
                    message: 'Please enter a number',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )

        return true
    }

    const validateGoogleLocation = (
        fieldLabel: string,
        path: string,
        clientLocationObject: any,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        oppId = '',
    ): boolean => {
        if (!clientLocationObject.formattedAddress || clientLocationObject.formattedAddress === '') {
            setError(
                path,
                {
                    message: 'Field is required',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        // if (!/^[a-zA-Z0-9\s.,'-]*$/.test(clientLocationObject.formattedAddress)) {
        //     setError(
        //         path,
        //         {
        //             message: 'Address contains invalid characters',
        //             wrapperEl: wrapperEl,
        //             projectLocationNumber: projectLocationNumber,
        //             fieldLabel: fieldLabel,
        //         },
        //         oppId,
        //     )
        //     return false
        // }

        if (!clientLocationObject || !clientLocationObject.lat) {
            setError(
                path,
                {
                    message: 'Please enter a correct location',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )
        return true
    }

    const validateMaxLength = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        maxLength = 255,
        oppId = '',
    ): boolean => {
        if (value.length > maxLength) {
            setError(
                path,
                {
                    message: 'Max 255 characters',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        } else {
            setError(
                path,
                {
                    message: '',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return true
        }
    }

    const validateZip = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        required = false,
        oppId = '',
    ): boolean => {
        if (required) {
            if (!validateRequiredInput(fieldLabel, path, value, setError, wrapperEl, projectLocationNumber, oppId))
                return false
        }

        const regulars: Record<string, RegExp> = {
            US: /^(\d{5}$)|(\d{5}-\d{4}$)/,
            CA: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] ?\d[A-Z]\d$/i,
        }

        let passedRegulars = false
        for (const country in regulars) {
            if (regulars[country].test(value)) {
                passedRegulars = true
                break
            }
        }

        if (!passedRegulars && value) {
            setError(
                path,
                {
                    message: 'Invalid US/CA Zip',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )
        return true
    }

    const validateRequiredCheckbox = (
        fieldLabel: string,
        path: string,
        checkedValuesArr: string[],
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        oppId = '',
    ): boolean => {
        if (checkedValuesArr?.length === 0) {
            setError(
                path,
                {
                    message: 'Please select at least one option',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        } else {
            setError(
                path,
                {
                    message: '',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
        }
        return true
    }

    const validateNumberInput = (
        fieldLabel: string,
        path: string,
        value: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        minValue = 0,
        oppId = '',
    ): boolean => {
        if (Number(value) < minValue && value !== '') {
            setError(
                path,
                {
                    message: `Quantity must be ${minValue} or greater`,
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
            return false
        } else {
            setError(
                path,
                {
                    message: '',
                    wrapperEl: wrapperEl,
                    projectLocationNumber: projectLocationNumber,
                    fieldLabel: fieldLabel,
                },
                oppId,
            )
        }
        return true
    }

    const validateDateInput = (
        fieldLabel: string,
        path: string,
        startDate: string,
        setError: SetErrorType,
        wrapperEl: HTMLDivElement,
        projectLocationNumber = '',
        oppId = '',
        endDate?: string,
        isStartInput?: boolean,
    ) => {
        const start = new Date(startDate)
        const end = new Date(endDate || '')

        if (startDate && endDate && end < start) {
            if (!isStartInput) {
                setError(
                    path,
                    {
                        message: 'End date must be after start',
                        wrapperEl: wrapperEl,
                        projectLocationNumber: projectLocationNumber,
                        fieldLabel: fieldLabel,
                    },
                    oppId,
                )
            }
            return false
        }

        setError(
            path,
            {
                message: '',
                wrapperEl: wrapperEl,
                projectLocationNumber: projectLocationNumber,
                fieldLabel: fieldLabel,
            },
            oppId,
        )
        return true
    }

    return {
        validateRequiredInput,
        validateRequiredCheckbox,
        validateEmail,
        validatePhone,
        validatePhoneExtention,
        validateMaxLength,
        validateZip,
        validateGoogleLocation,
        validateNumberInput,
        validateDateInput,
    }
}

export default useValidationRules
