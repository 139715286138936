import { HttpV2 } from '@shared/apiv2/api'

// const pulicEmailDomains = [
//     'gmail.com',
//     'yahoo.com',
//     'outlook.com',
//     'aol.com',
//     'icloud.com',
//     'hotmail.com',
//     'comcast.net',
//     'verizon.net',
//     'live.com',
//     'msn.com',
//     'att.net',
//     'sbcglobal.net',
//     'cox.net',
//     'charter.net',
//     'roadrunner.com',
//     'me.com',
//     'earthlink.net',
//     'mac.com',
//     'juno.com',
//     'netzero.net',
//     'mail.ru',
//     'yandex.ru',
//     'qq.com',
//     '163.com',
//     '126.com',
//     'sina.com.cn',
//     'gmx.com',
//     'protonmail.com',
//     'zoho.com',
//     '139.com',
//     'rediffmail.com',
//     'libero.it',
//     'laposte.net',
// ]
//
// // Function to escape special characters in a string for regex
// function escapeRegExp(str: string) {
//     return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
// }
//
// const corporateEmailPattern = new RegExp(
//     `\\b[A-Za-z0-9._%+-]+@(?!(${pulicEmailDomains
//         .map((domain) => escapeRegExp(domain))
//         .join('|')}))[A-Za-z0-9.-]+\\.[A-Za-z]{2,}\\b`,
// )
//
// export const testCorporateEmail = (inputValue: string) => corporateEmailPattern.test(inputValue)

export const isCorporateEmail = async (email: string): Promise<boolean> => {
    const { data } = await HttpV2.get(`/salesforce/ncd/${email}/check`)
    return data === false
}
