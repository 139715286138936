import Dropzone, { DropzoneOptions, DropzoneFile } from 'dropzone'
import { toast } from 'react-toastify'

type CustomDropzoneOptions = DropzoneOptions & {
    init(this: Dropzone): void
}

const setDropzoneConfig = (
    dropzoneElement: HTMLDivElement,
    previewsContainer: string,
    acceptedFiles: string[],
    maxFiles: number,
    maxFileSize: number,
) => {
    const dropzoneConfig: Partial<CustomDropzoneOptions> = {
        url: '/', // URL where the files will be uploaded. We do not use it in this case but it must be set.
        // method: 'put',                                        /// by default post
        // headers: {                                            /// By default 'multipart/form-data'
        //     "Content-Type": "application/json"
        // }
        paramName: 'files', // Name of the file parameter
        maxFilesize: maxFileSize, // Maximum file size in megabytes
        dictMaxFilesExceeded: '',
        // dictFileTooBig: 'File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.',
        acceptedFiles: acceptedFiles.join(','), // Accepted file types
        // dictInvalidFileType: 'Format is not valid. Supported file types are: ' + acceptedFiles.join(', '),
        maxFiles: maxFiles, // Maximum number of files allowed
        addRemoveLinks: true, // Show remove links for uploaded files
        autoProcessQueue: false, // Disable automatic file processing
        uploadMultiple: true,
        previewsContainer: previewsContainer,
        createImageThumbnails: true,
        thumbnailWidth: 500,
        thumbnailHeight: 290,
        dictRemoveFile:
            '<img src="https://olimpwarehousing.com/wp-content/uploads/2023/05/trash-fill.svg" alt="Delete" />',
        init: function (this: Dropzone) {
            this.on('error', (file: DropzoneFile, message: string) => {
                this.removeFile(file)

                if (message.includes('upload files of this type.')) {
                    toast(
                        `File ${
                            file.name
                        } upload error: Format is not valid. Supported file types are: '${acceptedFiles.join(', ')}`,
                        {
                            type: 'error',
                            autoClose: 3000,
                        },
                    )
                }
                // else if (!file.accepted) {
                //     toast(`File ${file.name} upload error: ${message}`, {
                //         type: 'error',
                //         autoClose: 3000,
                //     })
                // }
            })

            this.on('addedfile', (file: DropzoneFile) => {
                const fileSize = (file.size / (1024 * 1024)).toFixed(2)
                if (parseFloat(fileSize) > maxFileSize) {
                    this.removeFile(file)
                    toast(`File ${file.name} is too big(${fileSize}MB). Max file size is ${maxFileSize}MB.`, {
                        type: 'error',
                        autoClose: 3000,
                    })
                }

                if (this.options && this.options.maxFiles && this.files.length > this.options.maxFiles) {
                    this.removeFile(this.files[this.files.length - 1])

                    toast('Maximum file upload limit reached', {
                        type: 'error',
                        autoClose: 3000,
                    })
                }

                // Calculate file size in MB and round to 2 decimal places
                const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2)

                // Split the file name into the name and extension
                const fileNameParts = file.name.split('.')
                const fileExtension = fileNameParts.pop()
                let fileName = fileNameParts.join('.')

                // If the file name is longer than 24 characters, truncate it
                if (fileName.length > 5) {
                    fileName = fileName.substring(0, 5) + '...' // Add ellipsis to indicate that the file name has been truncated
                }

                // Combine the truncated file name with the extension
                const displayedFileName = `${fileName}.${fileExtension}`

                // Get the preview element
                const previewElement = file.previewElement

                // Update the file name
                const nameElement = previewElement.querySelector('[data-dz-name]')
                if (nameElement) {
                    // nameElement.innerHTML = `${displayedFileName} - <span class="size">${fileSizeMB}mb</span>`
                    nameElement.innerHTML = `<p class='preview-info' ><span>${displayedFileName}</span><span>${fileSizeMB}mb</span></p>`
                }

                // Hide the file size element
                const sizeElement = previewElement.querySelector('[data-dz-size]') as HTMLElement | null
                if (sizeElement) {
                    sizeElement.style.display = 'none'
                }

                // Hide dropzone area when the maximum number of files is exceeded
                if (this.options && this.options.maxFiles && this.files.length >= this.options.maxFiles) {
                    dropzoneElement.classList.remove('drop-zone_active')
                }
            })

            this.on('removedfile', (file: DropzoneFile) => {
                // Show dropzone area again when a file is removed
                if (this.options && this.options.maxFiles && this.files.length < this.options.maxFiles) {
                    dropzoneElement.classList.add('drop-zone_active')
                }
            })
        },

        thumbnail: function (file: DropzoneFile, dataUrl: string) {
            if (file.previewElement) {
                file.previewElement.classList.remove('dz-file-preview')
                const images = file.previewElement.querySelectorAll('[data-dz-thumbnail]')
                for (let i = 0; i < images.length; i++) {
                    const thumbnailElement = images[i] as HTMLImageElement // Cast to HTMLImageElement
                    thumbnailElement.alt = file.name
                    thumbnailElement.src = dataUrl
                }
                setTimeout(function () {
                    file.previewElement.classList.add('dz-image-preview')
                }, 1)
            }
        },
    }
    return dropzoneConfig as DropzoneOptions
}

export default setDropzoneConfig
