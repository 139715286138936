import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InYardStorage = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 1C3.67188 1 3 1.67156 3 2.5V10H1C0.446875 10 0 10.4469 0 11C0 11.5531 0.446875 12 1 12H11.0344C11.2781 10.3031 12.7375 9 14.5 9C16.2625 9 17.7219 10.3031 17.9647 12H18.5C19.3284 12 20 11.3281 20 10.5V2.5C20 1.67156 19.3284 1 18.5 1H4.5ZM18 3.5V8.25625C17.6928 8.00313 17.3578 7.78125 17 7.6V3.5C17 3.225 17.2238 3 17.5 3C17.7762 3 18 3.225 18 3.5ZM14.5 7C14.3313 7 14.1656 7.00625 14 7.02187V3.5C14 3.225 14.225 3 14.5 3C14.775 3 15 3.225 15 3.5V7.02187C14.8344 7.00625 14.6687 7 14.5 7ZM12 7.6C11.6438 7.78125 11.3062 8.00313 11 8.25625V3.5C11 3.225 11.225 3 11.5 3C11.775 3 12 3.225 12 3.5V7.6ZM9 3.5V9.5C9 9.775 8.775 10 8.5 10C8.225 10 8 9.775 8 9.5V3.5C8 3.225 8.225 3 8.5 3C8.775 3 9 3.225 9 3.5ZM5 3.5C5 3.225 5.225 3 5.5 3C5.775 3 6 3.225 6 3.5V9.5C6 9.775 5.775 10 5.5 10C5.225 10 5 9.775 5 9.5V3.5ZM17 12.5C17 11.1187 15.8812 10 14.5 10C13.1188 10 12 11.1187 12 12.5C12 13.8813 13.1188 15 14.5 15C15.8812 15 17 13.8813 17 12.5ZM14.5 13.5C13.9469 13.5 13.5 13.0531 13.5 12.5C13.5 11.9469 13.9469 11.5 14.5 11.5C15.0531 11.5 15.5 11.9469 15.5 12.5C15.5 13.0531 15.0531 13.5 14.5 13.5Z" />
            </svg>
        </IconBase>
    )
}
