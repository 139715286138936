import React, { useEffect } from 'react'
import {
    ColumnDef,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    Row,
    SortingState,
    useReactTable,
    getSortedRowModel,
} from '@tanstack/react-table'

import './ModernTable.scss'
import { OnChangeFn } from '@tanstack/table-core/build/lib/types'
import { PaginationState } from '@tanstack/table-core/build/lib/features/Pagination'
import Pagination from '@/components/ModernTable/Pagination'
import Progress from './Progress'
import { ModernTableHeader } from './ModernTableHeader'
import { ModernTableRow } from './ModernTableRow'
import { ModernTableFooter } from './ModernTableFooter'

type ModernTableProps<T> = {
    data?: T[]
    total?: number
    loading?: boolean
    columns: ColumnDef<T, any>[]
    expandedColumns?: any[]
    onClick?: (row: T) => void
    expanded?: any
    setExpanded?: (param?: any) => void
    onPaginationChange?: OnChangeFn<PaginationState>
    pagination?: PaginationState
    setSorting?: (param?: any) => void
    sorting?: SortingState
    onRowClick?: (param: React.MouseEvent<HTMLTableRowElement>, param2: Row<any>) => void
    headerClassName?: string
    globalFilter?: string
}

export type ColumnFilters = {
    id: string
    value: string
}[]

export function ModernTable<T>({
    data,
    columns,
    expandedColumns,
    onClick,
    loading,
    expanded,
    setExpanded,
    onPaginationChange,
    pagination,
    total,
    setSorting,
    sorting,
    onRowClick,
    headerClassName,
    globalFilter,
}: ModernTableProps<T & { subRows?: any[] }>) {
    const table = useReactTable({
        data: data ?? [],
        columns,
        state: {
            expanded,
            pagination,
            sorting,
            globalFilter,
        },
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.subRows,
        getCoreRowModel: getCoreRowModel(),
        // getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        manualPagination: true,
        manualExpanding: true,
        manualSorting: true,
        pageCount: total && pagination ? Math.ceil(total / pagination.pageSize) : undefined,
        onPaginationChange,
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        enableGlobalFilter: true,
    })
    return (
        <div className="olimp-table">
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup, index) => (
                        <ModernTableHeader
                            key={`headerGroup-${index}`}
                            headerClassName={headerClassName}
                            headerGroup={headerGroup}
                        />
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                        <ModernTableRow
                            key={`getRowModel-${index}`}
                            row={row}
                            onRowClick={onRowClick}
                            expandedColumns={expandedColumns}
                            columns={columns}
                        />
                    ))}
                </tbody>

                {/* 
                Do not show footer if it's content is empty!
                
                <tfoot>
                    {table.getFooterGroups().map((footerGroup, index) => (
                        <ModernTableFooter key={`getFooterGroups-${index}`} footerGroup={footerGroup} />
                    ))}
                </tfoot> */}
            </table>
            {total === 0 && !loading && (
                <div className="olimp-table__nothing-found">
                    <h3>No records were found.</h3>
                </div>
            )}
            {loading && (
                <div className="olimp-table__loading">
                    {' '}
                    <Progress />
                </div>
            )}
            {pagination && total !== undefined && total !== 0 ? <Pagination table={table} total={total} /> : null}
        </div>
    )
}
