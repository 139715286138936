import React, { FC, RefObject, useEffect, useState } from 'react'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'

interface RadioInputProps {
    currentOppId?: string
    errorMessage?: string | false
    avaliableOptions: string[]
    fieldName: string
    value: string
    handleChange: (item: string) => void
    handleClick?: (item: string) => void
    isFlex?: boolean
    allowUncheck?: boolean
    initialValue?: string
    wrapperRef?: RefObject<HTMLDivElement>
}

const RadioInput: FC<RadioInputProps> = ({
    currentOppId,
    errorMessage,
    avaliableOptions,
    fieldName,
    value,
    handleChange,
    handleClick,
    wrapperRef,
    isFlex = false,
    allowUncheck = false, // Makes the Radio required
    initialValue,
}) => {
    const [radioId] = useState(currentOppId || generateRandomId())

    useEffect(() => {
        if (initialValue) {
            handleChange(initialValue)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="group-wrapper" ref={wrapperRef}>
            <div className={isFlex ? 'flex-checkboxes' : 'checkbox-group'}>
                {avaliableOptions.map((item, index) => {
                    const itemId = `${item}`.replace(/\s/g, '_')

                    const name = fieldName + (radioId ? '-' + radioId : '')
                    const id = itemId + (radioId ? '-' + radioId : '')

                    return (
                        <div className="radio-wrapper" key={id}>
                            <input
                                type="radio"
                                name={name}
                                id={id}
                                checked={value === item}
                                onChange={() => handleChange(item)}
                                onClick={() => {
                                    if (allowUncheck && handleClick) {
                                        handleClick(item)
                                    }
                                }}
                            />
                            <label htmlFor={id}>{item === 'Freeze' ? 'Frozen' : item}</label>
                        </div>
                    )
                })}
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default RadioInput
