import React, { FC, Ref, useRef, useEffect, useState, KeyboardEventHandler, useMemo } from 'react'
import cn from 'classnames'
import { getDetails } from 'use-places-autocomplete'
import { useGoogleApis } from '@/pages/RFQPortal/components/GoogleAutocompleteInput/useGoogleApis'
import useOnclickOutside from 'react-cool-onclickoutside'
import { Loader as OlimpLoader } from '@/components/Loader'
import { v4 } from 'uuid'
import './index.scss'

interface GoogleAutocompleteInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    changePlaceFull: (path: string, value: any) => void
    onChangeText?: (path: string, value: any) => void
    validate?: () => void
    inputPath?: string
    shouldResetOnBlur?: boolean
    errorMessage?: string | undefined | false
    label: string
    wrapperClassName?: string
    id?: string
    value?: string
    initialValue?: string
    setUpdatedInitialValue?: (location: any) => void
    wrapperRef?: Ref<HTMLDivElement>
    className?: string
    disabled?: boolean
}

declare global {
    interface Window {
        googleMapsScriptLoaded?: boolean
        googleMapsAPILoading?: boolean
    }
}
export function buildAddress({ street, zipCode, state, city, countryCode }: any) {
    const addressComponents = []
    if (street) {
        addressComponents.push(street)
    }

    if (city) {
        addressComponents.push(city)
    }

    if (state) {
        addressComponents.push(state + (zipCode ? ' ' + zipCode : ''))
    } else if (zipCode) {
        addressComponents.push(zipCode)
    }

    if (countryCode) {
        addressComponents.push(countryCode)
    }

    return addressComponents.join(', ').trim()
}

const GoogleAutocompleteInput: FC<GoogleAutocompleteInputProps> = ({
    // changePlace,
    inputPath = '',
    errorMessage,
    changePlaceFull,
    wrapperClassName,
    label,
    id: propId,
    onChangeText,
    shouldResetOnBlur,
    value,
    initialValue,
    wrapperRef,
    className,
    disabled = false,
    setUpdatedInitialValue,
    validate,
    ...rest
}) => {
    const [placeLoading, setPlaceLoading] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)

    const [currentResult, setCurrentResult] = useState(undefined)
    const [displayOptions, setDisplayOptions] = useState(false)
    const [useInitialValue, setUseInitialValue] = useState<boolean | undefined>(undefined)

    const idRef = useRef<string>(propId || v4())
    const id = propId || idRef.current

    const { places, loading } = useGoogleApis(
        {
            strictBounds: true,
            types: ['geocode'],
            fields: ['address_components', 'geometry', 'icon', 'name'],
            language: 'en',
        },
        () => {
            return new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(14.0, -169.0),
                new window.google.maps.LatLng(85.0, -52.0),
            )
        },
    )

    useEffect(() => {
        const structuredFormatting = places.suggestions.data?.[focusedIndex]?.structured_formatting
        if (focusedIndex > -1 && locationRef.current && structuredFormatting) {
            const secondPart = (
                structuredFormatting.secondary_text && structuredFormatting.secondary_text !== 'undefined'
                    ? structuredFormatting.secondary_text
                    : ''
            ).trim()
            locationRef.current.value = structuredFormatting.main_text + (secondPart ? ', ' + secondPart : '')
        }
    }, [focusedIndex])

    const locationRef = useRef<HTMLInputElement | null>(null)
    // const wrapperRef = useRef<HTMLInputElement | null>(null)

    // useOnclickOutside(
    //     () => {
    //         setDisplayOptions(false)
    //         if (!currentResult) {
    //             const suggestion = places.suggestions.data[0]
    //             if (suggestion) {
    //                 handleSelect(suggestion)()
    //             } else {
    //                 changePlaceFull(inputPath, {})
    //                 onChangeText?.(inputPath, '')
    //             }
    //         }
    //         if (onBlurChange) {
    //             onBlurChange(value || '', currentResult)
    //         }
    //     },
    //     { refs: [wrapperRef] },
    // )

    const handleLocationChange = (e: any) => {
        // check if e.target is li element, then skip
        if (e.target.tagName === 'LI') {
            return
        }

        setDisplayOptions(false)
        const hasFocus = focusedIndex !== -1
        if (!currentResult || hasFocus) {
            const suggestion = places.suggestions.data[hasFocus ? focusedIndex : 0]
            if (places.value !== initialValue) {
                if (suggestion) {
                    handleSelect(suggestion)()
                } else if (!disabled) {
                    changePlaceFull(inputPath, {})
                    onChangeText?.(inputPath, '')
                }
            }
        }
        setFocusedIndex(-1)
        // if (onBlurChange) {
        //     onBlurChange(value || '', currentResult)
        // }
    }

    useOnclickOutside(handleLocationChange, { refs: [locationRef] })

    useEffect(() => {
        if ((places.value !== value || (useInitialValue && places.value !== initialValue)) && value) {
            places.setValue(value || '', false)
        }
    }, [value, places.ready, initialValue, useInitialValue])

    const handleSelect =
        ({ description, place_id }: any) =>
        () => {
            setPlaceLoading(true)
            // When the user selects a place, we can replace the keyword without request data from API
            // places.setValue(description, false)
            setDisplayOptions(false)

            getDetails({
                placeId: place_id,
                fields: ['address_components', 'geometry', 'icon', 'name'],
                language: 'en',
            })
                .then((results) => {
                    if (!results || typeof results === 'string') return
                    const selectedPlace = results

                    if (selectedPlace && selectedPlace.address_components) {
                        const addressComponents = selectedPlace.address_components

                        const extractedAddress = {
                            zip: '',
                            street: '',
                            building: '',
                            city: '',
                            state: '',
                        }

                        addressComponents.forEach((component) => {
                            const types = component.types

                            if (types.includes('postal_code')) {
                                extractedAddress.zip = component.long_name
                            } else if (types.includes('route')) {
                                extractedAddress.street = component.long_name
                            } else if (types.includes('street_number')) {
                                extractedAddress.building = component.long_name
                            } else if (types.includes('locality')) {
                                extractedAddress.city = component.long_name
                            } else if (types.includes('administrative_area_level_1')) {
                                extractedAddress.state = component.short_name
                            }
                        })

                        const countryComponent = addressComponents.find((component) =>
                            component.types.includes('country'),
                        )
                        const country = countryComponent ? countryComponent.short_name : ''

                        const formattedAddress = buildAddress({
                            street: ((extractedAddress.building ?? '') + ' ' + (extractedAddress.street ?? '')).trim(),
                            zipCode: extractedAddress.zip,
                            state: extractedAddress.state,
                            city: extractedAddress.city,
                            countryCode: country,
                        })

                        const resultObj = {
                            formattedAddress,
                            country,
                            zip: extractedAddress.zip,
                            street: extractedAddress.street,
                            building: extractedAddress.building,
                            city: extractedAddress.city,
                            state: extractedAddress.state,
                            lat: selectedPlace.geometry?.location?.lat?.(),
                            lng: selectedPlace.geometry?.location?.lng?.(),
                        }
                        setCurrentResult(resultObj as any)
                        places.setValue(resultObj.formattedAddress, false)

                        changePlaceFull(inputPath, resultObj)

                        if (useInitialValue && setUpdatedInitialValue) {
                            setUpdatedInitialValue(resultObj)
                        }
                    }
                })
                .finally(() => {
                    if (validate) {
                        setTimeout(() => {
                            validate()
                        }, 50)
                    }
                    setPlaceLoading(false)
                })
        }
    const renderSuggestions = useMemo(
        () =>
            places.suggestions.loading ? (
                <OlimpLoader type="small" />
            ) : (
                places.suggestions.data.map((suggestion, index) => {
                    const {
                        place_id,
                        structured_formatting: { main_text, secondary_text },
                    } = suggestion
                    const isFocused = index === focusedIndex

                    return (
                        <li
                            key={place_id}
                            onClick={handleSelect(suggestion)}
                            className={cn('suggestion-item', { focused: isFocused })}
                        >
                            <strong>{main_text}</strong> <small>{secondary_text}</small>
                        </li>
                    )
                })
            ),
        [places.suggestions.loading, places.suggestions.data, focusedIndex],
    )

    useEffect(() => {
        if (places.ready && initialValue && useInitialValue === undefined) {
            places.setValue(initialValue)
            setUseInitialValue(true)
        }
    }, [places.ready, initialValue, useInitialValue])

    useEffect(() => {
        if (
            places.ready &&
            useInitialValue &&
            !places.suggestions.loading &&
            places.suggestions.data.length > 0 &&
            !currentResult
        ) {
            handleSelect(places.suggestions.data[0])()
            setUseInitialValue(false)
        }
    }, [places.suggestions.loading, places.ready, useInitialValue])

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        const suggestionsCount = places.suggestions.data.length
        if (event.key === 'ArrowDown') {
            event.preventDefault() // Prevent scrolling the page
            setFocusedIndex((prevIndex) => (prevIndex < suggestionsCount - 1 ? prevIndex + 1 : prevIndex))
        } else if (event.key === 'ArrowUp') {
            event.preventDefault() // Prevent scrolling the page
            setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))
        } else if (event.key === 'Enter' && focusedIndex >= 0 && focusedIndex < suggestionsCount) {
            event.preventDefault() // Prevent form submission
            handleSelect(places.suggestions.data[focusedIndex])()
        }
    }

    return (
        <div
            className={cn('input-wrapper google-search', wrapperClassName)}
            ref={wrapperRef}
            style={{ opacity: disabled ? 0.8 : 1 }}
        >
            <label className="google-search__label" htmlFor={`serviceLocation-${id}`}>
                {label}
            </label>
            {placeLoading && <OlimpLoader type="small" />}
            <input
                ref={locationRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUseInitialValue(false)
                    places.setValue(e.target.value)
                    if (onChangeText) {
                        onChangeText(inputPath, e.target.value)
                    }
                    if (e.target.value === '') {
                        changePlaceFull(inputPath, {})
                    }
                    setCurrentResult(undefined)
                    setDisplayOptions(!!e.target.value)
                }}
                id={id}
                value={places.value}
                onKeyDown={handleKeyDown}
                className={cn('google-search__input', className, { 'input-error': !!errorMessage })}
                onBlur={(e) => {
                    handleLocationChange(e)
                    if (validate) {
                        validate()
                    }
                }}
                disabled={disabled}
                type="text"
                autoComplete="off"
                {...rest}
            />
            <div
                className={cn('suggestion-items', {
                    open: displayOptions && places.value !== '' && places.suggestions.data.length > 0,
                })}
            >
                {renderSuggestions}
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default GoogleAutocompleteInput
