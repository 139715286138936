import React, { FC, JSXElementConstructor, useEffect } from 'react'
import { Link, Navigate, RouteProps } from 'react-router-dom'
import { updateLoggedState, useUserState } from '@/store/user'
import ROUTES from '@/router'
import { useTermsOfService } from '@shared/apiv2/terms-of-service'
import { Info } from '@/components/Info'
import useSWR from 'swr'
import { defaultSWRConfig, getSWRFetcher } from '@shared/apiv2/api'

type IPrivateRouteProps = RouteProps & {
    isLoggedIn: boolean
    component: JSXElementConstructor<any>
}

export const PrivateRoute: FC<IPrivateRouteProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { isLoggedIn, component: Component } = props

    if (isLoggedIn) {
        if (window.location.pathname === '/' && localStorage.getItem('type')) {
            return <Navigate to={{ pathname: `/${localStorage.getItem('type')}` }} />
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Component />
    }
    return <Navigate to={{ pathname: '/' }} state={{ from: window.location.pathname }} />
}

export const useMe = () => {
    const { dispatch, state } = useUserState()
    const { data, error } = useSWR('auth/me', getSWRFetcher, defaultSWRConfig)
    useEffect(() => {
        if (data) {
            updateLoggedState(data, dispatch!)
        }
    }, [data])

    return {
        error,
        loading: !data && !error,
    }
}

export const LoggedInRoute: FC = () => {
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    const { loading, error } = useMe()

    if (error && error.response?.status === 401) {
        return <Navigate to={{ pathname: '/' }} />
    }

    if (error && error.response?.status === 504) {
        return <Navigate to={{ pathname: '/504' }} />
    }

    if (isLoggedIn && !userInfo?.verified) {
        return <Navigate to={{ pathname: ROUTES.signUp.verification }} />
    }

    if (isLoggedIn) {
        return null
    }
    return (
        <Navigate to={{ pathname: '/', search: window.location.search }} state={{ from: window.location.pathname }} />
    )
}
