import React, { useRef, FC } from 'react'
import Button, { ButtonType } from '@/components/Button'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'

interface FileUploadProps {
    onFileSelect: (files: FileList | null) => void
    types?: ButtonType
    title?: string
    maxSizeMB?: number
    id?: string
    multiple?: boolean
    loading?: boolean
    disabled?: boolean
    className?: string
    accept?: string
}

const calculateFilesize = (files: FileList) => {
    const sizeInBytes = Array.from(files).reduce((acc, file) => acc + file.size, 0)
    const sizeInMB = sizeInBytes / 1024 / 1024
    return sizeInMB
}

const FileUpload: FC<FileUploadProps> = ({
    onFileSelect,
    types = 'bordered',
    title = 'Upload File',
    id = `file-upload-${v4()}`,
    multiple = false,
    loading = false,
    maxSizeMB = 25,
    className,
    disabled = false,
    accept,
}) => {
    // const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        const sizeInMB = calculateFilesize(event.target.files)
        if (sizeInMB > maxSizeMB) {
            toast(`Files total size should be less than ${maxSizeMB}MB`, { type: 'error' })
            return
        }
        // setSelectedFiles(event.target.files)
        onFileSelect(event.target.files)
    }

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
            fileInputRef.current?.click()
        }
    }

    return (
        <div className={className}>
            <input
                id={id}
                accept={accept}
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                multiple={multiple}
            />
            <Button disabled={disabled || loading} types={[types]} onClick={handleClick} loading={loading}>
                {title}
            </Button>
        </div>
    )
}

export default FileUpload
