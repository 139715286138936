import React, { FC, useEffect, useState } from 'react'
import { OrderChat } from '@/pages/Order/ui/OrderChat'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { updateLoggedState, useUserState } from '@/store/user'
import { ChatSelector } from '@/pages/PSM/Chat/ChatSelector'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { InArrowLeft } from '@/shared/ui/Icons/InArrowLeft'
import { orderChatActions } from '@/pages/Order/ui/OrderChat/model/slice'

export const PSMRoutes: FC = () => {
    const { dispatch } = useUserState()
    const appDispatch = useAppDispatch()
    const selectedChat = useAppSelector((state) => state.orderChat.selectedChat)
    const currentChat = useAppSelector((state) => state.orderChat.currentChat)
    const [rfqId, setRfqId] = useState<string | null>(null)
    const setupToken = async () => {
        // read psmToken from URL
        const urlParams = new URLSearchParams(window.location.search)
        const psmToken = urlParams.get('psmToken')
        const rfq = urlParams.get('rfq')
        setRfqId(rfq)
        if (!psmToken) {
            // ignore now
        } else {
            // set token to local storage
            localStorage.setItem('token', psmToken!)
            setHttpV2Token(psmToken!)

            const { data: myData } = await HttpV2.get('/auth/me')

            updateLoggedState(myData, dispatch)
        }
    }

    useEffect(() => {
        setupToken()
    }, [])

    return (
        <>
            {currentChat && (
                <div className="flex justify-center items-center h-12 bg-primary text-white">
                    {/*make back icon*/}
                    <InArrowLeft
                        className="w-6 h-6 mr-2"
                        onClick={() => {
                            appDispatch(orderChatActions.selectChat(undefined))
                            appDispatch(orderChatActions.setCurrentChat(undefined))
                        }}
                    />
                    Chat with {currentChat?.account?.name ?? (selectedChat === 'DEMAND_PSM' ? 'Demand' : 'Supply')}
                </div>
            )}

            <div className={'flex justify-start align-center relative h-[600px] border-t border-t-gray-3'}>
                {!selectedChat && <ChatSelector />}

                {rfqId && (
                    <OrderChat
                        psmMode
                        reference={rfqId}
                        className="border-0 border-l rounded-none !shadow-none"
                        hide={!selectedChat}
                    />
                )}
            </div>
        </>
    )
}
