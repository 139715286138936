import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import notFoundImg from 'images/404.svg'
import Button from '@/components/Button'
import { useLocation } from 'react-router'
import qs from 'query-string'
import './NotFound.scss'

export const NotFound: React.FC = () => {
    const location = useLocation()
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    return (
        <div className={cn('not-found')}>
            <div className="wrapper">
                <div className="wrap">
                    <div className="title">404 Error</div>
                    <div className="description">{params?.message || 'This page does not exist'}</div>
                    <Link to="/">
                        <Button label="Back to homepage" types={['blue', 'large']} />
                    </Link>
                </div>

                <div className="img">
                    <img src={notFoundImg} alt="404" />
                </div>
            </div>
        </div>
    )
}
