import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'

interface WpDataContextProps {
    children: ReactNode
}

interface WpDataContextValue {
    wpContextData: any
    setWpContextData: (newData: any) => void
}

const WpDataContext = createContext<WpDataContextValue | null>(null)

export const WpDataProvider: React.FC<WpDataContextProps> = ({ children }) => {
    const [wpContextData, setWpData] = useState<any>()

    const setWpContextData = (newData: any) => {
        setWpData(newData)
    }

    const contextValue: WpDataContextValue = {
        wpContextData,
        setWpContextData,
    }

    return <WpDataContext.Provider value={contextValue}>{children}</WpDataContext.Provider>
}

export const useWpData = (): WpDataContextValue => {
    const context = useContext(WpDataContext)

    if (!context) {
        throw new Error('useWpData must be used within a WpDataProvider')
    }

    return context
}
