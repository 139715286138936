import React from 'react'
import './Badge.scss'
import { tcn } from '@/utils/tcn'

export enum BadgeVariant {
    green = 'green',
    yellow = 'yellow',
    danger = 'danger',
    blue = 'blue',
}

interface BadgeProps {
    children: React.ReactNode
    variant?: BadgeVariant
    className?: string
}

export const Badge = ({ children, variant, className }: BadgeProps) => {
    const badgeClass = tcn(`badge badge__${variant}`, className)
    return <div className={badgeClass}>{children}</div>
}
