import React from 'react'
import { ReactComponent as SearchIcon } from '@/assets/images/search-icon.svg'
import './WarehousesMenu.scss'
import { WarehouseInfo } from '../'
import { Loader } from '@/components/Loader'

interface MenuSelectProps {
    expandMenu: boolean
    setExpandMenu: (value: boolean) => void
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    data: WarehouseInfo[] | undefined
    id: string | null
    loading: boolean
    selectWarehouse: (id: string) => void
}

export const MenuSelect = ({
    expandMenu,
    setExpandMenu,
    handleSearch,
    data,
    id,
    loading,
    selectWarehouse,
}: MenuSelectProps) => {
    return (
        <div className="warehouses-menu__select">
            <div className="warehouses-menu__inputWrap">
                <input type="text" onChange={handleSearch} className="warehouses-menu__search" placeholder="Search" />
                {loading ? (
                    <div className="warehouses-menu__loading">
                        <Loader type="small" />
                    </div>
                ) : (
                    <SearchIcon className="warehouses-menu__searchIcon" />
                )}
            </div>

            {data?.length ? (
                <>
                    <div
                        className={`warehouses-menu__dropdown ${expandMenu ? 'warehouses-menu__dropdown_active' : ''}`}
                    >
                        <ul className="warehouses-menu__dropdown__list">
                            {data?.map((elem, index) => (
                                <li
                                    onClick={() => selectWarehouse(elem?.id)}
                                    className={`${
                                        elem?.id === id
                                            ? 'warehouses-menu__dropdown__item warehouses-menu__dropdown__item_active'
                                            : 'warehouses-menu__dropdown__item'
                                    }`}
                                    key={`warehouses-menu-${index}`}
                                >
                                    {elem.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div
                        className="warehouses-menu__viewAll"
                        onClick={(e) => {
                            e.stopPropagation()
                            setExpandMenu(!expandMenu)
                        }}
                    >
                        view all
                    </div>
                </>
            ) : null}
        </div>
    )
}
