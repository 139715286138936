import { Avatar, AvatarFallback } from '@/shared/ui/Avatar'
import React, { FC } from 'react'
import dayjs from 'dayjs'
import { ChatMessageDto } from '@/entities/chat/model/dto'
import cn from 'classnames'

export const ChatMessage: FC<ChatMessageDto> = ({ message, createdAt, name, nameShort, me }) => {
    return (
        <div className={cn('flex flex-col gap-2', { 'items-end ml-auto ': me, 'items-start mr-auto': !me })}>
            <div className="flex flex-row-reverse gap-2">
                <div
                    className={cn('max-w-[338px] p-3 border border-border font-medium', {
                        'bg-background-tertiary rounded-[20px_20px_20px_2px]': !me,
                        'bg-primary rounded-[20px_20px_2px_20px] text-white': me,
                    })}
                >
                    {message}
                </div>
                <div className="self-end">
                    <Avatar>
                        <AvatarFallback className="text-sm">{nameShort}</AvatarFallback>
                    </Avatar>
                </div>
            </div>
            <div className="flex flex-row-reverse gap-2 mr-auto">
                <div>
                    <span className="mr-2">{me ? 'You' : name}</span>
                    <span className="text-foreground-secondary text-sm">
                        {dayjs(createdAt).format('M/D/YYYY h:mm A')}
                    </span>
                </div>
                <div className="w-8 shrink-0" />
            </div>
        </div>
    )
}

// For reference
// <div className="flex flex-col gap-3 p-3">
//     {/** User Message */}
//     <div className="ml-auto flex flex-col items-end gap-2">
//         <div className="flex gap-2">
//             <div
//                 className="max-w-[338px] p-3 border border-border rounded-[20px_20px_2px_20px] bg-primary text-white font-medium">
//                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores eligendi
//                 facilis hic inventore libero nobis nostrum provident quaerat, reprehenderit?
//             </div>
//             <div className="self-end">
//                 <Avatar>
//                     <AvatarFallback className="text-sm">MM</AvatarFallback>
//                 </Avatar>
//             </div>
//         </div>
//         <div className="flex gap-2">
//             <div>
//                 <span className="mr-2">You</span>
//                 <span className="text-foreground-secondary text-sm">5/25/1977 4:46 PM</span>
//             </div>
//             <div className="w-8 shrink-0" />
//         </div>
//     </div>
//
//     {/** Olimp Message */}
//     <div className="mr-auto flex flex-col gap-2">
//         <div className="flex flex-row-reverse gap-2">
//             <div
//                 className="max-w-[338px] p-3 border border-border rounded-[20px_20px_20px_2px] bg-background-tertiary font-medium">
//                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores eligendi
//                 facilis hic inventore libero nobis nostrum provident quaerat, reprehenderit?
//             </div>
//             <div className="self-end">
//                 <Avatar>
//                     <AvatarFallback className="text-sm">MM</AvatarFallback>
//                 </Avatar>
//             </div>
//         </div>
//         <div className="flex flex-row-reverse gap-2 mr-auto">
//             <div>
//                 <span className="mr-2">First Last</span>
//                 <span className="text-foreground-secondary text-sm">5/25/1977 4:46 PM</span>
//             </div>
//             <div className="w-8 shrink-0" />
//         </div>
//     </div>
//
//     {/** User Message */}
//     <div className="ml-auto flex flex-col items-end gap-2">
//         <div className="flex gap-2">
//             <div
//                 className="max-w-[338px] p-3 border border-border rounded-[20px_20px_2px_20px] bg-primary text-white font-medium">
//                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores eligendi
//                 facilis hic inventore libero nobis nostrum provident quaerat, reprehenderit?
//             </div>
//             <div className="self-end">
//                 <Avatar>
//                     <AvatarFallback className="text-sm">MM</AvatarFallback>
//                 </Avatar>
//             </div>
//         </div>
//         <div className="flex gap-2">
//             <div>
//                 <span className="mr-2">You</span>
//                 <span className="text-foreground-secondary text-sm">5/25/1977 4:46 PM</span>
//             </div>
//             <div className="w-8 shrink-0" />
//         </div>
//     </div>
//
//     {/** Olimp Message */}
//     <div className="mr-auto flex flex-col gap-2">
//         <div className="flex flex-row-reverse gap-2">
//             <div
//                 className="max-w-[338px] p-3 border border-border rounded-[20px_20px_20px_2px] bg-background-tertiary font-medium">
//                 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dolores eligendi
//                 facilis hic inventore libero nobis nostrum provident quaerat, reprehenderit?
//             </div>
//             <div className="self-end">
//                 <Avatar>
//                     <AvatarFallback className="text-sm">MM</AvatarFallback>
//                 </Avatar>
//             </div>
//         </div>
//         <div className="flex flex-row-reverse gap-2 mr-auto">
//             <div>
//                 <span className="mr-2">First Last</span>
//                 <span className="text-foreground-secondary text-sm">5/25/1977 4:46 PM</span>
//             </div>
//             <div className="w-8 shrink-0" />
//         </div>
//     </div>
// </div>
