import React, { FC } from 'react'
import DotsMenu from '@/components/DotsMenu'

interface LogoWrapperProps {
    wrapperClassName: string
    logoClassName: string
    isImageAvaliable?: boolean
    deleteLogo: () => void
}

export const LogoWrapper: FC<LogoWrapperProps> = ({
    children,
    wrapperClassName,
    logoClassName,
    isImageAvaliable = false,
    deleteLogo,
}) => {
    return (
        <div className={wrapperClassName}>
            <div className={logoClassName}>{children}</div>
            {isImageAvaliable && (
                <DotsMenu
                    buttons={[
                        {
                            name: 'Delete',
                            onClick: deleteLogo,
                            className: 'delete-btn',
                        },
                    ]}
                />
            )}
        </div>
    )
}
