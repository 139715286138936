import React from 'react'
import OrdersMain from '@/pages/Warehouses/Orders/OrdersMain'
import OrdersSidebar from '@/pages/Warehouses/Orders/OrdersSidebar'

const WarehousesOrders = () => {
    return (
        <div className="flex gap-5 flex-col xl:flex-row">
            <OrdersMain />
            <OrdersSidebar />
        </div>
    )
}

export default WarehousesOrders
