import React, { FC, useEffect, useState } from 'react'
import RFQListMenu from '@/pages/RFQPortal/parts/RFQListMenu'
import NewFormRfqSearch from '@/parts/Forms/form-rfq-search/NewFormRfqSearch'
import { claims, RfqPortalService, useRFQs } from '@/services/rfq.portal.service'
import { IFilter } from '../RfqPortalRoutes'
import { HttpV2 } from '@shared/apiv2/api'
import useProjectTable from './useProjectTable'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import { createPortal } from 'react-dom'
import { Loader } from '@/components/Loader'
import { ReactComponent as UploadIcon } from '@/assets/images/icon-upload.svg'

interface RFQProjectListProps {
    filters: IFilter
    setFilters: any
}

export interface ProjectsData {
    projects: Project[]
    total: number
}

export interface Project {
    id: string
    name: string
    rfqs: any
}

const RFQProjectList: FC<RFQProjectListProps> = ({ filters, setFilters }) => {
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const [expanded, setExpanded] = useState<boolean>(false)

    const { data: projectsData, error } = useRFQs(pagination, filters, true)
    const loading = !projectsData && !error
    const userData = claims()
    const [downloadXLSXLoading, setDownloadXLSXLoading] = useState(false)

    const { tableData, tableColumns } = useProjectTable(projectsData?.projects ?? [])

    function handlePaginationChange(paging: any) {
        setPagination(paging)
    }

    const handleFiltersUpdate = (values: any) => {
        setPagination((paging) => ({ ...paging, pageIndex: 0 }))
        setFilters(values)
    }

    useEffect(() => {
        setExpanded(false)
    }, [pagination.pageIndex])

    const downloadXLSX = async () => {
        setDownloadXLSXLoading(true)
        try {
            const resp = await HttpV2.get('/rfq/export', {
                params: filters,
                responseType: 'blob',
            })
            const href = URL.createObjectURL(resp.data)

            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'rfq.xlsx')
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            setDownloadXLSXLoading(false)
        } catch (err: any) {
            console.error('downloadXLSX: ', err)
            setDownloadXLSXLoading(false)
        }
    }
    const toolsContainer = document.querySelector('#rfq-header-tools')

    return (
        <div className="rfq-table">
            <div className="rfq-table-list rfq-table_bg">
                <RFQListMenu />
                <div className="rfq-table-content">
                    <NewFormRfqSearch onUpdate={handleFiltersUpdate} filters={filters} admin={userData?.admin} />
                    <ModernTable
                        headerClassName={'rfq-table_header-bg'}
                        loading={loading}
                        setExpanded={setExpanded}
                        expanded={expanded}
                        columns={tableColumns}
                        pagination={pagination}
                        total={projectsData?.total}
                        onPaginationChange={handlePaginationChange}
                        data={tableData}
                    />
                    {toolsContainer &&
                        createPortal(
                            <div className="rfq-header__tools-button" onClick={downloadXLSX}>
                                {downloadXLSXLoading ? (
                                    <Loader type="small" />
                                ) : (
                                    <>
                                        Export <UploadIcon />
                                    </>
                                )}
                            </div>,
                            toolsContainer,
                        )}
                </div>
            </div>
        </div>
    )
}

export default RFQProjectList
