import useSWR from 'swr'
import { defaultSWRConfig, getSWRFetcher } from '@shared/apiv2/api'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'

export const useGetLoadboardOrder = (projectKey: string | undefined) => {
    return useSWR<LoadboardOrder>(
        projectKey ? `/load-board/order/${projectKey}` : null,
        getSWRFetcher,
        defaultSWRConfig,
    )
}
