import React, { useEffect, useState } from 'react'
import { UploadFile } from '../UploadFile'
import { Badge, BadgeVariant } from '@/components/Badge'
import WarehousesRow from '../components/WarehousesRow'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { useParams } from 'react-router-dom'
import { DocumentsType, FileType } from '../types'
import { toast } from 'react-toastify'
import { Loader } from '@/components/Loader'

interface DocumentProps {
    document: FileType
    refreshDocuments?: () => void
    deleteFile: (documentId: string) => void
    downloadFile: (documentId: string) => void
    isLoading?: boolean
    manager?: boolean
}

export default function Files({ documents, refreshDocuments, deleteFile, downloadFile, isLoading, manager }: any) {
    const { id } = useParams()
    const [currentDocuments, setCurrentDocuments] = useState<FileType[]>([])
    const [loading, setLoading] = useState(false)

    // get documents
    useEffect(() => {
        if (documents) {
            const filterDocuments = documents.filter((file: FileType) => file?.type === DocumentsType.ACCOUNT_WH_FILES)

            setCurrentDocuments(filterDocuments)
        }
    }, [documents])

    const handleDownloadFile = async (documentId: string) => {
        setLoading(true)
        await downloadFile(documentId)
        setLoading(false)
    }

    const handleDeleteFile = async (documentId: string) => {
        setLoading(true)
        await deleteFile(documentId)
        await refreshDocuments()
        setLoading(false)
    }

    // fetch post file
    const handleFileSelect = async (files: FileList | null) => {
        if (files) {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append('file', files[0])

                await HttpV2.post(`/accounts/${id}/documents/upload/${DocumentsType.ACCOUNT_WH_FILES}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(async (data) => {
                        // console.log('uploaded documents', data)
                        toast('Success!', { type: 'success' })
                        await refreshDocuments()
                    })
                    .catch((err: any) => {
                        console.log('err: ', err?.response?.data)
                        toast(err?.response?.data?.message, { type: 'error' })
                    })
                    .finally(() => setLoading(false))
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <section className="app-card">
            <UploadFile
                title="Files"
                manager={manager}
                handleFileSelect={handleFileSelect}
                disabled={loading}
                accept=".doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.png,.jpg"
                hintDescription="Word, Excel, PDF, PowerPoint, PNG or JPG are accepted"
            >
                {loading || isLoading ? (
                    <Loader type="small" />
                ) : (
                    <>
                        {currentDocuments?.map((elem: FileType, index: number) => (
                            <WarehousesRow
                                key={elem?.type + index}
                                name={elem?.filename}
                                loading={loading}
                                deleteFile={handleDeleteFile}
                                downloadFile={handleDownloadFile}
                                id={elem?.id}
                                disabled={!manager}
                                sfDocumentId={elem?.sfDocumentId}
                                // date={'formik.values.expire'}
                                // status={'formik.values.status'}
                            />
                        ))}
                    </>
                )}
            </UploadFile>
        </section>
    )
}
