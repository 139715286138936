import React from 'react'
import './Progress.scss' // Make sure to import the CSS file

const ProgressBar = () => {
    return (
        <div className="progress-container">
            <div className="background-line"></div>
            <div className="loading-bar">
                <div className="truck-icon"></div>
            </div>
        </div>
    )
}

export default ProgressBar
