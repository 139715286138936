import React, { FC, memo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { ModalRegistry } from '@shared/common/modals'
import useSWR from 'swr'
import { getSWRFetcher } from '@shared/apiv2/api'
import { UserType } from '@shared/api/types'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import Search from '@/components/Search'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import './PrimaryContactModal.scss'
import { Loader } from '@/components/Loader'

interface PrimaryContacts {
    companyId?: string | number
    accountName: string
    firstName?: string
    lastName?: string
    email: string
    id: string | number
    phone: string | number
    title: string
    userType: UserType
}

interface PrimaryContactModalProps {
    title: string
    contacts?: PrimaryContacts[]
    onSelect?: (selectedContactId: string, accountId: string) => void
}

const PrimaryContactModal: FC<PrimaryContactModalProps> = ({ title = 'Primary Contact', contacts, onSelect }) => {
    const [filterValue, setFilterValue] = useState<string>('')
    const [loadingButtons, setLoadingButtons] = useState<{ [key: string]: boolean }>({})

    const columns: ColumnDef<PrimaryContacts>[] = [
        {
            accessorKey: 'name',
            header: 'Name',
            meta: {
                cellStyle: { width: '170px', maxWidth: '170px', boxSizing: 'content-box' },
            },
            cell: ({ getValue, row }) => {
                return (
                    <div>
                        {row.original?.firstName} {row.original?.lastName}
                    </div>
                )
            },
        },
        {
            accessorKey: 'title',
            header: 'Title',
            meta: {
                cellStyle: { width: '170px', maxWidth: '170px', boxSizing: 'content-box' },
            },
            cell: ({ getValue, row }) => {
                return <div>{getValue()}</div>
            },
        },
        {
            accessorKey: 'phone',
            header: 'Phone #',
            meta: {
                cellStyle: { width: '170px', maxWidth: '170px', boxSizing: 'content-box' },
            },
            cell: ({ getValue, row }) => {
                return <div>{getValue()}</div>
            },
        },
        {
            accessorKey: 'email',
            header: 'Email',
            meta: {
                cellStyle: { width: '170px', maxWidth: '170px', boxSizing: 'content-box' },
            },
            cell: ({ getValue, row }) => {
                return <div>{getValue()}</div>
            },
        },
        {
            accessorKey: 'type',
            header: 'User Type',
            meta: {
                cellStyle: { width: '120px', maxWidth: '120px', boxSizing: 'content-box' },
            },
            cell: ({ row }) => {
                return <div style={{ textTransform: 'capitalize' }}>{row.original?.userType ?? 'User'}</div>
            },
        },
        {
            id: 'select',
            header: () => null,
            meta: {
                cellStyle: {
                    width: '120px',
                    maxWidth: '120px',
                    boxSizing: 'content-box',
                    display: 'flex',
                    justifyContent: 'flex-end',
                },
            },
            cell: ({ row }: any) => {
                const handleSelectClick = async () => {
                    const id = row.original?.id
                    setLoadingButtons((prev) => ({ ...prev, [id]: true }))
                    if (onSelect) {
                        await onSelect(row.original?.id, row.original?.accountId)
                    }
                    ModalRegistry.get().close(Modals.PrimaryContactModal)
                    setLoadingButtons((prev) => ({ ...prev, [id]: false }))
                }

                return (
                    <Button
                        className="modal-primary-contact__btnAdd"
                        label={loadingButtons[row.original?.id] ? 'Loading...' : 'Select'}
                        types={['blue']}
                        type="submit"
                        loading={loadingButtons[row.original?.id]}
                        onClick={handleSelectClick}
                    />
                )
            },
        },
    ]

    // get account contacts
    const { data, error, isValidating } = useSWR<PrimaryContacts[]>(
        () => `/accounts/contacts?search=${filterValue}`,
        getSWRFetcher,
    )

    if (error) {
        console.log(error)
    }

    return (
        <div className="modal-primary-contact">
            <h2 className="modal-primary-contact__title">{title}</h2>
            <div className="form">
                <div className="modal-primary-contact__searchWrap">
                    <div className="modal-primary-contact__input">
                        <Search onSearch={setFilterValue} filterValue={filterValue} label="Search" />
                    </div>
                </div>
                {isValidating ? (
                    <Loader />
                ) : (
                    <div className="modal-primary-contact__table" style={{ boxSizing: 'inherit' }}>
                        <ModernTable data={data} columns={columns} loading={isValidating} />
                    </div>
                )}
            </div>
        </div>
    )
}

ModalRegistry.get().register<PrimaryContactModalProps>(Modals.PrimaryContactModal, {
    id: 'PrimaryContactModal',
    className: 'modal-primary-contact',
    size: 'huge',
    Component: memo(PrimaryContactModal),
})

export const showPrimaryContactModal = (props: ModalPartialProps<PrimaryContactModalProps>): void =>
    ModalRegistry.get().show<PrimaryContactModalProps>(Modals.PrimaryContactModal, props)

export default memo(PrimaryContactModal)
