import React, { FC } from 'react'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { FormValueTypes } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import { ReactComponent as ProjectSvg } from '@/pages/RFQPortal/assets/img/solution-project.svg'
import { ReactComponent as OnDemandSvg } from '@/pages/RFQPortal/assets/img/solution-on-demand.svg'

interface StepChooseSolutionProps {
    changeFormData: (path: string, value: FormValueTypes) => void
    changeStep: (step: Steps) => void
}
interface SolutionProps extends React.HTMLAttributes<HTMLButtonElement> {
    image: React.ReactNode
    title: string
    desc: string
    startNewRfq: () => void
}

const mainBoardClass = 'main-board'

const Solution: FC<SolutionProps> = ({ image, title, desc, startNewRfq }) => {
    const solutionClass = mainBoardClass + '__solution'

    return (
        <div className={`${solutionClass}`} onClick={startNewRfq}>
            <div className={`${solutionClass}__image-wrapper`}>{image}</div>
            <div className={`${solutionClass}__info`}>
                <h3 className={`${solutionClass}__title`}>{title}</h3>
                <p className={`${solutionClass}__desc`}>{desc}</p>
            </div>
        </div>
    )
}

const StepChooseSolution: FC<StepChooseSolutionProps> = ({ changeFormData, changeStep }) => {
    const startRfqSingle = () => {
        changeStep(Steps.RFQ)
        changeFormData('project.single', true)
    }

    const startRfqProject = () => {
        changeStep(Steps.RFQ)
        changeFormData('project.single', false)
    }

    return (
        <>
            <div className={`${mainBoardClass}__welcome`}>
                <h2 className={`${mainBoardClass}__title`}>Welcome to the OLIMP RFQ!</h2>
                <ul className={`${mainBoardClass}__client-instructions`}>
                    <li className={`${mainBoardClass}__client-instruction`}>
                        Submit a quote request in 3 simple steps!
                    </li>
                    <li className={`${mainBoardClass}__client-instruction`}>
                        A dedicated Partner Success Manager provides a solution and quote for your review.
                    </li>
                    <li className={`${mainBoardClass}__client-instruction`}>
                        Approve the quote, we provide the warehouse information, and drop your freight!
                    </li>
                </ul>
            </div>
            <h3 className={`${mainBoardClass}__solutions-title`}>Choose Solution </h3>
            <div className={`${mainBoardClass}__solutions`}>
                <Solution
                    image={<OnDemandSvg className={`${mainBoardClass}-image`} />}
                    title="On Demand"
                    desc="For immediate single location solutions up to 31 days"
                    startNewRfq={startRfqSingle}
                />
                <p className={`${mainBoardClass}__solutions__divider`}>Or</p>
                <Solution
                    image={<ProjectSvg className={`${mainBoardClass}-image`} />}
                    title="Project"
                    desc="For long-term or multi-location solutions"
                    startNewRfq={startRfqProject}
                />
            </div>
        </>
    )
}

export default StepChooseSolution
