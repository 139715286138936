import React, { FC, useState, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { HttpV2 } from '@shared/apiv2/api'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'

import { useWpData } from '@/pages/RFQPortal/contexts/wpDataContext'
import MainBoard from '@/pages/RFQPortal/parts/MainBoard'
import Sidebar from '@/pages/RFQPortal/parts/Sidebar'
import StepThankYou from '@/pages/RFQPortal/parts/StepThankYou'
import { sitesFormHandlerUrls, submitToCallRail } from '@/pages/RFQPortal/utils/userActivityTracking'
import { navigateToWpPage } from '@/pages/RFQPortal/helpers/rfqHelper'
// import { sendAnalyticsToParent } from '@/hooks/useGoogleAnalytics'
import { rfqFormReducer, formDataActionType, ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'

import {
    FormValues,
    Project,
    FormValueTypes,
    SubmitRfqResponse,
    SetErrorType,
    ValidateMainBoardLocation,
    ErrorMessage,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import {
    trimStringFields,
    removeEmptyStrings,
    removeUndefinedProperties,
    extractFilesFromFormData,
    isProdEnv,
    trimField,
    generateOpportunityId,
} from '@/pages/RFQPortal/helpers/rfqHelper'

import './RfqOrder.scss'
const rfqOrderClass = 'rfq-order'

export enum LeadType {
    DEMAND = 'Demand',
    SUPPLY = 'Supply',
}

export enum Steps {
    CHOOSE_SOLUTION = 0,
    RFQ = 1,
    // REVIEW = 2,
    THANK_YOU = 3,
}

const initialFormData: FormValues = {
    project: {
        single: null,
        data: [],
    },
    clientData: {
        account: '',
        company: '',
        clientLocation: { formattedAddress: '' },
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phone: '',
        phoneExtention: '',
        teamMembers: [],
    },
    lead: {
        createDate: new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }) + ' Central',
        type: 'Demand',
        referral: '',
        formHandlerAE: '',
    },
}

export const getInitialOpportunityData = (oppId: string, oppName: string) => {
    const initialOpportunityData = {
        id: oppId,
        name: oppName,
        servicesNeeded: [],
        freightEnvironment: 'Dry',
        certifications: [],
        serviceLocation: {},
        serviceDate: {
            start: undefined,
            end: undefined,
            // start: dayjs().format('MM/DD/YYYY'),
            // end: dayjs().add(1, 'day').format('MM/DD/YYYY'),
        },
        commodity: '',
        loadNumber: '',
        unitType: 'Pallet - Standard',
        numberOfUnits: '',
        spaceNeeded: '',
        notes: '',
        files: [],
    }
    return initialOpportunityData
}

const IframedRfqOrder: FC = () => {
    const location = useLocation()

    const [formData, dispatchFormData] = useReducer<React.Reducer<FormValues, formDataActionType>>(rfqFormReducer, {
        ...initialFormData,
    })

    const [validateMainBoard, setValidateMainBoard] = useState<ValidateMainBoardLocation[]>([])
    const [validateSidebarFunc, setValidateSidebarFunc] = useState<() => Promise<boolean>>()
    const [redirectedData, setRedirectedData] = useState()

    const [submissionError, setSubmissionError] = useState<string>('')
    const [errorMessages, setErrorMessages] = useState<any>()
    const [currentStep, setCurrentStep] = useState<Steps>(0)
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

    const { wpContextData } = useWpData()
    const { accountId_WP, account_data_SF, lead, siteData } = wpContextData || {}

    const {
        PAGE_SLUG: pageSlug,
        SITE_SLUGS: siteSlags,
        CLIENT_SUPPORT_DATA: clientSupportData,
        CLIENT_NAME: clientName,
        CLIENT_SLUG: clientSlug,
        SITES_WITH_REGISTRATION: sitesWithRegistration,
        access_token: accessToken,
        CLIENT_NAMES_WITH_REGISTRATION: clientNamesWithRegistration,
        showRfqListLink,
        publicSites,
    } = siteData || {}

    const { referral } = lead || {}

    const newOpportunityname = clientSlug + '-'

    const validateForm = async (): Promise<any> => {
        let mainFormValidation = true
        let sidebarValidation = true

        if (validateMainBoard && validateMainBoard.length > 0) {
            validateMainBoard.forEach((validateLocation) => {
                const result = validateLocation.func()
                if (result === false) {
                    mainFormValidation = false
                }
            })
        } else {
            mainFormValidation = false
        }

        if (typeof validateSidebarFunc === 'function') {
            sidebarValidation = await validateSidebarFunc()
        } else {
            sidebarValidation = false
        }

        return mainFormValidation && sidebarValidation
    }

    // formData handlers
    const handleFormChange = (path: string, value: FormValueTypes) => {
        dispatchFormData({ type: 'UPDATE_NESTED_FIELD', path, value })
    }

    const manageOpportunity: ManageOpportunityType = (action, id) => {
        let newOppId = ''
        if (action === 'CREATE_NEW_OPPORTUNITY') {
            newOppId = generateOpportunityId()
        }

        switch (action) {
            case 'CREATE_NEW_OPPORTUNITY':
                dispatchFormData({
                    type: 'CREATE_NEW_OPPORTUNITY',
                    newOppId: newOppId,
                    newOppName: newOpportunityname,
                })
                createOppErrorObject(newOppId)
                break

            case 'DELETE_OPPORTUNITY':
                if (id) {
                    dispatchFormData({ type: 'DELETE_OPPORTUNITY', id })
                    deleteOppErrorObject(id)
                    setValidateMainBoard((prevState) => prevState.filter((item) => item.oppId !== id))
                }
                break
        }

        return newOppId
    }

    const updateProject = (value: FormValues['project']) => {
        dispatchFormData({ type: 'UPDATE_PROJECT', value })
    }
    //

    // formData Error handlers

    function setNestedErrorMessage<T>(object: T, path: string | string[], message: ErrorMessage): T {
        const newPath = Array.isArray(path) ? path : path.split('.')
        const newState = { ...object }
        let current = newState as any

        for (let i = 0; i < newPath.length - 1; i++) {
            const part = newPath[i]
            current[part] = current[part] || {}
            current = current[part]
        }

        const finalProperty = newPath[newPath.length - 1]
        current[finalProperty] = message

        return newState
    }

    const setError: SetErrorType = (path, message, opportunityId) => {
        if (opportunityId) {
            setErrorMessages((prevState: any) => {
                const fullPath = `opportunities.${opportunityId}.${path}`
                return setNestedErrorMessage(prevState, fullPath, message)
            })
        } else {
            setErrorMessages((prevState: any) => {
                return setNestedErrorMessage(prevState, path, message)
            })
        }
    }

    function deleteOppErrorObject(opportunityId: string) {
        setErrorMessages((prevState: any) => {
            const updatedOpportunities = { ...prevState.opportunities }
            delete updatedOpportunities[opportunityId]
            return {
                ...prevState,
                opportunities: updatedOpportunities,
            }
        })
    }

    function createOppErrorObject(opportunityId: string) {
        setErrorMessages((prevState: any) => ({
            ...prevState,
            opportunities: {
                ...prevState.opportunities,
                [opportunityId]: {},
            },
        }))
    }

    function clearAllErrors(onlyOpp = false) {
        if (onlyOpp) {
            setErrorMessages((prev: any) => ({
                ...prev,
                opportunities: {},
            }))
        } else {
            setErrorMessages({})
        }
    }
    //

    const changeCurrentStep = (type: 'increment' | 'decrement'): void => {
        const setCurrentStepCyclic = (value: number) => {
            const numSteps = Object.keys(Steps).length / 2
            const newValue = ((value % numSteps) + numSteps) % numSteps
            setCurrentStep(newValue)
        }

        switch (type) {
            case 'increment':
                setCurrentStepCyclic(currentStep + 1)
                break
            case 'decrement':
                setCurrentStepCyclic(currentStep - 1)
                break
        }

        setSubmissionError('')
    }

    const navigateToNewRfq = () => {
        const oppId = generateOpportunityId()

        const initialOppData = getInitialOpportunityData(oppId, newOpportunityname)

        const newRfqInit: Project = {
            single: null,
            data: [initialOppData],
        }

        updateProject(newRfqInit)
        clearAllErrors(true)
        createOppErrorObject(oppId)
        setCurrentStep(Steps.CHOOSE_SOLUTION)
        setSubmissionError('')
        setValidateMainBoard([])
    }

    useEffect(() => {
        console.log(formData.clientData.account)
    }, [formData.clientData.account])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const formValidation = await validateForm()
        if (!formValidation) {
            return
        }

        const { data, files } = extractFilesFromFormData(cloneDeep(formData))

        let newData = trimStringFields(data)
        newData = removeEmptyStrings(newData)
        newData = removeUndefinedProperties(newData)
        // newData = removeEmptyObjectsAndArrays(newData)

        const jsonData = JSON.stringify(newData)
        const body = new FormData()

        files.forEach((file) => {
            body.append('files', file)
        })
        body.append('data', jsonData)

        let rfqSubmitUrl = ''
        if (accessToken) {
            rfqSubmitUrl = '/rfq'
        } else {
            rfqSubmitUrl = '/rfq/public'
        }

        setSubmissionError('')
        setIsSubmiting(true)
        let isSubmited = false

        try {
            const response = await HttpV2.post(rfqSubmitUrl, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            // setCurrentStep(Steps.THANK_YOU)
            setSubmissionError('')

            isSubmited = true
        } catch (error: any) {
            setSubmissionError(error.response?.data?.message)
            isSubmited = false
        } finally {
            setIsSubmiting(false)
            if (isProdEnv) {
                submitToCallRail(newData, clientSlug)
            }

            const projectData = formData.project.data

            // sendAnalyticsToParent(`form_submission_${clientSlug.replace(/-/g, '_')}`, {
            //     SalesForce_account: formData.clientData.account,
            //     is_submit_successful: isSubmited ? 'yes' : 'no',
            //     first_name: formData.clientData.firstName,
            //     last_name: formData.clientData.lastName,
            //     client_location: formData.clientData.clientLocation?.formattedAddress || '',
            //     email: formData.clientData.email,
            //     phone: formData.clientData.phone,
            //     number_of_opportunities: projectData.length,
            //     // items: projectData.map((opp) => ({
            //     //     opportunity_name: opp.name,
            //     //     services_needed: opp.servicesNeeded.join(', '),
            //     //     freight_environment: opp.freightEnvironment,
            //     //     certifications: opp.certifications.join(', '),
            //     //     service_location: opp.serviceLocation?.formattedAddress || '',
            //     //     commodity: opp.commodity,
            //     //     unit_type: opp.unitType,
            //     //     number_of_units: Number(opp.numberOfUnits),
            //     // })),
            // })

            if (isSubmited) {
                let redirectLink = `/${clientSlug}/thank-you/`
                if (accessToken) {
                    redirectLink += '?logged=true'
                }

                navigateToWpPage(redirectLink)
                // toast.success('RFQ has been submitted!')
            }
        }
    }

    useEffect(() => {
        if (currentStep < Steps.RFQ || currentStep > Steps.THANK_YOU) {
            navigateToNewRfq()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, wpContextData, redirectedData])

    useEffect(() => {
        const formChangeParams: Record<string, FormValueTypes> = {
            'clientData.account': accountId_WP,
            'lead.referral': trimField(referral),
            'clientData.company': !publicSites?.includes(clientSlug) ? clientName : '',
        }

        if (clientSlug === 'evans') {
            formChangeParams['clientData.agentId'] = ''
        }

        if (accessToken) {
            const {
                accountId,
                firstName,
                companyName,
                lastName,
                title,
                email,
                address,
                phone,
                additionalEmail,
                clientLocation,
                phoneExtention,
            } = account_data_SF

            const formChangeParams_logged: Record<string, FormValueTypes> = {
                'clientData.phoneExtention': phoneExtention,
                'clientData.account': accountId,
                clientLocation: clientLocation,
                'clientData.clientLocation.formattedAddress': address || '',
                'clientData.company': companyName,
                'clientData.firstName': firstName,
                'clientData.lastName': lastName,
                'clientData.title': title,
                'clientData.email': email,
                'clientData.phone': phone,
                'clientData.teamMembers': additionalEmail
                    ? additionalEmail.split(',').map((em: string) => em.trim())
                    : [],
            }

            for (const path in formChangeParams_logged) {
                formChangeParams[path] = formChangeParams_logged[path]
            }
        }

        for (const path in formChangeParams) {
            if (formChangeParams[path]) {
                handleFormChange(path, formChangeParams[path])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wpContextData, redirectedData])

    useEffect(() => {
        const redirectedFormDataJSON = localStorage.getItem('redirectedFormData')

        if (
            redirectedFormDataJSON !== '' &&
            redirectedFormDataJSON !== null &&
            redirectedFormDataJSON !== undefined &&
            clientSlug &&
            clientSlug !== 'rfq'
        ) {
            const { redirectedFormData, isFile } = JSON.parse(redirectedFormDataJSON)

            setRedirectedData(redirectedFormData)

            dispatchFormData({ type: 'UPDATE_FORMDATA', formData: redirectedFormData })
            setCurrentStep(Steps.RFQ)
            localStorage.removeItem('redirectedFormData')

            if (isFile) {
                toast.success('Your data, except for files, was successfully transferred to your custom site.', {
                    position: 'top-right',
                    autoClose: 15000,
                    pauseOnHover: true,
                    draggable: true,
                })
                setTimeout(function () {
                    toast.warn('Please upload your files again!', {
                        position: 'top-right',
                        autoClose: 15000,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }, 3000)
            } else {
                toast.success('All of your data was successfully transferred to your custom site.', {
                    position: 'top-right',
                    autoClose: 15000,
                    pauseOnHover: true,
                    draggable: true,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientSlug])

    useEffect(() => {
        const app = document.getElementById('app')
        if (app) {
            app.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [currentStep])

    useEffect(() => {
        if (clientSlug && Object.prototype.hasOwnProperty.call(sitesFormHandlerUrls, clientSlug)) {
            handleFormChange('lead.formHandlerAE', sitesFormHandlerUrls[clientSlug])
        } else {
            handleFormChange('lead.formHandlerAE', sitesFormHandlerUrls.default)
        }
    }, [clientSlug])

    return (
        <>
            <form className={rfqOrderClass} onSubmit={handleSubmit}>
                {currentStep !== Steps.THANK_YOU && (
                    <>
                        <MainBoard
                            formData={formData}
                            changeFormData={handleFormChange}
                            step={currentStep}
                            changeStep={setCurrentStep}
                            manageOpportunity={manageOpportunity}
                            errorMessages={errorMessages}
                            setError={setError}
                            setValidateMainBoard={setValidateMainBoard}
                        />
                        {(window.innerWidth > 1140 || currentStep !== Steps.CHOOSE_SOLUTION) && (
                            <Sidebar
                                formData={formData}
                                changeFormData={handleFormChange}
                                step={currentStep}
                                changeStep={changeCurrentStep}
                                setCurrentStep={setCurrentStep}
                                errorMessages={errorMessages}
                                setError={setError}
                                submissionError={submissionError}
                                isSubmiting={isSubmiting}
                                setValidateSidebarFunc={setValidateSidebarFunc}
                                validateForm={validateForm}
                            />
                        )}
                    </>
                )}
                {currentStep === Steps.THANK_YOU && formData.project != null && (
                    <StepThankYou navigateToNewRfq={navigateToNewRfq} />
                )}
            </form>
        </>
    )
}

export default IframedRfqOrder
