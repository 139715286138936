import React, { FC, Dispatch, SetStateAction } from 'react'
import cn from 'classnames'

import {
    FormValues,
    FormValueTypes,
    SetErrorType,
    ValidateMainBoardLocation,
} from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { ManageOpportunityType } from '@/pages/RFQPortal/RfqOrder/rfqFormReducer'

import StepChooseSolution from './StepChooseSolution'
import SingleRfq from './SingleRfq'
import ProjectRfq from './ProjectRfq'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'
import './MainBoard.scss'

const mainBoardClass = 'main-board'

interface MainBoardProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    step: Steps
    changeStep: (step: Steps) => void
    manageOpportunity: ManageOpportunityType
    errorMessages?: any
    setError: SetErrorType
    setValidateMainBoard: Dispatch<SetStateAction<ValidateMainBoardLocation[]>>
}

const MainBoard: FC<MainBoardProps> = ({
    formData,
    changeFormData,
    step,
    changeStep,
    manageOpportunity,
    errorMessages,
    setError,
    setValidateMainBoard,
}) => {
    return (
        <div className={cn(mainBoardClass, { [`${mainBoardClass}_visible`]: step === Steps.CHOOSE_SOLUTION })}>
            {(step < Steps.RFQ || step > Steps.THANK_YOU || formData.project.single === null) && (
                <StepChooseSolution changeFormData={changeFormData} changeStep={changeStep} />
            )}

            {/* {(step === Steps.RFQ || step === Steps.REVIEW) && formData.project.single === true && ( */}
            {step === Steps.RFQ && formData.project.single === true && (
                <SingleRfq
                    formData={formData}
                    changeFormData={changeFormData}
                    manageOpportunity={manageOpportunity}
                    step={step}
                    changeStep={changeStep}
                    errorMessages={errorMessages}
                    setError={setError}
                    setValidateMainBoard={setValidateMainBoard}
                />
            )}
            {/* {(step === Steps.RFQ || step === Steps.REVIEW) && formData.project.single === false && ( */}
            {step === Steps.RFQ && formData.project.single === false && (
                <ProjectRfq
                    formData={formData}
                    changeFormData={changeFormData}
                    manageOpportunity={manageOpportunity}
                    step={step}
                    changeStep={changeStep}
                    errorMessages={errorMessages}
                    setError={setError}
                    setValidateMainBoard={setValidateMainBoard}
                />
            )}
        </div>
    )
}

export default MainBoard
