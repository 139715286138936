import React, { useState } from 'react'
import { ContentState, EditorState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import '../loadBoardStyles.scss'

import { IAdditionalDetails } from '../types'

export const AdditionalDetails = ({ formik }: IAdditionalDetails) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(`${formik.values?.notes}`) as any),
        ),
    )

    const handleChange = ({ blocks }: any) =>
        formik.setFieldValue('notes', blocks.map(({ text }: any) => text).join(' '))

    return (
        <div style={{ maxWidth: 820 }} className="order_detail">
            <Editor
                editorState={editorState}
                wrapperClassName="editor"
                editorClassName="load-board_review_notes_text"
                onEditorStateChange={setEditorState}
                onChange={handleChange}
            />
        </div>
    )
}
