import { ServiceMethods } from '../index'
import { StorageBreakdownComponent } from './BreakdownComponent'
import { StorageFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const storageService: ServiceMethods = {
    breakdownComponent: StorageBreakdownComponent,
    formComponent: StorageFormComponent,

    getDefaultService: (type?: string) => {
        return {
            name: RfqService.Storage,
            type: type ?? 'unit',
            pricings: {
                [RfqServicePricing.Storage]: { price: '', modifier: 'week' },
            },
        }
    },
}
