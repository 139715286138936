import React, { memo, useEffect } from 'react'

import { setMainStyle } from '../helper'
import Select from '@/components/Select'
import Datepicker from '@/components/Datepicker'
import { ILoadBoardItemsProps } from '../types'

export const Transloading = memo(({ formik, setDisabled }: ILoadBoardItemsProps) => {
    useEffect(() => {
        const { freightFormat, inDate, outDate } = formik.values.transloading || {}
        if (freightFormat && inDate && outDate) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [setDisabled, formik.values.transloading])

    return (
        <div style={{ width: 808 }}>
            <div style={{ marginBottom: 16 }}>
                <Datepicker
                    start={formik.values.transloading?.lastFreeDate as any}
                    hasReset={false}
                    range={false}
                    type="date"
                    past={false}
                    minInterval={1}
                    onChange={({ start }: any) => formik.setFieldValue('transloading.lastFreeDate', new Date(start.$d))}
                    errorHide
                />
            </div>

            <div style={{ marginBottom: 16 }}>
                <Select
                    label="Freight Format"
                    placeholder="Select"
                    items={[
                        { name: 'Palletized', value: 'palletized' },
                        { name: 'Floor Loaded', value: 'floor Loaded' },
                    ]}
                    name="status"
                    className={setMainStyle('_freightSelect_wh')}
                    value={formik.values.transloading?.freightFormat}
                    onAction={({ value }) => formik.setFieldValue('transloading.freightFormat', value)}
                />
            </div>

            <div className={setMainStyle('_warehousing_dates')}>
                <div style={{ flex: 1, flexDirection: 'row', marginRight: 12, maxWidth: 400 }}>
                    <div className={setMainStyle('_title_wh')} style={{ marginBottom: 0 }}>
                        In/Out Dates
                    </div>
                    <div className={setMainStyle('_title_wh')} style={{ fontSize: 14 }}>
                        Start Date
                    </div>
                    <Datepicker
                        start={formik.values.transloading?.inDate as any}
                        hasReset={false}
                        range={false}
                        type="date"
                        past={false}
                        minInterval={1}
                        onChange={({ start }: any) => formik.setFieldValue('transloading.inDate', new Date(start.$d))}
                        errorHide
                    />
                </div>
                <div style={{ flex: 1, maxWidth: 400 }}>
                    <div className={setMainStyle('_title_wh')}>End Date</div>
                    <Datepicker
                        start={formik.values.transloading?.outDate as any}
                        hasReset={false}
                        range={false}
                        type="date"
                        past={false}
                        minInterval={1}
                        onChange={({ start }: any) => formik.setFieldValue('transloading.outDate', new Date(start.$d))}
                        errorHide
                    />
                </div>
            </div>
        </div>
    )
})
