import React, { FC, useEffect, useState } from 'react'
import Input from '@/components/InputLabel'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import PhoneInput from '@/pages/RFQPortal/components/IntlTelInput'
import Button from '@/components/Button'
import Progress from '@/components/ModernTable/Progress'
import { toast } from 'react-toastify'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'

import './AdminGeneral.scss'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'

const CLASS_NAME = 'admin-general'

interface AccountInfoProps {
    accountData: any
    infoLoading: boolean
    fetchAccountdata: () => void
}

const AccountInfo: FC<AccountInfoProps> = ({ accountData, infoLoading, fetchAccountdata }) => {
    const [isAccountDataSubmiting, setIsAccountDataSubmiting] = useState<boolean>(false)
    const [acountDataSubmitError, setAccountDataSubmitError] = useState<string>('')

    const [orgName, setOrgName] = useState<string>(accountData.name || '')
    const [errorOrgName, setErrorOrgName] = useState<string>('')

    const [accountPhone, setAccountPhone] = useState<string>(accountData.phone || '')
    const [errorPhoneMessage, setErrorPhoneMessage] = useState<string>('')
    const [accountPhoneItiObject, setAccountPhoneItiObject] = useState<any>(null)
    const [accountLocation, setAccountLocation] = useState<any>({ formattedAddress: '' })
    const [errorAccountLocation, setErrorAccountLocation] = useState<string>('')

    const [editableMode, setEditableMode] = useState<boolean>(false)
    const editableModeRef = React.useRef<any>()
    const accountLocationRef = React.useRef<any>()

    useEffect(() => {
        setOrgName(accountData.name || '')
        setAccountPhone(accountData.phone || '')
        setAccountLocation({
            formattedAddress: accountData.apiLocation?.fullAddress || '',
            lat: accountData.apiLocation?.latitude || '',
            lng: accountData.apiLocation?.longitude || '',
        })
    }, [accountData])

    useEffect(() => {
        accountLocationRef.current = accountLocation
    }, [accountLocation])

    useEffect(() => {
        editableModeRef.current = editableMode
    }, [editableMode])

    useEffect(() => {
        const isEditable =
            orgName !== accountData.name ||
            accountLocationRef.current?.formattedAddress !== accountData?.apiLocation?.fullAddress ||
            accountPhone !== accountData.phone

        setEditableMode(isEditable)
        if (!isEditable) {
            clearAllErrors()
        }
    }, [orgName, accountLocationRef.current?.formattedAddress, accountPhone, accountData])

    const clearAllErrors = () => {
        setErrorPhoneMessage('')
        setErrorOrgName('')
        setErrorAccountLocation('')
        setAccountDataSubmitError('')
    }

    const validateAccountPhone = (): boolean => {
        if (!editableModeRef.current) {
            clearAllErrors()
            return false
        }

        if (!accountPhoneItiObject) {
            setErrorPhoneMessage('Error! Please contact support.')
            return false
        }

        if (accountPhoneItiObject.getNumber().trim() === '') {
            setErrorPhoneMessage('Field is required')
            return false
        }

        if (
            accountPhoneItiObject &&
            !accountPhoneItiObject.isValidNumber() &&
            accountPhoneItiObject.getNumber().trim() !== ''
        ) {
            setErrorPhoneMessage('Please enter a valid phone number')
            return false
        }

        setErrorPhoneMessage('')
        return true
    }

    const validateAccountLocation = (): boolean => {
        if (!editableModeRef.current) {
            clearAllErrors()
            return false
        }

        if (!accountLocationRef.current?.formattedAddress) {
            setErrorAccountLocation('Field is required')
            return false
        }

        if (!accountLocationRef.current?.lat || !accountLocationRef.current?.lng) {
            setErrorAccountLocation('Please enter a correct location')
            return false
        }

        setErrorAccountLocation('')
        return true
    }

    const validateOrgName = (): boolean => {
        if (!editableModeRef.current) {
            clearAllErrors()
            return false
        }

        if (!orgName) {
            setErrorOrgName('Field is required')
            return false
        }

        setErrorOrgName('')
        return true
    }

    const handleCancel = () => {
        setOrgName(accountData.name || '')
        setAccountPhone(accountData.phone || '')
        setAccountLocation({
            formattedAddress: accountData.apiLocation?.fullAddress || '',
            lat: accountData.apiLocation?.latitude || '',
            lng: accountData.apiLocation?.longitude || '',
        })
        setEditableMode(false)
    }

    const validateForm = (): boolean => {
        const orgNameValidation = validateOrgName()
        const locationValidation = validateAccountLocation()
        const phoneValidation = validateAccountPhone()

        return orgNameValidation && locationValidation && phoneValidation
    }

    const handleSubmit = async () => {
        const valid = validateForm()

        if (valid) {
            setAccountDataSubmitError('')
            setIsAccountDataSubmiting(true)

            try {
                await HttpV2.put(`/accounts/${accountData.id}/company`, {
                    phone: accountPhone,
                    name: orgName,
                    location: accountLocation,
                })

                fetchAccountdata()
                toast('Your account information has been updated successfully!', {
                    type: 'success',
                })
            } catch (error: any) {
                setAccountDataSubmitError(error.response?.data?.message)
            } finally {
                setIsAccountDataSubmiting(false)
            }
        }
    }

    const handleLocationValidation = () => {
        setTimeout(() => {
            validateAccountLocation()
        }, 500)
    }

    return (
        <>
            <div className="app-card__header bordered-block">
                <h2>General</h2>
            </div>
            {infoLoading ? (
                <Progress />
            ) : (
                <>
                    <div className={`${CLASS_NAME}__account-info-content`}>
                        <div className="app-card-flex-row">
                            <Input
                                label="Organization Name*"
                                id="orgName"
                                type="text"
                                name="orgName"
                                value={orgName}
                                onChange={(e) => setOrgName(e.target.value)}
                                onBlur={validateOrgName}
                                errorMessage={editableMode ? errorOrgName : ''}
                            />
                        </div>
                        <div className="app-card-flex-row">
                            <GoogleAutocompleteInput
                                label="Location Address*"
                                onChangeText={(path, value) => {
                                    setAccountLocation((curr: any) => ({ ...curr, formattedAddress: value }))
                                }}
                                changePlaceFull={(field, value) => {
                                    if ((value.lat && value.lng) || !value.formattedAddress) {
                                        setAccountLocation(value)
                                    }
                                }}
                                initialValue={accountData?.apiLocation?.fullAddress || ''}
                                value={accountLocation?.formattedAddress || ''}
                                autoComplete="off"
                                errorMessage={editableMode ? errorAccountLocation : ''}
                                validate={handleLocationValidation}
                            />
                        </div>
                        <div className="app-card-flex-row">
                            <PhoneInput
                                label="Phone #*"
                                type="tel"
                                id="phone"
                                placeholder="(999) 999-9999"
                                value={accountPhone}
                                changeValue={(value) => setAccountPhone(value)}
                                errorMessage={editableMode ? errorPhoneMessage : ''}
                                validatePhone={validateAccountPhone}
                                clientPhoneItiObject={accountPhoneItiObject}
                                setClientPhoneItiObject={setAccountPhoneItiObject}
                            />
                        </div>
                    </div>
                    {editableMode && (
                        <>
                            <div className="card-CD-btns">
                                <Button
                                    className="card-cencell"
                                    type="button"
                                    types={['bordered']}
                                    label="Cancel"
                                    onClick={handleCancel}
                                />
                                <Button
                                    className="card-delete"
                                    types={['blue']}
                                    type="submit"
                                    label="Save Changes"
                                    onClick={handleSubmit}
                                    loading={isAccountDataSubmiting}
                                />
                            </div>
                            {acountDataSubmitError && <div className="card-error-message">{acountDataSubmitError}</div>}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default AccountInfo
