import React, { memo } from 'react'

import Button from '@/components/Button'
import { NextButtonNameType, nextButtonTitle } from './types'

interface IBottomNavigationProps {
    counter: { start: number; end?: number }
    prevClick: (param?: any) => void
    nextClick: (param?: any) => void
    disabled: boolean
    nextButtonName: NextButtonNameType
    isCounter: boolean
}

export const BottomNavigation = memo(
    ({ counter, prevClick, nextClick, disabled, nextButtonName, isCounter }: IBottomNavigationProps) => {
        const isSHowButton = () => {
            if (nextButtonName === nextButtonTitle.ReviewPage) {
                return false
            } else {
                return true
            }
        }

        return (
            <div className="load-board_bottom">
                <div>
                    {counter.start > 1 && (
                        <Button types={['bordered', 'small', 'full']} onClick={prevClick}>
                            Back
                        </Button>
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isSHowButton() && (
                        <>
                            {!isCounter && (
                                <div className="load-board_counter">
                                    {counter.start} / {counter.end}
                                </div>
                            )}
                            <div className="load-board_next">
                                {counter.start <= counter.end! && (
                                    <Button disabled={disabled} types={['blue', 'small', 'full']} onClick={nextClick}>
                                        {nextButtonName}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    },
)
