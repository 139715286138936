export const getRelativePath = (path: string) => {
    const currentUrl = new URL(window.location.href)
    let basePath = currentUrl.pathname
    basePath = basePath.replace(/\/+$/, '')
    const normalizedPath = path.replace(/^\/+/, '')

    const newPath = normalizedPath ? `${basePath}/${normalizedPath}` : basePath
    const newURL = new URL(newPath, currentUrl.origin)
    return {
        path: newURL.pathname,
        search: newURL.search,
        href: newURL.href,
    }
}
