import React, { FC, useMemo, useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Http } from '@shared/api/Http'
import { HttpV2 } from '@shared/apiv2/api'
import { WarehouseInfo } from '@/pages/Warehouses'
import ROUTES from '@/router'
import SearchDropdown from '@/components/SearchDropdown'
import useClickOutside from '@/hooks/useClickOutside'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { userLogout, useUserState } from '@/store/user'
import cn from 'classnames'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import './UserAccountMenu.scss'
import { useMobileWidth } from '@shared/common/hooks'

const CLASS_NAME = 'navigation'

interface UserProfileNavigationProps {
    userInfo: any
    closeMenu: () => void
}

const UserAccountMenu: FC<UserProfileNavigationProps> = ({ userInfo, closeMenu }) => {
    const { state, dispatch } = useUserState()
    const location = useLocation()
    const navigate = useNavigate()
    const accountMenuRef = useRef<HTMLDivElement>(null)
    const { sendCustomEvent } = useCustomAnalyticsEvent()

    const [warehousesList, setWarehousesList] = useState<WarehouseInfo[]>([])
    const [isAccountMenuOpen, setIsAccountMenuOpen] = useState<boolean>(false)

    useClickOutside(accountMenuRef, () => {
        setIsAccountMenuOpen(false)
    })

    const fetchWarehouses = async () => {
        try {
            const warehouses = await HttpV2.get('accounts/related')
            setWarehousesList(warehouses.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchWarehouses()
    }, [])

    const handleDropdownSelect = (warehouseId: string) => {
        //To do: implenent API to change isPrimary warehouse

        fetchWarehouses()
    }
    const handleViewAll = () => {
        navigate(ROUTES.warehouses.base)
    }

    const warehouses = useMemo(() => warehousesList.map((item) => ({ name: item.name, id: item.id })), [warehousesList])
    const selectedWarehouse = useMemo(() => warehousesList.find((item) => item.isPrimary)?.id, [warehousesList])

    const userInitials = `${userInfo?.firstName.trim().charAt(0).toUpperCase()}${userInfo?.lastName
        .trim()
        .charAt(0)
        .toUpperCase()}`

    const handleAvatarClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.stopPropagation()
        setIsAccountMenuOpen(!isAccountMenuOpen)
    }

    const contactSupport = (e: any) => {
        e.preventDefault()
        showFeedbackModal({ props: {} })
        closeMenu()
        setIsAccountMenuOpen(false)

        sendCustomEvent('contact_support_opened', {
            action: 'User opened the form',
        })
    }

    const mobile = useMobileWidth()

    const rfqLogout = async () => {
        await userLogout(dispatch!)
        localStorage.clear()
        delete Http.defaults.headers.common.Authorization
    }

    return (
        <div className={`${CLASS_NAME}__profile`}>
            {/* <div className={`${CLASS_NAME}-warehouse`}>
                {warehouses && selectedWarehouse && (
                    <SearchDropdown
                        options={warehouses}
                        selectedValue={selectedWarehouse}
                        onSelect={handleDropdownSelect}
                        handleViewAll={handleViewAll}
                        showViewAll={location.pathname !== ROUTES.warehouses.base}
                    />
                )}
            </div> */}
            <div className={`${CLASS_NAME}-account`} ref={accountMenuRef}>
                <p className={cn(`${CLASS_NAME}-account__avatar`)} onClick={handleAvatarClick}>
                    {userInitials}
                </p>
                <ul className={cn(`${CLASS_NAME}-account-menu`, { active: isAccountMenuOpen || mobile, mobile })}>
                    <li className={`${CLASS_NAME}-account-menu-user`}>
                        <p className={`${CLASS_NAME}-account-menu-user-name`}>
                            {state?.userInfo?.firstName} {state?.userInfo?.lastName}
                        </p>
                        <p className={`${CLASS_NAME}-account-menu-user-email`}>{state?.userInfo?.email}</p>
                    </li>
                    <li>
                        <Link
                            to={ROUTES.account.base + ROUTES.account.settings}
                            onClick={() => {
                                closeMenu()
                                setIsAccountMenuOpen(false)
                                sendCustomEvent('account_settings_opened', {
                                    action: 'User opened account settings',
                                })
                            }}
                        >
                            Account
                        </Link>
                    </li>
                    <li>
                        <Link to="" onClick={contactSupport}>
                            Support
                        </Link>
                    </li>
                    <li className={`${CLASS_NAME}-account-menu-logOut`}>
                        <Link
                            to={ROUTES.public.logout}
                            onClick={(e) => {
                                if (window.location.href.includes('/rfq')) {
                                    e.preventDefault()
                                    rfqLogout()
                                }
                                closeMenu()
                                sendCustomEvent('logout_clicked', {
                                    action: 'User clicked on logout',
                                })
                            }}
                        >
                            Log out
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default UserAccountMenu
