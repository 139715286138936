import { ServiceMethods } from '../index'
import { HandlingBreakdownComponent } from './BreakdownComponent'
import { HandlingFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const handlingService: ServiceMethods = {
    breakdownComponent: HandlingBreakdownComponent,
    formComponent: HandlingFormComponent,

    getDefaultService: () => {
        return {
            name: RfqService.Handling,
            type: null,
            pricings: {
                [RfqServicePricing.HandlingIn]: { price: '' },
                [RfqServicePricing.HandlingOut]: { price: '' },
                [RfqServicePricing.HandlingTotal]: { price: '' },
            },
        }
    },
}
