import React, { FC, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { Userpilot } from 'userpilot'

import { updateGeoLocation, updateLoggedState, userLogout, useUserState } from '@/store/user'
import AppRoutes from '@/AppRoutes'
import { Footer } from '@/parts/Partials/Footer/Footer'
import { Disclaimer } from '@/parts/Partials/Disclaimer/Disclaimer'
import Header from '@/parts/Partials/Header/Header'
import { Loader } from '@/components/Loader'
import GeneralModal from '@/parts/GeneralModal'

import 'simplebar/dist/simplebar.min.css'
import 'react-responsive-modal/styles.css'

import './App.scss'
import { utils } from '@shared/common/utils'
import { useZendeskJWT } from '@/hooks/useZendeskJWT'
import { isDevEnv } from '@/common/consts'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { isProdEnv, reloginNeeded, wpTokenRefresh } from '@/pages/RFQPortal/helpers/rfqHelper'
import { sessionModalID, useSessionExpiration } from '@/parts/Modals/SessionModal'
import { toast } from 'react-toastify'
import { ModalRegistry } from '@shared/common/modals'
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics'
import HotjarTracking from '@/hotjar'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import { ToSModalProvider } from '@/parts/ToSModalProvider'

let stripeResolve: (stripe: Stripe | null) => void
const stripePromise: Promise<Stripe | null> = new Promise((resolve) => {
    stripeResolve = resolve
})

const initial = Date.now() + 1000 * 10

export const setup401Response = (onNonAuthorized: () => void) => {
    HttpV2.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response.status === 401) {
                console.log(error)
                onNonAuthorized()
            }
            return Promise.reject(error)
        },
    )
}

// handle HttpV2 error interceptor and navigate to 504 if its 504
HttpV2.interceptors.response.use(
    (response) => {
        if (window.location.pathname === '/504') {
            window.location.href = '/'
        }
        return response
    },
    (error) => {
        if (error.response.status === 504 && window.location.pathname !== '/504') {
            window.location.href = '/504'
        }
        return Promise.reject(error)
    },
)

function decodeToken(token: string) {
    try {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join(''),
        )

        const payload = JSON.parse(jsonPayload)
        if (payload) {
            const expirationTime = payload.exp * 1000 // Convert expiration time to milliseconds
            const currentTime = Date.now()
            const expired = expirationTime < currentTime

            const expiresInSec = (expirationTime - currentTime) / 1000

            console.log('Token will expire in ' + expiresInSec + ' seconds')

            return { expired, data: payload, expiresInSec, expirationTime }
        } else {
            return { expired: true }
        }
    } catch (e) {
        return { expired: true, data: null, expiresInSec: 0 }
    }
}

const isHeaderHidden = (pathname: string) => {
    const hiddenPaths = ['/sign-up', '/forgot-password', '/reset-password', '/verify-email', '/onboarding', '/psm']
    return hiddenPaths.some((path) => pathname.indexOf(path) !== -1)
}

// eslint-disable-next-line complexity
export const App: FC = () => {
    const location = useLocation()
    const { slug } = useMicrosite()

    useGoogleAnalytics({
        ga4MeasurementId: isProdEnv ? 'G-1MFMP45N3M' : 'G-NT87T4MYPS',
    })

    const [ZENDESK_KEY, setZENDESK_KEY] = useState<string>()
    const [USER_PILOT_KEY, setUSER_PILOT_KEY] = useState<string>()

    const navigate = useNavigate()
    const params = useParams()
    const [restored, setRestored] = useState<boolean>(false)
    const [refId, setRefId] = useState<string | null>(utils.getSearchParamByName(location.search, 'refId'))
    const { dispatch, state } = useUserState()
    const [shouldLogoReset, setShouldLogoReset] = useState<boolean>(false)

    // const { data: info, isLoading: infoLoading } = useInfo({
    //     onSuccess: async (data) => {
    //         stripeResolve(await loadStripe(data.payment_token))
    //         setZENDESK_KEY(data.zendesk_widget_key)
    //         setUSER_PILOT_KEY(data.user_pilot_token)
    //     },
    // })

    const isIframe = window.self !== window.top
    const isAuthPath = location.pathname.startsWith('/auth')
    // hidden header
    const showHeader = !refId && !isHeaderHidden(location.pathname) && !isAuthPath

    const token = localStorage.getItem('token')
    const tokenExpiration = useMemo(() => {
        if (token) {
            const decodedToken = decodeToken(token!)
            return decodedToken.expirationTime
        }
        return undefined
    }, [token])

    // eslint-disable-next-line complexity
    const restoreSession = async () => {
        const tokenFromParams = window.location.pathname.split('/auth/')[1]
        const localStorageToken = localStorage.getItem('token')
        const localToken = tokenFromParams ? tokenFromParams : localStorageToken
        if (localStorageToken && tokenFromParams) {
            localStorage.removeItem('token')
            delete HttpV2.defaults.headers.common.Authorization
            localStorage.setItem('token', tokenFromParams)
        }
        if (localToken && (!state.userInfo || tokenFromParams) && window.self === window.top) {
            let data

            const decodedToken = decodeToken(localToken)
            data = decodedToken.data
            if (!decodedToken.expired) {
                setHttpV2Token(localToken)
                try {
                    const { data: myData } = await HttpV2.get('/auth/me')

                    data = myData

                    updateLoggedState(data, dispatch)
                    if (tokenFromParams) {
                        navigate('/')
                    }
                } catch (e: any) {
                    if (e?.response?.status === 400 && e?.response?.data?.message) {
                        toast(e?.response?.data?.message, { type: 'error' })

                        setTimeout(() => {
                            localStorage.removeItem('token')
                            delete HttpV2.defaults.headers.common.Authorization
                            if (window.location.pathname.includes('/rfq')) {
                                localStorage.setItem('rfqLinkForSSO', window.location.href)
                            }
                            navigate('/login' + (slug ? `?slug=${slug}` : ''), { replace: true })
                        }, 3000)
                    }
                    console.error(e)
                }
            } else {
                localStorage.removeItem('token')
                HttpV2.defaults.headers.common.Authorization = ''
                if (window.location.pathname.includes('/rfq')) {
                    localStorage.setItem('rfqLinkForSSO', window.location.href)
                }
                navigate('/login' + (slug ? `?slug=${slug}` : ''), { replace: true })
            }
        }
        setRestored(true)
    }

    const onNonAuthorized = () => {
        localStorage.removeItem('token')
        delete HttpV2.defaults.headers.common.Authorization
        if (window.location.pathname.includes('/rfq')) {
            localStorage.setItem('rfqLinkForSSO', window.location.href)
        }
        navigate('/login' + (slug ? `?slug=${slug}` : ''), { replace: true })
    }

    useEffect(() => {
        updateGeoLocation(dispatch)

        if (window === window.parent) {
            setup401Response(onNonAuthorized)
        }
        restoreSession()
    }, [])

    useEffect(() => {
        setRefId(utils.getSearchParamByName(location.search, 'refId'))
    }, [location.search])

    // Userpilot start

    useEffect(() => {
        if (USER_PILOT_KEY) {
            Userpilot.initialize(`${USER_PILOT_KEY}`)
        }
    }, [USER_PILOT_KEY])

    useEffect(() => {
        if (USER_PILOT_KEY) {
            if (state.isLoggedIn && state.userInfo) {
                const { id, sfAccountId, sfContactId, firstName, lastName, position, type, email, phone } =
                    state.userInfo

                Userpilot.identify(id.toString(), {
                    user_id: id,
                    first_name: firstName,
                    last_name: lastName,
                    position: position,
                    type: type,
                    sf_account_id: sfAccountId,
                    sf_contact_id: sfContactId,
                    email: email,
                    phone: phone,
                })
            } else {
                Userpilot.anonymous()
            }
        }
    }, [state.isLoggedIn, USER_PILOT_KEY])

    useEffect(() => {
        Userpilot.reload()
    }, [location])
    // Userpilot end

    const onLogout = () => {
        // calculate difference in ms
        const diff = tokenExpiration! - Date.now()
        const isExpired = diff / 1000 < 1
        if (isExpired) {
            ModalRegistry.get().close(sessionModalID)
            if (window.self === window.top) {
                userLogout(dispatch!)
                navigate(
                    `/login?message=${encodeURIComponent('Your session has expired. Please login again.')}` +
                        (slug ? `&slug=${slug}` : ''),
                    { replace: true },
                )
            } else {
                userLogout(dispatch!)
                localStorage.removeItem('sf_token')
                reloginNeeded()
            }
        }
    }
    useSessionExpiration(
        () => {
            return HttpV2.post('/auth/token/refresh')
                .then((response) => {
                    const { token: authToken } = response.data
                    localStorage.setItem('token', authToken)
                    setHttpV2Token(authToken)
                    wpTokenRefresh(authToken)
                    toast.success('Session was extended!')
                    ModalRegistry.get().close(sessionModalID)
                })
                .catch((e) => {
                    console.error(e)
                    ModalRegistry.get().close(sessionModalID)
                    toast.error('Could not extend session, please login again')
                    onLogout()
                })
        },
        onLogout,
        isProdEnv ? 20 : 4,
        tokenExpiration,
    )

    // Zendesk start

    const { handleJwtToken } = useZendeskJWT()

    useEffect(() => {
        if (state.isLoggedIn) {
            // handleJwtToken()
        } else {
            ZendeskAPI('messenger', 'close')
        }
    }, [state.isLoggedIn])

    // Zendesk end

    if (!restored) {
        return <Loader />
    }

    const headerShown = showHeader && !isIframe
    return (
        <HelmetProvider>
            <Elements stripe={stripePromise}>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href="/static/favicon.png?23333" />
                    <link rel="apple-touch-icon" sizes="76x76" href="/static/apple-touch-icon-76x76.png?3333" />
                    <link rel="apple-touch-icon" sizes="120x120" href="/static/apple-touch-icon-120x120.png?3333" />
                    <link rel="apple-touch-icon" sizes="152x152" href="/static/apple-touch-icon-152x152.png?3333" />
                    <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon.png?3333" />
                    <link
                        id="favicon-32"
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/static/favicon-32x32.png?3333"
                    />
                    <link
                        id="favicon-16"
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/static/favicon-16x16.png?3333"
                    />
                </Helmet>
                <HotjarTracking />
                {ZENDESK_KEY && <Zendesk defer zendeskKey={`${ZENDESK_KEY}`} />}
                {isDevEnv && <div className="env">DEVELOPMENT</div>}
                {/* eslint-disable-next-line react/no-string-refs */}
                <div className={cn('app-container', { dev: isDevEnv, iframed: window.self !== window.top })}>
                    {/*{headerShown && <Feedback />}*/}
                    {headerShown && <Header shouldLogoReset={shouldLogoReset} />}
                    <AppRoutes shouldLogoReset={shouldLogoReset} setShouldLogoReset={setShouldLogoReset} />
                </div>
                <GeneralModal />
                <Footer info={{} as any} />
                {state.isLoggedIn && <ToSModalProvider />}
                {/*<Disclaimer />*/}
            </Elements>
        </HelmetProvider>
    )
}
