import React, { FC, HTMLAttributes, useState, useEffect } from 'react'
import { useUserState } from '@/store/user'
import { UserType } from '@shared/api/types'
import cn from 'classnames'
import { AppMenu } from '@/parts/Navigation/AppMenu'
import UserAccountMenu from './UserAccountMenu/UserAccountMenu'
import { useLocation } from 'react-router'
import LandingPageMenu from '@/parts/Navigation/LandingMenu'
import './Navigation.scss'
import Button from '@/components/Button'
import { ssoUrl } from '@/router'
import { ReactComponent as LogInIcon } from '@/assets/images/log-in.svg'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import useIsMobile from '@/components/ModernTable/useIsMobile'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { UserNotifications } from '@/widgets/header/UserNotifications'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    className?: string
    isOpen: boolean
    onToggle?: (isOpen?: boolean) => void
    shouldLogoReset: boolean
    mobile?: boolean
}

const CLASS_NAME = 'navigation'

export const Navigation: FC<IMenu> = ({ isOpen, onToggle, className, shouldLogoReset, mobile }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const isMobile = useIsMobile()
    const location = useLocation()
    const { data: micrositeData } = useMicrosite()
    const rfqGeneral = !micrositeData?.site && location.pathname.includes('rfq')

    const [safeAreaInsetBottom, setSafeAreaInsetBottom] = useState('0px')

    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    // const { data: selectedEntity } = useSelectedEntity()

    const isAdmin = userInfo?.type === UserType.admin
    const closeMenu = () => onToggle?.(false)

    useEffect(() => {
        const safeArea = getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom').trim()

        if (safeArea) {
            setSafeAreaInsetBottom(safeArea)
        } else {
            setSafeAreaInsetBottom('0px')
        }
    }, [])

    const onDemandWarehousingRfq = !isLoggedIn && location.pathname.includes('on-demand-warehousing')
    const showAuthMenu = !isLoggedIn && !onDemandWarehousingRfq
    return (
        <div className={cn(CLASS_NAME, className)}>
            <div
                className={cn(`${CLASS_NAME}__wrapper`, { [`${CLASS_NAME}__wrapper_landing`]: onDemandWarehousingRfq })}
            >
                {/* {userInfo?.companyName && (
                    <div className={`${CLASS_NAME}__special-menu`}> */}
                {/*{selectedEntity.type === EntityType.carrier && <CarrierMenu closeMenu={closeMenu} />}*/}
                {/* </div>
                )} */}

                {!onDemandWarehousingRfq && (
                    <div
                        className={`${CLASS_NAME}__user-menu`}
                        style={{
                            maxHeight:
                                showAuthMenu && isMobile
                                    ? `calc(100vh - 65px - 60px - ${safeAreaInsetBottom})`
                                    : `calc(100vh - 65px - 189px - ${safeAreaInsetBottom})`,
                        }}
                    >
                        <AppMenu closeMenu={closeMenu} />
                        {showAuthMenu && (
                            <div className={`${CLASS_NAME}__auth`}>
                                <div className={`${CLASS_NAME}__login-menu`}>
                                    <div
                                        className={`${CLASS_NAME}__login-menu-item ${CLASS_NAME}__login-menu-item_logo`}
                                        style={{ paddingLeft: 20 }}
                                    >
                                        <LogInIcon />
                                    </div>
                                    <div className={`${CLASS_NAME}__login-menu-item`}>
                                        <Button
                                            types={isMobile ? ['bordered'] : ['plain']}
                                            onClick={() => {
                                                const logInLink = `${ssoUrl}/login${
                                                    micrositeData?.site || rfqGeneral
                                                        ? `?slug=${micrositeData?.site}`
                                                        : ''
                                                }`

                                                sendCustomEvent('login_clicked', {
                                                    login_link: logInLink,
                                                    action: 'User clicked on login button in header',
                                                    device: 'mobile',
                                                })

                                                if (window.location.pathname.includes('/rfq')) {
                                                    localStorage.setItem('rfqLinkForSSO', window.location.href)
                                                }
                                                window.location.href = logInLink
                                            }}
                                        >
                                            Log in
                                        </Button>
                                    </div>
                                    <div className={`${CLASS_NAME}__login-menu-item`}>
                                        <Button
                                            className={`${CLASS_NAME}__sign-up-btn`}
                                            types={isMobile ? ['blue'] : ['plain']}
                                            onClick={() => {
                                                const signUpLink = `${ssoUrl}/${
                                                    micrositeData?.site || rfqGeneral
                                                        ? `?slug=${micrositeData?.site}`
                                                        : ''
                                                }`

                                                sendCustomEvent('signup_clicked', {
                                                    signup_link: signUpLink,
                                                    action: 'User clicked on signup button in header',
                                                    device: 'mobile',
                                                })

                                                if (window.location.pathname.includes('/rfq')) {
                                                    localStorage.setItem('rfqLinkForSSO', window.location.href)
                                                }
                                                window.location.href = signUpLink
                                            }}
                                        >
                                            Create account
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {onDemandWarehousingRfq && <LandingPageMenu closeMenu={closeMenu} />}

                {/*{isLoggedIn && <UserNotifications />}*/}
                {isLoggedIn && <UserAccountMenu userInfo={userInfo} closeMenu={closeMenu} />}

                {/*/!* <p onClick={() => showRegistrationModal({ props: {} })}>showModal</p> *!/*/}
                {/* <UserAccountMenu
                    isOpen={isOpen}
                    isAdmin={isAdmin}
                    closeMenu={closeMenu}
                    className={`${CLASS_NAME}__account-menu`}
                /> */}
                {/*{userInfo?.companyName}*/}
                {/*{userInfo?.firstName}*/}
                {/*{userInfo?.lastName}*/}
            </div>
        </div>
    )
}
