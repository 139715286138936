import React from 'react'
import cn from 'classnames'
import { ReactComponent as TrashIcon } from '@/assets/images/icon-trash.svg'
import { ReactComponent as EyeIcon } from '@/assets/images/icon-eye.svg'
import { ReactComponent as SignatureIcon } from '@/assets/images/icon-signature.svg'
// import { ReactComponent as DownloadIcon } from '@/assets/images/download.svg'
import DownloadIcon from '@/assets/images/download.png'
import { Loader } from '@/components/Loader'
import Button from '@/components/Button'
import { TextCutter } from '@/components/TextCutter'
import { ILoadedEditableDocument } from '../types'
import { useLoadedDocument } from '../useLoadedDocument'
import './EditableDocument.scss'
import { cutStringHandler } from '@/utils/filters'
import { RFQDocument } from '@/pages/RFQPortal/rfqHelper'

const CLASS_NAME = 'editable-document-view'

const EditableDocument: React.FC<ILoadedEditableDocument & { newVariant?: boolean; data?: any }> = (props) => {
    const { loading: docLoading, onOpen } = useLoadedDocument(props)
    const { className, documentNumber, filename, disabled, canRemove, signed, onRemoveClick, newVariant, data } = props

    return (
        <div
            className={cn(CLASS_NAME, newVariant && CLASS_NAME + '_new', className, {
                disabled: disabled || docLoading,
            })}
        >
            {documentNumber && <span className={`${CLASS_NAME}__number`}>{documentNumber}</span>}
            {/* new variant of list view */}
            {newVariant ? (
                <button className={`${CLASS_NAME}__link`} type="button" onClick={onOpen}>
                    <div>
                        <span className={`${CLASS_NAME}__fileName_title`}>{RFQDocument[data?.type]}</span>
                        <span style={{ textAlign: 'left' }} className={`${CLASS_NAME}__fileName`}>
                            {cutStringHandler(filename)}
                        </span>
                    </div>
                    {!docLoading ? <img src={DownloadIcon} alt="" /> : <Loader type="small" />}{' '}
                    {signed && <SignatureIcon />}
                </button>
            ) : (
                <>
                    <button className={`${CLASS_NAME}__link`} type="button" onClick={onOpen}>
                        {!docLoading ? <EyeIcon /> : <Loader type="small" />} {signed && <SignatureIcon />}
                        <TextCutter title={filename}>{filename}</TextCutter>
                    </button>

                    {canRemove && !docLoading && (
                        <Button onClick={onRemoveClick} className={`${CLASS_NAME}__remove`}>
                            <TrashIcon />
                        </Button>
                    )}
                </>
            )}
        </div>
    )
}

export { EditableDocument }
