import React, { FC, ImgHTMLAttributes, useEffect, useState } from 'react'
import { Loader } from '@/components/Loader'

interface WrapperProps<T> {
    WrapperComponent?: FC<T>
    wrapperComponentProps?: T
    resetTrigger?: {
        shouldLogoReset: boolean
        setShouldLogoReset?: (value: boolean) => void
    }
    setIsImageAvaliable?: (isImage: boolean) => void
    onImageError?: () => void

    fallback?: any
}

type ConditionalImageProps<T> = ImgHTMLAttributes<HTMLImageElement> & WrapperProps<T>

const ConditionalImage = <T,>({
    WrapperComponent,
    src,
    alt,
    wrapperComponentProps,
    resetTrigger,
    setIsImageAvaliable,
    onImageError,
    onLoad,
    onError,
    fallback,
    ...props
}: ConditionalImageProps<T>) => {
    const [hasImg, setHasImg] = useState(false)
    const [loading, setLoading] = useState(true)
    const [imgSrc, setImgSrc] = useState(src)
    const handleError = (p: any) => {
        setLoading(false)

        setHasImg(false)
        resetTrigger?.setShouldLogoReset?.(false)

        onError?.(p)
        if (onImageError) {
            onImageError()
        }
    }

    const handleLoaded = (p: any) => {
        setLoading(false)
        setHasImg(true)
        resetTrigger?.setShouldLogoReset?.(false)
        onLoad?.(p)
    }

    useEffect(() => {
        if (resetTrigger?.shouldLogoReset || imgSrc !== src) {
            setLoading(true)
            setHasImg(true)
            setImgSrc(src + `?${Date.now()}`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetTrigger?.shouldLogoReset, src, imgSrc])

    useEffect(() => {
        if (setIsImageAvaliable) {
            if (hasImg) {
                setIsImageAvaliable(true)
            } else {
                setIsImageAvaliable(false)
            }
        }
    }, [hasImg])

    if (imgSrc && (hasImg || loading)) {
        const imgComponent = (
            <>
                <img
                    src={imgSrc}
                    style={{
                        visibility: loading || !hasImg ? 'hidden' : 'visible',
                        display: loading || !hasImg ? 'none' : 'block',
                    }}
                    alt={alt}
                    onError={handleError}
                    onLoad={handleLoaded}
                    {...props}
                />
                {loading && (
                    <div style={{ marginTop: 6 }}>
                        <Loader type="small" />
                    </div>
                )}
            </>
        )

        return WrapperComponent && !loading ? (
            <WrapperComponent {...(wrapperComponentProps ?? ({} as any))}>{imgComponent}</WrapperComponent>
        ) : (
            imgComponent
        )
    }

    return fallback ?? null
}

export default ConditionalImage
