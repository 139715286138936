import { ServiceMethods } from '../index'
import { TransloadingBreakdownComponent } from './BreakdownComponent'
import { TransloadingFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const transloadingService: ServiceMethods = {
    breakdownComponent: TransloadingBreakdownComponent,
    formComponent: TransloadingFormComponent,

    getDefaultService: (type?: string) => {
        return {
            name: RfqService.Transloading,
            type: type ?? 'allIn',
            pricings: {
                [RfqServicePricing.Unloading]: { price: '' },
                [RfqServicePricing.Palletization]: { price: '' },
                [RfqServicePricing.PalletOut]: { price: '' },
                [RfqServicePricing.HandlingIn]: { price: '' },
                [RfqServicePricing.HandlingOut]: { price: '' },
                [RfqServicePricing.HandlingTotal]: { price: '' },
            },
        }
    },
}
