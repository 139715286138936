export type Address = {
    building?: string
    city: string
    country: string
    formattedAddress: string
    lat: string
    lng: string
    state: string
    street: string
    zip: string
}

export interface Warehouse {
    id?: string | number
    isPrimary?: boolean
    isUserAcc?: boolean
    location: Partial<Address>
    name: string
    totalLoadingDocks?: number | string
    usdot?: number | string
    apiLocation?: Address
    contact?: {
        email: string
        firstName: string
        lastName: string
        phone: string
        id: string
    }
}

export enum DocumentsType {
    ACCOUNT_WH_INSURANCE = 'WH_INSURANCE',
    ACCOUNT_WH_FILES = 'WH_FILES',
}

export interface FileType {
    id: string
    filename: string
    link: string
    type: DocumentsType
    sfDocumentId: string
    expirationDate?: string
    status?: 'Pending' | 'Confirmed' | 'Rejected'
}
