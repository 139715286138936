import useSWR from 'swr'
import { defaultSWRConfig, getSWRFetcher } from '@shared/apiv2/api'
import { toast } from 'react-toastify'
import { Account } from '@/entities/accounts/model'
import { SWRConfiguration } from 'swr/dist/types'

export const useGetRelatedAccounts = (SWRConfig?: SWRConfiguration) => {
    const response = useSWR<Account[]>(
        'accounts/related',
        async (url) => {
            try {
                return await getSWRFetcher(url)
            } catch (e) {
                toast('We could not find related accounts.', { type: 'error' })
                throw new Error()
            }
        },
        { ...defaultSWRConfig, ...SWRConfig },
    )

    return response
}
