import React from 'react'
import cn from 'classnames'
import { getOrderStatusStyle, IStatus } from '@shared/common/order-status'
import './Status.scss'
import Tooltip from 'react-tooltip-lite'
import { Hint } from '@/components/Hint'

interface IStatusProps extends IStatus {
    className?: string
    ignoreAdj?: boolean
}
export const Status: React.FC<IStatusProps> = ({ data, type, ignoreAdj, className }) => {
    const style = getOrderStatusStyle(data.code, data.type, ignoreAdj)
    return (
        <div className={cn('order-status-wrap', type, className)}>
            <div className={cn('order-status', style, { 'has-style': style })} title={data.short_title}>
                {data.short_title}
            </div>
        </div>
    )
}

export const StatusDot: React.FC<any> = ({ color, msg, title }) => (
    <Hint
        type="right status-dot"
        toggle={
            <>
                <div className="status-dot-inner" style={{ backgroundColor: color || '#FFFFFF' }} />
                {title}
            </>
        }
    >
        {msg}
    </Hint>
)
