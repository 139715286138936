import React, { FC, useEffect, useState } from 'react'
import './VerifyResetPassword.scss'
import { Loader } from '@/components/Loader'
import { updateLoggedState, useUserState } from '@/store/user'
import { UserActionType } from '@/store/user/user'
import Button from '@/components/Button'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { InputText } from '@/components/InputText'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const VerifyResetPassword: FC = () => {
    // token is url query param
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const token = new URLSearchParams(window.location.search).get('token')
    const navigate = useNavigate()
    const { state, dispatch } = useUserState()
    const [pwError, setPwError] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const resetPassword = async (e: any) => {
        setPwError('')
        e.preventDefault()
        if (!token) {
            setError('Reset Password Code is missing.')
            return
        }
        if (password.length < 8) {
            setPwError('Password must be at least 8 characters long.')
            return
        }
        if (password !== passwordRepeat) {
            setPwError('Passwords do not match.')
            return
        }
        try {
            setLoading(true)
            const { data: verificationData } = await HttpV2.put<{ token: string; user: any }>('/auth/reset-password', {
                resetCode: token,
                password,
            })

            toast('Password has been reset. Please Sign In!', { type: 'success' })
            navigate('/login')
        } catch (err: any) {
            setError((err.response?.data?.password ?? err.response?.data?.resetCode) || 'Something went wrong.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (state.isLoggedIn) {
            dispatch!({ type: UserActionType.LOGOUT })
        }
    }, [])
    const tryAgain = () => {
        setError('')
    }
    return (
        <div className="verify-password-page">
            <div className="verify-box">
                <div className="verify-box__title">Reset Your Password</div>
                <div className="verify-box__description">
                    {loading ? (
                        <>
                            <div className="verify-box__loader">
                                <Loader type="medium" />
                            </div>
                            <div className="verify-box__description__text">Please wait...</div>
                            <div className="verify-box__description__text">We are verifying your email address.</div>
                        </>
                    ) : error ? (
                        <>
                            <div className="verify-box__description__text">We couldn&apos;t Reset your password</div>
                            <div className="verify-box__description__error">{error}</div>
                            <div className="verify-box__description__text">Please try again or contact support.</div>
                            <Button types={['blue']} className="verify-box__description__button" onClick={tryAgain}>
                                Back
                            </Button>
                        </>
                    ) : (
                        <>
                            <form className="verify-box__description__text" onSubmit={resetPassword}>
                                <br />
                                <InputText
                                    autoFocus
                                    text
                                    label="Password"
                                    name="password"
                                    type="password"
                                    error={pwError}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    errorHide={false}
                                    errorOnFocus
                                    textError
                                />
                                <InputText
                                    text
                                    label="Repeat password"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Repeat password"
                                    onChange={(e) => setPasswordRepeat(e.target.value)}
                                    value={passwordRepeat}
                                    errorHide={false}
                                    textError
                                />
                                <Button types={['blue']} type="submit" className="verify-box__description__button">
                                    Reset Password
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
