import React, { useMemo } from 'react'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { Separator } from '@/shared/ui/Separator'
import { getSQValuesFromQuote } from '@/pages/Order/ui/OrderQuote/lib'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { useAppSelector } from '@/app/store'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { standardQuoteService } from '@/pages/Order/ui/OrderQuote/lib/standard-quote-services'
import { RfqService } from '@/shared/lib/rfq-services'

type OrderQuoteBreakdownProps = {
    quote: Quote
}

export const OrderQuoteBreakdown = ({ quote }: OrderQuoteBreakdownProps) => {
    const { state: userState } = useUserState()
    const isSupplyAccount = userState.userInfo?.role === UserRole.WAREHOUSE_MANAGER

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const isStandardQuote = quote.type === QuoteType.STANDARD

    const services = useMemo(() => {
        if (!isStandardQuote) return null
        return getSQValuesFromQuote({
            order,
            quote,
            userType: isSupplyAccount ? 'supply' : 'demand',
            mode: 'read',
        })
    }, [order, quote])

    if (!isStandardQuote)
        return (
            <div className="space-y-3">
                <div className="flex items-center w-full md:w-fit">
                    <span className="text-sm text-foreground-secondary font-normal">Total:</span>
                    <span className="ml-1 text-xl text-primary font-semibold">${quote.price.toLocaleString('en')}</span>
                </div>
                <Separator />
                <div>
                    {quote.notes?.split('\n').map((s, index) => (
                        <p key={index} className="min-h-[1.5rem] text-base">
                            {s}
                        </p>
                    ))}
                </div>
            </div>
        )

    return (
        <div className="sm:space-y-3">
            <div className="hidden sm:flex gap-5 text-foreground-secondary items-center">
                <div className="md:w-[100px] lg:w-[170px] text-sm font-medium">Service Requested</div>
                <Separator orientation="vertical" className="h-auto self-stretch" />
                <div className="text-sm font-medium">Charges</div>
            </div>

            {Object.entries(services!).map(([key, service], index) => {
                const Comp = standardQuoteService[key as RfqService]?.breakdownComponent
                if (!Comp) return null

                return (
                    <React.Fragment key={index}>
                        <div className="flex flex-col sm:flex-row gap-3 px-3 pt-2 pb-4 sm:p-0 sm:gap-5 sm:items-center">
                            <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                                {service.name}
                            </div>
                            <Separator orientation="vertical" className="hidden md:block h-auto self-stretch" />
                            <Comp order={order} service={service} />
                        </div>
                        <Separator />
                    </React.Fragment>
                )
            })}

            <div className="flex flex-col md:flex-row gap-1 px-3 pt-2 pb-4 md:p-0 md:gap-5 sm:items-center">
                <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                    Minimum Charge
                </div>
                <Separator orientation="vertical" className="hidden md:block h-auto self-stretch" />
                {quote.price ? (
                    <div className="text-xl leading-6 text-primary font-semibold">
                        ${Number(quote.price).toLocaleString('en')}
                    </div>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>

            {quote.notes ? (
                <>
                    <Separator />
                    <div className="flex flex-col md:flex-row gap-1 px-3 pt-2 pb-4 md:p-0 md:gap-5">
                        <div className="sm:w-[100px] lg:w-[170px] flex items-center text-base font-semibold shrink-0 self-start">
                            Notes
                        </div>
                        <Separator orientation="vertical" className="hidden md:block h-auto" />
                        <div className="mt-2">
                            {quote.notes?.split('\n').map((s, index) => (
                                <p key={index} className="min-h-[1.5rem] text-base">
                                    {s}
                                </p>
                            ))}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}
