import React from 'react'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { ServiceBreakdownComponentProps } from '../index'

export const CrossDockingBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        return (
            <div className="flex flex-col gap-3">
                <span className="font-medium leading-5">Floor Loaded</span>
                {service.pricings[RfqServicePricing.CrossDocking]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.CrossDocking]!.price).toLocaleString('en')}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            <span className="font-medium leading-5">Palletized</span>
            <div className="flex flex-col gap-1">
                <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                </span>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.HandlingTotal]!.price).toLocaleString('en')}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        </div>
    )
}
