import React from 'react'
import { ReactComponent as ArrowIcon } from '@/assets/images/caret-down-fill.svg'
import { FaqDataType } from '.'

interface FaqItemProps {
    data: FaqDataType
    toggleHeight: (id: number) => void
    index: number
}

export default function FaqItem({ data, index, toggleHeight }: FaqItemProps) {
    return (
        <li key={`faq-${index}`} className={`faq__item ${data?.visible ? 'faq__item_active' : ''} `}>
            <div className="faq__iconWrap">
                <ArrowIcon className="faq__icon" />
            </div>
            <div>
                <div className="faq__section faq__section_q" onClick={() => toggleHeight(index)}>
                    <div className="faq__char">Q:</div>
                    <div className="faq__qp">{data?.question}</div>
                </div>
                <div className="faq__section faq__section_answer">
                    <div className="faq__char">A:</div>
                    <div className="faq__text">{data?.answer}</div>
                </div>
            </div>
        </li>
    )
}
