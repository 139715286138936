import React, { FC, memo, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { Navigation } from '@/parts/Navigation'
import './MobilePopup.scss'
import { useLocation } from 'react-router'

interface IProps {
    visible: boolean
    onClose: () => void
    headerRef: React.RefObject<HTMLDivElement | null>
    shouldLogoReset: boolean
}
const MobilePopup: FC<IProps> = ({ visible, onClose, headerRef, shouldLogoReset }) => {
    const [hidden, setHidden] = useState(true)
    const [show, setShow] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const location = useLocation()

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        if (!visible) {
            setHidden(true)
            timeout = setTimeout(() => setShow(false), 500)
            document.body.style.overflow = 'visible'
        } else {
            setShow(true)
            setHidden(false)
            document.body.style.overflow = 'hidden'
        }
        return () => {
            if (timeout) clearTimeout(timeout)
        }
    }, [visible])

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (!ref?.current?.contains(e.target as Node) && !headerRef?.current?.contains(e.target as Node)) {
                onClose()
            }
        }

        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    })

    useEffect(() => {
        onClose()
    }, [location])

    return (
        <div className={cn('mobile-popup', { visible: show, hidden })}>
            <div className="mobile-popup__content" ref={ref}>
                <Navigation isOpen={show} onToggle={onClose} shouldLogoReset={shouldLogoReset} mobile />
            </div>
        </div>
    )
}

export default memo(MobilePopup)
