import React, { FC } from 'react'
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import Warehouses from '@/pages/Warehouses'
import { WarehousesMenu } from '@/pages/Warehouses/Menu'
import WarehousesGeneral from '@/pages/Warehouses/General'
import WarehousesServices from '@/pages/Warehouses/Services'
import WarehousesOrders from '@/pages/Warehouses/Orders'
import { StandardQuote } from '@/pages/StandardQuote/StandardQuote'
import WarehousesStaff from '@/pages/Warehouses/WarehousesStaff'

export const WarehousesRoutes: FC = () => {
    const navigate = useNavigate()

    const selectAndSetWarehouse = async (accId: string) => {
        const newPath = `/warehouses/${accId}`
        navigate(newPath)
    }

    return (
        <>
            <LoggedInRoute />
            <div className="app-page-container app-page-container_wrapper app-page-container_wrapper--no-margin">
                <Routes>
                    <Route path="/" element={<Warehouses />}></Route>
                    <Route
                        path="/:id"
                        element={
                            <div>
                                <WarehousesMenu selectWarehouse={selectAndSetWarehouse} />
                                <Outlet />
                            </div>
                        }
                    >
                        <Route index element={<WarehousesGeneral />} />
                        <Route path="/:id/general" element={<WarehousesGeneral />} />
                        <Route path="/:id/services" element={<WarehousesServices />} />
                        <Route path="/:id/staff" element={<WarehousesStaff />} />
                        <Route path="/:id/orders" element={<WarehousesOrders />} />
                    </Route>

                    <Route path="/quote/:id" element={<StandardQuote />} />
                </Routes>
            </div>
        </>
    )
}
