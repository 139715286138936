import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react'
import Input from '@/pages/RFQPortal/components/Input'
import Button from '@/components/Button'
import Select from '@/pages/RFQPortal/components/Select'
import Checkbox, { Type } from '@/pages/RFQPortal/components/Checkbox'
import { Days, Hours, compareTimes } from '@/components/Schedule/enums'
import CheckBoxNew from '@/components/CheckboxNew'

import './Schedule.scss'

interface ScheduleProps {
    id: number
    name: string
    days: string[]
    from: string
    disabled?: boolean
    to: string
    onDelete?: () => void
    disabledInputs?: string[]
    updateSchedule: (id: number, fieldName: string, fieldValue: any) => void
    setValidateSchedules: Dispatch<SetStateAction<ValidateSchedule[]>>
}

export interface ValidateSchedule {
    id: number
    func: () => boolean
}

const schedule = 'schedule'

// eslint-disable-next-line complexity
const Schedule: FC<ScheduleProps> = ({
    id,
    name,
    days,
    disabled,
    from,
    to,
    onDelete,
    disabledInputs,
    updateSchedule,
    setValidateSchedules,
}) => {
    const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
    const [fromErrorMessage, setFromErrorMessage] = useState<string>('')
    const [toErrorMessage, setToErrorMessage] = useState<string>('')

    const [showAfterHours, setShowAfterHours] = useState<boolean>(true)

    const isAfterHours = name === 'After Hours'

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSchedule(id, 'name', e.target.value)
    }

    const handleDaysChange = (isChecked: boolean, inputValue: string) => {
        if (isChecked && days) {
            updateSchedule(id, 'days', [...days, inputValue])
        } else {
            updateSchedule(
                id,
                'days',
                days.filter((element) => element !== inputValue),
            )
        }
    }

    const handleFromChange = (e: any) => {
        updateSchedule(id, 'from', e.target.value)
    }

    const handleToChange = (e: any) => {
        updateSchedule(id, 'to', e.target.value)
    }

    const validateName = (): boolean => {
        setNameErrorMessage('')
        const value = name.trim()

        if (!showAfterHours) {
            return true
        }

        if (value === '') {
            setNameErrorMessage('Field is required')
            return false
        }

        if (value.length >= 255) {
            setNameErrorMessage('Max 255 character')
            return false
        }

        return true
    }

    const validateScheduleDates = (): boolean => {
        setFromErrorMessage('')
        setToErrorMessage('')

        if (!showAfterHours) {
            return true
        }

        if (!from) {
            setFromErrorMessage('Field is required')
            return false
        }

        if (!to) {
            setToErrorMessage('Field is required')
            return false
        }

        // if (compareTimes(from as Hours, to as Hours) <= 0) {
        //     setToErrorMessage('End date must be after start')
        //     return false
        // }

        return true
    }

    const scheduleValidation = () => {
        const validationResults = [validateName(), validateScheduleDates()]

        return validationResults.every((result) => result === true)
    }

    useEffect(() => {
        const validationObj = { id, func: scheduleValidation }
        setValidateSchedules((prev) => [...prev, validationObj])

        return () => setValidateSchedules((prev) => prev.filter((v) => v.id !== id))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValidateSchedules, id, name, days, from, to])

    useEffect(() => {
        if (!showAfterHours && isAfterHours) {
            updateSchedule(id, 'days', [])
            updateSchedule(id, 'to', '')
            updateSchedule(id, 'from', '')

            setFromErrorMessage('')
            setToErrorMessage('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAfterHours])

    useEffect(() => {
        if (!from || !to) {
            setShowAfterHours(false)
        } else {
            setShowAfterHours(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${schedule}`}>
            <div className={`${schedule}__content`}>
                {!disabledInputs?.includes('schedule-name') ? (
                    <Input
                        id="schedule-name"
                        name="schedule-name"
                        label="Schedule Name"
                        disabled={disabled}
                        value={name}
                        onChange={handleNameChange}
                        errorMessage={nameErrorMessage}
                        onBlur={validateName}
                    />
                ) : isAfterHours && !disabled ? (
                    <CheckBoxNew
                        id="set-up-after-hours"
                        name="set-up-after-hours"
                        onChange={(checked: boolean) => {
                            if (!disabled) {
                                setShowAfterHours(checked)

                                if (checked) {
                                    if (!from) {
                                        updateSchedule(id, 'from', Hours['12:00am'])
                                    }

                                    if (!from) {
                                        updateSchedule(id, 'to', Hours['12:00am'])
                                    }
                                }
                            }
                        }}
                        checked={showAfterHours}
                        label="After Hours"
                        className={`${schedule}__setUpAfterHours`}
                        roundLabel
                    />
                ) : (
                    <p className={`${schedule}__input_disabled`}>{name}</p>
                )}
                <>
                    <Checkbox
                        disabled={disabled}
                        avaliableOptions={Object.values(Days).filter((value) => typeof value === 'string')}
                        fieldName={`schedule-days-${id}`}
                        stateData={isAfterHours && !showAfterHours ? [] : days || []}
                        handleChange={handleDaysChange}
                        type={Type.days}
                        currentOppId={id.toString()}
                        disabledOptions={
                            isAfterHours && !showAfterHours
                                ? Object.values(Days).filter((day) => typeof day === 'string')
                                : []
                        }
                    />
                    <div className={`${schedule}__time`}>
                        <Select
                            className={`${schedule}__from`}
                            id="schedule-from"
                            label="From"
                            options={isAfterHours && !showAfterHours ? [''] : Object.values(Hours)}
                            value={from}
                            onChange={handleFromChange}
                            onBlur={validateScheduleDates}
                            errorMessage={fromErrorMessage}
                            disabled={(isAfterHours && !showAfterHours) || disabled}
                        />
                        <Select
                            className={`${schedule}__to`}
                            id="schedule-to"
                            label="To"
                            options={isAfterHours && !showAfterHours ? [''] : Object.values(Hours)}
                            value={to}
                            onChange={handleToChange}
                            onBlur={validateScheduleDates}
                            errorMessage={toErrorMessage}
                            disabled={(isAfterHours && !showAfterHours) || disabled}
                        />
                    </div>
                </>
            </div>
            <div className={`${schedule}__btns`}>
                {!disabled && onDelete && (
                    <Button className={`${schedule}__delete-btn`} type="button" label="Delete" onClick={onDelete} />
                )}
            </div>
        </div>
    )
}

export default Schedule
