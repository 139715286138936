import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { WarehouseOrderStatus } from '@/entities/warehouse-orders/model'

export type OrderPageSlice = {
    order: LoadboardOrder | null
}

const initialState: OrderPageSlice = {
    order: null,
}

export const orderPageSlice = createSlice({
    name: 'orderPage',
    initialState,
    reducers: {
        setOrder: (state, action: PayloadAction<LoadboardOrder>) => {
            state.order = action.payload
        },
        removeQuote: (state, action: PayloadAction<number>) => {
            if (!state.order) return
            state.order.bids = state.order.bids.filter((bid) => bid.id !== action.payload)
        },
        acceptQuote: (state, action: PayloadAction<number>) => {
            if (!state.order) return
            const quote = state.order.bids.find((bid) => bid.id === action.payload)
            if (quote) {
                quote.status = QuoteStatus.ACCEPTED
                state.order.rfqStatus = WarehouseOrderStatus.Booked
            }
        },
        addQuote: (state, action: PayloadAction<Quote>) => {
            state.order!.bids.push(action.payload)
        },
        setQuote: (state, action: PayloadAction<{ quoteId: number; quote: Quote }>) => {
            if (state.order) {
                state.order.bids = state.order.bids.map((b) =>
                    b.id === action.payload.quoteId ? action.payload.quote : b,
                )
            }
        },
    },
})

export const orderPageActions = orderPageSlice.actions

export const orderPageReducer = orderPageSlice.reducer
