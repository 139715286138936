import React, { useEffect } from 'react'
import { useUserState } from '@/store/user'
import { useNavigate } from 'react-router-dom'
import './LoginPage.scss'
import { ssoUrl } from '@/router'
import { Loader } from '@/shared/ui/Loader'
export const LoginPage = () => {
    const navigate = useNavigate()
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    useEffect(() => {
        const isSSOAuthToken = window.location.search.includes('auth_token')
        if (isLoggedIn && userInfo && !isSSOAuthToken) {
            window.location.href = '/'
        } else {
            window.location.href = `${ssoUrl}/login${window.location.search}`
        }
    }, [isLoggedIn, navigate, userInfo])
    return <Loader />
}
