export enum Days {
    Sun = 'Sun',
    Mon = 'Mon',
    Tue = 'Tue',
    Wed = 'Wed',
    Thu = 'Thu',
    Fri = 'Fri',
    Sat = 'Sat',
}

export enum Hours {
    '12:00am' = '12 AM',
    '01:00am' = '1 AM',
    '02:00am' = '2 AM',
    '03:00am' = '3 AM',
    '04:00am' = '4 AM',
    '05:00am' = '5 AM',
    '06:00am' = '6 AM',
    '07:00am' = '7 AM',
    '08:00am' = '8 AM',
    '09:00am' = '9 AM',
    '10:00am' = '10 AM',
    '11:00am' = '11 AM',
    '12:00pm' = '12 PM',
    '01:00pm' = '1 PM',
    '02:00pm' = '2 PM',
    '03:00pm' = '3 PM',
    '04:00pm' = '4 PM',
    '05:00pm' = '5 PM',
    '06:00pm' = '6 PM',
    '07:00pm' = '7 PM',
    '08:00pm' = '8 PM',
    '09:00pm' = '9 PM',
    '10:00pm' = '10 PM',
    '11:00pm' = '11 PM',
}

export const convertTimeToDisplayFormat = (time: string): string => {
    const formattedTime = time.toLowerCase()
    return Hours[formattedTime as keyof typeof Hours] || time
}

export function compareTimes(timeFrom: Hours, timeTo: Hours): number {
    const values = Object.values(Hours)
    const indexFrom = values.indexOf(timeFrom)
    const indexTo = values.indexOf(timeTo)

    return indexTo - indexFrom
}
