import React, { useMemo } from 'react'
import { Project } from './index'
import { createColumnHelper, ColumnDef, Row } from '@tanstack/react-table'
import { RFQ_CLASS } from '@/pages/RFQPortal/rfq.columns'
import { DropdownDocuments } from '@/components/DropdownDocuments'
import { ReactComponent as ArrowDown } from '@/assets/images/arrow-down.svg'
import { ReactComponent as ArrowUp } from '@/assets/images/arrow-up.svg'
import { ReactComponent as RFQFileIcon } from '@/assets/images/file.svg'
import { utils } from '@shared/common/utils'
import { getRFQFileUrl } from '@/pages/RFQPortal/rfq.columns'

interface TableData {
    name: string
    createdAt: string
    rfqCount: number
    firstName: string
    lastName: string
    invoice: Blob[]
    projectRfqs: any[]
    moreDetails: any
}

const columnHelper = createColumnHelper<TableData>()

const useProjectTable = (projects: Project[]) => {
    const tableData = useMemo(
        () =>
            projects.map((project) => {
                const allAttachments: Blob[] = project.rfqs.reduce((acc: Blob[], rfq: any) => {
                    return acc.concat(rfq.attachments)
                }, [])
                const { firstName = '', lastName = '', createdAt = '' } = project.rfqs[0] || {}

                const projectRfqsTableData = project.rfqs.map((rfq: any) => ({
                    ...rfq,
                    subRows: [
                        {
                            freightCommodity: rfq.freightCommodity,
                            freightEnvironment: rfq.freightEnvironment,
                            services: rfq.services,
                            freightCertification: rfq.freightCertification,
                            notes: rfq.notes,
                        },
                    ],
                }))

                return {
                    name: project.name,
                    createdAt,
                    rfqCount: project.rfqs.length,
                    firstName,
                    lastName,
                    invoice: allAttachments,
                    projectRfqs: projectRfqsTableData,
                    subRows: [{}],
                    moreDetails: null,
                }
            }),
        [projects],
    )

    const tableColumns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: 'Project #',
                meta: {
                    cellStyle: { width: '300px', maxWidth: '300px' },
                },
                cell: ({ getValue, row }: any) => {
                    return (
                        <div>
                            <div className="rfq-portal__rfq">{getValue()}</div>
                            <div className="rfq-portal__rfq_date">
                                {new Date(row.original.createdAt).toLocaleDateString()}
                            </div>
                        </div>
                    )
                },
                id: 'projectNumber',
            }),
            columnHelper.accessor('firstName', {
                header: 'User',
                meta: {
                    cellStyle: { width: '300px', maxWidth: '300px' },
                },
                cell: (cell: any) => {
                    const { getValue, row } = cell
                    return (
                        <div>
                            {row.original.firstName} {row.original.lastName}
                        </div>
                    )
                },
                id: 'user',
            }),
            columnHelper.accessor('invoice', {
                header: 'Files',
                id: 'attachments',
                meta: {
                    cellStyle: { width: '200px', maxWidth: '200px' },
                    cellClass: 'no-text-truncate',
                },
                cell: (info) => {
                    const attachments = info.getValue() || []
                    return (
                        <div className={`${RFQ_CLASS}__invoice`} onClick={(e) => e.stopPropagation()}>
                            {attachments.length > 0 ? (
                                <DropdownDocuments
                                    newVariant
                                    list={attachments.map((att: any) => ({
                                        id: att.key,
                                        title: att.name,
                                        file_name: att.name,
                                        ...att,
                                    }))}
                                    toggleEl={
                                        <div style={{ cursor: 'pointer' }}>
                                            <span className={`${RFQ_CLASS}__invoice_number`}>{attachments.length}</span>{' '}
                                            <span className={`${RFQ_CLASS}__invoice_arrow`}>
                                                <ArrowDown />
                                            </span>
                                            <RFQFileIcon style={{ fontSize: 20 }} />
                                        </div>
                                    }
                                    type="right"
                                    getUrlFunc={({ id, title, file_name }) => getRFQFileUrl(id.toString())}
                                    title={utils.pluralize(attachments.length, 'File')}
                                />
                            ) : (
                                <div style={{ cursor: 'default' }}>-</div>
                            )}
                        </div>
                    )
                },
            }),
            columnHelper.accessor('rfqCount', {
                header: '# of orders',
                meta: {
                    cellStyle: { width: '200px', maxWidth: '200px' },
                },
                id: 'rfqCount',
            }),
            columnHelper.accessor('moreDetails', {
                id: 'moreDetails',
                header: '',
                meta: {
                    cellStyle: { width: '167px', maxWidth: '167px' },
                },
                cell: ({ getValue, row }: any) => {
                    return (
                        <div>
                            {row.getCanExpand() && (
                                <div className={`${RFQ_CLASS}__invoice ${RFQ_CLASS}__details`}>
                                    {row.getIsExpanded() ? (
                                        <div>
                                            <span className={`${RFQ_CLASS}__invoice_arrowUpDown`}>
                                                <ArrowUp />
                                            </span>
                                            <span className={`${RFQ_CLASS}__moreDetails`}>Less details</span>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className={`${RFQ_CLASS}__invoice_arrowUpDown`}>
                                                <ArrowDown />
                                            </span>
                                            <span className={`${RFQ_CLASS}__moreDetails`}>More details</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {getValue()}
                        </div>
                    )
                },
            }),
        ],
        [],
    )

    return {
        tableData,
        tableColumns,
    }
}

export default useProjectTable
