import * as React from 'react'

import { cn } from '@/shared/utils/cn'
import { Label } from '@/shared/ui/Label'
import { v4 } from 'uuid'

const InputWrapper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div className={cn('relative', className)} ref={ref} {...props} />
    },
)
InputWrapper.displayName = 'InputWrapper'

export type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement>

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                'peer flex h-9 w-full text-sm rounded-md border border-border ring-none outline-none focus:border-primary hover:border-primary disabled:hover:border-border transition-colors duration-75 bg-background-secondary px-3 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-secondary/70 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className,
            )}
            ref={ref}
            {...props}
        />
    )
})
InputField.displayName = 'InputField'

const InputLabel = React.forwardRef<React.ElementRef<typeof Label>, React.ComponentPropsWithoutRef<typeof Label>>(
    ({ className, ...props }, ref) => {
        return (
            <Label
                className={cn(
                    'bg-white px-[6px] text-foreground-secondary font-normal absolute z-[1] top-0 -translate-y-1/2 left-[6px] peer-disabled:cursor-not-allowed peer-disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
InputLabel.displayName = 'InputLabel'

const InputIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                className={cn(
                    'absolute right-0 top-0 h-full w-fit flex items-center bg-transparent text-foreground-secondary peer-disabled:cursor-not-allowed peer-disabled:pointer-events-none peer-disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
InputIcon.displayName = 'InputIcon'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    wrapperClassName?: string
    wrapperProps?: Omit<React.ComponentProps<typeof InputWrapper>, 'className'>

    label?: React.ReactNode
    labelClassName?: string
    labelProps?: Omit<React.ComponentProps<typeof InputLabel>, 'className'>

    icon?: React.ReactNode
    iconWrapperClassName?: string
    iconWrapperProps?: Omit<React.ComponentProps<typeof InputIcon>, 'className'>
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            wrapperClassName,
            wrapperProps,

            label,
            labelClassName,
            labelProps,

            icon,
            iconWrapperClassName,
            iconWrapperProps,

            id,

            ...inputProps
        },
        ref,
    ) => {
        const actualId = React.useRef(id ?? v4())

        return (
            <InputWrapper className={wrapperClassName} {...wrapperProps}>
                <InputField ref={ref} id={actualId.current} {...inputProps} />

                {label !== undefined ? (
                    <InputLabel className={labelClassName} htmlFor={actualId.current} {...labelProps}>
                        {label}
                    </InputLabel>
                ) : null}

                {icon !== undefined ? (
                    <InputIcon className={iconWrapperClassName} {...iconWrapperProps}>
                        {icon}
                    </InputIcon>
                ) : null}
            </InputWrapper>
        )
    },
)
Input.displayName = 'Input'

export { InputWrapper, InputField, InputLabel, InputIcon, Input }
