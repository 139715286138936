import React, { useState } from 'react'
import { DialogClose, DialogFooter, DialogHeader, DialogTitle } from '@/shared/ui/Dialog'
import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { DocumentsType } from '@/pages/Warehouses/types'
import { format } from 'date-fns'
import { accountsApi } from '@/entities/accounts/api'
import { DatePicker } from '@/shared/ui/DatePicker'
import { ModalExtraProps } from '@/shared/utils/modalExtraProps'

type UploadInsuranceModalProps = ModalExtraProps & {
    accountId: string
}

interface UploadInsuranceForm {
    file: File | null
    expirationDate: Date | null
}

const UploadInsuranceFormSchema = Yup.object().shape({
    file: Yup.mixed().nullable().required('Please upload your certificate of insurance'),
    expirationDate: Yup.date().nullable().required('You must enter an expiration date.'),
})

export const UploadInsuranceModal = ({
    accountId,

    isLoading,
    setIsLoading,

    setOpen,

    onSuccess,
    onError,
    onFinally,
}: UploadInsuranceModalProps) => {
    const [submissionAttempted, setSubmissionAttempted] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const form = useFormik<UploadInsuranceForm>({
        initialValues: {
            file: null,
            expirationDate: null,
        },
        validateOnChange: submissionAttempted,
        validateOnMount: false,
        validationSchema: UploadInsuranceFormSchema,
        onSubmit: async (values) => {
            if (!values.file || !values.expirationDate) return

            setErrorMessage('')
            setIsLoading?.(true)

            try {
                const formData = new FormData()
                formData.append('file', values.file)

                const formattedDate = format(values.expirationDate, 'MM/dd/yyyy')
                formData.append('expirationDate', formattedDate)

                const response = await accountsApi.uploadFile({
                    accountId,
                    documentType: DocumentsType.ACCOUNT_WH_INSURANCE,
                    formData,
                })

                await onSuccess?.(response)

                toast('Insurance has been successfully uploaded.', { type: 'success' })

                setOpen?.(false)
            } catch (error: any) {
                console.error(error)
                await onError?.(error)
                if (error && error?.response) {
                    setErrorMessage(error?.response?.data?.message)
                }
                toast(error.response?.data?.message ?? 'We could not upload your insurance.', { type: 'error' })
            } finally {
                await onFinally?.()
                setIsLoading?.(false)
            }
        },
    })

    const isActuallyLoading = isLoading ?? form.isSubmitting

    return (
        <div>
            <DialogHeader>
                <DialogTitle>Upload Your Insurance</DialogTitle>
            </DialogHeader>
            <form onSubmit={form.handleSubmit} className="mt-3 space-y-4">
                <div className="relative">
                    <DropZoneArea
                        filesCollection={form.values.file ? [form.values.file] : []}
                        changeFormData={(path, files) => form.setFieldValue('file', files[0])}
                        allowedFormats={['image/jpeg', 'application/pdf']}
                        maxFiles={1}
                    />
                    {form.errors.file ? <div className="mt-1 text-sm text-danger">{form.errors.file}</div> : null}
                </div>
                <div className="flex flex-col">
                    <DatePicker
                        mode="single"
                        selected={form.values.expirationDate ?? undefined}
                        onSelect={(date) => form.setFieldValue('expirationDate', date ?? null)}
                        label="Expiration Date*"
                        datesDisabled={{ before: new Date() }}
                    />
                    {form.errors.expirationDate ? (
                        <div className="mt-1 text-sm text-danger">{form.errors.expirationDate}</div>
                    ) : null}
                </div>
                <DialogFooter className="flex-col gap-1 items-end">
                    <div className="flex flex-row justify-end gap-3">
                        <DialogClose asChild>
                            <Button variant="outlined" disabled={isActuallyLoading}>
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button disabled={isActuallyLoading}>
                            Submit {isActuallyLoading ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                        </Button>
                    </div>
                    {errorMessage ? <div className="mt-1 text-sm text-danger">{errorMessage}</div> : null}
                </DialogFooter>
            </form>
        </div>
    )
}
