export enum ChatAction {
    GetChat = 'GetChat',
    GetChats = 'GetChats',
    SendMessage = 'SendMessage',
    SeenMessage = 'SeenMessage',
}

export enum ChatEvent {
    ChatReceived = 'ChatReceived',
    ChatsReceived = 'ChatsReceived',
    MessageReceived = 'MessageReceived',
    MessageSeen = 'MessageSeen',
}
