import usePlacesAutocomplete from 'use-places-autocomplete'
import { useEffect, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

export const mapsLoader: Loader = new Loader({
    apiKey: process.env.REACT_APP_GMAP_API_KEY ?? '',
    version: 'weekly',
    libraries: ['places'],
    language: 'en',
    region: 'US',
})

export const useGoogleApis = (requestOptions: any, bounds?: () => any) => {
    const [requestOpts, setRequestOpts] = useState(requestOptions)
    const [loading, setLoading] = useState(false)
    const places = usePlacesAutocomplete({
        initOnMount: false,
        requestOptions: requestOpts,
        cacheKey: 'olimp',
    })

    const mount = async () => {
        setLoading(true)
        const googleMapsPlacesPromise = mapsLoader.importLibrary('places')

        googleMapsPlacesPromise
            .then(() => {
                if (bounds) {
                    setRequestOpts((prev: any) => ({
                        ...prev,
                        locationRestriction: bounds(),
                    }))
                }
                places.init()
            })
            .catch((e) => {
                console.error(e)
            })
    }

    useEffect(() => {
        mount()
    }, [])

    return {
        loading,
        places,
    }
}
