import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { RFQStatus, WarehouseOrderDto } from '@shared/apiv2/wh-orders'
import { Badge, BadgeVariant } from '@/components/Badge'

const columnHelper = createColumnHelper<WarehouseOrderDto>()

export const getUnableDeleteAccountModalColumns = () => {
    const columns = [
        columnHelper.accessor('key', {
            header: 'Order #',
            cell: ({ row }) => {
                return <div className="h-[44px] flex items-center">{row.getValue('key')}</div>
            },
        }),
        columnHelper.accessor('totalBuyCost', {
            header: 'Total',
            cell: ({ row }) => {
                return <div>${row.getValue('totalBuyCost')}</div>
            },
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: ({ row }) => {
                const status = row.original.status

                if (status === RFQStatus.Booked || status === RFQStatus.Dropped) {
                    return <Badge variant={BadgeVariant.blue}>{RFQStatus[status]}</Badge>
                }
                if (row.original.invoice?.id) {
                    return <Badge variant={BadgeVariant.yellow}>Invoice Generated</Badge>
                }
                return <Badge variant={BadgeVariant.danger}>Invoice Is Not Uploaded</Badge>
            },
        }),
    ]

    return columns
}
