import React, { FC } from 'react'
import Tooltip from 'react-tooltip-lite'
import cn from 'classnames'

import './Hintbox.scss'

interface HintBoxProps extends React.ComponentProps<typeof Tooltip> {
    content: React.ReactNode
    children?: React.ReactNode
    className?: string
    arrow?: boolean
}

const HintBox: FC<HintBoxProps> = ({ content, children, className, arrow = true, ...props }) => {
    return (
        <Tooltip content={content} className={cn('hint-box-tooltip', className)} arrow={arrow} {...props}>
            <p className="hint-box-trigger">
                {children || (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L7.92875 11.293C7.85844 11.6328 7.95805 11.8262 8.23344 11.8262C8.4268 11.8262 8.71977 11.7559 8.91898 11.5801L8.83109 11.9961C8.54398 12.3418 7.91117 12.5938 7.36625 12.5938C6.66313 12.5938 6.3643 12.1719 6.55766 11.2754L7.29594 7.80664C7.36039 7.51367 7.3018 7.4082 7.00883 7.33789L6.55766 7.25586L6.63969 6.875L8.9307 6.58789ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5C9 5.05228 8.55229 5.5 8 5.5Z" />
                    </svg>
                )}
            </p>
        </Tooltip>
    )
}

export default HintBox
