import React, { FC, useContext, useReducer } from 'react'
import { UserInfo, UserType } from '@shared/api/types'
import { cache } from 'swr'
import { UserAction, UserActionType, UserData, UserState, UserStore } from './user'

const userDefaultState: UserState = {
    lastPathname: '',
    pathname: window.location.pathname,
    isLoggedIn: false,
    userType: UserType.manager,
    geolocation: {
        lat: 40.754932,
        long: -73.984016,
    },
    signUp: JSON.parse(localStorage.getItem('signUp') || '{}'),
}

const UserContext = React.createContext<UserStore>({ state: userDefaultState })

export const useUserState = (): UserStore => useContext<UserStore>(UserContext)

export const userReducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case UserActionType.SET_LOGGED_STATE:
            return { ...state, isLoggedIn: !!action.userInfo, userInfo: action.userInfo }
        case UserActionType.LOGOUT:
            localStorage.removeItem('token')
            localStorage.removeItem('signUp')
            localStorage.removeItem('refLogo')
            cache.clear()
            return { ...state, isLoggedIn: false, userInfo: undefined, signUp: {} }
        case UserActionType.UPDATE_LAST_PATH:
            return { ...state, lastPathname: state.pathname, pathname: action.pathname }
        case UserActionType.UPDATE_GEO:
            return { ...state, geolocation: action.geolocation }
        case UserActionType.SIGN_UP_CHOOSE_ENTITY_TYPE: {
            const newSignUpState = {
                ...state.signUp,
                chosenEntityType: action.entityType,
            }
            localStorage.setItem('signUp', JSON.stringify(newSignUpState))
            return { ...state, signUp: newSignUpState }
        }
        case UserActionType.SIGN_UP_VERIFY_LATER: {
            const newSignUpState = {
                ...state.signUp,
                verificationSkipped: true,
            }
            localStorage.setItem('signUp', JSON.stringify(newSignUpState))
            return { ...state, signUp: newSignUpState, userInfo: { ...state.userInfo!, verified: true } }
        }
        case UserActionType.SIGN_UP_LOCAL_DELIVERY_ANSWER: {
            const newSignUpState = {
                ...state.signUp,
                localDeliverySkipped: !action.answer,
            }
            localStorage.setItem('signUp', JSON.stringify(newSignUpState))
            return { ...state, signUp: newSignUpState }
        }
        default:
            throw new Error('Unknown user action')
    }
}

export const updateGeoLocation = (dispatch?: React.Dispatch<UserAction>): void => {
    if (navigator.geolocation && dispatch) {
        navigator.geolocation.getCurrentPosition((position) => {
            dispatch({
                type: UserActionType.UPDATE_GEO,
                geolocation: {
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                },
            })
        })
    }
}

export const updateLoggedState = (userInfo?: UserData, dispatch?: React.Dispatch<UserAction>): void => {
    if (dispatch) {
        dispatch({
            type: UserActionType.SET_LOGGED_STATE,
            userInfo,
        })
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const userLogout = (dispatch: React.Dispatch<UserAction>) => {
    dispatch({ type: UserActionType.LOGOUT })
}

export const UserProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, userDefaultState)
    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
}
