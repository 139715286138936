import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InDrayage = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 14C2.67578 14 1.60156 13.6687 0.722656 13.1844C0.505859 13.0609 0.25293 12.9992 0 12.9992V15.1687C0.175781 15.2437 0.382812 15.325 0.609375 15.4094C1.38281 15.6906 2.50391 16 3.75 16C5.03906 16 6.30859 15.6781 7.44141 15.1906C7.96094 15.4563 8.75781 15.7938 9.71484 15.9719C10.3867 16.0969 11.0586 15.7625 11.2148 15.225C11.3711 14.6875 10.9531 14.15 10.2812 14.025C9.57422 13.8938 8.86719 13.6094 8.30469 13.2375C7.87109 12.9406 7.23828 12.9187 6.77344 13.1844C5.91406 13.6625 4.80078 14 3.75 14Z" />
                <path d="M2.5 0C3.19141 0 3.75 0.446875 3.75 1V2H5.625C6.66016 2 7.5 2.67188 7.5 3.5V7.5H9.37483C10.2812 7.5 10.3867 9.13438 9.68359 9.64688L5.73828 12.5406C5.10547 12.8344 4.38281 13.0125 3.75 13.0125C2.98437 13.0125 2.15625 12.7719 1.4375 12.3781C1.00642 12.1363 0.503255 12.0152 0 12.0148V6.83437H5V4H0V0H2.5Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.03836 3.07692C9.03836 2.65209 9.46885 2.30769 9.99989 2.30769H13.7869C15.5924 2.30769 17.0561 3.47864 17.0561 4.92308V6.28082L17.8207 5.73415C18.2166 5.45105 18.8245 5.47833 19.1784 5.79509C19.5322 6.11185 19.4981 6.59813 19.1022 6.88124L16.0946 9.03168L13.0869 6.88124C12.691 6.59813 12.6569 6.11185 13.0108 5.79509C13.3647 5.47833 13.9725 5.45105 14.3685 5.73415L15.133 6.28082V4.92308C15.133 4.32831 14.5303 3.84615 13.7869 3.84615H9.99989C9.46885 3.84615 9.03836 3.50176 9.03836 3.07692Z"
                />
                <path d="M12.3076 12.3077C12.3076 11.628 12.9964 11.0769 13.846 11.0769H19.9999V16H12.3076V12.3077Z" />
            </svg>
        </IconBase>
    )
}
