import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Separator } from '@/shared/ui/Separator'

export const TransloadingBreakdownComponent = ({ order, service }: ServiceBreakdownComponentProps) => {
    if (service.type === 'allIn') {
        return (
            <div className="flex flex-col gap-3">
                <span className="font-medium leading-5">Floor Loaded</span>

                {service.pricings[RfqServicePricing.Unloading]?.price ? (
                    <div className="flex gap-2 items-center w-full">
                        <span className="text-xl text-primary font-semibold">
                            ${Number(service.pricings[RfqServicePricing.Unloading]!.price).toLocaleString('en')}
                        </span>
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            {order.unitType}
                        </span>
                    </div>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}

                <Separator />

                <div className="space-y-1">
                    <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                        {RfqServicePricingLabel[RfqServicePricing.Palletization]}
                    </span>
                    <div className="space-y-3">
                        {service.pricings[RfqServicePricing.Palletization]?.price ? (
                            <div className="flex gap-2 items-center w-full">
                                <span className="text-xl text-primary font-semibold">
                                    $
                                    {Number(service.pricings[RfqServicePricing.Palletization]!.price).toLocaleString(
                                        'en',
                                    )}
                                </span>
                                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet (including materials)
                                </span>
                            </div>
                        ) : (
                            <div className="flex gap-2 items-center w-full">
                                <span className="text-foreground-secondary font-medium">N/A</span>
                                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet (including materials)
                                </span>
                            </div>
                        )}
                        {service.pricings[RfqServicePricing.PalletOut]?.price ? (
                            <div className="flex gap-2 items-center w-full">
                                <span className="text-xl text-primary font-semibold">
                                    ${Number(service.pricings[RfqServicePricing.PalletOut]!.price).toLocaleString('en')}
                                </span>
                                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet Out
                                </span>
                            </div>
                        ) : (
                            <div className="flex gap-2 items-center w-full">
                                <span className="text-foreground-secondary font-medium">N/A</span>
                                <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                                <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                                    Pallet Out
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-3">
            <span className="font-medium leading-5">Palletized</span>
            <div className="flex flex-col gap-1">
                <span className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.HandlingTotal]}
                </span>
                {service.pricings[RfqServicePricing.HandlingTotal]?.price ? (
                    <span className="text-xl leading-6 text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.HandlingTotal]!.price).toLocaleString('en')}
                    </span>
                ) : (
                    <span className="text-foreground-secondary font-medium">N/A</span>
                )}
            </div>
        </div>
    )
}
