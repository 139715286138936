import React, { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import AdminGeneral from '@/pages/Admin/AdminGeneral'
import AdminWarehouses from '@/pages/Admin/Warehouses'
import AdminStaff from '@/pages/Admin/Staff'
import AdminInvoices from '@/pages/Admin/Invoices'
import { UserType } from '@shared/api/types'
import { useUserState } from '@/store/user'
import AdminLayout from './AdminLayout'

interface AdminRoutesProps {
    shouldLogoReset: boolean
    setShouldLogoReset: (shouldReset: boolean) => void
}

export const AdminRoutes: FC<AdminRoutesProps> = ({ shouldLogoReset, setShouldLogoReset }) => {
    const {
        state: { userInfo },
    } = useUserState()

    return (
        <>
            <LoggedInRoute />
            <AdminRoute userType={userInfo?.type} />
            <Routes>
                {/* <Route path="/" element={<Navigate to="/admin/general" replace />} /> */}
                <Route
                    path="/"
                    element={<AdminLayout companyName={userInfo?.parentCompanyName ?? userInfo?.companyName} />}
                >
                    <Route
                        index
                        element={
                            <AdminGeneral shouldLogoReset={shouldLogoReset} setShouldLogoReset={setShouldLogoReset} />
                        }
                    />
                    <Route path="/warehouses" element={<AdminWarehouses />} />
                    <Route path="/staff" element={<AdminStaff />} />
                    <Route path="/invoices" element={<AdminInvoices />} />
                </Route>
            </Routes>
        </>
    )
}

interface AdminRouteProps {
    userType?: string
}

const AdminRoute: FC<AdminRouteProps> = ({ userType }) => {
    if (userType === UserType.admin) {
        return null
    } else {
        return <Navigate to={{ pathname: '/' }} state={{ from: window.location.pathname }} />
    }
}
