export enum QuoteStatus {
    CREATED,
    ACCEPTED,
    DECLINED,
    QUOTED,
    REQUOTE,
}

export enum QuoteType {
    ALLIN,
    STANDARD,
}

export enum RequoteReason {
    Price = 'Price',
    Location = 'Location',
    NotAvailable = 'Services',
    Hours = 'Hours',
    Incomplete = 'Incomplete Quote',
    ResponseTime = 'Response Time',
    Other = 'Other',
}

export const RequoteReasonText = {
    [RequoteReason.Price]: 'Price',
    [RequoteReason.Location]: 'Location',
    [RequoteReason.NotAvailable]: 'Service Not Available',
    [RequoteReason.Hours]: 'Hours',
    [RequoteReason.Incomplete]: 'Incomplete Quote',
    [RequoteReason.ResponseTime]: 'Response Time',
    [RequoteReason.Other]: 'Other',
}
