import React, { FC } from 'react'
import cn from 'classnames'
import { ReactComponent as CheckSelect } from '@/pages/RFQPortal/assets/img/check-select.svg'
import './ServiceOptions.scss'

export interface IServiceOption {
    id: string
    name: string
    isSelected?: boolean
}

export interface ServiceOptionProps extends IServiceOption {
    onChange: (id: string, isChecked: boolean) => void
    editMode?: boolean
}

export interface ServiceOptionsProps {
    options: IServiceOption[]
    onChange: (id: string, isChecked: boolean) => void
    isStacked?: boolean
    editMode?: boolean
}

const ServiceOption: FC<ServiceOptionProps> = ({ id, name, isSelected, onChange, editMode }) => (
    <div
        className={cn('service-option', { 'service-option--selected': isSelected, 'service-option--read': !editMode })}
        onClick={() => {
            if (editMode) {
                onChange(id, !isSelected)
            }
        }}
    >
        {name}
        {isSelected && (
            <span className="service-option__arrow">
                <CheckSelect />
            </span>
        )}
    </div>
)

const ServiceOptions: FC<ServiceOptionsProps> = ({ options, onChange, isStacked, editMode }) => {
    return (
        <div className={cn('service-options', { isStacked })}>
            {options.map((service) => (
                <ServiceOption
                    key={service.id}
                    editMode={editMode}
                    onChange={onChange}
                    id={service.id}
                    name={service.name}
                    isSelected={service.isSelected}
                />
            ))}
        </div>
    )
}

export default ServiceOptions
