import React, { useEffect, useState } from 'react'
import { acceptTermsOfService, useTermsOfService } from '@shared/apiv2/terms-of-service'
import { Modal as ReactModal } from 'react-responsive-modal'
import { userLogout, useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import Button from '@/components/Button'
import { toast } from 'react-toastify'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import { useNavigate } from 'react-router-dom'
import { HttpV2 } from '@shared/apiv2/api'
import { Loader } from '@/components/Loader'
import { getOLIMPLandingURL } from '@/utils/url'

export const ToSModalProvider = () => {
    const { slug } = useMicrosite()
    const navigate = useNavigate()
    const { state: userState, dispatch } = useUserState()

    const [open, setOpen] = useState(false)
    const { loading, agreed } = useTermsOfService()

    useEffect(() => {
        if (!loading && !agreed) {
            setOpen(true)
        }
    }, [loading, agreed])

    const [isLoading, setIsLoading] = useState(false)

    const onAccept = async () => {
        setIsLoading(true)
        try {
            await acceptTermsOfService()
            setOpen(false)
        } catch (e) {
            toast('Something went wrong. We are sorry.', { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    const onDecline = () => {
        if (window.location.pathname.includes('/rfq')) {
            localStorage.setItem('rfqLinkForSSO', window.location.href)
        }
        userLogout(dispatch!)
        HttpV2.defaults.headers.common.Authorization = ''
        navigate('/login' + (slug ? `?slug=${slug}` : ''), { replace: true })
    }

    if (!userState.isLoggedIn || !userState.userInfo?.role) {
        return null
    }

    if (loading || agreed) return null

    return (
        <ReactModal
            center
            open={open}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            onClose={() => setOpen(false)}
            classNames={{
                modal: 'rounded-xl !max-w-[600px] !py-4 px-5',
            }}
        >
            <div className="space-y-5">
                <span className="text-2xl font-semibold">We Have Updated Our Terms</span>
                <div>
                    {userState.userInfo.role === UserRole.FREIGHT_BROKER_MANAGER
                        ? 'To use OLIMP’s RFQ Portal you must accept the new Terms and Conditions'
                        : 'To use OLIMP’s Warehouse Load Board you must accept the new Terms and Conditions'}
                </div>
                <div>
                    <a
                        className="!text-base !font-normal text-primary active:text-primary hover:text-primary/80 transition-colors duration-150 underline"
                        href={getOLIMPLandingURL('terms-and-conditions')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View full Terms and Conditions
                    </a>
                </div>
                <div className="flex flex-col-reverse sm:flex-row gap-3 justify-end">
                    <Button
                        className="border border-solid border-danger text-danger hover:bg-danger/10"
                        onClick={onDecline}
                        disabled={isLoading}
                    >
                        Decline and sign out
                    </Button>
                    <Button types={['blue']} onClick={onAccept} disabled={isLoading}>
                        Accept {isLoading && <Loader type="button" />}
                    </Button>
                </div>
            </div>
        </ReactModal>
    )
}
