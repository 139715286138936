import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import InputLabel from '@/components/InputLabel'
import { toast } from 'react-toastify'
import './AddWarehouseModal.scss'

interface WarehouseLocation {
    building?: string
    city?: string
    country?: string
    formattedAddress: string
    lat?: number | string
    lng?: number | string
    state?: string
    street?: string
    zip?: string
}
interface AddWarehouseProps {
    warehouseName: string
    warehouseAddress: WarehouseLocation | string
}

const AddWarehouseSchema = Yup.object().shape({
    warehouseName: Yup.string().required('Warehouse Name is required').min(3),
    warehouseAddress: Yup.lazy((value) =>
        typeof value === 'object'
            ? Yup.object().shape({
                  formattedAddress: Yup.string().required('Warehouse Location is not valid'),
              })
            : Yup.string().required('Warehouse Location is required'),
    ),
})

type Address = {
    building: string
    city: string
    country: string
    formattedAddress: string
    lat: string
    lng: string
    state: string
    street: string
    zip: string
}
interface AddWarehouseModalProps {
    onClose?: (success: boolean) => void
}

const AddWarehouseModal: FC<AddWarehouseModalProps> = ({ onClose }) => {
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const onCoseFunc = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ModalRegistry.get().close(Modals.AddWarehouseModal)
    }, [])

    const formik = useFormik<AddWarehouseProps>({
        initialValues: {
            warehouseName: '',
            warehouseAddress: '',
        },
        validationSchema: AddWarehouseSchema,
        validateOnMount: false,
        validateOnChange: validateOnChange,
        onSubmit: async (values, { setErrors }) => {
            try {
                await HttpV2.post<AddWarehouseProps>('accounts/warehouse', values)

                formik.setSubmitting(false)
                toast.success('Success')
                onClose?.(true)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ModalRegistry.get().close(Modals.AddWarehouseModal)
            } catch (e: any) {
                formik.setSubmitting(false)
                formik.setErrors(e?.response?.data)
                console.log(e)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // ModalRegistry.get().close(Modals.AddWarehouseModal)
                toast.error(e?.response?.data?.message ?? e?.response?.data ?? 'Something went wrong')
            }
        },
    })

    const handleInputChange = (e: any) => {
        formik.handleChange(e)
        formik.setErrors({})
    }

    const handleChangeAddress = (address: string | WarehouseLocation) => {
        if (typeof address === 'object' && address.formattedAddress && address.lat && address.lng) {
            setSearchInput(address.formattedAddress)
            formik.setFieldValue('warehouseAddress', address)
            formik.setFieldError('warehouseAddress', '')
        } else {
            setSearchInput('')
            formik.setFieldValue('warehouseAddress', '')
            formik.setFieldError('warehouseAddress', 'Warehouse Location is not valid')
        }
    }

    return (
        <div className="modal-info">
            <form onSubmit={formik.handleSubmit}>
                <div className="form">
                    <div className="form-company">
                        <div className="section">
                            <div className="form-wrap">
                                <div className="add-warehouse-modal__field">
                                    <InputLabel
                                        name="warehouseName"
                                        label="Warehouse Name*"
                                        onChange={handleInputChange}
                                        value={formik.values.warehouseName}
                                        errorMessage={formik.errors.warehouseName}
                                    />
                                </div>
                                <div className="add-warehouse-modal__field">
                                    <div className="add-warehouse-modal__field">
                                        <GoogleAutocompleteInput
                                            type="text"
                                            id="warehouseAddress"
                                            name="warehouseAddress"
                                            data-name="warehouseAddress"
                                            value={searchInput}
                                            inputPath="search"
                                            label="Warehouse Location*"
                                            onChangeText={(path, value) => handleChangeAddress(value)}
                                            changePlaceFull={(field, value) => {
                                                if ((value.lat && value.lng) || !value.formattedAddress) {
                                                    handleChangeAddress(value)
                                                    formik.setErrors({})
                                                }
                                            }}
                                            errorMessage={formik.errors.warehouseAddress}
                                            placeholder="Type to search..."
                                            onBlur={() => {}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-warehouse-modal__btnWrap">
                    <Button
                        className="add-warehouse-modal__firstBtn"
                        label="Cancel"
                        type="submit"
                        types={['bordered']}
                        onClick={onCoseFunc}
                    />
                    <Button
                        label="Add Warehouse"
                        type="submit"
                        loading={formik.isSubmitting}
                        types={['blue']}
                        disabled={!formik.isValid || formik.isSubmitting}
                    />
                </div>
            </form>
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ModalRegistry.get().register<AddWarehouseModalProps>(Modals.AddWarehouseModal, {
    id: 'AddWarehouseModalProps',
    className: 'add-warehouse-modal ',
    size: 'large',
    Component: memo(AddWarehouseModal),
    title: 'Add Warehouse',
})

export const showAddWarehouseModal = (props: ModalPartialProps<AddWarehouseModalProps>): void =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ModalRegistry.get().show<AddWarehouseModalProps>(Modals.AddWarehouseModal, props)

export default memo(AddWarehouseModal)
