import React from 'react'

import { setMainStyle } from '../helper'
import { loadBoardServicesTitle } from '../types'
import Checkbox from '@/components/Checkbox'

export const ServicesCheckboxes = ({
    isServicesChecked,
    handleServicesChange,
}: {
    isServicesChecked: any
    handleServicesChange: any
}) => {
    return (
        <div style={{ flexWrap: 'wrap', width: 800 }}>
            <p className={setMainStyle('_label')} style={{ paddingBottom: 0 }}>
                Services
            </p>
            <div className={setMainStyle('_wrap')}>
                <Checkbox
                    className={setMainStyle('_checkbox')}
                    label="Warehousing"
                    value={isServicesChecked(loadBoardServicesTitle.Warehousing)}
                    name={loadBoardServicesTitle.Warehousing}
                    onChange={handleServicesChange}
                />

                <Checkbox
                    className={setMainStyle('_checkbox')}
                    label="Transloading"
                    value={isServicesChecked(loadBoardServicesTitle.Transloading)}
                    name={loadBoardServicesTitle.Transloading}
                    onChange={handleServicesChange}
                />
                <Checkbox
                    label="Drayage"
                    value={isServicesChecked(loadBoardServicesTitle.Drayage)}
                    name={loadBoardServicesTitle.Drayage}
                    onChange={handleServicesChange}
                />
            </div>
        </div>
    )
}
