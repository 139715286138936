import React, { FC } from 'react'
import { v4 } from 'uuid'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { FormValues } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import EditButton from '@/pages/RFQPortal/components/EditBotton'
import { notImageTypeIcons } from '@/pages/RFQPortal/components/DropZoneArea/index'
import { isProdEnv } from '@/pages/RFQPortal/helpers/rfqHelper'

import { rfqTemplateClass, headerClass } from '@/pages/RFQPortal/components/RfqTemplate'
import TemplateTitle from '@/pages/RFQPortal/components/RfqTemplate/TemplateTitle'

import './RfqTemplate.scss'

interface ReviewToRenderProps {
    formData: FormValues
    oppIndex: number
    changeStep: (step: Steps) => void
}

// eslint-disable-next-line complexity
const ReviewToRender: FC<ReviewToRenderProps> = ({ formData, oppIndex, changeStep }) => {
    const rfqReviewClass = 'rfq-review'
    const dropZoneClass = 'drop-zone'

    const projectData = formData.project.data[oppIndex]
    const detailsSectionClass =
        projectData?.files?.length > 0 && projectData.notes !== ''
            ? `${rfqTemplateClass}__section_with-border ${rfqReviewClass}__section_with-border`
            : ''

    const handleEdit = () => {
        changeStep(Steps.RFQ)
    }

    return (
        <div className={`${rfqTemplateClass}-wrapper`}>
            <div className={headerClass}>
                <TemplateTitle
                    isProjectSingle={formData.project.single}
                    projectData={projectData}
                    oppIndex={oppIndex}
                />
                <EditButton action={handleEdit} />
            </div>
            <div className={`${rfqTemplateClass}`}>
                <section
                    className={`${rfqTemplateClass}__section ${rfqReviewClass}__section ${rfqTemplateClass}__section_with-border ${rfqReviewClass}__section_with-border`}
                >
                    {(projectData.servicesNeeded?.length > 0 ||
                        projectData.freightEnvironment ||
                        projectData.certifications?.length > 0) && (
                        <div className={`${rfqReviewClass}__group`}>
                            {projectData.servicesNeeded?.length > 0 && (
                                <div>
                                    <h3 className={`${rfqReviewClass}__title`}>Services Needed*</h3>
                                    <div className={`${rfqReviewClass}__checkbox-wrapper`}>
                                        {projectData.servicesNeeded.map((item, index) => (
                                            <p className={`${rfqReviewClass}__text`} key={index}>
                                                {item}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {projectData.freightEnvironment && (
                                <div>
                                    <h3 className={`${rfqReviewClass}__title`}>Freight Environment*</h3>
                                    <p className={`${rfqReviewClass}__text`}>{projectData.freightEnvironment}</p>
                                </div>
                            )}
                            {projectData.certifications?.length > 0 && (
                                <div>
                                    <h3 className={`${rfqReviewClass}__title`}>Certifications</h3>
                                    {projectData.certifications.map((item, index) => (
                                        <p className={`${rfqReviewClass}__text`} key={index}>
                                            {item}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </section>
                <section className={`${rfqTemplateClass}__section ${rfqReviewClass}__section ${detailsSectionClass}`}>
                    <h3 className={`${rfqReviewClass}__section-title`}>Details</h3>
                    <div>
                        {(projectData.serviceLocation || projectData.serviceDate?.start) && (
                            <div className={`${rfqReviewClass}__group`}>
                                {projectData.serviceLocation && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Service Location*</h3>
                                        <p className={`${rfqReviewClass}__text`}>
                                            {projectData.serviceLocation.formattedAddress}
                                        </p>
                                    </div>
                                )}
                                {projectData.serviceDate?.start && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Service Date</h3>
                                        <p className={`${rfqReviewClass}__text`}>
                                            {projectData.serviceDate?.start} - {projectData.serviceDate?.end}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        {(projectData.commodity || projectData.loadNumber) && (
                            <div className={`${rfqReviewClass}__group`}>
                                {projectData.commodity && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Commodity</h3>
                                        <p className={`${rfqReviewClass}__text`}>{projectData.commodity}</p>
                                    </div>
                                )}
                                {projectData.loadNumber && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Load/Container #</h3>
                                        <p className={`${rfqReviewClass}__text`}>{projectData.loadNumber}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        {(projectData.unitType || projectData.numberOfUnits) && (
                            <div className={`${rfqReviewClass}__group`}>
                                {projectData.unitType && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Unit Type</h3>
                                        <p className={`${rfqReviewClass}__text`}>{projectData.unitType}</p>
                                    </div>
                                )}
                                {projectData.numberOfUnits && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}># of Units</h3>
                                        <p className={`${rfqReviewClass}__text`}>{projectData.numberOfUnits}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        {projectData.spaceNeeded && !isProdEnv && (
                            <div className={`${rfqReviewClass}__group`}>
                                {projectData.spaceNeeded && (
                                    <div>
                                        <h3 className={`${rfqReviewClass}__title`}>Space Needed</h3>
                                        <p className={`${rfqReviewClass}__text`}>{projectData.spaceNeeded}</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </section>
                {projectData.notes && (
                    <section className={`${rfqTemplateClass}__section ${rfqReviewClass}__section`}>
                        <div>
                            <h3 className={`${rfqReviewClass}__section-title`}>Notes</h3>
                            <p className={`${rfqReviewClass}__text`}>{projectData.notes}</p>
                        </div>
                    </section>
                )}
                {projectData.files?.length > 0 && (
                    <section className={`${rfqTemplateClass}__section ${rfqReviewClass}__section`}>
                        <h3 className={`${rfqReviewClass}__section-title ${rfqReviewClass}__section-title_images`}>
                            Images/Files
                        </h3>
                        <div className={`${dropZoneClass}__wrapper`}>
                            {projectData.files.map((file) => {
                                const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2)

                                const fileNameParts = file.name.split('.')
                                const fileExtension = fileNameParts.pop()
                                let fileName = fileNameParts.join('.')

                                if (fileName?.length > 8) {
                                    fileName = fileName.substring(0, 8) + '...' // Add ellipsis to indicate that the file name has been truncated
                                }

                                const displayedFileName = `${fileName}.${fileExtension}`

                                let link = URL.createObjectURL(file)

                                let style: any = {
                                    height: '100%',
                                    objectFit: 'fill',
                                }

                                for (const type in notImageTypeIcons) {
                                    if (file.type === type) {
                                        link = notImageTypeIcons[type]
                                        style = {
                                            width: 'auto',
                                            height: '90%',
                                            margin: 'auto',
                                            paddingTop: '20px',
                                        }
                                    }
                                }

                                return (
                                    <div className="dz-preview dz-image-preview" key={v4()}>
                                        <div className="dz-image">
                                            <img src={link} alt={file.name} style={style} />
                                        </div>
                                        <div className="dz-details">
                                            <div className="dz-filename">
                                                <span>{`${displayedFileName} - `}</span>
                                                <span>{`${fileSizeMB}mb`}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                )}
            </div>
        </div>
    )
}

export default ReviewToRender
