import React from 'react'
import { FaqDataType } from '.'
import FaqItem from './FaqItem'

interface FaqListProps {
    data: FaqDataType[]
    toggleHeight: (id: number) => void
}

export default function FaqList({ data, toggleHeight }: FaqListProps) {
    return (
        <ul className="faq__list">
            {data.map((elem, index) => (
                <FaqItem key={`faq__list-${index}`} data={elem} toggleHeight={toggleHeight} index={index} />
            ))}
        </ul>
    )
}
