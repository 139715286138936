import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { UserProvider } from '@/store/user'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { EntityProvider } from '@/store/entity'
import { CompanyProvider } from '@/store/company'
import { WpDataProvider } from '@/pages/RFQPortal/contexts/wpDataContext'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { Toast } from '@/components/Toast'

import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import { Provider } from 'react-redux'
import { store } from '@/app/store'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Routes>
                        <Route
                            path={'/*'}
                            element={
                                <UserProvider>
                                    <CompanyProvider>
                                        <EntityProvider>
                                            <WpDataProvider>
                                                <App />
                                            </WpDataProvider>
                                        </EntityProvider>
                                    </CompanyProvider>
                                </UserProvider>
                            }
                        />
                    </Routes>
                </QueryParamProvider>
            </BrowserRouter>
            <Toast />
        </Provider>
    </React.StrictMode>,
    document.getElementById('app'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
