import React, { useState } from 'react'
import { DialogClose, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle } from '@/shared/ui/Dialog'
import { Select, SelectOption } from '@/shared/ui/Select'
import { Quote, RequoteReason, RequoteReasonText } from '@/entities/quotes/model'
import { InputLabel, InputWrapper } from '@/shared/ui/Input'
import { Textarea } from '@/shared/ui/Textarea'
import { Button } from '@/shared/ui/Button'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { quotesApi } from '@/entities/quotes/api'
import { orderPageActions } from '@/pages/Order/model'
import { toast } from 'react-toastify'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { useAppDispatch } from '@/app/store'

type RequoteModalProps = {
    open: boolean
    onOpenChange: (open: boolean) => void

    quoteRef: React.MutableRefObject<Quote | null>
    order: LoadboardOrder

    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

const REQUOTE_REASON_OPTIONS: SelectOption[] = [
    { value: RequoteReason.Location, label: RequoteReasonText[RequoteReason.Location] },
    { value: RequoteReason.Price, label: RequoteReasonText[RequoteReason.Price] },
    { value: RequoteReason.NotAvailable, label: RequoteReasonText[RequoteReason.NotAvailable] },
    { value: RequoteReason.Hours, label: RequoteReasonText[RequoteReason.Hours] },
    { value: RequoteReason.Incomplete, label: RequoteReasonText[RequoteReason.Incomplete] },
    { value: RequoteReason.ResponseTime, label: RequoteReasonText[RequoteReason.ResponseTime] },
    { value: RequoteReason.Other, label: RequoteReasonText[RequoteReason.Other] },
]

export const RequoteModal = ({ open, onOpenChange, quoteRef, order, isLoading, setIsLoading }: RequoteModalProps) => {
    const dispatch = useAppDispatch()

    const [requoteReason, setRequoteReason] = useState<RequoteReason | null>(null)
    const [requoteReasonComment, setRequoteReasonComment] = useState('')
    const [error, setError] = useState<string | null>(null)

    const onRequote = async () => {
        const quote = quoteRef.current
        if (!quote || isLoading) return

        if (!requoteReason?.trim()) {
            setError('Please select a reason')
            return
        }

        setIsLoading?.(true)

        try {
            await quotesApi.requote({
                orderKey: order.id,
                quoteId: quote.id,
                reason: requoteReason,
                reasonComment: requoteReasonComment,
            })

            dispatch(orderPageActions.removeQuote(quote.id))

            toast('Quote has been sent for requote.', { type: 'success' })
            onOpenChange(false)
        } catch (e) {
            console.error(e)
            toast('We could not send this Quote for the Requote.', { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    if (!quoteRef.current) {
        return null
    }

    return (
        <DialogRoot
            open={open}
            onOpenChange={(newOpen) => {
                quoteRef.current = null
                setRequoteReason(null)
                setRequoteReasonComment('')
                onOpenChange(newOpen)
            }}
        >
            <DialogContent className="flex flex-col gap-5">
                <DialogHeader>
                    <DialogTitle>Requote</DialogTitle>
                </DialogHeader>
                <div className="space-y-5">
                    <div>
                        <Select
                            options={REQUOTE_REASON_OPTIONS}
                            onValueChange={(v: RequoteReason) => {
                                setRequoteReason(v)
                                setError(null)
                            }}
                            label="Reason*"
                        />
                        {error && <div className="text-sm text-danger">{error}</div>}
                    </div>
                    <InputWrapper>
                        <Textarea
                            onChange={(e) => {
                                setRequoteReasonComment(e.target.value)
                            }}
                            className="resize-none h-20"
                        />
                        <InputLabel>Tell us more (Optional)</InputLabel>
                    </InputWrapper>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="outlined" disabled={isLoading}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button onClick={onRequote} disabled={isLoading}>
                        Send {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    )
}
