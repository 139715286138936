export const DAYS: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

export const isDevEnv = false

export enum ServiceTypes {
    SPECIAL = 0,
    STORAGE = 1,
    DELIVERY = 2,
    FEATURE = 3,
    SUB_SERVICE_REQUIRED = 4,
    PRICE_MODIFICATOR = 5,
    SUB_SERVICE_OPTIONAL = 6,
    PICKUP = 7,
    DELIVERY_STOP = 8,
}

export default {
    warehouse: {
        prices: {
            1: 'Per pallet per day',
            2: 'Per pallet',
            3: 'Flat rate',
            4: 'Per unit per day',
            5: 'Per unit',
            6: 'Per stop',
        },
        excludedPricesForUnit: {
            1: 'Per pallet per day',
            2: 'Per pallet',
        },
        units: {
            PALLET: 1,
            SQ_FEET: 2,
        },
        serviceTypes: {
            special: 0,
            main: 1,
            delivery: 2,
            feature: 3,
            subServiceRequired: 4,
            priceModificator: 5,
            subServiceOptional: 6,
            pickup: 7,
            deliveryStop: 8,
        },
        serviceTypeLabels: {
            1: 'Warehousing',
            2: 'Delivery options',
            4: 'Required sub-services',
            6: 'Optional sub-services',
        },
    },
    order: {
        adjustments: {
            5: 'Lumper',
            6: 'Detention',
            7: 'Additional Service',
        },
    },
    timeZones: {
        1: {
            title: 'EST',
            offset: -5,
            description: 'Eastern Time (UTC-5)',
            name: 'America/Detroit',
        },
        2: {
            title: 'CST',
            offset: -6,
            description: 'Central Time (UTC-6)',
            name: 'America/Chicago',
        },
        3: {
            title: 'MST',
            offset: -7,
            description: 'Mountain Time (UTC-7)',
            name: 'America/Boise',
        },
        4: {
            title: 'MDT',
            offset: -7,
            description: 'Mountain Time (UTC-7. No DST)',
            name: 'America/Whitehorse',
        },
        5: {
            title: 'PST',
            offset: -8,
            description: 'Pacific Time (UTC-8)',
            name: 'America/Los_Angeles',
        },
        6: {
            title: 'AKST',
            offset: -9,
            description: 'Alaska Time (UTC-9)',
            name: 'America/Juneau',
        },
        7: {
            title: 'HST',
            offset: -10,
            description: 'Hawaii-Aleutian (UTC-10)',
            name: 'US/Aleutian',
        },
        8: {
            title: 'HDT',
            offset: -10,
            description: 'Hawaii-Aleutian Time (UTC-10. No DST)',
            name: 'Pacific/Honolulu',
        },
    },
}

export const CONSTS = Object.freeze({
    warehouseId: 'warehouseId',
})
