import React, { FC, useEffect, useState } from 'react'
import File from '@/components/FileUpload'
import { HttpV2 } from '@shared/apiv2/api'
import { toast } from 'react-toastify'
import AccountInfo from './AccountInfo'
import WarehouseSchedule from '@/pages/Warehouses/General/WarehouseSchedule'

import './AdminGeneral.scss'
import ConditionalImage from '@/components/ConditionalImage/ConditionalImage'
import { LogoWrapper } from '@/pages/Admin/AdminGeneral/LogoWrapper'
import HelpLinks from '@/parts/Cards/HelpLinks'
import { useUserState } from '@/store/user'

const CLASS_NAME = 'admin-general'

interface AdminGeneralProps {
    shouldLogoReset: boolean
    setShouldLogoReset: (shouldReset: boolean) => void
}

const AdminGeneral: FC<AdminGeneralProps> = ({ shouldLogoReset, setShouldLogoReset }) => {
    const [accountData, setAccountData] = useState<any>({})
    const [accountDataLoading, setAccountDataLoading] = useState<boolean>(false)

    const { state } = useUserState()

    const { admin } = state.userInfo!

    const [sendingLogo, setSendingLogo] = useState<boolean>(false)
    const [errorLogoMessage, setErrorLogoMessage] = useState<string>('')
    const [isImageAvaliable, setIsImageAvaliable] = useState<boolean>(false)

    const fetchAccountdata = async () => {
        setAccountDataLoading(true)
        try {
            const { data } = await HttpV2.get('accounts/related')
            const account = data.filter((item: any) => item.isCmpAcc)

            if (account.length === 1) {
                setAccountData(account[0])
                console.log('acc is ', account[0])
            } else {
                throw Error('Account is not awailable!')
            }
        } catch (e) {
            console.error(e)
        } finally {
            setAccountDataLoading(false)
        }
    }

    useEffect(() => {
        fetchAccountdata()
    }, [])

    const uploadLogo = async (account_id: string, file: File) => {
        const formData = new FormData()
        formData.append('file', file)

        try {
            setSendingLogo(true)
            setErrorLogoMessage('')

            await HttpV2.post(`/accounts/${account_id}/documents/upload/Logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

            setShouldLogoReset(true)
            toast('Logo uploaded successfully', { type: 'success' })
        } catch (error: any) {
            setErrorLogoMessage(`Error uploading logo: ${error.response?.data?.message}`)
        } finally {
            setSendingLogo(false)
        }
    }

    const handleFileSelect = (files: FileList | null) => {
        if (accountData?.id && files) {
            Array.from(files).forEach((file) => {
                uploadLogo(accountData?.id, file)
            })
        }
    }

    const deleteLogo = async () => {
        try {
            await HttpV2.delete(`/accounts/${accountData?.id}/logo`)

            setShouldLogoReset(true)
            toast('Logo deleted successfully', { type: 'success' })
        } catch (error: any) {
            toast(error.response?.data?.message, { type: 'warning' })
        }
    }

    return (
        <div className="app-page-container app-page-container_flex">
            <div className="app-page">
                <div className={`app-card ${CLASS_NAME}__account-info`}>
                    <AccountInfo
                        accountData={accountData}
                        infoLoading={accountDataLoading}
                        fetchAccountdata={fetchAccountdata}
                    />
                </div>
                <div className="app-card">
                    <WarehouseSchedule
                        manager={admin}
                        warehouseId={accountData?.id || ''}
                        description="This is the default schedule for new warehouses added to your organization"
                    />
                </div>
                <div className="app-card">
                    <div className="bordered-block">
                        <div className={`${CLASS_NAME}__logo-upload`}>
                            <h2>Company Logo</h2>
                            <File
                                onFileSelect={(files) => handleFileSelect(files)}
                                types="blue"
                                maxSizeMB={2}
                                accept={'image/*'}
                                multiple={false}
                                loading={sendingLogo}
                                className={`${CLASS_NAME}__logo-upload-btn`}
                            />
                        </div>
                        {errorLogoMessage && (
                            <p className={`${CLASS_NAME}__logo-upload-btn-error`}>{errorLogoMessage}</p>
                        )}
                    </div>
                    <ConditionalImage
                        resetTrigger={{
                            shouldLogoReset,
                            setShouldLogoReset,
                        }}
                        src={accountData?.id && `${HttpV2.defaults.baseURL}/accounts/${accountData?.id}/logo`}
                        alt={'Logo'}
                        WrapperComponent={LogoWrapper}
                        wrapperComponentProps={{
                            logoClassName: `${CLASS_NAME}__logo`,
                            wrapperClassName: `${CLASS_NAME}__logo-card`,
                            isImageAvaliable: isImageAvaliable,
                            deleteLogo: deleteLogo,
                        }}
                        setIsImageAvaliable={setIsImageAvaliable}
                    />
                </div>
            </div>
            <div className="app-sidebar">
                <HelpLinks />
            </div>
        </div>
    )
}

export default AdminGeneral
