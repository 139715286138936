import axios, { AxiosInstance } from 'axios'
import useSWR from 'swr'
import { PaginationState } from '@tanstack/table-core/build/lib/features/Pagination'
import { iframeSolution } from '@/pages/RFQPortal/RfqPortalRoutes'
import { getSWRFetcher } from '@shared/apiv2/api'

export class RfqPortalService {
    api?: AxiosInstance

    error?: string

    static instance: RfqPortalService

    constructor() {
        const urlParams = new URLSearchParams(window.location.search)
        const tokenInURL = urlParams.get('token')
        const token = tokenInURL ?? localStorage.getItem('sf_token')

        if (token) {
            localStorage.setItem('sf_token', token)
            this.api = axios.create({
                baseURL: '/api/v2',
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                responseType: 'json',
            })

            if (tokenInURL) {
                urlParams.delete('token')
                const paramsString = urlParams.toString()
                const newUrl = `${window.location.pathname}${paramsString ? '?' + paramsString : ''}`
                window.history.replaceState({}, '', newUrl)
            }
        } else {
            this.error = 'No token provided'
        }
    }

    getOpps() {
        return this.api?.get('/salesforce/opportunities')
    }

    // add static method to get one instance in runtime
    static getInstance() {
        // check if instance is available
        if (!RfqPortalService.instance) {
            // if not, create new instance
            RfqPortalService.instance = new RfqPortalService()
        }

        // return instance
        return RfqPortalService.instance
    }
}

export const getIframeSWRFetcher = (url: string, params?: any) => {
    return RfqPortalService.getInstance()
        .api!.get(url, { params })
        .then((res) => res.data)
}

interface UserClaims {
    accountId?: string
    admin?: string
    contactId?: string
    id: number
}
export const claims = (): UserClaims | null => {
    const token = localStorage.getItem('sf_token')
    if (!token) {
        return null
    }
    try {
        return JSON.parse(atob(token.split('.')[1])) as UserClaims
    } catch (e) {
        return null
    }
}

export const defaultSWRConfig = {
    revalidateOnFocus: false,
}

export const useRFQs = (pagination: PaginationState, filters: any, projects = false) => {
    let baseQuery

    if (projects) {
        baseQuery = 'rfq/by-project'
    } else {
        baseQuery = '/rfq'
    }

    baseQuery += `?page=${pagination.pageIndex}&limit=${pagination.pageSize}`
    if (filters.start) {
        baseQuery += `&startDate=${filters.start.startOf('day').toDate()}`
    }
    if (filters.end) {
        baseQuery += `&endDate=${filters.end.endOf('day').toDate()}`
    }
    if (filters.user) {
        baseQuery += `&search=${filters.user}`
    }
    if (filters.status) {
        baseQuery += `&status=${filters.status}`
    }
    if (filters.payment !== undefined) {
        baseQuery += `&payment=${filters.payment}`
    }
    return useSWR(baseQuery, iframeSolution ? getIframeSWRFetcher : getSWRFetcher)
}
