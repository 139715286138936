import React, { FC, useEffect } from 'react'
import { postFetcher, Http, apiVersion, getFetcher } from '@shared/api/Http'
import { useParams, useNavigate } from 'react-router-dom'
import useSWR, { cache } from 'swr'
import InfoModal, { showInfoModal } from '@/parts/Modals/InfoModal'
import { useUser } from '@shared/api/user'
import { updateLoggedState, useUserState } from '@/store/user'
import { Loader } from '@/components/Loader'
import { getSWRFetcher, setHttpV2Token } from '@shared/apiv2/api'

export const ServiceEmailConfirm: FC = () => {
    const navigate = useNavigate()
    const { dispatch, state } = useUserState()
    // const { mutate, loading, data: uInfo } = useUser()
    const { token } = useParams<{ type: string; token: string }>()
    const { data, error } = useSWR<any>(() => (token ? `/auth/email-verification?token=${token}` : null), getSWRFetcher)

    const onClose = async () => {
        if (state.userInfo?.verified) {
            navigate(state.lastPathname || '/company')
        } else {
            navigate('/sign-up')
        }
    }

    useEffect(() => {
        if (data?.token) {
            const setup = async () => {
                if (!state.isLoggedIn) {
                    setHttpV2Token(data.token)
                }
                cache.clear()

                // await mutate(state?.userInfo, true)
                // await updateLoggedState(uInfo!, dispatch)

                showInfoModal({
                    props: {
                        title: 'Your email has been confirmed',
                        message: '',
                        onClose,
                    },
                })
            }
            setup()
        }

        if (error) {
            showInfoModal({
                props: {
                    title: 'Link has already been used',
                    message: '',
                    onClose,
                },
            })
        }
    }, [data, error])

    if (!data) {
        return <Loader />
    }
    return <></>
}
