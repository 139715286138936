import React, { Dispatch, SetStateAction } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { ManageStaffTableRecord } from './index'
import { PermissionLevel } from './index'
import Checkbox from '@/components/CheckboxNew'
import Select from '@/pages/RFQPortal/components/Select'

const columnHelper = createColumnHelper<ManageStaffTableRecord>()

const modalClass = 'manage-staff-modal'

const useManageStaffColumns = (setData: Dispatch<SetStateAction<ManageStaffTableRecord[]>>, isSuperAdmin: boolean) => {
    const changeVisibility = (rowId: string, isVisible: boolean) => {
        if (isVisible) {
            setData((currentData) =>
                currentData.map((item) =>
                    item.id === rowId ? { ...item, visibility: isVisible, permission: PermissionLevel.User } : item,
                ),
            )
        } else {
            setData((currentData) =>
                currentData.map((item) =>
                    item.id === rowId ? { ...item, visibility: isVisible, permission: '' } : item,
                ),
            )
        }
    }

    const changePermission = (rowId: string, permission: any) => {
        setData((currentData) =>
            currentData.map((item) =>
                item.id === rowId ? { ...item, permission: permission, visibility: true } : item,
            ),
        )
    }

    const mainColumns = [
        columnHelper.accessor('name', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Name</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '310px', maxWidth: '310px' },
            },
        }),
        columnHelper.accessor('location', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <p>Location</p>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '310px', maxWidth: '310px', marginBottom: '6px' },
            },
        }),
        columnHelper.accessor('visibility', {
            cell: (info) => {
                const row = info.row.original
                return (
                    <div className={`${modalClass}__visibility`}>
                        <Checkbox
                            checked={row.visibility}
                            onChange={(value) => changeVisibility(row.id, value)}
                            disabled={isSuperAdmin}
                        />
                    </div>
                )
            },
            header: () => <p>User Visibility</p>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '160px', maxWidth: '160px' },
            },
        }),
        columnHelper.accessor('permission', {
            cell: (info) => {
                const row = info.row.original

                return (
                    <Select
                        id={row.id}
                        name={`permission__${row.id}`}
                        value={row.permission ? PermissionLevel[row.permission as any] : ''}
                        options={Object.keys(PermissionLevel).filter((key) => isNaN(Number(key)))}
                        onChange={(e: any) => changePermission(row.id, PermissionLevel[e.target.value])}
                        isInitEmpty={false}
                        disabled={!row.permission || isSuperAdmin}
                    />
                )
            },
            header: () => <div>User Level</div>,
            enableSorting: false,
            meta: {
                cellStyle: { width: '120px', maxWidth: '120px' },
                cellClass: `${modalClass}__perm-cell cell`,
            },
        }),
    ]

    return mainColumns
}

export default useManageStaffColumns
