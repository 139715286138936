import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Input } from '@/shared/ui/Input'

export const ReworkFormComponent = ({ order, isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string) => {
        if (service?.pricings?.rework) {
            service!.pricings.rework.price = value
        }
    }

    return (
        <div className="w-full">
            <div className="flex gap-2 w-full">
                <Input
                    label={RfqServicePricingLabel[RfqServicePricing.Rework]}
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.rework?.price}
                    onChange={(e) => onChange(e.target.value)}
                />
                <div className="flex gap-2 items-center w-fit shrink-0">
                    <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                    <div className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                        {!isFloorLoaded ? (
                            <>
                                <span>Pallet</span>
                                <span className="hidden sm:inline">&nbsp;(including materials)</span>
                            </>
                        ) : (
                            order.unitType ?? 'Unit'
                        )}
                    </div>
                </div>
            </div>
            {errors?.[RfqServicePricing.Rework] ? (
                <div className="text-sm text-danger">{errors[RfqServicePricing.Rework]}</div>
            ) : null}
        </div>
    )
}
