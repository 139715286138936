import React, { FC } from 'react'
import HelpLinks from '@/parts/Cards/HelpLinks'

const AdminInvoices: FC = () => {
    return (
        <div className="app-page-container app-page-container_flex">
            <div className="app-page">
                <div className="app-card">
                    <h2>Invoices</h2>
                </div>
            </div>
            <div className="app-sidebar">
                <HelpLinks />
            </div>
        </div>
    )
}

export default AdminInvoices
