import React, { useEffect } from 'react'
import ChatBox from '@/components/Chat/Chat'
import useWebSocket from '@/pages/StandardQuote/useChats'

export const StandardQuote = () => {
    const { connected, sendMessage } = useWebSocket('ws://localhost:3001/chat')
    console.log(connected)
    useEffect(() => {
        sendMessage({ message: 'Hello' })
    }, [])
    return (
        // need grid (left and right cols)
        <div className="md:container mx-auto">
            <div className="grid grid-cols-8 gap-4">
                <div className="lg:col-span-5 col-span-8">Left 5 Columns</div>
                <div className="lg:col-span-3 col-span-8">
                    <ChatBox />
                </div>
            </div>
        </div>
    )
}
