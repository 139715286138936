import React, { useEffect } from 'react'
import HomeDashboard from '@/pages/Home/HomeDashboard'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { Navigate } from 'react-router-dom'

const Home = () => {
    const { dispatch, state } = useUserState()
    const targetAppUrl = localStorage.getItem('targetAppUrl')!

    if (state.isLoggedIn && state.userInfo) {
        if (!state.userInfo.verified && state.userInfo.sfContactId) {
            return <Navigate to={'/sign-up/verification'} />
        }
        if (targetAppUrl) {
            return <Navigate to={{ pathname: targetAppUrl }} />
        }
        return <Navigate to={state.userInfo?.role === UserRole.WAREHOUSE_MANAGER ? '/loadboard' : '/rfq'} />
    } else {
        return (
            <Navigate
                to={{
                    pathname: '/login',
                    search: window.location.search,
                }}
            />
        )
    }
}

export default Home
