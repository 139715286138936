import { createColumnHelper } from '@tanstack/react-table'
import React, { ChangeEvent, useState } from 'react'
import Select from '@/pages/RFQPortal/components/Select'
import dayjs from 'dayjs'
import { WarehouseOrderDto, RFQStatus, updateOrderStatus, WarehouseOrdersListResponse } from '@shared/apiv2/wh-orders'
import { toast } from 'react-toastify'
import { getEnumValueByKeyForRFQStatus } from '@/utils/getEnumValueByKey'
import { MutatorCallback } from 'swr/dist/types'
import { showInvoiceUploadModal } from '@/parts/Modals/InvoiceUploadModal'
import { Badge } from '@/components/Badge'
import { tcn } from '@/utils/tcn'
import { HttpV2 } from '@shared/apiv2/api'
import saveAs from 'file-saver'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { Button } from '@/shared/ui/Button'
import { useNavigate } from 'react-router-dom'
import ROUTES from '@/router'

const STATUS_OPTIONS = {
    [RFQStatus.Booked]: RFQStatus[RFQStatus.Booked],
    [RFQStatus.Dropped]: RFQStatus[RFQStatus.Dropped],
    [RFQStatus.Finished]: RFQStatus[RFQStatus.Finished],
}

const columnHelper = createColumnHelper<WarehouseOrderDto>()

type TMutate = (
    data?:
        | WarehouseOrdersListResponse
        | Promise<WarehouseOrdersListResponse>
        | MutatorCallback<WarehouseOrdersListResponse>,
    shouldRevalidate?: boolean,
) => Promise<WarehouseOrdersListResponse | undefined>

export const getOrdersColumns = (warehouseId: string | undefined, mutate: TMutate) => {
    const columns = [
        columnHelper.accessor('key', {
            header: 'Order #',
            cell: ({ row }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const navigate = useNavigate()

                return (
                    <Button variant="plain" onClick={() => navigate(`${ROUTES.order.base}/${row.getValue('key')}`)}>
                        {row.getValue('key')}
                    </Button>
                )
            },
        }),
        columnHelper.accessor('totalBuyCost', {
            header: 'Total',
            cell: ({ row }) => {
                return <div>${row.getValue('totalBuyCost')}</div>
            },
        }),
        columnHelper.accessor('sfCreatedAt', {
            header: 'Order Date',
            cell: ({ row }) => {
                return <div>{dayjs.utc(row.original.sfCreatedAt).format('MM/DD/YYYY')}</div>
            },
        }),
        columnHelper.accessor('closeDate', {
            header: 'Completion Date',
            cell: ({ row }) => {
                if (!row.original.closeDate) return null
                return <div>{dayjs.utc(row.original.closeDate).format('MM/DD/YYYY')}</div>
            },
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: ({ row }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [isLoading, setIsLoading] = useState(false)

                const changeStatus = async (status: RFQStatus.Booked | RFQStatus.Dropped | RFQStatus.Finished) => {
                    setIsLoading(true)

                    try {
                        await updateOrderStatus(warehouseId!, row.original.key, status)

                        await mutate((data) => {
                            const orders = data?.data
                            if (orders) {
                                const newOrders = orders.map((order) =>
                                    order.key === row.original.key
                                        ? {
                                              ...order,
                                              status: status,
                                          }
                                        : order,
                                )

                                return { data: newOrders, count: data.count }
                            }
                        }, false)

                        toast(`Status has been changed to ${RFQStatus[status]}.`, { type: 'success' })
                    } catch (error: any) {
                        toast(error.response?.data?.message ?? 'Could not update order status', { type: 'error' })
                    } finally {
                        setIsLoading(false)
                    }
                }

                const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
                    if (!warehouseId) return

                    const status = getEnumValueByKeyForRFQStatus(e.target.value)
                    if (status === RFQStatus.Booked || status === RFQStatus.Dropped || status === RFQStatus.Finished) {
                        changeStatus(status)
                    }
                }

                const onUndoClick = async () => {
                    if (!warehouseId) return
                    const status = RFQStatus.Dropped
                    changeStatus(status)
                }

                const onInvoiceClick = () => {
                    if (warehouseId)
                        showInvoiceUploadModal({
                            props: {
                                warehouseId,
                                orderKey: row.original.key,
                                mutate,
                                setIsLoading: setIsLoading,
                            },
                        })
                }

                const onViewInvoice = () => {
                    if (!row.original.invoice?.documentId) return

                    setIsLoading(true)
                    HttpV2.get(`/accounts/${warehouseId}/documents/${row.original.invoice.documentId}`, {
                        responseType: 'blob',
                    })
                        .then((data) => {
                            // get content disposition
                            const contentDisposition = data?.headers['content-disposition']
                            const filename = contentDisposition?.split(';')[1].split('=')[1].trim()
                            saveAs(data?.data, filename)
                            toast('File successfully downloaded', { type: 'success' })
                        })
                        .catch((err) => {
                            console.log('Error download file:', err)
                            toast.error('Could not download the file.')
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                }

                /** If invoice is uploaded, show this */
                if (row.original.invoice?.id) {
                    const invoiceStatus = row.original.invoice.status

                    return (
                        <span className="flex items-center">
                            <Badge
                                className={tcn(
                                    invoiceStatus === 'Confirmed' && '!bg-success',
                                    invoiceStatus === 'Pending' && '!bg-warning',
                                    invoiceStatus === 'Rejected' && '!bg-danger',
                                )}
                            >
                                {invoiceStatus === 'Confirmed' ? 'Invoice Sent' : null}
                                {invoiceStatus === 'Pending' ? 'Pending Invoice' : null}
                                {invoiceStatus === 'Rejected' ? 'Invoice Rejected' : null}
                            </Badge>
                            <Button variant="link" disabled={isLoading} onClick={onViewInvoice}>
                                View
                            </Button>
                            {isLoading ? <ImSpinner8 size={16} className="animate-spin" /> : null}
                        </span>
                    )
                }

                /** If status is "Finished", show buttons */
                if (row.original.status === RFQStatus.Finished) {
                    return (
                        <div className="w-30 flex gap-2 items-center">
                            <Button disabled={isLoading} onClick={onInvoiceClick} className="!w-1/2 shrink-0">
                                Invoice
                            </Button>
                            <Button
                                variant="link"
                                className="!font-normal text-primary underline justify-start"
                                disabled={isLoading}
                                onClick={onUndoClick}
                            >
                                Undo
                            </Button>
                            <ImSpinner8 size={16} className={tcn('opacity-0', isLoading && 'opacity-1 animate-spin')} />
                        </div>
                    )
                }

                /** If status other than "Finished" and invoice is not uploaded,
                 *  show Select with statuses */
                return (
                    <div className="w-30 flex gap-3 items-center">
                        <Select
                            disabled={isLoading || row.original.status === RFQStatus.Cancelled}
                            id="orderStatusSelect"
                            isInitEmpty={false}
                            options={STATUS_OPTIONS}
                            value={RFQStatus[row.original.status]}
                            onChange={onSelectChange}
                            wrapperClassName="flex-grow"
                        />
                        {isLoading ? <ImSpinner8 size={16} className="animate-spin" /> : null}
                    </div>
                )
            },
        }),
    ]

    return columns
}
