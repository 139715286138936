enum Services {
    Transloading = 'transloading',
    Warehousing = 'warehousing',
    Drayage = 'drayage',
}

enum Certifications {
    Bonded = 'bonded',
    Hazmat = 'hazmat',
    FDA = 'fda',
}

export enum Equipment {
    ClampTruck = 'clamp-truck',
    SlipSheet = 'slip-sheet',
    Forklift = 'forklift',
}

export enum Features {
    GatedYard = 'gatedyard',
    Ramp = 'ramp',
    Security = 'security',
    Sprinkler = 'sprinkler',
}

interface ILoadBoardCheckboxNameType {
    services: Record<Services, string>
    certifications: Record<Certifications, string>
    equipment: Record<Equipment, string>
    features: Record<Features, string>
}

export enum LoadBoardCheckboxType {
    certifications = 'certifications',
    equipment = 'equipment',
    features = 'features',
}

// TODO @Mario please review all values coming from API, try to create from RFQ 2.0 first
// TODO @Mario please review filters panel - values we use

export const LoadBoardCheckboxName: ILoadBoardCheckboxNameType = {
    services: {
        [Services.Transloading]: 'transloading',
        [Services.Warehousing]: 'warehousing',
        [Services.Drayage]: 'drayage',
    },
    certifications: {
        [Certifications.Bonded]: 'bonded',
        [Certifications.Hazmat]: 'hazmat',
        [Certifications.FDA]: 'fda',
    },
    equipment: {
        [Equipment.ClampTruck]: 'clamp truck forklift',
        [Equipment.SlipSheet]: 'slip sheet forklift',
        [Equipment.Forklift]: 'forklift',
    },
    features: {
        [Features.GatedYard]: 'gated yard',
        [Features.Ramp]: 'ramp',
        [Features.Security]: 'on-site security',
        [Features.Sprinkler]: 'sprinkler system',
    },
}

export const useLoadBoardNames = () => {
    const convertCheckboxName = (value: string, name: LoadBoardCheckboxType) => {
        return value
            .split(/[,;]/)
            .map((elem: string) => (LoadBoardCheckboxName[name] as Record<string, string>)[elem])
            .join('\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0')
    }

    return { convertCheckboxName }
}

export const stringPointToXY = (point: string) => {
    if (!point) return null
    const coordinates = point.match(/\(([^)]+)\)/)![1]

    // Split coordinates into longitude and latitude
    const [longitude, latitude] = coordinates.split(' ').map(parseFloat)
    return {
        x: longitude,
        y: latitude,
    }
}

export const createFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer()
    files.forEach((file) => dataTransfer.items.add(file))
    return dataTransfer.files
}

// export function getEnumKeyByValue(object: any, value: string | number): string | undefined {
//     return Object.keys(object).find((key) => object[key] === value)
// }

export function getEnumKeyByValue<T extends object>(object: T, value: string | number): keyof T | undefined {
    return Object.keys(object).find((key) => object[key as keyof T] === value) as keyof T | undefined
}
