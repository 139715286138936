import React, { useEffect, useState } from 'react'
import { flexRender } from '@tanstack/react-table'
import useIsMobile from '@/components/ModernTable/useIsMobile'
import cn from 'classnames'

import { rfqColumns } from '@/pages/RFQPortal/rfq.columns'
import { ModernTable } from './ModernTable'

const { columns: columns_subTable, expandedColumns: expandedColumns_subTable } = rfqColumns

const tableRow = 'olimp-table__table-row'

export const ModernTableRow = ({ row, onRowClick, expandedColumns, columns }: any) => {
    const isMobile = useIsMobile()

    const [expandedSubTable, setExpandedSubTable] = useState(false)

    return (
        <React.Fragment key={row.id}>
            <tr
                className={`${tableRow}`}
                onClick={(event) => {
                    if (row.getCanExpand()) {
                        row.toggleExpanded()
                    }
                    if (onRowClick) {
                        onRowClick(event, row.original)
                    }
                }}
                style={{ cursor: row.getCanExpand() || onRowClick ? 'pointer' : 'default' }}
            >
                {row.getVisibleCells().map((cell: any) => {
                    const cellMaxWidth = cell.column.columnDef.cellStyle?.maxWidth
                    const metaMaxWidth = cell.column.columnDef.meta?.cellStyle?.maxWidth

                    const cellWidth = cell.column.columnDef.cellStyle?.width
                    const metaWidth = cell.column.columnDef.meta?.cellStyle?.width

                    return (
                        <td
                            style={{
                                maxWidth: isMobile ? '100%' : cellMaxWidth ? cellMaxWidth : metaMaxWidth,
                                width: isMobile ? '100%' : cellWidth ? cellWidth : metaWidth,
                                ...cell.column.columnDef.meta?.cellStyle,
                            }}
                            key={cell.id}
                            className={cn(
                                'olimp-table__table-cell',
                                // cn({ [`${olimpTable}__column-name_mobile`]: isMobile && })
                                // {
                                //     'text-truncate':
                                //         cell.column.columnDef.id !== 'actions' &&
                                //         cell.column.columnDef.accessorKey !== 'invoice',
                                // },
                                cell.column.columnDef.meta?.cellClass ?? '',
                            )}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    )
                })}
            </tr>
            {expandedColumns && row.getIsExpanded() && !row.original.projectRfqs ? (
                <tr className={`${tableRow}-expanded`}>
                    <td colSpan={columns.length}>
                        {/* Render the nested columns */}
                        <table>
                            <thead>
                                <tr>
                                    {expandedColumns.map((column: any) => (
                                        <th key={column.cellName} style={column.headerStyle}>
                                            {column.cellHeader}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {row.subRows.map((subRow: any) => (
                                    <tr key={subRow.id}>
                                        {expandedColumns.map((column: any, idx: number) => {
                                            const maxWidth = column.cellStyle?.maxWidth
                                            const width = column.cellStyle?.width
                                            const colCount = expandedColumns?.length

                                            return (
                                                <td
                                                    style={{
                                                        maxWidth: isMobile
                                                            ? '100%'
                                                            : maxWidth
                                                            ? maxWidth
                                                            : `${100 / colCount}%`,
                                                        width: isMobile ? '100%' : width ? width : `${100 / colCount}%`,
                                                    }}
                                                    key={idx}
                                                >
                                                    <div className={cn('olimp-table__table-cell-expanded')}>
                                                        {column.cell(subRow.original)}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </td>
                </tr>
            ) : null}
            {row.getIsExpanded() && row.original.projectRfqs && (
                <tr className={`${tableRow}-table`}>
                    <td colSpan={columns.length} className={`${tableRow}-table-td`}>
                        <ModernTable
                            data={row.original.projectRfqs}
                            columns={columns_subTable}
                            expandedColumns={expandedColumns_subTable}
                            expanded={expandedSubTable}
                            setExpanded={setExpandedSubTable}
                        />
                    </td>
                </tr>
            )}
        </React.Fragment>
    )
}
