import React, { FC, KeyboardEvent, Ref, useState } from 'react'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'
import cn from 'classnames'

import '@/pages/RFQPortal/assets/style/rfq-form.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id?: string
    label: string
    errorMessage?: string | false
    wrapperRef?: Ref<HTMLDivElement>
    wrapperClassName?: string
    value?: string
}

const Input: FC<InputProps> = ({ id, label, errorMessage, wrapperRef, value = '', wrapperClassName, ...rest }) => {
    const [inputId] = useState(id || generateRandomId())
    const checkIfError = errorMessage ? 'input-error' : ''

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (rest.type === 'number' && ['e', 'E', '+', '-', '.'].includes(event.key)) {
            event.preventDefault()
        }
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (typeof rest.onBlur === 'function') {
            rest.onBlur(e)
        }
    }

    return (
        <div className={cn(`input-wrapper ${wrapperClassName}`)} ref={wrapperRef}>
            <label className="select-label" htmlFor={inputId}>
                {label}
            </label>
            <input
                {...rest}
                id={inputId}
                className={checkIfError}
                value={value || ''}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
            />
            {errorMessage && <span className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }}></span>}
        </div>
    )
}

export default Input
