import pdf from '@/pages/RFQPortal/assets/img/PDF.png'
import docIcon from '@/pages/RFQPortal/assets/img/DOC.png'
import docxIcon from '@/pages/RFQPortal/assets/img/DOCX.png'
import xls from '@/pages/RFQPortal/assets/img/XLS.png'
import xlsx from '@/pages/RFQPortal/assets/img/XLSX.png'
import ppt from '@/pages/RFQPortal/assets/img/PPT.png'
import pptx from '@/pages/RFQPortal/assets/img/PPTX.png'

export const mediaImageExtensions = ['png', 'jpg', 'jpeg']

export const mediaDocumentsExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'pptx', 'ppt']

export const mediaDocumentIcons: { [key: string]: File | undefined } = {
    pdf: pdf,
    doc: docIcon,
    docx: docxIcon,
    xls: xls,
    xlsx: xlsx,
    ppt: ppt,
    pptx: pptx,
}
