import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './RFQListMenu.scss'

const parentClass = 'rfq-header'
const rfqListClass = 'rfq-list-menu'

const RFQListMenu = () => {
    const location = useLocation()

    return (
        <nav className={`${parentClass}__menu ${rfqListClass}`}>
            <ul className={`${parentClass}__menu-list`}>
                <li className={`${parentClass}__menu-item ${location.pathname.endsWith('/rfq/list') ? 'active' : ''}`}>
                    <Link to="/rfq/list" className={`${parentClass}__menu-item-link`}>
                        On Demand
                    </Link>
                </li>
                <li
                    className={`${parentClass}__menu-item ${
                        location.pathname.endsWith('/rfq/list/projects') ? 'active' : ''
                    }`}
                >
                    <Link to={'/rfq/list/projects'} className={`${parentClass}__menu-item-link`}>
                        Projects
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default RFQListMenu
