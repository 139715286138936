import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Input } from '@/shared/ui/Input'
import { Button } from '@/shared/ui/Button'
import { ContentState, EditorState } from 'draft-js'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { quotesApi } from '@/entities/quotes/api'
import { Account } from '@/entities/accounts/model'
import { toast } from 'react-toastify'
import {
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/shared/ui/AlertDialog'
import { cn } from '@/shared/utils/cn'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { Quote, QuoteType } from '@/entities/quotes/model'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { setEditMode, setSelectedQuote } from '../model'
import { orderPageActions } from '@/pages/Order/model'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

type OrderQuoteAllInSubmitProps = {
    mode?: undefined | 'submit'
    submittedQuote?: never
}

type OrderQuoteAllInEditProps = {
    mode: 'edit'
    submittedQuote: Quote
}

type OrderQuoteAllInProps = OrderQuoteAllInSubmitProps | OrderQuoteAllInEditProps

type AllInQuoteForm = {
    editor: EditorState
    total: string
}

const AllInQuoteSchema = Yup.object().shape({
    total: Yup.number()
        .typeError('Total can be a number only.')
        .positive('Total can be a positive number only.')
        .required('Total cannot be empty.'),
    editor: Yup.object().test('test-editor', 'Please add a note for your quote.', (editor) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const editorState = editor as EditorState
        const quoteText = editorState.getCurrentContent().getPlainText()
        return !!quoteText
    }),
})

const toolbarOptions = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
    },
}

export const OrderQuoteAllIn = ({ mode, submittedQuote }: OrderQuoteAllInProps) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const warehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse) as Account

    /** Form data. Contain fields:
     *  - editor - current state of editor
     *  - total - current entered total price
     *  Does not validate inputs until first submission is attempted
     *  */
    const [submissionAttempted, setSubmissionAttempted] = useState(false)

    const form = useFormik<AllInQuoteForm>({
        /** Set editor and total values from quote if they are available. */
        initialValues: {
            editor:
                mode === 'edit' && submittedQuote.notes
                    ? EditorState.createWithContent(ContentState.createFromText(submittedQuote.notes))
                    : EditorState.createEmpty(),
            total: mode === 'edit' && submittedQuote ? String(submittedQuote.price) : '',
        },
        validationSchema: AllInQuoteSchema,
        validateOnChange: submissionAttempted,
        onSubmit: async (values) => {
            try {
                const notes = values.editor.getCurrentContent().getPlainText()
                const numericTotal = +values.total

                let response

                /** If editing, update existing quote */
                if (mode === 'edit') {
                    const newQuote = {
                        orderKey: order.id,
                        quoteId: submittedQuote.id,
                        notes: notes,
                        price: numericTotal,
                    }
                    response = await quotesApi.updateQuote(newQuote)
                    sendCustomEvent('all_in_quote_updated', newQuote)

                    dispatch(orderPageActions.setQuote({ quoteId: submittedQuote.id, quote: response.data }))
                    dispatch(setEditMode(false))
                } else {
                    /** If submitting, create new quote */
                    const newQuote = {
                        orderKey: order.id,
                        warehouseId: warehouse.id,
                        notes: notes,
                        price: numericTotal,
                        type: QuoteType.ALLIN,
                    }

                    response = await quotesApi.submitQuote(newQuote)

                    dispatch(orderPageActions.addQuote(response.data))
                    sendCustomEvent('all_in_quote_created', newQuote)
                }

                dispatch(setSelectedQuote(response.data))

                toast(`Your quote is successfully ${mode === 'edit' ? 'updated' : 'submitted'}.`, { type: 'success' })

                setConfirmDialogOpen(false)
            } catch (error: any) {
                console.error(error)
                let errorMessage = ''
                if (error.response?.data?.message) {
                    errorMessage = error.response.data.message
                } else {
                    errorMessage = `We could not ${mode === 'edit' ? 'update' : 'submit'} your bid. We are sorry!`
                }

                toast(errorMessage, {
                    type: 'error',
                })
            }
        },
    })

    /** Confirm dialog controlled state */
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const onConfirmDialogOpenChange = async (open: boolean) => {
        /** If closing, just close. */
        if (!open) {
            setConfirmDialogOpen(open)
            return
        }

        /** If opening, set submissionAttempted and validate form. If no errors in form, open dialog. */
        setSubmissionAttempted(true)
        const validateResult = await form.validateForm()
        if (Object.keys(validateResult).length <= 0) {
            setConfirmDialogOpen(open)
        }
    }

    return (
        <form onSubmit={form.handleSubmit}>
            <Editor
                editorState={form.values.editor}
                onEditorStateChange={(editorState) => {
                    form.setFieldValue('editor', editorState)
                }}
                wrapperClassName={cn(
                    'border border-border rounded-md bg-background-secondary',
                    form.errors.editor && 'border-danger',
                )}
                toolbarClassName="!min-h-[56px] !border-0 !border-b !border-border !rounded-none !rounded-t-md overflow-visible"
                editorClassName="!h-[224px] px-4 py-3 cursor [&_.public-DraftStyleDefault-block]:!m-0 *:!h-auto"
                editorStyle={{
                    wordBreak: 'break-word',
                }}
                toolbar={toolbarOptions}
            />
            {form.errors.editor ? <div className="mt-1 text-sm text-danger">{form.errors.editor}</div> : null}

            <div className="mt-5 flex justify-end gap-3 flex-col sm:flex-row">
                <div className="flex-grow sm:max-w-[360px]">
                    <Input
                        label="Estimated Total"
                        id="total"
                        name="total"
                        value={form.values.total}
                        onChange={form.handleChange}
                        className={cn(form.errors.total && 'border-danger')}
                        labelClassName={cn(form.errors.total && 'text-danger')}
                    />
                    {form.errors.total ? <div className="mt-1 text-sm text-danger">{form.errors.total}</div> : null}
                </div>

                <div className="flex gap-3">
                    {mode === 'edit' ? (
                        <Button
                            variant="outlined"
                            type="button"
                            onClick={() => dispatch(setEditMode(false))}
                            className="basis-0 flex-grow sm:basis-auto sm:flex-grow-0"
                        >
                            Cancel
                        </Button>
                    ) : null}

                    {/** Modal with confirmation for quote submission. Can be opened only if fields are validated correctly */}
                    <AlertDialogRoot open={confirmDialogOpen} onOpenChange={onConfirmDialogOpenChange}>
                        <AlertDialogTrigger asChild>
                            <Button
                                type="button"
                                disabled={form.isSubmitting}
                                className="basis-0 flex-grow sm:basis-auto sm:flex-grow-0"
                            >
                                {mode === 'edit' ? 'Update ' : 'Submit '}
                                <span className="hidden sm:block">&nbsp;Quote</span>
                                {form.isSubmitting ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent hideCloseButton={form.isSubmitting}>
                            <AlertDialogHeader>
                                <AlertDialogTitle>{mode === 'edit' ? 'Update ' : 'Confirm '}Bid</AlertDialogTitle>
                            </AlertDialogHeader>
                            <div className="bg-background-tertiary px-3 py-2 rounded-lg space-y-3">
                                <div className="font-normal">
                                    Total:&nbsp;
                                    <span className="font-semibold text-primary text-[20px]">
                                        ${Number(form.values.total).toLocaleString('en')}
                                    </span>
                                </div>
                            </div>
                            <AlertDialogFooter>
                                <AlertDialogCancel type="button" disabled={form.isSubmitting}>
                                    Cancel
                                </AlertDialogCancel>
                                <Button onClick={form.submitForm} disabled={form.isSubmitting}>
                                    {mode === 'edit' ? 'Update' : 'Confirm'}
                                    {form.isSubmitting ? <ImSpinner8 className="ml-2 animate-spin" /> : null}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogRoot>
                </div>
            </div>
        </form>
    )
}
