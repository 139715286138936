import React, { FC, memo, useCallback, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import TagsInput from 'react-tagsinput'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import { toast } from 'react-toastify'
import { ReactComponent as CopyIcon } from '@/assets/images/copy-icon.svg'

import './AddStaff.scss'

interface AddStaffModalProps {}

const modalClass = 'add-staff-modal'

const AddStaffModal: FC<AddStaffModalProps> = () => {
    const location = useLocation()
    const warehouseAcc = location.pathname.indexOf('admin') >= 0 ? '' : location.pathname.split('/')[2]

    const teamMembersRef = useRef<TagsInput | null>(null)

    const [invitationEmails, setInvitationEmails] = useState<string[]>([])
    const [invitationEmailsError, setInvitationEmailsError] = useState<string>('')

    const [isPostingInvites, setIsPostingInvites] = useState<boolean>(false)
    const [invitesRespError, setInvitesRespError] = useState<string>('')

    const [invitesLink, setInvitesLink] = useState<string>('')

    const postInvites = async () => {
        setInvitesRespError('')

        const payload = { emails: invitationEmails, accountId: warehouseAcc }

        try {
            setIsPostingInvites(true)
            await HttpV2.post('accounts/invites', payload)
            toast('Hooray! Invitation has been sent.', { type: 'success' })

            ModalRegistry.get().close(Modals.AddStaffModal)
        } catch (error: any) {
            setInvitesRespError(error.response?.data?.emails)
        } finally {
            setIsPostingInvites(false)
        }
    }

    const handleInvite = () => {
        const validation = validateTeammemberEmails()

        if (validation) {
            postInvites()
        }
    }

    const validateTeammemberEmails = (): boolean => {
        setInvitationEmailsError('')

        if (invitationEmails.length === 0) {
            setInvitationEmailsError('Please enter at least one email address to invite a team member.')
            return false
        }

        return true
    }

    const copyToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text)
            toast('The link copied to clipboard', { type: 'success' })
        } catch (error) {
            toast('Failed to copy the link', { type: 'warning' })
        }
    }

    const onCoseFunc = useCallback(() => {
        ModalRegistry.get().close(Modals.AddStaffModal)
    }, [])

    useEffect(() => {
        const getInvitesLink = async () => {
            try {
                const { data } = await HttpV2.get(`accounts/invites/link?accountId=${warehouseAcc}`)
                setInvitesLink(data)
            } catch (error) {
                console.error('Error fetching invites link: ', error)
            }
        }
        getInvitesLink()
    }, [])

    return (
        <div className={`${modalClass}`}>
            <p>Invite team members to your org (You can add multiple emails)</p>
            <div className={`${modalClass}__email-wrapper`}>
                <p className={`${modalClass}__email-label`}>Email(s)</p>
                <TagsInput
                    value={invitationEmails || []}
                    ref={teamMembersRef}
                    onChange={(addedStaff) => setInvitationEmails(addedStaff)}
                    inputProps={{
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
                                e.preventDefault()

                                //Make accept of email on '', ',' and 'Enter'
                                if (teamMembersRef.current) {
                                    teamMembersRef.current.accept()
                                }
                            }
                        },
                        className: 'react-tagsinput-input', //Default
                        placeholder: 'Add emails',
                        onBlur: () => validateTeammemberEmails(),
                    }}
                    tagProps={{
                        className: 'react-tagsinput-tag', //Default
                        classNameRemove: 'react-tagsinput-remove', //Default
                    }}
                    validationRegex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
                    onlyUnique
                    addOnBlur
                    addOnPaste
                    pasteSplit={(data: string) => data.split(/[,\s]+/)} // Splits the pasted content using a regular expression that considers both commas and whitespace as delimiters.
                    renderTag={({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }) => {
                        if (tag && getTagDisplayValue(tag).trim() !== '') {
                            return (
                                <div key={key} {...other}>
                                    <span className="tag-text">{getTagDisplayValue(tag)}</span>
                                    {!disabled && (
                                        <button
                                            type="button"
                                            className={classNameRemove}
                                            onClick={(e) => onRemove(key)}
                                        >
                                            X
                                        </button>
                                    )}
                                </div>
                            )
                        }
                        // If the tag is empty, you can return null or some fallback UI.
                        return null
                    }}
                />
                {invitationEmailsError && (
                    <p className={`error-message ${modalClass}__error-message`}>{invitationEmailsError}</p>
                )}
            </div>
            <div className={`${modalClass}__buttons`}>
                <Button label="Cancel" type="submit" types={['bordered']} onClick={onCoseFunc} />
                <Button
                    className={`${modalClass}__invite-btn`}
                    label="Invite"
                    type="button"
                    loading={isPostingInvites}
                    types={['blue']}
                    disabled={isPostingInvites}
                    onClick={handleInvite}
                />
                {invitesRespError && (
                    <p className={`error-message ${modalClass}__invite-btn-error`}>{invitesRespError}</p>
                )}
            </div>
            <div className={`${modalClass}__invite-link-section`}>
                <p className={`${modalClass}__invite-link-label`}>Invite Via Link</p>
                <div className={`${modalClass}__invite-link-wrapper`}>
                    <p className={`${modalClass}__invite-link-scroll`}>
                        <a href={invitesLink} className={`${modalClass}__invite-link`}>
                            {invitesLink}
                        </a>
                    </p>
                    {invitesLink && (
                        <CopyIcon
                            className={`${modalClass}__coppy-icon`}
                            onClick={() => copyToClipboard(invitesLink)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

ModalRegistry.get().register<AddStaffModalProps>(Modals.AddStaffModal, {
    id: 'AddStaffModal',
    className: 'add-staff-modal ',
    size: 'huge',
    Component: memo(AddStaffModal),
    title: 'Add Staff',
})

export const showAddStaffModal = (props: ModalPartialProps<AddStaffModalProps>): void =>
    ModalRegistry.get().show<AddStaffModalProps>(Modals.AddStaffModal, props)

export default memo(AddStaffModal)
