import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DropzoneFile } from 'dropzone'
import * as Yup from 'yup'
import { HttpV2 } from '@shared/apiv2/api'

const useRfqValidationSchema = (phoneObject: any) => {
    Yup.addMethod(Yup.string, 'trim', function () {
        return this.transform((value) => (typeof value === 'string' ? value.trim() : value))
    })

    return Yup.object({
        serviceLocation: Yup.object({
            formattedAddress: Yup.string().required('Type and select a location'),
        }),
        commodity: Yup.string().max(255, 'Max 255 characters'),
        serviceDate: Yup.object({
            start: Yup.string().required('Field is required'),
            end: Yup.string()
                .notRequired()
                .test('is-after-start', 'End date must not precede start date', function (value) {
                    return validateDates(this.parent.start, value)
                }),
        }),
        servicesNeeded: Yup.array().required().min(1, 'Select at least one option'),
        freightEnvironment: Yup.string().required('Select an option'),
        certifications: Yup.array(),
        unitType: Yup.string().required('Field is required'),
        numberOfUnits: Yup.number().typeError('Must be a number').required('Field is required').min(1),
        company: Yup.string().required('Field is required'),
        firstName: Yup.string().required('Field is required'),
        lastName: Yup.string().required('Field is required'),
        phone: Yup.string()
            .test('object-undefined', 'Error! Please contact support.', () => {
                return !!phoneObject
            })
            .test('required', 'Field is required', () => {
                return phoneObject && phoneObject.getNumber().trim() !== ''
            })
            .test('not-valid', 'Please enter a valid phone number', () => {
                return phoneObject && phoneObject.isValidNumber() && phoneObject.getNumber().trim() !== ''
            }),
        phoneExtention: Yup.string().max(4, 'Max 4 characters'),
        email: Yup.string().required('Field is required').email('Please enter a valid email address'),
        files: Yup.array().test('valid-file', 'Please ensure your files meet the upload criteria', (files) => {
            return files ? files.every((file: DropzoneFile) => file.accepted !== false) : true
        }),
    })
}

export const isCorporateEmail = async (email: string | undefined): Promise<boolean> => {
    if (!email) {
        return false
    }

    const { data } = await HttpV2.get(`/salesforce/ncd/${email}/check`)
    return data === false
}

function validateDates(start?: string, value?: string): boolean {
    if (!start || !value) {
        return true
    }

    const startDate = new Date(start)
    const endDate = new Date(value)

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false
    }

    return endDate >= startDate
}

export default useRfqValidationSchema
