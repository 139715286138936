import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Account } from '@/entities/accounts/model'
import { Quote } from '@/entities/quotes/model'
import { COIStatus } from '@/entities/accounts/model/enums'

export type OrderQuoteSupplySlice = {
    quoteType: 'standard' | 'allIn'
    isEditMode: boolean
    warehouses: Account[] | null
    selectedWarehouse: Account | null
    selectedQuote: Quote | null
}

const initialState: OrderQuoteSupplySlice = {
    quoteType: 'allIn',
    isEditMode: false,
    warehouses: null,
    selectedWarehouse: null,
    selectedQuote: null,
}

export const orderQuoteSupplySlice = createSlice({
    name: 'orderQuoteSupply',
    initialState,
    reducers: {
        revertToInitial: () => {
            return { ...initialState }
        },
        setQuoteType: (state, action: PayloadAction<string>) => {
            state.quoteType = action.payload as 'standard' | 'allIn'
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            state.isEditMode = action.payload
        },
        setWarehouses: (state, action: PayloadAction<Account[]>) => {
            state.warehouses = action.payload
        },
        setSelectedWarehouse: (state, action: PayloadAction<string>) => {
            const warehouse = state.warehouses?.find((wh) => wh.id === action.payload)
            if (warehouse) {
                state.selectedWarehouse = warehouse
            }
        },
        setWarehouseCOIStatus: (state, action: PayloadAction<{ id: string; status: COIStatus | null }>) => {
            const warehouse = state.warehouses?.find((wh) => wh.id === action.payload.id)
            if (warehouse) {
                warehouse.coiStatus = action.payload.status

                if (state.selectedWarehouse?.id === warehouse.id) {
                    state.selectedWarehouse.coiStatus = action.payload.status
                }
            }
        },
        setSelectedQuote: (state, action: PayloadAction<Quote | null>) => {
            state.selectedQuote = action.payload
        },
    },
})

export const orderQuoteSupplyActions = orderQuoteSupplySlice.actions

export const {
    revertToInitial,
    setQuoteType,
    setWarehouses,
    setEditMode,
    setSelectedQuote,
    setSelectedWarehouse,
    setWarehouseCOIStatus,
} = orderQuoteSupplySlice.actions

export const orderQuoteSupplyReducer = orderQuoteSupplySlice.reducer
