import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationDto } from '@/entities/notifications/model/dto'

export type NotificationSlice = {
    unreadNotifications: number
    notifications: NotificationDto[]
    totalNotifications: number
    notificationsLoading: boolean
    hasMoreNotifications: boolean
}

const initialState: NotificationSlice = {
    unreadNotifications: 0,
    notifications: [],
    totalNotifications: 0,
    notificationsLoading: false,
    hasMoreNotifications: true,
}

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setUnreadNotifications(state, action: PayloadAction<number>) {
            state.unreadNotifications = action.payload
        },
        setNotifications(state, action: PayloadAction<NotificationDto[]>) {
            if (state.notifications.length === 0) {
                state.notifications = action.payload
            } else {
                state.notifications = [...state.notifications, ...action.payload]
            }
        },
        markAllAsRead(state) {
            state.notifications = state.notifications.map((notification) => {
                return {
                    ...notification,
                    read: true,
                }
            })
            state.unreadNotifications = 0
        },
        markAsRead(state, action: PayloadAction<number>) {
            state.notifications = state.notifications.map((notification) => {
                if (notification.id === action.payload) {
                    return {
                        ...notification,
                        read: true,
                    }
                }
                return notification
            })
            state.unreadNotifications = state.unreadNotifications - 1
        },
        addNotification(state, action: PayloadAction<NotificationDto>) {
            state.notifications = [action.payload, ...state.notifications]
            state.unreadNotifications = state.unreadNotifications + 1
        },
        setTotalNotifications(state, action: PayloadAction<number>) {
            state.totalNotifications = action.payload
        },
        setNotificationsLoading(state, action: PayloadAction<boolean>) {
            state.notificationsLoading = action.payload
        },
        setHasMoreNotifications(state, action: PayloadAction<boolean>) {
            state.hasMoreNotifications = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const notificationsActions = notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer
