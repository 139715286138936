import React from 'react'

import './ProgressBar.scss'

/**
 *
 * @description ProgressBar set Progress bar line for load board steps
 * @param percent type number
 * @returns component JSX progress bar
 */
export const ProgressBar = ({ percent }: { percent: number }) => {
    return (
        <div className="progress-bar">
            <div className="progress" style={{ width: `${percent}%`, backgroundColor: '#00aaff' }}></div>
        </div>
    )
}
