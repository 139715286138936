import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExpandedState, SortingState } from '@tanstack/react-table'
import { LoadBoardPostDto } from '@shared/apiv2/load-board'
import { PaginationState } from '@tanstack/table-core/build/lib/features/Pagination'
import { OnChangeFn } from '@tanstack/table-core/build/lib/types'

import { ModernTable } from '@/components/ModernTable/ModernTable'
import './LoadBoardList.scss'
import { loadBoardColumns } from './loadBoard.columns'
import useMobileScreen from '@/hooks/useMobileScreen'
import ROUTES from '@/router'

type LoadBoardListType = {
    sorting?: SortingState
    onSortingChange?: (sorting: SortingState) => void
    data?: LoadBoardPostDto[]
    error?: any
    total?: number
    showDistance?: boolean
    onPaginationChange: OnChangeFn<PaginationState>
    pagination: PaginationState
    tabValue?: string | number | undefined
}

export const LoadBoardList: FC<LoadBoardListType> = ({
    sorting,
    onSortingChange,
    data,
    showDistance,
    error,
    total,
    pagination,
    onPaginationChange,
    tabValue,
}) => {
    const [expanded, setExpanded] = useState<ExpandedState>()
    const navigate = useNavigate()
    const isMobile = useMobileScreen()

    const { columns, tableData, expandedColumns } = loadBoardColumns({
        showDistance,
        data,
        tabValue,
        isMobile,
    })

    return (
        <div className="load-board-table">
            <ModernTable<LoadBoardPostDto>
                columns={columns}
                onRowClick={(ev, row) => navigate(`${ROUTES.order.base}/${row.id}`)}
                data={tableData}
                loading={!error && !data}
                total={total}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                setSorting={onSortingChange}
                sorting={sorting}
                expanded={expanded}
                setExpanded={setExpanded}
                expandedColumns={expandedColumns}
            />
        </div>
    )
}
