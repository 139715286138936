import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import SubHeader, { LinkSettings } from '@/components/SubHeader'

interface AdminLayoutProps {
    companyName?: string
}

const AdminLayout: FC<AdminLayoutProps> = ({ companyName }) => {
    const location = useLocation()

    const links: LinkSettings[] = [
        {
            label: 'General',
            path: '/admin',
            className: location.pathname === '/admin' ? 'active' : '',
        },
        {
            label: 'All Warehouses',
            path: '/admin/warehouses',
            className: location.pathname.startsWith('/admin/warehouses') ? 'active' : '',
        },
        {
            label: 'All Staff',
            path: '/admin/staff',
            className: location.pathname.startsWith('/admin/staff') ? 'active' : '',
        },
        // {
        //     label: 'Invoices/Billing',
        //     path: '/admin/invoices',
        //     className: location.pathname.startsWith('/admin/invoices') ? 'active' : '',
        // },
    ]

    return (
        <>
            <SubHeader title={companyName} links={links} />
            <Outlet />
        </>
    )
}

export default AdminLayout
