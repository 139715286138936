import { Button } from '@/shared/ui/Button'
import React, { useState } from 'react'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import {
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from '@/shared/ui/Dialog'
import { Input } from '@/shared/ui/Input'
import { toast } from 'react-toastify'
import {
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/shared/ui/AlertDialog'
import { useCountdown } from '@/hooks/useCountdown'
import { HttpV2 } from '@shared/apiv2/api'

export const ConfirmEmailChangeAlert = ({ onContinue, isLoading, email, valid }: any) => {
    const { start, counter } = useCountdown()
    const [open, setOpen] = useState(false)

    return (
        <AlertDialogRoot
            open={open}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    start(4)
                }
                setOpen(isOpen)
            }}
        >
            <AlertDialogTrigger asChild>
                <Button type="submit" disabled={isLoading || !valid}>
                    Submit {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        <p className="text-left text-base max-w-full overflow-hidden">
                            Changing your email will require you to verify your new email address before you can
                            continue using our services.
                            <span className="block break-all mt-2 text-black font-semibold text-sm md:text-xl">
                                {email}
                            </span>
                        </p>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                        className={'w-[150px]'}
                        variant={'default'}
                        onClick={onContinue}
                        disabled={counter > 0 || isLoading}
                    >
                        {isLoading ? (
                            <ImSpinner8 className="ml-2 animate-spin" />
                        ) : (
                            `Continue ${counter > 0 ? `(${counter})` : ''}`
                        )}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogRoot>
    )
}

const isValidEmail = (email: string) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    return regex.test(email)
}

export const ChangeEmailModal = () => {
    const [open, setOpen] = useState(false)
    const [newEmail, setNewEmail] = useState('')
    const onSubmit = async () => {
        try {
            setIsLoading(true)

            const { data } = await HttpV2.put('/sso/account/email', {
                email: newEmail,
            })
            toast('Success! Redirecting to Single Sign On...', { type: 'success' })
            setTimeout(() => {
                // redirect to login?token=
                window.location.href = `/login?auth_token=${data.token}`
            }, 2000)
        } catch (e: any) {
            console.log('Error')
            toast(e?.response?.data?.message ?? 'Something went wrong, please contact support', { type: 'error' })
        } finally {
            setOpen(false)

            setTimeout(() => {
                setIsLoading(false)
            })
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const onChange = (newOpen: boolean) => {
        setOpen(newOpen)
        if (newOpen) {
            setNewEmail('')
        }
    }

    return (
        <DialogRoot onOpenChange={onChange} open={open}>
            <DialogTrigger asChild>
                <Button variant="link" disabled={isLoading}>
                    Change Email
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader className="mb-4">
                    <DialogTitle>Change Your Email</DialogTitle>
                </DialogHeader>

                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        return
                    }}
                >
                    <Input
                        label="New Email"
                        value={newEmail}
                        type="email"
                        onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <DialogFooter className="mt-3">
                        <DialogClose asChild>
                            <Button variant="outlined" disabled={isLoading} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </DialogClose>
                        <ConfirmEmailChangeAlert
                            email={newEmail}
                            onContinue={onSubmit}
                            valid={isValidEmail(newEmail)}
                            onCancel={() => setOpen(false)}
                            isLoading={isLoading}
                        />
                    </DialogFooter>
                </form>
            </DialogContent>
        </DialogRoot>
    )
}
