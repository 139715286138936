import ROUTES from '@/router'

export const WarehousesMenuList = [
    {
        title: 'General',
        link: '',
    },
    {
        title: 'Services',
        link: 'services',
    },
    {
        title: 'Orders/Invoices',
        link: 'orders',
    },
    {
        title: 'Staff',
        link: 'staff',
    },
]
