import React, { useEffect, useState } from 'react'
import { UploadFile } from '../UploadFile'
import { Badge, BadgeVariant } from '@/components/Badge'
import WarehousesRow from '../components/WarehousesRow'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { useParams } from 'react-router-dom'
import { DocumentsType, FileType } from '../types'
import { toast } from 'react-toastify'
import { Loader } from '@/components/Loader'
import Button from '@/components/Button'
import { showInsuranceRequirementsModal } from '@/parts/Modals/InsuranceRequirementsModal'

interface DocumentProps {
    document: FileType
    refreshDocuments?: () => void
    deleteFile: (documentId: string) => void
    downloadFile: (documentId: string) => void
    manager?: boolean
    isLoading?: boolean
}

export default function Insurance({ documents, refreshDocuments, deleteFile, downloadFile, isLoading, manager }: any) {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [currentDocuments, setCurrentDocuments] = useState<FileType[]>([])

    useEffect(() => {
        if (documents) {
            const filterGetInsuranceFiles = documents.filter(
                (file: FileType) => file?.type === DocumentsType.ACCOUNT_WH_INSURANCE,
            )

            setCurrentDocuments(filterGetInsuranceFiles)
        }
    }, [documents])

    const handleDownloadFile = async (documentId: string) => {
        setLoading(true)
        await downloadFile(documentId)
        setLoading(false)
    }

    const handleDeleteFile = async (documentId: string) => {
        setLoading(true)
        await deleteFile(documentId)
        await refreshDocuments()
        setLoading(false)
    }

    const handleFileSelect = async (files: FileList | null, expirationDate?: string) => {
        if (files) {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append('file', files[0])

                if (expirationDate) {
                    formData.append('expirationDate', expirationDate)
                }

                await HttpV2.post(`/accounts/${id}/documents/upload/${DocumentsType.ACCOUNT_WH_INSURANCE}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(async (data) => {
                        // console.log('uploaded documents', data)
                        toast('Success!', { type: 'success' })
                        await refreshDocuments()
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        handleFileSelect('')
                    })
                    .catch((err) => {
                        console.log(err?.response?.data)
                        toast(err?.response?.data?.message, { type: 'error' })
                    })
                    .finally(() => setLoading(false))
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <section className="app-card">
            <UploadFile
                multiple={false}
                manager={manager}
                title="Insurance"
                handleFileSelect={handleFileSelect}
                disabled={loading}
                accept=".pdf,.jpeg,.jpg"
                hintDescription="PDF and JPEG files accepted"
            >
                {loading || isLoading ? (
                    <Loader type="small" />
                ) : (
                    <>
                        {currentDocuments?.map((elem: FileType, index: number) => (
                            <WarehousesRow
                                key={elem?.type + index}
                                name={elem?.filename}
                                loading={loading}
                                disabled={!manager}
                                deleteFile={handleDeleteFile}
                                downloadFile={handleDownloadFile}
                                id={elem?.id}
                                sfDocumentId={elem?.sfDocumentId}
                                expirationDate={elem?.expirationDate}
                                status={elem?.status}
                                // date={'formik.values.expire'}
                                // status={'formik.values.status'}
                            />
                        ))}
                    </>
                )}
            </UploadFile>
            <div>
                <Button
                    className="!text-base !font-normal text-primary hover:text-primary/80 transition-colors duration-150 underline p-0 h-auto"
                    onClick={() => showInsuranceRequirementsModal()}
                >
                    Requirements
                </Button>
            </div>
        </section>
    )
}
