import React from 'react'
import { Button } from '@/shared/ui/Button'
import { Account } from '@/entities/accounts/model'
import { UploadInsuranceModal } from '@/features/modals/UploadInsuranceModal'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { DialogContent, DialogRoot, DialogTrigger } from '@/shared/ui/Dialog'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'
import { setWarehouseCOIStatus } from '@/pages/Order/ui/OrderQuote/ui/OrderQuoteSupply/model'
import { COIStatus } from '@/entities/accounts/model/enums'

export const OrderQuoteInsurance = () => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const dispatch = useAppDispatch()
    const warehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse) as Account

    const onInsuranceUploaded = () => {
        dispatch(setWarehouseCOIStatus({ id: warehouse.id, status: COIStatus.Pending }))
        sendCustomEvent('coi_uploaded', {
            action: 'user uploaded coi',
        })
    }

    return (
        <>
            {/** If Insurance does not exist */}
            {!warehouse.coiStatus ? (
                <div className="flex flex-col sm:flex-row gap-3 justify-between items-center p-3 bg-background-tertiary border rounded-xl">
                    <span>
                        Upload your Certificate of Insurance for <b>{warehouse.name}</b> or switch to a warehouse with
                        an active COI to place bids.
                    </span>
                    <DialogRoot>
                        <DialogTrigger asChild>
                            <Button className="shrink-0">Upload COI</Button>
                        </DialogTrigger>
                        <DialogContent>
                            <UploadInsuranceModal accountId={warehouse.id} onSuccess={onInsuranceUploaded} />
                        </DialogContent>
                    </DialogRoot>
                </div>
            ) : null}

            {/** If Insurance is uploaded, but not confirmed */}
            {warehouse.coiStatus === 'Pending' ? (
                <div className="flex flex-col sm:flex-row gap-3 justify-between items-center p-3 bg-warning/10 border border-warning rounded-xl">
                    <span>
                        Your Certificate of Insurance for <b>{warehouse.name}</b> has been uploaded, but not confirmed
                        yet. Please contact us if you have any questions.
                    </span>
                </div>
            ) : null}

            {/** If Insurance has been rejected */}
            {warehouse.coiStatus === 'Rejected' ? (
                <div className="flex flex-col sm:flex-row gap-3 justify-between items-center p-3 bg-danger/10 border border-danger rounded-xl">
                    <span>
                        Your Certificate of Insurance for <b>{warehouse.name}</b> has been rejected. Please contact us
                        if you have any questions or upload a new Certificate of Insurance.
                    </span>
                    <DialogRoot>
                        <DialogTrigger asChild>
                            <Button className="shrink-0">Upload COI</Button>
                        </DialogTrigger>
                        <DialogContent>
                            <UploadInsuranceModal accountId={warehouse.id} onSuccess={onInsuranceUploaded} />
                        </DialogContent>
                    </DialogRoot>
                </div>
            ) : null}

            {/** If Insurance has expired */}
            {warehouse.coiStatus === 'Expired' ? (
                <div className="flex flex-col sm:flex-row gap-3 justify-between items-center p-3 bg-danger/10 border border-danger rounded-xl">
                    <span>
                        Your Certificate of Insurance for <b>{warehouse.name}</b> has expired. Please upload new
                        Certificate of Insurance.
                    </span>
                    <DialogRoot>
                        <DialogTrigger asChild>
                            <Button className="shrink-0">Upload COI</Button>
                        </DialogTrigger>
                        <DialogContent>
                            <UploadInsuranceModal accountId={warehouse.id} onSuccess={onInsuranceUploaded} />
                        </DialogContent>
                    </DialogRoot>
                </div>
            ) : null}
        </>
    )
}
