/* eslint-disable complexity */
import * as React from 'react'
import { ReactComponent as ArrowUp } from '@/assets/images/arrow-up.svg'
import { EntityType } from '@shared/api/types'

import { LBPostStatus, LoadBoardPostDto } from '@shared/apiv2/load-board'

import { ReactComponent as CrossDockingIcon } from '@/assets/images/cross_docking.svg'
import { ReactComponent as Storage } from '@/assets/images/warehousing.svg'
import { ReactComponent as FinalMileIcon } from '@/assets/images/final_mile.svg'
import { ReactComponent as DisposalIcon } from '@/assets/images/disposal.svg'
import { ReactComponent as ReworkIcon } from '@/assets/images/restack.svg'
import { ReactComponent as TransloadingIcon } from '@/assets/images/transloading.svg'
import { ReactComponent as DrayageIcon } from '@/assets/images/drayage.svg'
import { ReactComponent as YardIcon } from '@/assets/images/yard.svg'
import { LoadBoardCertifications, LoadBoardEnvironments, RfqServiceType } from './types'
import Tooltip from 'react-tooltip-lite'
import cn from 'classnames'

interface IconComponentsMap {
    [key: string]: React.FC
}

export const serviceIcons: IconComponentsMap = {
    Storage: Storage,
    Transloading: TransloadingIcon,
    'Cross-Docking': CrossDockingIcon,
    'Rework / Restack': ReworkIcon,
    Drayage: DrayageIcon,
    'Final Mile / Local Delivery': FinalMileIcon,
    Disposal: DisposalIcon,
    'Yard Storage / Trailer Parking': YardIcon,
}

export const setServicesIcon = (props: string) => {
    const services = props.split(',').map((service) => service.trim())
    services.sort((a, b) => a.localeCompare(b))

    return services.map((trimmedService) => {
        const IconComponent = serviceIcons[trimmedService]

        if (IconComponent) {
            return (
                <span style={{ marginRight: '5px' }} key={trimmedService}>
                    <Tooltip
                        content={trimmedService}
                        tipContentClassName={cn('load-board__tooltip')}
                        distance={15}
                        background="white"
                        tipContentHover
                    >
                        <IconComponent />
                    </Tooltip>
                </span>
            )
        }
        return null
    })
}

export const getStatus = (row: LoadBoardPostDto) => {
    if (row.status === LBPostStatus.DRAFT) {
        return ['#DF5151', 'Draft']
    } else if (row.status === LBPostStatus.ACTIVE) {
        return ['#7cce66', 'Active']
    } else if (row.status === LBPostStatus.BID_ACCEPTED) {
        return ['#52a775', 'Bid Accept.']
    } else if (row.status === LBPostStatus.CLOSED) {
        return ['#DF5151', 'Cancelled']
    } else if (row.status === LBPostStatus.ARCHIVED) {
        return ['#979ea6', 'Archived']
    }
}

export const LoadBoardWhTabs = [
    { value: 'new-opportunities', name: 'New Opportunities' },
    { value: 'quoted', name: 'Quoted' },
    { value: 'accepted', name: 'Accepted' },
    { value: 'lost', name: 'Lost' },
]

export const LoadBoardCarrierTabs = [
    { value: 'new-opportunities', name: 'New Opportunities' },
    { value: 'quoted', name: 'Quoted' },
    { value: 'accepted', name: 'Accepted' },
]

export const setTabsListForCurrentEntity = (type: EntityType) => {
    return type === EntityType.carrier ? LoadBoardCarrierTabs : LoadBoardWhTabs
}

export const AccordionServices = ({ title, children }: any) => {
    const [isAccordionOpen, setIsAccordionOpen] = React.useState(false)
    return (
        <div style={{ width: '100%' }} className={'load-board-sidebar__sidebar'}>
            <div
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    marginBottom: 8,
                }}
            >
                <div>{title}</div>
                <div className={isAccordionOpen ? 'arrow' : 'arrow_active'}>
                    <ArrowUp />
                </div>
            </div>
            <div className={isAccordionOpen ? 'closed' : 'open'} style={{ overflow: 'hidden' }}>
                {children}
            </div>
        </div>
    )
}

export enum LoadBoardServiceNames {
    services = 'services',
    environment = 'environment',
    certification = 'certification',
}

// "value" and "name" field should be the same
export const equipmentServiceOption = [
    { value: 'clamp-truck', name: 'equipment_clamp-truck', label: 'Clamp Truck Forklift' },
    { value: 'slip-sheet', name: 'equipment_slip-sheet', label: 'Slip Sheet Forklift' },
    { value: 'forklift', name: 'equipment_forklift', label: 'Forklift' },
]
export const serviceOption = [
    { value: 'cross-docking', name: 'services_cross-docking', label: RfqServiceType['Cross-Docking'] },
    { value: 'storage', name: 'services_storage', label: RfqServiceType.Storage },
    { value: 'final mile / local delivery', name: 'services_local-delivery', label: RfqServiceType['Local Delivery'] },
    { value: 'disposal', name: 'services_disposal', label: RfqServiceType.Disposal },
    { value: 'rework / restack', name: 'services_rework', label: RfqServiceType.Rework },
    {
        value: 'transloading',
        name: 'services_transloading',
        label: RfqServiceType.Transloading,
    },
    { value: 'drayage', name: 'services_drayage', label: RfqServiceType.Drayage },

    { value: 'yard storage / trailer parking', name: 'services_yard', label: RfqServiceType['Yard Storage'] },
]
export const featureServiceOption = [
    { value: 'gatedyard', name: 'features_gatedyard', label: 'Gated yard' },
    { value: 'ramp', name: 'features_ramp', label: 'Ramp' },
    { value: 'security', name: 'features_security', label: 'On-Site Security' },
    { value: 'sprinkler', name: 'features_sprinkler', label: 'Sprinkler System' },
]

export const environmentServiceOption = [
    { value: 'Dry', name: 'environment_dry', label: LoadBoardEnvironments.Dry },
    { value: 'frozen', name: 'environment_frozen', label: LoadBoardEnvironments.Frozen },
    {
        value: 'chilled/refrigerated',
        name: 'environment_chilled_refrigerated',
        label: LoadBoardEnvironments.Chilled,
    },
    { value: 'temp controlled', name: 'environment_temp_controlled ', label: 'Temp Controlled (50° to 65°)' },
    { value: 'ambient', name: 'environment_ambient', label: LoadBoardEnvironments.Ambient },
    {
        value: 'Specific Temp w/in Environment',
        name: 'environment_specific_temp_w/in_selected_environment',
        label: LoadBoardEnvironments.Specific,
    },
]

export const certificationsServiceOption = [
    { value: 'Food Grade', name: 'certifications_food_grade', label: LoadBoardCertifications['Food Grade'] },
    {
        value: LoadBoardCertifications['Food (AIB)'],
        name: 'certifications_food',
        label: LoadBoardCertifications['Food (AIB)'],
    },
    { value: LoadBoardCertifications.Organic, name: 'certifications_organic', label: LoadBoardCertifications.Organic },
    { value: LoadBoardCertifications.Medical, name: 'certifications_medical', label: LoadBoardCertifications.Medical },
    { value: LoadBoardCertifications.GDP, name: 'certifications_gdp', label: LoadBoardCertifications.GDP },
    {
        value: LoadBoardCertifications['Foreign Trade Zone (FTZ)'],
        name: 'certifications_foreign',
        label: LoadBoardCertifications['Foreign Trade Zone (FTZ)'],
    },
    {
        value: LoadBoardCertifications['TSA Approved'],
        name: 'certifications_tsa',
        label: LoadBoardCertifications['TSA Approved'],
    },
    {
        value: LoadBoardCertifications.Military,
        name: 'certifications_military',
        label: LoadBoardCertifications.Military,
    },
    {
        value: LoadBoardCertifications.Hazmat,
        name: 'certifications_hazmat',
        label: LoadBoardCertifications.Hazmat,
    },
    {
        value: LoadBoardCertifications.Bonded,
        name: 'certifications_bonded',
        label: LoadBoardCertifications.Bonded,
    },
]
