import React, { useEffect, useState } from 'react'
import { HttpV2 } from '@shared/apiv2/api'
import { useParams } from 'react-router-dom'
import Insurance from './Insurance'
import { FileType } from '../types'
import Files from './Files'
import './Sidebar.scss'
import { toast } from 'react-toastify'
import saveAs from 'file-saver'
import HelpLinks from '@/parts/Cards/HelpLinks'

export default function Sidebar({ manager }: { manager: boolean }) {
    const [documents, setDocuments] = useState<FileType[]>([])
    const [insuranceLoading, setInsuranceLoading] = useState(false)
    const [filesLoading, setFilesLoading] = useState(false)
    const { id } = useParams()
    useEffect(() => {
        fetchData()
    }, [id])

    // Get related documents
    const fetchData = async () => {
        try {
            const { data } = await HttpV2.get(`/accounts/${id}/documents`)
            setDocuments(data)
            // console.log('get documents', data)
        } catch (error: any) {
            console.log(error?.response?.message)
        }
    }

    const downloadFile = async (documentId: string, component: 'insurance' | 'files') => {
        if (component === 'insurance') {
            setInsuranceLoading(true)
        } else if (component === 'files') {
            setFilesLoading(true)
        }
        await HttpV2.get(`/accounts/${id}/documents/${documentId}`, {
            responseType: 'blob',
        })
            .then((data) => {
                // get content disposition
                const contentDisposition = data?.headers['content-disposition']
                const filename = contentDisposition?.split(';')[1].split('=')[1].trim()
                saveAs(data?.data, filename)
                toast('File successfully download', { type: 'success' })
            })
            .catch((err) => {
                console.log('Error download file:', err)
                toast.error('Error downloading the file')
            })
            .finally(() => {
                if (component === 'insurance') {
                    setInsuranceLoading(false)
                } else if (component === 'files') {
                    setFilesLoading(false)
                }
            })
    }

    const deleteFile = async (documentId: string, component: 'insurance' | 'files') => {
        if (component === 'insurance') {
            setInsuranceLoading(true)
        } else if (component === 'files') {
            setFilesLoading(true)
        }
        await HttpV2.delete(`/accounts/${id}/documents/${documentId}`)
            .then((data) => {
                // console.log('deleted file', data)
                toast('File deleted successfully', { type: 'success' })
                fetchData()
            })
            .catch((err) => {
                console.log('Error delete file:', err)
                toast.error('Error deleting file')
            })
            .finally(() => {
                if (component === 'insurance') {
                    setInsuranceLoading(false)
                } else if (component === 'files') {
                    setFilesLoading(false)
                }
            })
    }

    return (
        <div>
            <div className="sidebar__section">
                <Insurance
                    manager={manager}
                    documents={documents}
                    refreshDocuments={fetchData}
                    deleteFile={(docId: string) => deleteFile(docId, 'insurance')}
                    downloadFile={(docId: string) => downloadFile(docId, 'insurance')}
                    isLoading={insuranceLoading}
                />
            </div>
            <div className="sidebar__section">
                <Files
                    manager={manager}
                    documents={documents}
                    refreshDocuments={fetchData}
                    deleteFile={(docId: string) => deleteFile(docId, 'files')}
                    downloadFile={(docId: string) => downloadFile(docId, 'files')}
                    isLoading={filesLoading}
                />
            </div>
            <HelpLinks />
        </div>
    )
}
