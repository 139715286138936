import React, { FC, HTMLAttributes } from 'react'
import { Link, useLocation, useParams, useMatch, Navigate, useNavigate } from 'react-router-dom'
import Button from '@/components/Button'
import ROUTES from '@/router'
import { FeatureFlags, useFeatureFlags } from '@shared/apiv2/feature-flags'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { getOLIMPLandingURL } from '@/utils/url'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import SubMenu from '@/parts/Navigation/Submenu'
import useNavHelper from '@/hooks/useNavHelper'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

import cn from 'classnames'
import './MenuItem.scss'

interface IMenu extends HTMLAttributes<HTMLDivElement> {
    closeMenu: () => void
}

// eslint-disable-next-line complexity
export const AppMenu: FC<IMenu> = ({ closeMenu }) => {
    const { sendMenuClick } = useCustomAnalyticsEvent()
    const { isEnabled } = useFeatureFlags()
    const location = useLocation()
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()
    const { data: micrositeData, slug } = useMicrosite()
    const { hasMicrosite, pages, userTypes } = useNavHelper()

    const landingLink = getOLIMPLandingURL()

    const getLink = (baseLink: string, msLink: string) => {
        return slug ? msLink : baseLink
    }

    const createLinklabel = (linkSlug: any): string => {
        if (linkSlug === 'faq') {
            return linkSlug.toUpperCase().replace(/-/g, ' ').replace(/\//g, '')
        }

        return linkSlug.replace(/-/g, ' ').replace(/\//g, '')
    }

    const moreMenuLinks = [
        {
            label: 'How it works',
            to: '/how-it-works',
            active: location.pathname === '/how-it-works',
        },
        {
            label: 'FAQ',
            to: '/faq',
            active: location.pathname === '/faq',
        },
    ]

    return (
        <>
            {((userTypes.isBroker && isLoggedIn) || (!isLoggedIn && hasMicrosite)) && slug !== 'on-demand-warehousing' && (
                <>
                    <Link
                        to={getLink('/rfq', `/ms/${slug}/rfq`)}
                        className={cn('menu-item', { active: pages.isRfqPage })}
                    >
                        <Button
                            label="Request for Quote"
                            onClick={() =>
                                sendMenuClick({
                                    label: 'Request for Quote',
                                    link: getLink('/rfq', `/ms/${slug}/rfq`),
                                    callBack: closeMenu,
                                })
                            }
                            activated={pages.isRfqPage}
                        />
                    </Link>
                    <Link
                        to={getLink('/rfq/list', `/ms/${slug}/rfq/list`)}
                        className={cn('menu-item', { active: pages.isOrdersPage })}
                    >
                        <Button
                            label="Your RFQs"
                            onClick={() =>
                                sendMenuClick({
                                    label: 'Your RFQs',
                                    link: getLink('/rfq/list', `/ms/${slug}/rfq/list`),
                                    callBack: closeMenu,
                                })
                            }
                            activated={pages.isOrdersPage}
                        />
                    </Link>
                    {!location.pathname.startsWith('/rfq') && hasMicrosite && (
                        <div className="menu-item_active">
                            <Link to="#" className="menu-item more-btn">
                                <Button label="More" />
                            </Link>
                            <div className="menu-item__submenu">
                                <div className="wrapper">
                                    <div>
                                        <ul className="menu-item__list">
                                            {['solutions', 'how-it-works', 'business-case', 'faq', 'support'].map(
                                                (path) => {
                                                    return (
                                                        <li key={path} className="menu-item__item">
                                                            <Link
                                                                to="#"
                                                                onClick={(e) => {
                                                                    sendMenuClick({
                                                                        label: createLinklabel(path),
                                                                        link: `${landingLink}/${slug}-${path}/`,
                                                                        callBack: closeMenu,
                                                                    })

                                                                    e.preventDefault()
                                                                    window.location.href = `${landingLink}/${slug}-${path}/`
                                                                }}
                                                                className="menu-item menu-item__link"
                                                            >
                                                                {createLinklabel(path)}
                                                            </Link>
                                                        </li>
                                                    )
                                                },
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {isEnabled(FeatureFlags.WH_LOAD_BOARD) && userTypes.isWH && (
                <Link to={`${ROUTES.loadboard.base}`} className={cn('menu-item', { active: pages.isLoadBoardPage })}>
                    <Button
                        label="Load Board"
                        onClick={() =>
                            sendMenuClick({
                                label: 'Load Board',
                                link: `${ROUTES.loadboard.base}`,
                                callBack: closeMenu,
                            })
                        }
                        activated={pages.isLoadBoardPage}
                    />
                </Link>
            )}

            {isEnabled(FeatureFlags.WAREHOUSE_LIST) && !userTypes.isBroker && isLoggedIn && (
                <Link to={`${ROUTES.warehouses.base}`} className={cn('menu-item', { active: pages.isWarehousesPage })}>
                    <Button
                        label="Warehouses"
                        onClick={() =>
                            sendMenuClick({
                                label: 'Warehouses',
                                link: `${ROUTES.warehouses.base}`,
                                callBack: closeMenu,
                            })
                        }
                        activated={pages.isWarehousesPage}
                    />
                </Link>
            )}

            {!isEnabled(FeatureFlags.ADMIN) && isLoggedIn && userInfo?.type === 'admin' && userTypes.isWH && (
                <Link to={`${ROUTES.admin.base}`} className={cn('menu-item', { active: pages.isAdminPage })}>
                    <Button
                        label="Admin"
                        onClick={() =>
                            sendMenuClick({
                                label: 'Admin',
                                link: `${ROUTES.admin.base}`,
                                callBack: closeMenu,
                            })
                        }
                        activated={pages.isAdminPage}
                    />
                </Link>
            )}

            {userTypes.isWH && (
                <div className="menu-item_active">
                    <Link
                        to="#"
                        className={cn('menu-item', 'more-btn', {
                            active: ['/how-it-works', '/faq'].includes(location.pathname),
                        })}
                    >
                        <Button
                            label="More"
                            onClick={() => closeMenu()}
                            activated={moreMenuLinks.some((link) => link.to === location.pathname)}
                        />
                    </Link>
                    <SubMenu
                        links={[
                            {
                                label: 'How it works',
                                to: '/how-it-works',
                                active: location.pathname === '/how-it-works',
                            },
                            {
                                label: 'FAQ',
                                to: '/faq',
                                active: location.pathname === '/faq',
                            },
                        ]}
                        onLinkClick={(link) => {
                            sendMenuClick({
                                label: link.label,
                                link: link.to,
                                place: 'header',
                                callBack: closeMenu,
                            })
                        }}
                    />
                </div>
            )}
        </>
    )
}
