import React from 'react'

import { setMainStyle } from '../helper'
import Checkbox from '@/components/Checkbox'

import '../loadBoardStyles.scss'

export const EquipmentCheckboxes = ({
    isEquipmentChecked,
    handleEquipmentChange,
}: {
    isEquipmentChecked: (param: string) => any
    handleEquipmentChange: (param: any) => any
}) => {
    return (
        <div>
            <p className={setMainStyle('_label')}>Equipment</p>
            <div className={setMainStyle('_wrap')}>
                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    label={'Clamp Truck Forklift'}
                    name={'clamp-truck'}
                    value={isEquipmentChecked('clamp-truck')}
                    onChange={handleEquipmentChange}
                />

                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    label={'Slip Sheet Forklift'}
                    value={isEquipmentChecked('slip-sheet')}
                    name={'slip-sheet'}
                    onChange={handleEquipmentChange}
                />
                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    label={'Forklift'}
                    value={isEquipmentChecked('forklift')}
                    name={'forklift'}
                    onChange={handleEquipmentChange}
                />
            </div>
        </div>
    )
}
