import { useEffect } from 'react'
import { isDevEnv } from '@/common/consts'
import { iframeSolution } from '@/pages/RFQPortal/RfqPortalRoutes'

function sendHeightToParent() {
    // const iframe = document.querySelector('#app > .app-container')!
    // if (!iframe) {
    //     return
    // }
    // const { height } = iframe.getBoundingClientRect()
    // if (window.parent) {
    //     window.parent.postMessage({ type: 'heightUpdate', height: height }, '*')
    // }
    window.parent.postMessage({ type: 'heightUpdate' }, '*')
}

export const useDynamicHeightEvents = () => {
    useEffect(() => {
        if (iframeSolution) {
            let observer: MutationObserver

            window.addEventListener('load', sendHeightToParent)
            window.addEventListener('resize', sendHeightToParent)
            if ('MutationObserver' in window) {
                observer = new MutationObserver(sendHeightToParent)
                observer.observe(document.body, { childList: true, subtree: true })
            }
            const container = document.querySelector('#app > .app-container') as HTMLElement
            container.style.paddingTop = '0'
            sendHeightToParent()
            return () => {
                window.removeEventListener('load', sendHeightToParent)
                window.removeEventListener('resize', sendHeightToParent)
                if (observer) {
                    observer.disconnect()
                }
            }
        }
    }, [])
}

// function throttle<T extends (...args: any[]) => any>(func: T, limit: number): (...args: Parameters<T>) => void {
//     let inThrottle: boolean
//     return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
//         if (!inThrottle) {
//             func.apply(this, args)
//             inThrottle = true
//             setTimeout(() => {
//                 inThrottle = false
//             }, limit)
//         }
//     }
// }
