import React from 'react'
import { LoadboardOrder, LoadboardOrderStatus } from '@/entities/loadboard-orders/model'
import { Badge } from '@/shared/ui/Badge'
import { useAppSelector } from '@/app/store'

export const OrderDetailsStatus = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    if (order.status < LoadboardOrderStatus.CLOSED) {
        return <Badge variant="success">Open</Badge>
    }

    return <Badge variant="secondary">Closed</Badge>
}
