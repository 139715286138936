/* eslint-disable complexity */
import React from 'react'
import { LoadBoardContainer } from '../LoadBoardContainer'
import { START_COUNTER, nextButtonTitle } from '../types'
import { MainStep } from '.'
import { WarehousingStep } from './WarehousingStep'
import { AdditionalDetailsStep } from './AdditionalDetailsStep'
import { DrayageStep } from './DrayageStep'
import { ReviewPageSteps } from './ReviewPageSteps'
import { TransloadingSteps } from './TransloadingSteps'

/**
 *
 * @description StepsList this is all modal steps components
 * @returns modal steps
 */
export const StepsList = ({
    counter,
    formik,
    handleServicesChange,
    handleCertificationsChange,
    handleEquipmentChange,
    handleFeaturesChange,
    cancelClick,
    setDisabled,
    isWarehousingSteps,
    stepOptionMain,
    stepOptionWarehouse,
    nextButtonName,
    stepOptionAdditionalDetails,
    stepOptionReview,
    isTransloadingSteps,
    isDrayageSteps,
    stepOptionDrayage,
    isWarehousingAndTransloadingSteps,
    stepOptionTransloading,
    isWarehousingAndDrayageSteps,
    isTransloadingAndDrayageSteps,
    isAllServicesSteps,
}: any) => {
    return (
        <>
            {/* Main modal screen */}
            {counter.start === START_COUNTER && (
                <LoadBoardContainer counter={counter} {...stepOptionMain}>
                    <MainStep
                        formik={formik}
                        handleServicesChange={handleServicesChange}
                        handleCertificationsChange={handleCertificationsChange}
                        handleEquipmentChange={handleEquipmentChange}
                        handleFeaturesChange={handleFeaturesChange}
                        cancelClick={cancelClick}
                        setDisabled={setDisabled}
                    />
                </LoadBoardContainer>
            )}

            {/* Warehousing steps */}
            {isWarehousingSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionWarehouse}>
                            <WarehousingStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}

                    {counter.start === 3 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* Transloading steps */}
            {isTransloadingSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionTransloading}>
                            <TransloadingSteps formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}

                    {counter.start === 3 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* Drayage steps */}
            {isDrayageSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionDrayage}>
                            <DrayageStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}

                    {counter.start === 3 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* Warehousing snd Transloading steps */}
            {isWarehousingAndTransloadingSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionWarehouse}>
                            <WarehousingStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 3 && (
                        <LoadBoardContainer {...stepOptionTransloading}>
                            <TransloadingSteps formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 4 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* Warehousing snd Drayage steps */}
            {isWarehousingAndDrayageSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionWarehouse}>
                            <WarehousingStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 3 && (
                        <LoadBoardContainer {...stepOptionDrayage}>
                            <DrayageStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 4 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* Transloading snd Drayage steps */}
            {isTransloadingAndDrayageSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionTransloading}>
                            <TransloadingSteps formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 3 && (
                        <LoadBoardContainer {...stepOptionDrayage}>
                            <DrayageStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 4 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}

            {/* all steps */}
            {isAllServicesSteps && (
                <>
                    {counter.start === 2 && (
                        <LoadBoardContainer {...stepOptionWarehouse}>
                            <WarehousingStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 3 && (
                        <LoadBoardContainer {...stepOptionTransloading}>
                            <TransloadingSteps formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 4 && (
                        <LoadBoardContainer {...stepOptionDrayage}>
                            <DrayageStep formik={formik} setDisabled={setDisabled} />
                        </LoadBoardContainer>
                    )}
                    {counter.start === 5 && nextButtonName !== nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionAdditionalDetails}>
                            <AdditionalDetailsStep formik={formik} />
                        </LoadBoardContainer>
                    )}
                    {nextButtonName === nextButtonTitle.ReviewPage && (
                        <LoadBoardContainer {...stepOptionReview}>
                            <ReviewPageSteps formik={formik} />
                        </LoadBoardContainer>
                    )}
                </>
            )}
        </>
    )
}
