import React, { FC, useState } from 'react'
import { SignUpTemplate } from '@/pages/SignUp/components/SignUpTemplate'
import { InputText } from '@/components/InputText'
import Button from '@/components/Button'
import { useFormik } from 'formik'
import { HttpV2, setHttpV2Token } from '@shared/apiv2/api'
import { canadaProvinces, usStates } from '@/pages/RFQPortal/helpers/states'
import { updateLoggedState, useUserState } from '@/store/user'
import { Navigate, useNavigate } from 'react-router-dom'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import * as Yup from 'yup'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { toast } from 'react-toastify'
import './Onboarding.scss'

const emptyForm = {
    email: '',
    warehouseName: '',
    warehouseAddress: '',
    state: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    position: '',
    warehouseAddressObject: {},
    url: window.location.origin,
}

export const sendOnboardingRequest = (data: any) => {
    return HttpV2.post('/auth/onboarding', data)
}
const stateOptions = Object.entries({ ...usStates, ...canadaProvinces }).map(([item, name]) => {
    return { value: name, name }
})

// eslint-disable-next-line complexity
export const Onboarding: FC = () => {
    const [accountData, setAccountData] = useState<any>()
    const [submittedOnce, setSubmittedOnce] = useState(false)
    const { state: userState, dispatch } = useUserState()
    const emailFromQuery = new URLSearchParams(window.location.search).get('email')
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            email: userState.userInfo?.email ?? emailFromQuery ?? '',
            warehouseName: '',
            warehouseAddress: accountData?.address?.fullAddress ?? '',
            clientAddress: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            position: '',
            clientAddressObject: { state: null },
            warehouseAddressObject: { state: null },
            url: window.location.origin,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            warehouseName: Yup.string().required('Required'),
            warehouseAddress: Yup.string().required('Required'),
            clientAddress: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            phoneNumber: Yup.string().required('Required'),
            position: Yup.string(),
        }),
        onSubmit: async (body) => {
            try {
                const { data: responseData } = await sendOnboardingRequest(body)
                setHttpV2Token(responseData.token)
                updateLoggedState(responseData.user, dispatch)
                navigate('/')
            } catch (e: any) {
                const errMessage =
                    e.response?.data?.message ?? 'Something went wrong. Please try again later or contact support.'
                toast.error(errMessage, { autoClose: 10000 })
            }
        },
        validateOnChange: submittedOnce,
    })

    const matchAccountUsingEmailAndState = async () => {
        const { data } = await HttpV2.post('/auth/onboarding/check', {
            state: formik.values.clientAddressObject?.state,
        })
        setAccountData(data || {})
        if (data) {
            if (data.name) {
                formik.setFieldValue('warehouseName', data.name)
            }

            if (data.address) {
                formik.setFieldValue('warehouseAddress', data.address.fullAddress)
            }
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!formik.values.clientAddressObject?.state) {
            // eslint-disable-next-line
            formik.setFieldError('clientAddress', `State couldn't be empty`)
            return
        }
        if (accountData) {
            if (!formik.values.warehouseAddressObject?.state) {
                // eslint-disable-next-line
                formik.setFieldError('warehouseAddress', `State couldn't be empty`)
                return
            }
            setSubmittedOnce(true)
            formik.handleSubmit(e)
        } else {
            matchAccountUsingEmailAndState()
        }
    }

    if (!userState.isLoggedIn || userState.userInfo?.sfContactId) {
        return <Navigate to="/" />
    }
    return (
        <SignUpTemplate title="Warehouse On-Boarding" progress={50}>
            <form onSubmit={handleSubmit}>
                {accountData && (
                    <InputText
                        autoFocus
                        disabled={!!accountData.name}
                        text
                        value={accountData.name ?? formik.values.warehouseName}
                        error={formik.errors.warehouseName as any}
                        textError
                        onChange={formik.handleChange}
                        placeholder="Warehouse name"
                        label="Warehouse name"
                        required
                        name="warehouseName"
                    />
                )}
                {/*<InputLocation*/}
                {/*    textError*/}
                {/*    allowLocationSelect*/}
                {/*    errorOnFocus*/}
                {/*    error={formik.errors.warehouseAddress as any}*/}
                {/*    value={formik.values.warehouseAddress}*/}
                {/*    onChange={handleLocationChange}*/}
                {/*    name="warehouseAddress"*/}
                {/*    label="Warehouse Location address"*/}
                {/*    required*/}
                {/*/>*/}
                {accountData && (
                    <div className="input-text">
                        <div className={' ffBeVietnamPro'}>
                            <div className="label">Warehouse Location*</div>
                        </div>
                        <div className="input-wrapper">
                            <GoogleAutocompleteInput
                                type="text"
                                id="warehouseAddress"
                                name="warehouseAddress"
                                data-name="warehouseAddress"
                                disabled={accountData?.address}
                                initialValue={accountData?.address?.fullAddress}
                                value={formik.values.warehouseAddress}
                                inputPath="warehouseAddress"
                                label=""
                                changePlaceFull={(field, value) => {
                                    formik.setFieldValue('warehouseAddress', value.formattedAddress)
                                    formik.setFieldValue('warehouseAddressObject', value)
                                    if (value.state) {
                                        formik.setFieldError('warehouseAddress', undefined)
                                    }
                                }}
                                placeholder="Type to search..."
                                onBlur={() => {}}
                            />

                            <div className="w-error" style={{ marginTop: -6 }}>
                                <div className="error">{formik.errors.warehouseAddress}</div>
                            </div>
                        </div>
                    </div>
                )}
                <InputText
                    text
                    label="E-mail"
                    name="email"
                    placeholder="E-mail"
                    className="email"
                    required
                    disabled
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                    errorHide={false}
                    textError
                />
                {/*label*/}
                <div className="input-text">
                    <div className={' ffBeVietnamPro'}>
                        <div className="label">Contact Location*</div>
                    </div>
                    <div className="input-wrapper">
                        <GoogleAutocompleteInput
                            type="text"
                            id="contactAddress"
                            name="contactAddress"
                            data-name="warehouseAddress"
                            value={formik.values.clientAddress ?? ''}
                            inputPath="clientAddress"
                            label=""
                            changePlaceFull={(field, value) => {
                                formik.setFieldValue('clientAddress', value.formattedAddress)
                                formik.setFieldValue('clientAddressObject', value)
                                if (value.state) {
                                    formik.setFieldError('clientAddress', undefined)
                                }
                            }}
                            placeholder="Type to search..."
                            onBlur={() => {}}
                        />
                        <div className="w-error" style={{ marginTop: -6 }}>
                            <div className="error">{formik.errors.clientAddress}</div>
                        </div>
                    </div>
                </div>

                {accountData && (
                    <InputText
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        error={formik.errors.phoneNumber}
                        errorOnFocus
                        errorHide={false}
                        required
                        textError
                        label="Phone"
                        format="+1 (###) ### ####"
                    />
                )}
                {accountData && (
                    <InputText
                        text
                        label="First name"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        error={formik.errors.firstName}
                        required
                        errorOnFocus
                        errorHide={false}
                        textError
                    />
                )}
                {accountData && (
                    <InputText
                        text
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.errors.lastName}
                        required
                        errorOnFocus
                        errorHide={false}
                        textError
                        label="Last name"
                    />
                )}
                {accountData && (
                    <InputText
                        text
                        name="position"
                        onChange={formik.handleChange}
                        value={formik.values.position}
                        error={formik.errors.position}
                        label="Position"
                    />
                )}
                <Button
                    label="Continue"
                    type="submit"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                    types={['blue', 'full']}
                />
                <p className="have-account">
                    Have a problem?{' '}
                    <a onClick={() => showFeedbackModal({ props: {} })} className="secondary-link media-desktop">
                        Contact Support
                    </a>
                </p>
            </form>
        </SignUpTemplate>
    )
}
