import React, { FC, useEffect, useMemo, useState } from 'react'

import { ModernTable } from '@/components/ModernTable/ModernTable'
import { claims, RfqPortalService, useRFQs } from '@/services/rfq.portal.service'
import { rfqColumns } from '@/pages/RFQPortal/rfq.columns'
import RfqHeader from '../parts/RfqHeader'
import './RfqTable.scss'
import NewFormRfqSearch from '@/parts/Forms/form-rfq-search/NewFormRfqSearch'
import { HttpV2 } from '@shared/apiv2/api'
import { IFilter } from '../RfqPortalRoutes'
import { createPortal } from 'react-dom'
import { Loader } from '@/components/Loader'
import { ReactComponent as UploadIcon } from '@/assets/images/icon-upload.svg'
import RFQListMenu from '@/pages/RFQPortal/parts/RFQListMenu'

const { columns, expandedColumns } = rfqColumns

interface IRFQListProps {
    filters: IFilter
    setFilters: any
}

export const RFQList = ({ filters, setFilters }: IRFQListProps) => {
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const { data, error } = useRFQs(pagination, filters)
    const userData = claims()
    const loading = !data && !error
    const [downloadXLSXLoading, setDownloadXLSXLoading] = useState(false)

    // useEffect(() => {
    //     if (data && data.rfqs.length > 0) {
    //         const x = data.rfqs.find((item: any) => {
    //             return item.key === 'dev-sHrqVT-7988'
    //         })
    //         console.log('opp', x)
    //     }
    //     console.log('data', data)
    // }, [data])

    const rfqMapped = useMemo(() => {
        if (data) {
            return data.rfqs.map((rfq: any) => {
                const { freightCommodity, freightEnvironment, services, freightCertification, notes, ...rest } = rfq
                return {
                    ...rest,
                    subRows: [{ freightCommodity, freightEnvironment, services, freightCertification, notes }],
                }
            })
        }
        return []
    }, [data])

    useEffect(() => {
        setExpanded(false)
    }, [pagination.pageIndex])
    const [expanded, setExpanded] = React.useState(false)

    const downloadXLSX = async () => {
        setDownloadXLSXLoading(true)
        try {
            const resp = await HttpV2.get('/rfq/export', {
                params: filters,
                responseType: 'blob',
            })
            const href = URL.createObjectURL(resp.data)

            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'rfq.xlsx')
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            setDownloadXLSXLoading(false)
        } catch (err: any) {
            console.error('downloadXLSX: ', err)
            setDownloadXLSXLoading(false)
        }
    }
    const handleFiltersUpdate = (values: any) => {
        setPagination((paging) => ({ ...paging, pageIndex: 0 }))
        setFilters(values)
    }

    function handlePaginationChange(paging: any) {
        setPagination(paging)
    }
    const toolsContainer = document.querySelector('#rfq-header-tools')

    return (
        <>
            <div className="rfq-table" id="rfq-list">
                <div className="rfq-table-list rfq-table_bg">
                    <RFQListMenu />
                    <div className="rfq-table-content">
                        <NewFormRfqSearch onUpdate={handleFiltersUpdate} filters={filters} admin={userData?.admin} />
                        <ModernTable
                            headerClassName={'rfq-table_header-bg'}
                            loading={loading}
                            setExpanded={setExpanded}
                            expanded={expanded}
                            expandedColumns={expandedColumns}
                            columns={columns}
                            pagination={pagination}
                            total={data?.total}
                            onPaginationChange={handlePaginationChange}
                            data={rfqMapped}
                        />
                        {toolsContainer &&
                            createPortal(
                                <div className="rfq-header__tools-button" onClick={downloadXLSX}>
                                    {downloadXLSXLoading ? (
                                        <Loader type="small" />
                                    ) : (
                                        <>
                                            Export <UploadIcon />
                                        </>
                                    )}
                                </div>,
                                toolsContainer,
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
