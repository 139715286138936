/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import FaqList from './FaqList'
import { LoadBoardRoute } from '@/pages/LoadBoard/LoadBoardRoutes'

import './FAQ.scss'

export interface FaqDataType {
    visible: boolean
    question: string
    answer: string | React.ReactNode
}

export default function FaqPage() {
    const [faqData, setFaqData] = useState<FaqDataType[]>([
        {
            visible: false,
            question: 'What services does OLIMP Warehousing provide?',
            answer: 'Cross-docking, storage, reworks/restacks, final mile / local delivery, transloading, drayage, disposal, yard storage / trailer parking',
        },
        {
            visible: false,
            question: 'What happens after I place a bid?',
            answer: 'A Partner Success Manager will communicate with our client and either accept or reject your bid. If there are additional questions, the PSM will contact you directly. The opportunity will also move to the Quoted tab of your Load Board',
        },
        {
            visible: false,
            question: 'What happens if my bid is accepted?',
            answer: 'You will receive an email that your bid has been accepted. Then our Partner Success Manager will contact you to finalize the details of the opportunity. The opportunity will move to the Accepted tab of your Load Board',
        },
        {
            visible: false,
            question: 'What happens if my bid is not accepted?',
            answer: 'You will receive an email that states your bid was rejected, along with the reason it was not accepted. The opportunity will also move to the Lost tab on your Load board',
        },
        {
            visible: false,
            question: 'Where can I upload a Certificate of Insurance?',
            answer: 'On the Warehouses Tab under General',
        },
        {
            visible: false,
            question: 'How do I get paid?',
            answer: 'OLIMP will pay you directly via ACH, Comp Check or Credit Card',
        },
        {
            visible: false,
            question: 'What if I don’t want to bid on an opportunity?',
            answer: 'Simply ignore the opportunity and once bid is accepted for it, it will no longer show up on your loadboard',
        },
        {
            visible: false,
            question: 'What categories of staff are there?',
            answer: (
                <ul>
                    <li>Admin - Update Warehouse information for all locations, Bid on Opportunities, Add Staff</li>
                    <li>
                        Manager - Update Warehouse information for your location only, Bid on Opportunities, Add Staff
                    </li>
                    <li>User - Bid on Opportunities</li>
                </ul>
            ),
        },
        {
            visible: false,
            question: 'Where can I see my orders?',
            answer: 'The Orders tab will show all Won Opportunities.  You can see the order status, upload your invoice and see if it has been paid by OLIMP.',
        },
        {
            visible: false,
            question: 'Who do I contact if I have questions?',
            answer: (
                <div>
                    <a className="faq__link" href="mailto:warehousing@olimpwarehousing.com">
                        warehousing@olimpwarehousing.com
                    </a>{' '}
                    or{' '}
                    <a className="faq__link" href="tel:+1773-570-3481">
                        773-570-3481
                    </a>
                </div>
            ),
        },
    ])
    const toggleHeight = (index: number) => {
        const newData = faqData.map((item, idx) => ({
            ...item,
            visible: idx === index ? !item.visible : item.visible,
        }))
        setFaqData(newData)
    }

    return (
        <>
            <LoadBoardRoute />
            <div className="wrapper">
                <div className="faq">
                    <h1 className="faq__title">FAQ</h1>
                    <div className="app-card">
                        <div className="faq__container">
                            <FaqList data={faqData} toggleHeight={toggleHeight} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
