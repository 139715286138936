import React from 'react'
import './ServiceNotAvailable.scss'
import Button from '@/components/Button'
export const ServiceNotAvailable = () => {
    return (
        <div className="ServiceNotAvailable">
            <img role={'presentation'} src="/static/504.png" width={200} alt="Service not available" />
            <h1 className="ServiceNotAvailable__title">Service is not available</h1>
            <p className="ServiceNotAvailable__subtitle">
                🤷 We are sorry, but the service is currently not available.
                <br /> Please grab a ☕ and try again later.
            </p>
            <br />
            <Button
                types={['blue']}
                label="Refresh"
                onClick={() => {
                    window.location.href = '/'
                }}
            />
        </div>
    )
}
