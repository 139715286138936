import React, { FC, useEffect, useState } from 'react'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { SubmitRfqResponse, ErrorMessage } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import SidebarHeader, { sidebarClass } from '@/pages/RFQPortal/parts/Sidebar/SidebarHeader'
import { Status } from '@/pages/RFQPortal/parts/Sidebar'
import SidebarButtons from '@/pages/RFQPortal/parts/Sidebar/SidebarButtons'

interface MobileFixedSectionProps {
    step: Steps
    changeStep: (type: 'increment' | 'decrement') => void
    setCurrentStep: (step: Steps) => void
    submissionError: string
    isSubmiting?: boolean
    status: Status
    validateForm?: () => Promise<boolean>
    errorMessages?: { [key: string]: ErrorMessage }
}

const MobileFixedSection: FC<MobileFixedSectionProps> = ({
    step,
    changeStep,
    submissionError,
    isSubmiting = false,
    status,
    validateForm,
    errorMessages,
    setCurrentStep,
}) => {
    const [scrollBottomPosition, setScrollBottomPosition] = useState<number>(0)

    const handleScroll = () => {
        const scrollBottom = document.documentElement.scrollHeight - window.innerHeight - window.scrollY

        if (scrollBottom < 160 && scrollBottom > 50) {
            return
        }

        setScrollBottomPosition(scrollBottom)
    }

    // const receiveMessage = (event: MessageEvent) => {
    //     const scrollBottom = event.data.scrollBottomPosition

    //     if (scrollBottom) {
    //         console.log('scrollBottom', scrollBottom)

    //         if (scrollBottom !== undefined) {
    //             setScrollBottomPosition(scrollBottom)
    //         }
    //     }
    // }

    // useEffect(() => {
    //     console.log(scrollBottomPosition)
    // }, [scrollBottomPosition])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll) // Handle scroll when there is no parent
        // window.addEventListener('message', receiveMessage) // Receive 'scrollBottom'  from parent

        return () => {
            window.removeEventListener('scroll', handleScroll)
            // window.removeEventListener('message', receiveMessage)
        }
    }, [])

    return (
        <div
            className={`${sidebarClass}__mobile-nav ${
                scrollBottomPosition < 100 ? `${sidebarClass}__mobile-nav_relative` : ''
            }`}
        >
            <div className={`${sidebarClass}__mobile-nav-container`}>
                <SidebarHeader status={status} mobile />
                <SidebarButtons
                    setCurrentStep={setCurrentStep}
                    step={step}
                    changeStep={changeStep}
                    submissionError={submissionError}
                    isSubmiting={isSubmiting}
                    mobile
                    validateForm={validateForm}
                    errorMessages={errorMessages}
                />
            </div>
        </div>
    )
}

export default MobileFixedSection
