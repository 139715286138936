import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'

import { cn } from '@/shared/utils/cn'
import { Label } from '@/shared/ui/Label'

const PopoverRoot = PopoverPrimitive.Root

const PopoverTriggerWrapper = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div className={cn('relative', className)} ref={ref} {...props} />
    },
)
PopoverTriggerWrapper.displayName = 'PopoverTriggerWrapper'

const PopoverTrigger = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <PopoverPrimitive.Trigger
        ref={ref}
        className={cn(
            !props.asChild &&
                'peer flex h-9 px-3 w-full items-center justify-between rounded-lg !text-sm border border-solid border-border outline-none focus:border-primary hover:border-primary disabled:hover:border-border bg-transparent !font-normal ring-offset-background placeholder:text-muted-foreground data-[state=open]:border-primary transition-colors duration-75 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
            className,
        )}
        {...props}
    >
        {children}
    </PopoverPrimitive.Trigger>
))
PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName

const PopoverTriggerLabel = React.forwardRef<
    React.ElementRef<typeof Label>,
    React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => (
    <Label
        className={cn(
            'bg-white px-[6px] text-foreground-secondary font-normal absolute z-50 top-0 -translate-y-1/2 left-[6px]',
            className,
        )}
        ref={ref}
        {...props}
    />
))
PopoverTriggerLabel.displayName = 'PopoverTriggerLabel'

const PopoverTriggerIcon = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                className={cn(
                    'absolute right-0 top-1/2 flex -translate-y-1/2 peer-disabled:cursor-not-allowed peer-disabled:pointer-events-none peer-disabled:opacity-50',
                    className,
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
PopoverTriggerIcon.displayName = 'PopoverTriggerIcon'

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            className={cn(
                'z-50 rounded-md border border-border bg-background-secondary p-1 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                className,
            )}
            {...props}
        />
    </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName

const PopoverArrow = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Arrow>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ className, ...props }, ref) => (
    <PopoverPrimitive.Arrow
        className={cn(
            'h-4 w-4 rotate-45 -translate-y-[calc(50%+1px)] bg-white border border-border border-t-transparent border-l-transparent rounded-br',
            className,
        )}
        {...props}
        asChild
    >
        <div />
    </PopoverPrimitive.Arrow>
))
PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName

export {
    PopoverRoot,
    PopoverTriggerWrapper,
    PopoverTrigger,
    PopoverTriggerLabel,
    PopoverTriggerIcon,
    PopoverContent,
    PopoverArrow,
}
