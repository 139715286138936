/* eslint-disable complexity */
import React from 'react'
import { LoadBoardPostBodyDto } from '@OlimpDev/lib/dist/apiv2/load-board'
import dayjs from 'dayjs'

import { LoadBoardServicesType, loadBoardServicesTitle } from './types'
import { setMainStyle } from './helper'
import './loadBoardStyles.scss'
import { LoadBoardCheckboxType, useLoadBoardNames } from '@/utils/helper'

export const ReviewPage = ({ values }: { values: LoadBoardPostBodyDto }) => {
    const isWarehousing = values?.services?.includes(loadBoardServicesTitle.Warehousing)
    const isTransloading = values?.services?.includes(loadBoardServicesTitle.Transloading)
    const isDrayage = values?.services?.includes(loadBoardServicesTitle.Drayage)

    const { convertCheckboxName } = useLoadBoardNames()

    return (
        <div>
            <div className={setMainStyle('_review')}>
                <div className={setMainStyle('_post-details_wrap')}>
                    <div className={setMainStyle('_post-details_left')} style={{ width: 400 }}>
                        <div className={setMainStyle('_post-details_flex')}>
                            <div className={setMainStyle('_post-details_flex')}>
                                <div className={setMainStyle('_post-details_list')}>
                                    <div className={setMainStyle('_post-details_item _post-details_p')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>Commodity </span>
                                        <span className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.commodity}
                                        </span>
                                    </div>
                                    <div className={setMainStyle('_post-details_item _post-details_p')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>Volume </span>
                                        <span className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.volume}
                                        </span>
                                    </div>
                                    <div className={setMainStyle('_post-details_item _post-details_p')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>
                                            Freight Environment{' '}
                                        </span>
                                        <span className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.freightEnvironment}
                                        </span>
                                    </div>
                                    <div className={setMainStyle('_post-details_item _post-details_p')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>Certifications </span>
                                        <span className={setMainStyle('_post-details_pb,capitalize')}>
                                            {convertCheckboxName(
                                                values?.certifications as LoadBoardCheckboxType,
                                                LoadBoardCheckboxType.certifications,
                                            )}
                                        </span>
                                    </div>
                                    <div className={setMainStyle('_post-details_item _post-details_p,flex')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>Equipment </span>
                                        <span
                                            style={{ maxWidth: 210, display: 'inline-block' }}
                                            className={setMainStyle('_post-details_pb,capitalize')}
                                        >
                                            {convertCheckboxName(
                                                values?.equipment as LoadBoardCheckboxType,
                                                LoadBoardCheckboxType.equipment,
                                            )}
                                        </span>
                                    </div>
                                    <div className={setMainStyle('_post-details_item _post-details_p,flex')}>
                                        <span style={{ width: 160, display: 'inline-block' }}>Features </span>
                                        <span
                                            className={setMainStyle(' _post-details_pb,capitalize')}
                                            style={{ maxWidth: 210, display: 'inline-block' }}
                                        >
                                            {convertCheckboxName(
                                                values?.features as LoadBoardCheckboxType,
                                                LoadBoardCheckboxType.features,
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={setMainStyle('_post-details_right')} style={{ width: 400 }}>
                        {/* Warehousing */}
                        {isWarehousing && (
                            <div className={setMainStyle('_post-details_line')}>
                                <h5 className={setMainStyle('_post-details_serviceTitle')}>
                                    {loadBoardServicesTitle.Warehousing}
                                </h5>

                                <div className={setMainStyle('_post-details_bb')}>
                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                        >
                                            Format
                                        </p>
                                        <p className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.warehousing?.freightFormat}
                                        </p>
                                    </div>
                                    <div className={setMainStyle('_post-details_list _post-details_flex')}>
                                        <p
                                            style={{ marginBottom: 0, width: 160 }}
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                        >
                                            In/Out Dates
                                        </p>
                                        <p
                                            style={{ marginBottom: 0 }}
                                            className={setMainStyle('_post-details_item _post-details_pb')}
                                        >
                                            {`${dayjs(values.warehousing?.inDate).format('MM/DD/YYYY')} - ${dayjs(
                                                values.warehousing?.outDate,
                                            ).format('MM/DD/YYYY')}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Transloading */}
                        {isTransloading && (
                            <div className={setMainStyle('_post-details_line')}>
                                <h5 className={setMainStyle('_post-details_serviceTitle')}>
                                    {loadBoardServicesTitle.Transloading}
                                </h5>

                                <div className={setMainStyle('_post-details_bb')}>
                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                        >
                                            LFD
                                        </p>
                                        <p className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {dayjs(values.transloading?.lastFreeDate).format('MM/DD/YYYY')}
                                        </p>
                                    </div>

                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                        >
                                            Format
                                        </p>
                                        <p className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.transloading?.freightFormat}
                                        </p>
                                    </div>

                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            style={{ marginBottom: 0 }}
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p  _post-details_l',
                                            )}
                                        >
                                            In/Out Dates
                                        </p>
                                        <p
                                            style={{ marginBottom: 0 }}
                                            className={setMainStyle('_post-details_item _post-details_pb')}
                                        >
                                            {`${dayjs(values.transloading?.inDate).format('MM/DD/YYYY')} - ${dayjs(
                                                values.transloading?.outDate,
                                            ).format('MM/DD/YYYY')}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Drayage */}
                        {isDrayage && (
                            <div className={setMainStyle('_post-details_line')}>
                                <h5 className={setMainStyle('_post-details_serviceTitle')}>
                                    {loadBoardServicesTitle.Drayage}
                                </h5>

                                <div className={setMainStyle('_post-details_bb')}>
                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                            style={{ width: 160 }}
                                        >
                                            ETA
                                        </p>
                                        <p
                                            style={{ textTransform: 'lowercase' }}
                                            className={setMainStyle('_post-details_item _post-details_pb')}
                                        >
                                            {dayjs(values.drayage?.timeFrom, 'h:mm').format('h:mm a')} -{' '}
                                            {dayjs(values.drayage?.timeTo, 'h:mm').format('h:mm a')}
                                        </p>
                                    </div>

                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            style={{ width: 160 }}
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p  _post-details_l',
                                            )}
                                        >
                                            Port
                                        </p>
                                        <p className={setMainStyle('_post-details_item _post-details_pb ')}>
                                            {values.drayage?.port}
                                        </p>
                                    </div>
                                    <div className={setMainStyle('_post-details_list _post-details_flex')}>
                                        <p
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p  _post-details_l',
                                            )}
                                            style={{ width: 160 }}
                                        >
                                            Terminal
                                        </p>
                                        <p className={setMainStyle('_post-details_item _post-details_pb')}>
                                            {values.drayage?.terminal}
                                        </p>
                                    </div>

                                    <div
                                        className={setMainStyle('_post-details_list _post-details_flex')}
                                        style={{ width: '100%' }}
                                    >
                                        <p
                                            style={{ marginBottom: 0, width: 160 }}
                                            className={setMainStyle(
                                                '_post-details_item _post-details_p _post-details_l',
                                            )}
                                        >
                                            In/Out Dates
                                        </p>
                                        <p
                                            style={{ marginBottom: 0 }}
                                            className={setMainStyle('_post-details_item _post-details_pb')}
                                        >
                                            {`${dayjs(values.warehousing?.inDate).format('MM/DD/YYYY')} - ${dayjs(
                                                values.warehousing?.outDate,
                                            ).format('MM/DD/YYYY')}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={setMainStyle('_review_notes')}>
                <h4 className={setMainStyle('_review_notes_title')}>{LoadBoardServicesType.AdditionalDetails}</h4>
                <p className={setMainStyle('_review_notes_text')}>{values?.notes}</p>
            </div>
        </div>
    )
}
