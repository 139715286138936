import axios from 'axios'
import qs from 'query-string'

export const callAE = async (endpoint: string, request: any) => {
    try {
        await axios.post(endpoint, qs.stringify(request), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            maxRedirects: 0,
        })
        console.log('AE Called')
        return null
    } catch (e) {
        console.error(e)
    }
}
