import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { cn } from '@/shared/utils/cn'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { AccordionMultipleProps, AccordionSingleProps } from '@radix-ui/react-accordion'

const AccordionRoot = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item ref={ref} className={cn('border-b border-border', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

type AccordionTriggerProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    icon?: React.ReactNode
}

const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, AccordionTriggerProps>(
    ({ className, children, icon, ...props }, ref) => (
        <AccordionPrimitive.Header className="flex">
            <AccordionPrimitive.Trigger
                ref={ref}
                className={cn(
                    'flex flex-1 items-center justify-between h-9 px-0 !text-sm !font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:hover:no-underline',
                    className,
                )}
                {...props}
            >
                {children}
                {icon !== undefined ? (
                    icon
                ) : (
                    <InChevronDown className="h-3 w-3 mr-1 shrink-0 transition-transform duration-200 text-foreground-secondary/70" />
                )}
            </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
    ),
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
        {...props}
    >
        <div className={cn('pb-2 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export type AccordionOption = {
    value: string
    trigger: React.ReactNode
    content: React.ReactNode
}

type AccordionProps = (AccordionSingleProps | AccordionMultipleProps) & {
    items: AccordionOption[]

    itemClassName?: string
    triggerClassName?: string
    contentClassName?: string
}

const Accordion = ({
    items,
    className,
    itemClassName,
    triggerClassName,
    contentClassName,
    ...rest
}: AccordionProps) => (
    <AccordionRoot {...rest} className={className}>
        {items.map((item) => (
            <AccordionItem key={item.value} value={item.value} className={itemClassName}>
                <AccordionTrigger className={triggerClassName}>{item.trigger}</AccordionTrigger>
                <AccordionContent className={contentClassName}>{item.content}</AccordionContent>
            </AccordionItem>
        ))}
    </AccordionRoot>
)

export { AccordionRoot, AccordionItem, AccordionTrigger, AccordionContent, Accordion }
