import { useUserState } from '@/store/user'
import cn from 'classnames'
import { Helmet } from 'react-helmet-async'
import React from 'react'
import { acceptTermsOfService, useTermsOfService } from '@shared/apiv2/terms-of-service'
import { Loader } from '@/components/Loader'
import Checkbox from '@/components/Checkbox'
import Button from '@/components/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import ROUTES from '@/router'
import { toast } from 'react-toastify'
import './UserTermsOfService.scss'

const PAGE_TITLE = 'Olimp - Terms of Service'

const CLASS_NAME = 'user-tos-agreement'

export const UserTermsOfService = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isAgreed, setIsAgreed] = React.useState(false)
    const { agreed, loading, data: tos } = useTermsOfService()

    const {
        state: { userInfo },
    } = useUserState()

    const acceptTos = async () => {
        setIsLoading(true)
        await acceptTermsOfService()
        localStorage.removeItem('tosSkipped')
        toast('New Terms of Service was accepted', { type: 'success' })
        setIsLoading(false)
        navigate(ROUTES.index)
    }

    const skipTos = async () => {
        localStorage.setItem('tosSkipped', 'true')
        toast('New Terms of Service was not accepted - functionality will be limited', { type: 'warning' })
        navigate(ROUTES.index)
    }

    if (loading) {
        return <Loader />
    }
    if (agreed) {
        return <Navigate to={{ pathname: ROUTES.index }} />
    }

    return (
        <div className={cn(CLASS_NAME, 'wrapper', 'page')}>
            <Helmet>
                <title>{PAGE_TITLE}</title>
                <meta name="description" content={PAGE_TITLE} />
            </Helmet>
            <div className={`${CLASS_NAME}__wrapper`}>
                <div className="page-title">Terms Of Service</div>

                <div className={`${CLASS_NAME}__inner`}>
                    <div className="tos-agreement" dangerouslySetInnerHTML={{ __html: tos?.content }}></div>
                </div>
                <div className={`${CLASS_NAME}__footer`}>
                    {tos && (
                        <Checkbox
                            label={tos.message}
                            value={isAgreed}
                            onChange={() => {
                                setIsAgreed(!isAgreed)
                            }}
                        />
                    )}
                    <div className={`${CLASS_NAME}__footer__buttons`}>
                        {!isAgreed && (
                            <Button types={['plain', 'large']} onClick={skipTos}>
                                Skip, I will do it later
                            </Button>
                        )}
                        {isAgreed && (
                            <Button types={['blue', 'large']} onClick={acceptTos} loading={isLoading}>
                                Accept
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
