import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InTransloading = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5664 8.96874C15.9754 8.49624 15 8.83043 15 9.49905V11H13.125L6 3.39999C5.76562 3.14999 5.39453 2.99999 5 2.99999H1.25C0.558984 2.99999 0 3.44687 0 3.99999C0 4.55312 0.558984 4.99999 1.25 4.99999H4.375L11.5 12.6C11.7344 12.8531 12.1055 13 12.5 13H14.9988V14.4981C14.9988 15.1666 16.009 15.5019 16.6 15.0294L19.6898 12.53C20.0565 12.2366 20.0565 11.7616 19.6898 11.4684L16.5664 8.96874ZM13.125 4.99999H14.9988V6.50093C14.9988 7.16937 16.0094 7.50374 16.5652 7.03124L19.6551 4.53187C20.0218 4.2384 20.0217 3.76343 19.655 3.4703L16.5652 0.970929C15.9742 0.498429 14.964 0.83371 14.964 1.50218V2.99999H12.5C12.107 2.99999 11.7359 3.14843 11.5004 3.4003L9.92188 5.08437L11.4492 6.74999L13.125 4.99999ZM4.375 11H1.25C0.558984 11 0 11.4472 0 12C0 12.5528 0.558984 13 1.25 13H5C5.39297 13 5.76406 12.8516 5.99961 12.5997L7.57773 10.9162L6.01562 9.24999L4.375 11Z" />
            </svg>
        </IconBase>
    )
}
