import { useEffect } from 'react'
import { isProdEnv } from '@/pages/RFQPortal/helpers/rfqHelper'

/* eslint-disable */
// prettier-ignore
const HotjarTracking = () => {
    useEffect(() => {
        if (!isProdEnv) {
            return
        }
        ;(function (h: any, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    ;(h.hj.q = h.hj.q || []).push(arguments)
                }
            h._hjSettings = { hjid: 4964672, hjsv: 6 }
            // @ts-ignore
            a = o.getElementsByTagName('head')[0]
            // @ts-ignore
            r = o.createElement('script')
            // @ts-ignore
            r.async = 1
            // @ts-ignore
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
            // @ts-ignore
            a.appendChild(r)
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

        // Cleanup function to remove script when component unmounts
        return () => {
            const script = document.querySelector(`script[src*="hotjar-${(window as any)._hjSettings.hjid}.js"]`)
            if (script) {
                script.remove()
            }
        }
    }, [])

    return null
}

export default HotjarTracking
