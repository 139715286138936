import React, { useRef, useState } from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger } from '@/shared/ui/Accordion'
import { InChevronDown } from '@/shared/ui/Icons/InChevronDown'
import { Button } from '@/shared/ui/Button'
import { Quote } from '@/entities/quotes/model'
import { OrderQuoteBreakdown } from '../OrderQuoteBreakdown'
import { useAppSelector } from '@/app/store'
import { RequoteModal } from './RequoteModal'
import { AcceptModal } from './AcceptModal'

export const OrderQuoteDemandList = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quoteRef = useRef<Quote | null>(null)

    /** Control dialogs open state */
    const [requoteDialogOpen, setRequoteDialogOpen] = useState(false)
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className="space-y-4">
            <span className="text-2xl font-semibold">Quotes</span>
            <AccordionRoot type="multiple" className="flex flex-col gap-5">
                {order.bids.map((quote) => (
                    <AccordionItem
                        key={quote.id}
                        value={quote.id.toString()}
                        className="border border-border rounded-xl bg-background-tertiary p-4"
                    >
                        <div className="flex flex-col sm:flex-row justify-between gap-1">
                            <AccordionTrigger
                                className="flex-grow-0 flex-none !text-xl !font-semibold justify-start gap-3"
                                icon={null}
                            >
                                {quote.warehouseAddress}
                                <InChevronDown
                                    size={20}
                                    className="text-foreground-secondary/70 mr-1 shrink-0 transition-transform duration-200"
                                />
                            </AccordionTrigger>
                            <div className="flex flex-col gap-1 md:flex-row justify-between items-center">
                                <div className="flex gap-3 w-full md:w-fit">
                                    <Button
                                        variant="outlined"
                                        className="border-danger text-danger hover:bg-danger/10 px-6 basis-0 flex-grow shrink-0 md:flex-grow-0 md:basis-auto"
                                        onClick={() => {
                                            quoteRef.current = quote
                                            setRequoteDialogOpen(true)
                                        }}
                                        disabled={isLoading}
                                    >
                                        Requote
                                    </Button>
                                    <Button
                                        className="px-6 flex-grow basis-0 shrink-0 md:flex-grow-0 md:basis-auto"
                                        onClick={() => {
                                            quoteRef.current = quote
                                            setAcceptDialogOpen(true)
                                        }}
                                        disabled={isLoading}
                                    >
                                        Accept Quote
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <AccordionContent className="p-0">
                            <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3">
                                <OrderQuoteBreakdown quote={quote} />
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </AccordionRoot>

            {/** Accept Quote Dialog */}
            <AcceptModal
                open={acceptDialogOpen}
                onOpenChange={setAcceptDialogOpen}
                quoteRef={quoteRef}
                order={order}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />

            {/** Requote Dialog */}
            <RequoteModal
                open={requoteDialogOpen}
                onOpenChange={setRequoteDialogOpen}
                quoteRef={quoteRef}
                order={order}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </div>
    )
}
