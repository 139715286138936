import React, { ChangeEvent, useEffect, useMemo, useState, FC } from 'react'
import cn from 'classnames'
import { LoadBoardListFilters, useLoadBoardPosts } from '@shared/apiv2/load-board'
import { SortingState } from '@tanstack/react-table'
import { FeatureFlags, useFeatureFlags } from '@shared/apiv2/feature-flags'
import './LoadBoardSidebar.scss'
import Button from '@/components/Button'
import { showLoadBoardModal } from '@/parts/Modals/LoadBoardModal'
import { LoadBoardSidebar } from './LoadBoardSidebar'
import { LoadBoardTable } from './LoadBoardTable'
import { useUserState } from '@/store/user'
import { UserRole } from '@/store/user/user'
import { useSimpleWarehouse } from '@shared/apiv2/warehouse'
import { LoggedInRoute } from '@/components/PrivateRoute/PrivateRoute'
import Tabs, { TabsVariant } from '@/components/Tabs'
import { LoadBoardWhTabs, setTabsListForCurrentEntity } from '@/pages/LoadBoard/LoadBoardHelper'
import useSWR from 'swr'
import { EntityType } from '@shared/api/types'
import { defaultSWRConfig, getSWRFetcher } from '@shared/apiv2/api'
import useDebounce from '@/utils/useDebounce'
import { Navigate, useNavigate } from 'react-router-dom'

export const useLoadBoardTotals = (shouldFetch = false) =>
    useSWR<any>(shouldFetch ? '/load-board/totals' : null, getSWRFetcher, defaultSWRConfig)

const CLASS_NAME = 'load-board-sidebar'

let searchTimeout: any = null

const TabItem = ({ name, total }: any) => {
    return (
        <span className="tabs__item">
            <span className="tabs__name">{name}</span>
            {total > 0 && <span className="tabs__number">{total}</span>}
        </span>
    )
}

// eslint-disable-next-line complexity
export const LoadBoardRoutes = () => {
    const { state: userState } = useUserState()
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'startDate',
            desc: true,
        },
    ])
    const [tabValue, setTabValue] = useState<string | number>(LoadBoardWhTabs[0].value)
    const [distance, setDistance] = useState(100)
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
    const [searchInput, setSearchInput] = useState({ name: '', value: '' })
    const debouncedInputValues = useDebounce(searchInput, 500)
    const { isEnabled } = useFeatureFlags()
    const [showFilter, setShowFilter] = useState(false)

    const totals = useLoadBoardTotals(userState.isLoggedIn)
    const navigate = useNavigate()

    const [filters, setFilters] = useState<LoadBoardListFilters>({
        address: '',
        search: '',
        searchAroundMe: false,
    })

    const url = new URL(window.location.href)
    const accessToken = url.searchParams.get('access_token')

    const entity = { type: EntityType.warehouse }
    const isCarrier = false
    const loading = false
    const { data: whData } = useSimpleWarehouse(userState.isLoggedIn)
    const { data, error, revalidate } = useLoadBoardPosts(
        {
            sorting: sorting[0],
            filters: {
                ...filters,
                category: tabValue,
                services:
                    filters.services
                        ?.split(',')
                        ?.map((s) => s.replace('services_', ''))
                        ?.join(',') ?? '',
                startDate: filters.startDate,
                endDate: filters.endDate,
                warehouseCoordinateX: whData?.shippingAddress?.latitude,
                warehouseCoordinateY: whData?.shippingAddress?.longitude,
            } as any,
            pagination,
            access_token: accessToken,
        },
        !userState.isLoggedIn,
    )

    const tabItems = useMemo(() => {
        const tabs = setTabsListForCurrentEntity((entity?.type as EntityType) ?? EntityType.warehouse)
        return tabs.map((tab) => ({
            ...tab,
            totalValue: totals?.data?.[tab.value] ?? 0,
        }))
    }, [entity?.type, totals?.data])

    const handleSortChange = (s: SortingState) => {
        setPagination({ ...pagination, pageIndex: 0 })
        setSorting(s)
    }

    // if public LB and token is invalid, redirect to login
    useEffect(() => {
        if (!userState.isLoggedIn && error?.status === 401) {
            navigate('/login')
        }
    }, [error])

    // if public LB and no query token, redirect to login
    useEffect(() => {
        if (!accessToken && !userState.isLoggedIn) {
            navigate('/login')
        }
    }, [accessToken, url])

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            [searchInput.name]: searchInput.value,
        }))

        if (debouncedInputValues.value) {
            setPagination({ ...pagination, pageIndex: 0 })
        }
    }, [debouncedInputValues])

    if (!isEnabled(FeatureFlags.WH_LOAD_BOARD)) {
        return null
    }

    if (userState.isLoggedIn && userState.userInfo!.role === UserRole.FREIGHT_BROKER_MANAGER) {
        return <Navigate to="/rfq" />
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        searchTimeout = setTimeout(() => {
            setPagination({ ...pagination, pageIndex: 0 })

            setFilters({
                ...filters,
                [e.target.name]: e.target.value,
            })
        }, 500)
    }

    const loadBoardServicesValues = (name: string, serviceName: string) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (filters[serviceName]?.split(',').indexOf?.(name) ?? -1) >= 0

    const onCheckboxFilterChange = (
        e: ChangeEvent<HTMLInputElement>,
        filterCategory: keyof LoadBoardListFilters,
        exactVal?: string,
    ) => {
        setPagination({ ...pagination, pageIndex: 0 })

        setFilters((f) => {
            const srv = (f[filterCategory] as string)?.split(',') ?? []
            const val = (e.target as HTMLInputElement).value
            const newArr = val ? [...srv, exactVal] : srv.filter((s) => s && s !== exactVal)
            return {
                ...f,
                [filterCategory]: newArr.filter(Boolean).join(','),
            }
        })
    }

    // change LB checkbox services
    const onLoadBoardServicesChanged = (e: ChangeEvent, val: string, nameOfService: string) =>
        onCheckboxFilterChange(e as any, nameOfService as any, val)

    // update filter
    const updateFilter = (name: string, param: any) => {
        setPagination({ ...pagination, pageIndex: 0 }) // reset page
        setFilters({ ...filters, [name]: param })
    }

    const onPeriodChange = ({ start, end }: any) => {
        setPagination({ ...pagination, pageIndex: 0 })

        setFilters({
            ...filters,
            endDate: end?.endOf('day')?.toDate().toISOString(),
            startDate: start?.startOf('day')?.toDate().toISOString(),
        } as any)
    }

    const clearFilter = () => {
        setShowFilter(false)
        setPagination({ ...pagination, pageIndex: 0 })
        setFilters({
            address: '',
            search: '',
            searchAroundMe: false,
            services: '',
        })
        setSearchInput({ name: '', value: '' })
    }

    const loadBoardSidebarProps = {
        onLoadBoardServicesChanged,
        loadBoardServicesValues,
        updateFilter,
        onPeriodChange,
        CLASS_NAME,
        filters,
        loading,
        isCarrier,
        setFilters,
        distance,
        whData,
        setDistance,
        searchInput,
        setSearchInput,
        setShowFilter,
        showFilter,
        clearFilter,
    }

    const loadBoardTableProps = {
        CLASS_NAME,
        search,
        handleSearchChange,
        isCarrier,
        sorting,
        handleSortChange,
        data,
        filters,
        pagination,
        setPagination,
        setFilters,
        error,
        tabValue,
    }

    return (
        <>
            {userState.isLoggedIn && <LoggedInRoute />}
            <div className={cn(CLASS_NAME)}>
                <div className={cn(`${CLASS_NAME}__header`)}>
                    <div className={`${CLASS_NAME}__title-container`}>
                        <h1 className={`${CLASS_NAME}__title`}>Load Board</h1>
                        {userState.isLoggedIn && (
                            <Tabs
                                className={`${CLASS_NAME}__item`}
                                list={tabItems}
                                variant={TabsVariant.primary}
                                value={tabValue!}
                                name="who am I"
                                onChange={setTabValue}
                                Item={TabItem}
                                size={14}
                            />
                        )}
                    </div>

                    {/* if entity carrier */}
                    {userState.userInfo?.role === UserRole.FREIGHT_BROKER_MANAGER && (
                        <Button
                            className={cn(`${CLASS_NAME}__button`)}
                            types={['small', 'blue']}
                            onClick={() => {
                                showLoadBoardModal({
                                    props: {
                                        title: 'Order Details',
                                        revalidate: revalidate, // revalidate table when cancel load board modal
                                    },
                                })
                            }}
                        >
                            New Post +
                        </Button>
                    )}
                </div>
                <div className={cn(`${CLASS_NAME}__content`)}>
                    {/* SideBar */}
                    <LoadBoardSidebar {...loadBoardSidebarProps} />

                    {/* Table */}
                    <LoadBoardTable {...loadBoardTableProps} setShowFilter={setShowFilter} showFilter={showFilter} />
                </div>
            </div>
        </>
    )
}

export const LoadBoardRoute: FC = () => {
    const { state: userState } = useUserState()

    if (userState.userInfo?.role !== UserRole.WAREHOUSE_MANAGER) {
        return <Navigate to="/login" />
    }

    return null
}
