import React from 'react'
import { cn } from '@/shared/utils/cn'

const Section = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <section
            ref={ref}
            className={cn('bg-background-secondary shadow-shadow rounded-xl w-full p-3 md:p-5', className)}
            {...props}
        />
    ),
)
Section.displayName = 'Section'

export { Section }
