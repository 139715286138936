import React, { FC, useEffect, useState, useCallback } from 'react'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'

import Button from '@/components/Button'
import useStaffColumns from './useStaffColumns'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import Search from '@/components/Search'
import { showAddStaffModal } from '@/parts/Modals/AddStaff'
import useCustomPagination, { PaginationInfo } from '@/hooks/useCustomPagination'
import { PermissionLevel } from '@/parts/Modals/ManageStaffModal'

import './Staff.scss'

const staffClass = 'adminStaff'

export interface StaffInfo {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    title: string
    // location: string
    // visibility: boolean
    userType: keyof typeof PermissionLevel | null
    // admin: boolean
    accountName: string //Warehouse name
    accountId: string
}

const AdminStaff: FC = () => {
    const [updateStaffList, setUpdateStaffList] = useState<boolean>(false)

    const triggerUpdateStaffList = useCallback(() => {
        setUpdateStaffList((prev) => !prev)
    }, [])

    const staffColumns = useStaffColumns(triggerUpdateStaffList)

    const [staffList, setStaffList] = useState<StaffInfo[]>([])
    const [isStaffListLoading, setIsStaffListLoading] = useState<boolean>()
    const [pagination, setPagination] = useState<PaginationInfo>({ pageIndex: 0, pageSize: 10 })
    const [filter, setFilter] = useState('')

    const { paginatedItems } = useCustomPagination(staffList, pagination)

    const handleFilter = (searchQuery: string) => {
        setFilter(searchQuery)
        setPagination({ ...pagination, pageIndex: 0 })
    }

    const fetchStaff = async (searchFilter: string) => {
        const updatedSearchFilter = searchFilter.trim().startsWith('+') ? searchFilter.substring(1) : searchFilter

        try {
            setIsStaffListLoading(true)

            const response: any = await HttpV2.get(`/accounts/contacts?search=${updatedSearchFilter}`)
            const { data } = response

            setStaffList(data)

            return { status: 'success', data: data }
        } catch (error: any) {
            return { status: 'error', message: error.message }
        } finally {
            setIsStaffListLoading(false)
        }
    }

    useEffect(() => {
        fetchStaff(filter)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, updateStaffList])

    return (
        <div className="app-page-container app-page-container_flex">
            <div className="app-page">
                <div className="app-card">
                    <div className="olimp-table__header">
                        <h2>All Staff</h2>
                        <div className="olimp-table__search-wrapper">
                            <Search
                                placeholder="Search by Name, Title, Phone, Email, Warehouse, and User Type"
                                onSearch={handleFilter}
                                filterValue={filter}
                                label="Search"
                            />
                        </div>
                        <Button
                            types={['bordered']}
                            onClick={() =>
                                showAddStaffModal({
                                    props: {},
                                })
                            }
                        >
                            Add Staff
                        </Button>
                    </div>
                    <ModernTable
                        columns={staffColumns}
                        data={paginatedItems}
                        loading={isStaffListLoading}
                        total={staffList.length}
                        pagination={pagination}
                        onPaginationChange={(pag) => {
                            setPagination(pag)
                            return pag
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminStaff
