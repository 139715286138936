import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/store'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { WarehouseOrderInvoice, WarehouseOrderStatus } from '@/entities/warehouse-orders/model'
import { Button } from '@/shared/ui/Button'
import { InFileInvoice } from '@/shared/ui/Icons/InFileInvoice'
import { UploadInvoiceModal } from '@/features/modals/UploadInvoiceModal'
import { Account } from '@/entities/accounts/model'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { Badge } from '@/shared/ui/Badge'
import { HttpV2 } from '@shared/apiv2/api'
import saveAs from 'file-saver'
import { toast } from 'react-toastify'
import { ImSpinner8 } from '@react-icons/all-files/im/ImSpinner8'
import { orderPageActions } from '@/pages/Order/model'
import { DialogContent, DialogRoot, DialogTrigger } from '@/shared/ui/Dialog'

export const OrderQuoteInvoice = () => {
    const dispatch = useAppDispatch()

    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder
    const warehouse = useAppSelector((state) => state.orderQuoteSupply.selectedWarehouse) as Account
    const quote = useAppSelector((state) => state.orderQuoteSupply.selectedQuote) as Quote

    const [isLoading, setIsLoading] = useState(false)

    const onViewInvoice = () => {
        if (!order.invoice?.documentId) return

        setIsLoading(true)

        HttpV2.get(`/accounts/${warehouse.id}/documents/${order.invoice.documentId}`, {
            responseType: 'blob',
        })
            .then((data) => {
                // get content disposition
                const contentDisposition = data?.headers['content-disposition']
                const filename = contentDisposition?.split(';')[1].split('=')[1].trim()
                saveAs(data?.data, filename)
                toast('File successfully downloaded', { type: 'success' })
            })
            .catch((err) => {
                console.log('Error download file:', err)
                toast.error('Could not download the file.')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const onInvoiceUploaded = (data: WarehouseOrderInvoice) => {
        dispatch(orderPageActions.setOrder({ ...order, invoice: data }))
    }

    if (
        quote.status !== QuoteStatus.ACCEPTED ||
        !order.rfqStatus ||
        (order.rfqStatus !== WarehouseOrderStatus.Booked &&
            order.rfqStatus !== WarehouseOrderStatus.Dropped &&
            order.rfqStatus !== WarehouseOrderStatus.Finished)
    ) {
        return null
    }

    if (!order.invoice?.id) {
        return (
            <div className="bg-background-tertiary border border-primary rounded-lg p-3 flex justify-between gap-3 flex-col sm:flex-row">
                <div className="flex items-center gap-2">
                    <InFileInvoice className="h-6 w-auto text-primary" />
                    <p className="font-medium">Please upload your invoice so you can get paid</p>
                </div>
                <DialogRoot>
                    <DialogTrigger asChild>
                        <Button className="shrink-0" disabled={isLoading}>
                            Upload Invoice
                            {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <UploadInvoiceModal
                            warehouseId={warehouse.id}
                            orderKey={order.id}
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            onSuccess={onInvoiceUploaded}
                        />
                    </DialogContent>
                </DialogRoot>
            </div>
        )
    }

    return (
        <div className="bg-background-tertiary border border-primary rounded-lg p-3 flex justify-between gap-3 flex-col sm:flex-row">
            <div className="flex items-center gap-2">
                <InFileInvoice className="h-6 w-auto text-primary" />
                <p className="font-medium">Invoice Status:</p>
                {order.paid ? (
                    <Badge
                        variant="secondary"
                        className="border-0 bg-success-light text-success-dark hover:bg-success/50"
                    >
                        Paid
                    </Badge>
                ) : (
                    <Badge
                        variant="secondary"
                        className="border-0 bg-warning-light text-warning-dark hover:bg-warning/50"
                    >
                        Uploaded
                    </Badge>
                )}
            </div>
            <Button variant="outlined" onClick={onViewInvoice} disabled={isLoading}>
                View Invoice
                {isLoading && <ImSpinner8 className="ml-2 animate-spin" />}
            </Button>
        </div>
    )
}
