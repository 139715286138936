import React, { FC, createContext, useState, ReactNode, useContext } from 'react'

export enum ModalId {
    CalculateSpace = 'calculateSpace',
}

interface ProviderProps {
    children: ReactNode
}

interface ProviderValue {
    expandedId: string | null
    setExpandedId: (id: string | null) => void
}

// Define outside of provider to prevent recreating the context every time the component re-renders
export const ExpandedOppContext = createContext<ProviderValue | undefined>(undefined)

const ExpandedOppProvider: FC<ProviderProps> = ({ children }) => {
    const [expandedId, setExpandedId] = useState<string | null>(null)

    const ExpandedOppContextValue: ProviderValue = {
        expandedId,
        setExpandedId,
    }

    return <ExpandedOppContext.Provider value={ExpandedOppContextValue}>{children}</ExpandedOppContext.Provider>
}

export const useExpandedOppContext = (): ProviderValue => {
    const context = useContext(ExpandedOppContext)

    if (context === undefined) {
        throw new Error('useExpandedOppContext must be used within a ExpandedOppProvider')
    }

    return context
}

export default ExpandedOppProvider
