import React, { FC, useEffect, useRef, useState } from 'react'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import Button from '@/components/Button'
import './SessionModal.scss'
import dayjs from 'dayjs'
import EventBus from '@shared/common/eventBus'
;(Modals as any).SessionExpirationModal = 2414
export const sessionModalID = (Modals as any).SessionExpirationModal

interface SessionExpirationModalProps {
    expirationTime: number
    onExtendSession: () => Promise<void>
    onClosed: () => void
}

const SessionExpirationModal: FC<SessionExpirationModalProps> = ({ expirationTime, onExtendSession, onClosed }) => {
    const [loading, setLoading] = useState(false)
    const [timeLeft, setTimeLeft] = useState<string>(calculateTimeLeft())

    useEffect(() => {
        EventBus.bus.on('CLOSE_MODAL' as any, onClosed)
    }, [])

    useEffect(() => {
        const el = document.querySelector('.react-responsive-modal-containerCenter')
        if (el && window.parent !== window.self) {
            el.classList.add('modal-session-expiration-container')
        }
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft())
        }, 1000)

        return () => {
            if (el) {
                el.classList.remove('modal-session-expiration-container')
            }

            clearInterval(interval)
        }
    }, [])

    const extend = async () => {
        setLoading(true)
        await onExtendSession()
        setLoading(false)
    }

    function calculateTimeLeft(): string {
        return dayjs(expirationTime).fromNow()
    }
    return (
        <div className="modal-session-expiration">
            <div className="modal-description">
                You will be logged out {timeLeft}.<br />
                <br /> Please extend your session to continue your work.
            </div>
            <div className="btns">
                <Button loading={loading} label="Extend Session" onClick={extend} types={['blue']} />
            </div>
        </div>
    )
}

ModalRegistry.get().register<SessionExpirationModalProps>(sessionModalID, {
    id: 'SessionExpirationModalProps',
    className: 'modal-session-expiration',
    size: 'medium',
    Component: SessionExpirationModal,
    title: 'Your session will expire soon',
})

export const showSessionExpirationModal = (props: ModalPartialProps<SessionExpirationModalProps>): void =>
    ModalRegistry.get().show<SessionExpirationModalProps>(sessionModalID, props)
export const useSessionExpiration = (
    onExtendSession: () => Promise<void>,
    onFinalEvent: () => void,
    totalMinutes = 20,
    expirationTime?: number,
): void => {
    const [modalOpen, setModalOpen] = useState(false)
    const expirationRef = useRef<number | undefined>(expirationTime)

    // Update the ref every time the expirationTime changes
    useEffect(() => {
        expirationRef.current = expirationTime
    }, [expirationTime])
    useEffect(() => {
        const checkTimeLeft = () => {
            if (!expirationRef.current) return

            const currentTime = Date.now()
            const timeLeft = Math.floor((expirationRef.current - currentTime) / 1000)
            if (timeLeft <= 0) {
                onFinalEvent()
            } else if (timeLeft !== undefined && timeLeft <= totalMinutes * 60 && !modalOpen) {
                setModalOpen(true)
                showSessionExpirationModal({
                    props: {
                        expirationTime: expirationRef.current!,
                        onExtendSession: async () => {
                            await onExtendSession()
                            setModalOpen(false)
                        },
                        onClosed: () => {
                            setModalOpen(false)
                        },
                    },
                })
            }
        }

        // Check immediately and then set an interval
        const intervalId = setInterval(checkTimeLeft, 1000)

        return () => clearInterval(intervalId)
    }, []) // Empty dependency array means this runs once on mount
}
