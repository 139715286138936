import React, { FC } from 'react'
import { showFeedbackModal } from '@/parts/Modals/FeedbackModal'
import { Section } from '@/components/Section'
import Button from '@/components/Button'

const HelpLinks: FC = () => {
    const contactSupport = () => {
        showFeedbackModal({
            props: {},
        })
    }

    return (
        <Section className="flex flex-col gap-3">
            <h3 className="text-xl">Help Links</h3>
            <ul>
                <li>
                    <Button className="!h-auto p-0 text-primary underline !font-normal" onClick={contactSupport}>
                        Contact Support
                    </Button>
                </li>
            </ul>
        </Section>
    )
}

export default HelpLinks
