import { isDevEnv } from '@/common/consts'
import { freightEnvironment, freightFormat, loadBoardServicesTitle } from './types'

export const checkBoxesEntities = {
    services: ['transloading', 'warehousing', 'drayage'],
    certifications: ['bonded', 'hazmat', 'fda'],
    equipment: ['clamp truck forklift', 'slip sheet forklift', 'forklift'],
    features: ['gatedyard', 'ramp', 'vanramp', 'security', 'sprinkler'],
}

export const handleEntityChange = ({
    entityName,
    e,
    formik,
}: {
    entityName: string
    e: { target: HTMLInputElement }
    formik: any
}) => {
    const { name, value } = e.target

    const entityValue = formik.values[entityName] as any

    let updatedEntityValue = entityValue

    if (value) {
        updatedEntityValue = entityValue ? `${entityValue},${name}` : name
    } else {
        updatedEntityValue = entityValue
            .split(',')
            .filter((v: string) => v !== name)
            .join(',')
    }

    return formik.setFieldValue(entityName, updatedEntityValue)
}

export const setCurrentCheckboxesToArrays = ({
    name,
    active,
    nameOfArr,
}: {
    name: string
    active: boolean
    nameOfArr?: any[]
}) => {
    const arr: any[] | undefined = nameOfArr
    if (arr) {
        if (active) {
            if (!arr.includes(name)) {
                arr.push(name)
            }
        } else {
            const index = arr.indexOf(name)
            if (index > -1) {
                arr.splice(index, 1)
            }
        }
    }
    return arr
}

export const initialValuesLoadBoard = {
    address: isDevEnv ? 'Elk Grove Village, IL 60007' : '',
    serviceDate: new Date(),
    services: '',
    commodity: '',
    volume: '',
    certifications: '',
    equipment: '',
    features: '',
    freightEnvironment: freightEnvironment,
    warehousing: {
        freightFormat: freightFormat,
        inDate: new Date(),
        outDate: new Date(),
    },
    transloading: {
        freightFormat: '',
        lastFreeDate: new Date(),
        inDate: new Date(),
        outDate: new Date(),
    },
    drayage: {
        freightFormat: '',
        timeFrom: '',
        timeTo: '',
        port: '',
        terminal: '',
        inDate: new Date(),
        outDate: new Date(),
    },
    notes: '',
}

export const LB = 'load-board'

/**
 * Transforms the class structure, removes all commas, and adds the "load-board" prefix to each class.
 * @param param A string of classes separated by spaces
 * @returns Array string of classes with prefix "load-board", or undefined if empty
 */

export const setMainStyle = (param: any) => {
    return param
        ?.split(' ')
        ?.map((elem: string) => `${LB}${elem}`.replaceAll(',', ' '))
        .join(' ')
}

interface setOptionsForLoadBoardStepsType {
    formik: any
    cancelClick: (params?: any) => void
    submittedButtonName: any
    isBiddingDate: boolean
    biddingCloseDate: Date
    setBiddingCloseDate: any
}

/**
 *
 * @description setOptionsForLoadBoardSteps set option for all modal steps
 * @returns options for all steps
 */
export const setOptionsForLoadBoardSteps = ({
    formik,
    cancelClick,
    submittedButtonName,
    isBiddingDate,
    biddingCloseDate,
    setBiddingCloseDate,
}: setOptionsForLoadBoardStepsType) => {
    const BaseOptions = {
        title: loadBoardServicesTitle.OrderDetails,
        // resetForm: formik.resetForm,
        cancelClick: cancelClick,
        loading: formik.isSubmitting,
        // handleSubmit: formik.handleSubmit,
        // values: formik.values,
        formik: formik,
    }

    const stepOptionMain = {
        ...BaseOptions,
    }
    const stepOptionWarehouse = {
        subTitle: loadBoardServicesTitle.Warehousing,
        ...BaseOptions,
    }
    const stepOptionAdditionalDetails = {
        subTitle: loadBoardServicesTitle.AdditionalDetails,
        ...BaseOptions,
    }
    const stepOptionReview = {
        ...BaseOptions,
        title: loadBoardServicesTitle.Review,
        submittedButtonName: submittedButtonName,
        setField: formik.setFieldValue,
        isBiddingDate: isBiddingDate,
        biddingCloseDate: biddingCloseDate,
        setBiddingCloseDate: setBiddingCloseDate,
    }
    const stepOptionDrayage = {
        ...BaseOptions,
        subTitle: loadBoardServicesTitle.Drayage,
    }
    const stepOptionTransloading = {
        ...BaseOptions,
        subTitle: loadBoardServicesTitle.Transloading,
    }

    return {
        stepOptionMain,
        stepOptionWarehouse,
        stepOptionAdditionalDetails,
        stepOptionReview,
        stepOptionDrayage,
        stepOptionTransloading,
    }
}

export const checkIsServices = ({ loadBoardServices }: { loadBoardServices: string }) => {
    // set checked services step
    const isWarehousingSteps = loadBoardServices.toLowerCase() === loadBoardServicesTitle.Warehousing?.toLowerCase()
    const isTransloadingSteps = loadBoardServices.toLowerCase() === loadBoardServicesTitle.Transloading?.toLowerCase()
    const isDrayageSteps = loadBoardServices.toLowerCase() === loadBoardServicesTitle.Drayage.toLowerCase()

    // check if which services are checked
    const isWarehousingAndTransloadingSteps =
        loadBoardServices.includes(loadBoardServicesTitle.Warehousing) &&
        loadBoardServices.includes(loadBoardServicesTitle.Transloading) &&
        !loadBoardServices.includes(loadBoardServicesTitle.Drayage)

    const isWarehousingAndDrayageSteps =
        loadBoardServices.includes(loadBoardServicesTitle.Warehousing) &&
        loadBoardServices.includes(loadBoardServicesTitle.Drayage) &&
        !loadBoardServices.includes(loadBoardServicesTitle.Transloading)

    const isTransloadingAndDrayageSteps =
        loadBoardServices.includes(loadBoardServicesTitle.Transloading) &&
        loadBoardServices.includes(loadBoardServicesTitle.Drayage) &&
        !loadBoardServices.includes(loadBoardServicesTitle.Warehousing)

    const isAllServicesSteps =
        loadBoardServices.includes(loadBoardServicesTitle.Warehousing) &&
        loadBoardServices.includes(loadBoardServicesTitle.Transloading) &&
        loadBoardServices.includes(loadBoardServicesTitle.Drayage)

    return {
        isWarehousingSteps,
        isTransloadingSteps,
        isDrayageSteps,
        isWarehousingAndTransloadingSteps,
        isWarehousingAndDrayageSteps,
        isTransloadingAndDrayageSteps,
        isAllServicesSteps,
    }
}

export const checkedBoxes = (featureName: string, valuesName: string, formik: any) => {
    const formikName = formik.values[valuesName]
    const regex = new RegExp(`(^|,\\s*)${featureName}(\\s*,|$)`, 'i')
    return regex.test(formikName!)
}
