import { useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { ChatAction, ChatEvent } from '@/entities/chat/model/enums'
import { ChatMessageDto } from '@/entities/chat/model/dto'

export const useChatSocket = () => {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [connected, setConnected] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        let isMounted = true

        const token = localStorage.getItem('token')
        const processLocalSocketURL = process.env.REACT_APP_LOCAL_SOCKET_URL
        const socketURL = (processLocalSocketURL ? processLocalSocketURL : window.location.origin) + '/chat'
        const chatSocket = io(socketURL, {
            transports: ['websocket'],
            auth: {
                Authorization: `Bearer ${token}`,
            },
        })

        chatSocket.on('connect', () => {
            if (isMounted) {
                setConnected(true)
            }
        })

        chatSocket.on('disconnect', () => {
            if (isMounted) {
                setConnected(false)
            }
        })

        chatSocket.on('error', (message) => {
            if (isMounted) {
                console.error('Error received:', message)
                setError(message)
            }
        })

        setSocket(chatSocket)

        return () => {
            isMounted = false
            chatSocket.close()
            setConnected(false)
        }
    }, [])

    return {
        error,
        socket,
        connected: socket ? connected : false,
    }
}

export interface ChatOptions {
    callbacks?: {
        onMessageReceived: (message: ChatMessageDto) => void
        onChatReceived: (chat: any) => void
        onChatsReceived: (chat: any[]) => void
    }
}

export const useChat = ({ callbacks }: ChatOptions) => {
    const { socket, connected, error } = useChatSocket()

    const sendMessage = (body: { message: string; reference: string }) => {
        if (connected) {
            socket!.emit(ChatAction.SendMessage, body)
        }
    }

    const getChat = (referenceId: string | number) => {
        if (connected) {
            socket!.emit(ChatAction.GetChat, referenceId)
        }
    }

    const getChats = (referenceId: string) => {
        if (connected) {
            socket!.emit(ChatAction.GetChats, referenceId)
        }
    }

    useEffect(() => {
        if (socket && callbacks) {
            socket.on(ChatEvent.MessageReceived, (message: ChatMessageDto) => {
                callbacks.onMessageReceived(message)
            })
            socket.on(ChatEvent.ChatReceived, (chat: any) => {
                callbacks.onChatReceived(chat)
            })
            socket.on(ChatEvent.ChatsReceived, (chats: any[]) => {
                callbacks.onChatsReceived(chats)
            })
        }
    }, [socket])

    return {
        getChat,
        getChats,
        sendMessage,
        connected,
        error,
    }
}
