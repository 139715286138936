import React, { useState } from 'react'
import * as Yup from 'yup'
import { ModalRegistry } from '@shared/common/modals'
import { ModalPartialProps, Modals } from '@shared/types'
import CancelDeleteButtons from '@/components/CancelDeleteButtons'
import DropZoneArea from '@/pages/RFQPortal/components/DropZoneArea'
import InputLabel from '@/components/InputLabel'
import { useFormik } from 'formik'
import { createFileList } from '@/utils/helper'
import { uploadOrderInvoice, WarehouseOrderInvoiceDto, WarehouseOrdersListResponse } from '@shared/apiv2/wh-orders'
import { toast } from 'react-toastify'
import { MutatorCallback } from 'swr/dist/types'
import { AxiosResponse } from 'axios'

type TMutate = (
    data?:
        | WarehouseOrdersListResponse
        | Promise<WarehouseOrdersListResponse>
        | MutatorCallback<WarehouseOrdersListResponse>,
    shouldRevalidate?: boolean,
) => Promise<WarehouseOrdersListResponse | undefined>

interface InvoiceUploadModalProps {
    warehouseId: string
    orderKey: string
    mutate: TMutate
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

interface InvoiceUploadForm {
    file: File | null
    invoiceUrl: string
}

const InvoiceUploadFormSchema = Yup.object().shape(
    {
        file: Yup.mixed().when('invoiceUrl', {
            is: (invoiceUrl: string) => !invoiceUrl,
            then: Yup.mixed().required('Please upload file or enter invoice url.'),
        }),
        invoiceUrl: Yup.mixed().when('file', {
            is: (file: File | null) => !file,
            then: Yup.string().required('Please upload file or enter invoice url.'),
        }),
    },
    [['invoiceUrl', 'file']],
)

const InvoiceUploadModal = ({ warehouseId, orderKey, mutate, setIsLoading }: InvoiceUploadModalProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false)

    const formik = useFormik<InvoiceUploadForm>({
        initialValues: {
            file: null,
            invoiceUrl: '',
        },
        validateOnChange: triedToSubmit,
        validateOnMount: false,
        validationSchema: InvoiceUploadFormSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            setErrorMessage('')
            formik.setSubmitting(true)
            setIsLoading?.(true)

            try {
                let fileList
                if (values.file) {
                    fileList = createFileList([values.file])
                }

                let result: AxiosResponse<WarehouseOrderInvoiceDto, any>
                if (fileList) {
                    result = await uploadOrderInvoice(warehouseId, orderKey, { file: fileList[0] })
                } else {
                    result = await uploadOrderInvoice(warehouseId, orderKey, { downloadInvoiceURL: values.invoiceUrl })
                }

                await mutate((data) => {
                    const orders = data?.data
                    if (orders) {
                        const newOrders = orders.map((order) =>
                            order.key === orderKey
                                ? {
                                      ...order,
                                      invoice: {
                                          ...result.data,
                                      },
                                  }
                                : order,
                        )

                        return { data: newOrders, count: data.count }
                    }
                }, false)

                toast('Invoice has been successfully uploaded.', { type: 'success' })
                ModalRegistry.get().close(Modals.InvoiceUploadModal)
            } catch (errors: any) {
                formik.setSubmitting(false)
                if (errors && errors?.response) {
                    setErrorMessage(errors?.response?.data?.message)
                }
            } finally {
                setIsLoading?.(false)
            }
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-3 rounded-xl">
                    <div className="relative">
                        <DropZoneArea
                            filesCollection={formik.values.file ? [formik.values.file] : []}
                            changeFormData={(path, files) => formik.setFieldValue('file', files[0])}
                            allowedFormats={['image/jpeg', 'application/pdf']}
                            maxFiles={1}
                        />
                        <p className="error-message">{formik.errors?.file}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-medium">Or Invoice Via Link</div>
                        <InputLabel
                            label="Invoice URL"
                            value={formik.values.invoiceUrl}
                            onChange={(e) => formik.setFieldValue('invoiceUrl', e.target.value)}
                            errorMessage={formik.errors?.invoiceUrl}
                        />
                    </div>
                </div>
                <CancelDeleteButtons
                    wrapperClassName="mt-3"
                    handleCancel={() => ModalRegistry.get().close(Modals.InvoiceUploadModal)}
                    isSubmitting={formik.isSubmitting}
                    disableSubmit={!formik.isValid}
                    errorMessage={errorMessage}
                    textSubmit="Send"
                    stylesSubmit={{ width: 'auto' }}
                    buttonsPosition="right"
                    onSubmitClick={() => setTriedToSubmit(true)}
                />
            </form>
        </div>
    )
}

ModalRegistry.get().register<InvoiceUploadModalProps>(Modals.InvoiceUploadModal, {
    id: 'InvoiceUploadModalProps',
    className: 'invoice-upload-modal',
    size: 'huge',
    Component: InvoiceUploadModal,
    title: 'Send your invoice',
})

export const showInvoiceUploadModal = (props: ModalPartialProps<InvoiceUploadModalProps>): void =>
    ModalRegistry.get().show<InvoiceUploadModalProps>(Modals.InvoiceUploadModal, props)

export default InvoiceUploadModal
