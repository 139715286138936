/* eslint-disable no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import Button from '@/components/Button'
import cn from 'classnames'

import { Steps } from '@/pages/RFQPortal/RfqOrder'
import { SubmitRfqResponse, ErrorMessage } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import { sidebarClass } from '@/pages/RFQPortal/parts/Sidebar/SidebarHeader'
import { ReactComponent as CloseBtnIcon } from '@/pages/RFQPortal/assets/img/closeRfqToast.svg'
import { handleToasterWarning } from '@/pages/RFQPortal/helpers/rfqHelper'
import { useExpandedOppContext } from '@/pages/RFQPortal/contexts/ExpandedOppProvider'

const toaster = 'error-toaster'
const toasterHeader = toaster + '-header'

interface SidebarButtonsProps {
    step: Steps
    changeStep: (type: 'increment' | 'decrement') => void
    setCurrentStep: (step: Steps) => void
    submissionError: string
    isSubmiting?: boolean
    mobile?: boolean
    validateForm?: () => Promise<boolean>
    errorMessages?: { [key: string]: ErrorMessage }
}

export interface ToasterWarning {
    errorLabel: string
    wrapperEl: HTMLDivElement | null
    oppId?: string
}

const SidebarButtons: FC<SidebarButtonsProps> = ({
    step,
    changeStep,
    submissionError,
    isSubmiting = false,
    mobile = false,
    validateForm,
    errorMessages,
    setCurrentStep,
}) => {
    const { setExpandedId } = useExpandedOppContext()
    const [toasterWarning, setToasterWarning] = useState<ToasterWarning[]>([])
    const [isValidForm, setIsValidForm] = useState<boolean>(false)

    const handleValidation = async () => {
        if (validateForm) {
            const valid = await validateForm()

            if (valid) {
                setIsValidForm(true)
                setCurrentStep(Steps.THANK_YOU)
                // changeStep('increment')
            } else {
                setIsValidForm(false)
            }
        }
    }

    const closeToaster = () => {
        setToasterWarning([])
    }

    const scrollToInput = (wrapperEl: HTMLDivElement | null, oppId = ''): void => {
        if (!wrapperEl) {
            return
        }

        if (oppId) {
            setExpandedId(oppId)
        }

        setTimeout(() => {
            wrapperEl.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })

            const focusableEl = wrapperEl.querySelector('input, textarea')
            if (focusableEl) {
                ;(focusableEl as HTMLInputElement | HTMLTextAreaElement).focus()
            }
        }, 5)
    }

    useEffect(() => {
        const toasterData = handleToasterWarning(errorMessages)
        if (toasterData) {
            setToasterWarning(toasterData)
        } else {
            setToasterWarning([])
        }
    }, [errorMessages])

    return (
        <div className={!mobile ? `${sidebarClass}__btns-desktop` : ''}>
            {step > Steps.CHOOSE_SOLUTION && (
                <>
                    <div className={`${sidebarClass}-btns`} style={{ justifyContent: 'flex-end' }}>
                        {/* <Button
                            types={['bordered', 'inverse']}
                            className={`${sidebarClass}_btn-bordered`}
                            onClick={() => changeStep('decrement')}
                            disabled={isSubmiting}
                            type="button"
                        >
                            Back
                        </Button> */}
                        <div className="action-btn">
                            <Button
                                // type={step === Steps.REVIEW ? 'submit' : 'button'}
                                type="submit"
                                types={['blue']}
                                // className={step === Steps.REVIEW ? 'rfq-submit-btn' : ''}
                                className="rfq-submit-btn"
                                // onClick={step <= Steps.RFQ ? handleValidation : undefined}
                                disabled={isSubmiting}
                                loading={isSubmiting}
                            >
                                {/* {step === Steps.RFQ ? 'Review' : 'Submit RFQ'} */}
                                Submit RFQ
                            </Button>
                            <div
                                className={cn(`${toaster}-wrapper`, {
                                    [`${toaster}-wrapper_active`]: toasterWarning?.length > 0 && step === Steps.RFQ,
                                })}
                            >
                                <div className={toaster}>
                                    <div className={toasterHeader}>
                                        <h3 className={`${toasterHeader}__title`}>
                                            Please complete all required fields
                                        </h3>
                                        <CloseBtnIcon
                                            className={`${toasterHeader}__btn-close`}
                                            onClick={closeToaster}
                                        />
                                    </div>
                                    <ul className={`${toaster}__errors`}>
                                        {toasterWarning.map((item, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={`${toaster}__item`}
                                                    onClick={() => scrollToInput(item.wrapperEl, item.oppId)}
                                                >
                                                    {item.errorLabel}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {step === Steps.REVIEW && submissionError && <p className="submit-error">{submissionError}</p>} */}
                    {submissionError && (
                        <p className="submit-error" style={{ marginTop: '5px', textAlign: 'right' }}>
                            {submissionError}
                        </p>
                    )}
                </>
            )}
        </div>
    )
}

export default SidebarButtons
