import * as React from 'react'
import { LBPostStatus } from '@shared/apiv2/load-board'
import { ReactComponent as CrossDockingIcon } from '@/assets/images/cross_docking.svg'
import { ReactComponent as Storage } from '@/assets/images/warehousing.svg'
import { ReactComponent as FinalMileIcon } from '@/assets/images/final_mile.svg'
import { ReactComponent as DisposalIcon } from '@/assets/images/disposal.svg'
import { ReactComponent as ReworkIcon } from '@/assets/images/restack.svg'
import { ReactComponent as TransloadingIcon } from '@/assets/images/transloading.svg'
import { ReactComponent as DrayageIcon } from '@/assets/images/drayage.svg'
import { ReactComponent as YardIcon } from '@/assets/images/yard.svg'
import { isDevEnv } from '@/common/consts'
import { RfqServiceType } from './types'

export const LOAD_BOARD_DETAILS_CLASS = 'load-board_post-details'

export const initialDevValues = {
    address: isDevEnv ? 'Elk Grove Village, IL 60007' : '',
    services: '',
    status: LBPostStatus.DRAFT,
    commodity: '',
    coordinate: undefined,
    volume: '',
    certifications: '',
    equipment: '',
    features: '',
    bids: [],
    freightEnvironment: '',
    startDate: new Date(),
    endDate: new Date(),
    warehousing: {
        freightFormat: '',
        inDate: new Date(),
        outDate: new Date(),
    },
    transloading: {
        freightFormat: '',
        lastFreeDate: new Date(),
        inDate: new Date(),
        outDate: new Date(),
    },
    drayage: {
        freightFormat: '',
        timeFrom: '',
        timeTo: '',
        port: '',
        terminal: '',
        inDate: new Date(),
        outDate: new Date(),
    },
    notes: '',
    id: 0,
    state: '',
    zipCode: '',
}

export const setIconForCurrentService = (param: string) => {
    switch (param) {
        case RfqServiceType['Cross-Docking']:
            return <CrossDockingIcon />
        case RfqServiceType.Disposal:
            return <DisposalIcon />
        case RfqServiceType.Drayage:
            return <DrayageIcon />
        case RfqServiceType['Local Delivery']:
            return <FinalMileIcon />
        case RfqServiceType.Rework:
            return <ReworkIcon />
        case RfqServiceType.Storage:
            return <Storage />
        case RfqServiceType.Transloading:
            return <TransloadingIcon />
        case RfqServiceType['Yard Storage']:
            return <YardIcon />

        default:
            return null
    }
}
