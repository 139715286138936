import React, { useEffect } from 'react'
import { isProdEnv } from '@/pages/RFQPortal/helpers/rfqHelper'
import { HttpV2 } from '@OlimpDev/lib/dist/apiv2/api'
import { FormValues } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

declare global {
    interface Window {
        piAId: string
        piCId: string | { [key: string]: string }
        piHostname: string
        CallRail: any
    }
}

interface SitesFormHandlerUrls {
    [key: string]: string
}

export const useCallRailTracking = () => {
    useEffect(() => {
        if (isProdEnv && !document.querySelector('#callrail-script')) {
            const script = document.createElement('script')
            script.id = 'callrail-script'
            script.src = '//cdn.callrail.com/companies/453164813/f813f65a41e57f0445fc/12/swap.js'
            script.async = true
            script.onload = () => {
                console.log('CallRail script loaded.')

                // Disable automatic form submission
                if (window.CallRail) {
                    window.CallRail.configure({
                        forms: {
                            auto_detect: false,
                        },
                    })
                }
            }
            script.onerror = () => console.error('Error loading CallRail script.')
            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [])
}

export const usePardotCampaign = (piCId = '125404') => {
    useContentSecurityPolicy()

    useEffect(() => {
        if (isProdEnv && !document.querySelector('#pardot-campaign-script')) {
            const script = document.createElement('script')
            const piHostname = 'pi.pardot.com'

            window.piHostname = piHostname
            window.piAId = '1037033'
            window.piCId = piCId

            script.id = 'pardot-campaign-script'
            script.src = `https://${piHostname}/pd.js`
            script.async = true
            script.onload = () => console.log('Pardot Campaign script loaded')
            script.onerror = () => console.error('Error Pardot Campaign script.')
            document.head.appendChild(script)

            return () => {
                document.head.removeChild(script)
            }
        }
    }, [piCId])
}

export const useContentSecurityPolicy = () => {
    useEffect(() => {
        const cspMetaTag = document.createElement('meta')
        cspMetaTag.httpEquiv = 'Content-Security-Policy'
        cspMetaTag.content = 'upgrade-insecure-requests'

        if (!document.querySelector('meta[http-equiv="Content-Security-Policy"]')) {
            document.head.appendChild(cspMetaTag)
        }

        return () => {
            if (document.querySelector('meta[http-equiv="Content-Security-Policy"]')) {
                document.head.removeChild(cspMetaTag)
            }
        }
    }, [])
}

export const sitesFormHandlerUrls: SitesFormHandlerUrls = {
    default: 'https://go.olimpwarehousing.com/l/1036033/2023-08-22/367z5h',
    uber: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695mr',
    synchrogistics: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695jk',
    savealot: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695mn',
    ntg: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695ly',
    magellan: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695mk',
    jbh: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695mg',
    rfq: 'https://go.olimpwarehousing.com/l/1036033/2023-08-28/368l7j',
    flexport: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695mc',
    evans: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695m8',
    echo: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695j8',
    convoy: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695m5',
    chr: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695j5',
    cargomatic: 'https://go.olimpwarehousing.com/l/1036033/2023-08-31/3695m2',
    'on-demand-warehousing': 'https://go.olimpwarehousing.com/l/1036033/2023-09-26/36ckwg',
    sage: 'https://go.olimpwarehousing.com/l/1036033/2023-11-16/36kk65',
    forward: 'https://go.olimpwarehousing.com/l/1036033/2023-12-14/36ndwq',
    'western-express': 'https://go.olimpwarehousing.com/l/1036033/2023-12-14/36ndxq',
    arrive: 'https://go.olimpwarehousing.com/l/1036033/2023-11-29/36lnxr',
    ease: 'https://go.olimpwarehousing.com/l/1036033/2024-02-15/36vjrd',
    traffix: 'https://go.olimpwarehousing.com/l/1036033/2024-02-22/36wkqs',
    tql: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk2z',
    coyote: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk33',
    ryan: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zjgl',
    transfix: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk3p',
    ryder: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk1z',
    expeditors: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk26',
    penskelogistics: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk1w',
    'kuehne-nagel': 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk2s',
    nfiindustries: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk1s',
    cevalogistics: 'https://go.olimpwarehousing.com/l/1036033/2024-03-11/36zk2w',
}

// eslint-disable-next-line complexity
export const submitToCallRail = async (data: any, clientSlug: string) => {
    const apiToken = process.env.REACT_APP_CALLRAIL_API_TOKEN
    const accountId = process.env.REACT_APP_CALLRAIL_ACCOUNT_ID

    const { clientData, lead: _lead, project } = data

    const payload: any = {
        account: clientData?.account || '',
        company: clientData?.company || '',
        clientLocation: clientData?.clientLocation?.formattedAddress || '',
        firstName: clientData?.firstName || '',
        lastName: clientData?.lastName || '',
        title: clientData?.title || '',
        email: clientData?.email || '',
        phone: clientData?.phone || '',
        clientId: clientData?.agentId || '',
        teamMembers: clientData?.teamMembers.join(', '),
        createDate: _lead?.createDate || '',
        referral: _lead?.referral || '',
        type: _lead?.type || '',
    }

    if (project?.data?.length > 0) {
        // eslint-disable-next-line complexity
        project?.data.forEach((opp: any, oppIndex: number) => {
            payload[`id opp#${oppIndex + 1}`] = opp.id || ''
            payload[`name opp#${oppIndex + 1}`] = opp.name || ''
            payload[`servicesNeeded opp#${oppIndex + 1}`] = opp.servicesNeeded.join(', ') || ''
            payload[`freightEnvironment opp#${oppIndex + 1}`] = opp.freightEnvironment
            payload[`certifications opp#${oppIndex + 1}`] = opp.certifications.join(', ') || ''
            payload[`serviceLocation opp#${oppIndex + 1}`] = opp.serviceLocation?.formattedAddress || ''
            payload[`serviceDateStart opp#${oppIndex + 1}`] = opp.serviceDate?.start || ''
            payload[`serviceDateEnd opp#${oppIndex + 1}`] = opp.serviceDate?.end || ''
            payload[`commodity opp#${oppIndex + 1}`] = opp.commodity || ''
            payload[`loadNumber opp#${oppIndex + 1}`] = opp.loadNumber || ''
            payload[`unitType opp#${oppIndex + 1}`] = opp.unitType || ''
            payload[`numberOfUnits opp#${oppIndex + 1}`] = opp.numberOfUnits || ''
            // payload[`spaceNeeded opp#${oppIndex + 1}`] = opp.spaceNeeded || ''
            payload[`notes opp#${oppIndex + 1}`] = opp.notes || ''
        })
    }

    try {
        const response = await HttpV2.post(
            `https://api.callrail.com/v3/a/${accountId}/form_submissions.json`,
            {
                form_submission: {
                    company_id: process.env.REACT_APP_CALLRAIL_COMPANY_ID,
                    referrer: `${clientSlug} RFQ`,
                    referring_url: document.referrer || 'direct',
                    landing_page_url: _lead?.referral || '',
                    form_url: _lead?.referral || '',
                    form_data: payload,
                },
            },
            {
                headers: {
                    Authorization: `Token token=${apiToken}`,
                    'Content-Type': 'application/json',
                },
            },
        )
        console.log('CallRail submit success:', response)
    } catch (er: any) {
        console.error('CallRail submit fail:', er)
    }
}
