import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Section } from '@/components/Section'
import AllWarehousesHeader from './AllWarehousesHeader'
import { WarehouseInfo } from '@/pages/Warehouses'
import { HttpV2 } from '@shared/apiv2/api'
import useDebounce from '@shared/hooks/useDebounce'
import { Loader } from '@/components/Loader'
import { ModernTable } from '@/components/ModernTable/ModernTable'
import getAdminWarehousesColumns from '@/pages/Admin/Warehouses/getAdminWarehousesColumns'
import { useUserState } from '@/store/user'

const fetchWarehouses = async () => {
    const { data } = await HttpV2.get<WarehouseInfo[]>('accounts/related')
    const filterMainCompany = data.filter((item: any) => item.permission > 0)
    return filterMainCompany
}

const AdminWarehouses = () => {
    const { state } = useUserState()
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)

    const [warehouses, setWarehouses] = useState<WarehouseInfo[] | null>(null)

    const filteredWarehouses = useMemo(() => {
        if (!warehouses) return null
        if (!search) return warehouses

        return warehouses.filter((warehouse) => {
            const contactName = `${warehouse.contact?.firstName || ''} ${warehouse.contact?.lastName || ''}`
            const s = search.toLowerCase().trim()

            return (
                warehouse.name.toLowerCase().trim().includes(s) ||
                warehouse.location.toLowerCase().trim().includes(s) ||
                (contactName && contactName.toLowerCase().trim().includes(s))
            )
        })
    }, [warehouses, debouncedSearch])

    const fetchAndSetWarehouses = useCallback(() => {
        setIsLoading(true)
        fetchWarehouses()
            .then((data) => {
                setWarehouses(data)
            })
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        fetchAndSetWarehouses()
    }, [])

    const columns = getAdminWarehousesColumns({
        fetchWarehousesInfo: fetchAndSetWarehouses,
        admin: state.userInfo!.admin,
    })

    return (
        <div className="app-page-container">
            <Section>
                {isLoading || !warehouses ? (
                    <Loader />
                ) : (
                    <div className="space-y-3">
                        <AllWarehousesHeader
                            search={search}
                            onSearch={(v) => setSearch(v)}
                            onWarehouseAdded={async () => {
                                // Async hack because otherwise won't fetch
                                await new Promise((resolve) => setTimeout(resolve, 1000))
                                fetchAndSetWarehouses()
                            }}
                        />
                        <ModernTable columns={columns} data={filteredWarehouses!} />
                    </div>
                )}
            </Section>
        </div>
    )
}

export default AdminWarehouses
