import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Separator } from '@/shared/ui/Separator'

const NON_ACCESSORIAL_PRICINGS = [RfqServicePricing.LineHaul]

export const FinalMileBreakdownComponent = ({ service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex flex-col gap-3 w-full">
            <div>
                <div className="text-sm leading-[18px] text-foreground-secondary font-medium">
                    {RfqServicePricingLabel[RfqServicePricing.LineHaul]}
                </div>
                {service.pricings[RfqServicePricing.LineHaul]?.price ? (
                    <div className="text-xl text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.LineHaul]!.price).toLocaleString('en')}
                    </div>
                ) : (
                    <div className="text-foreground-secondary font-medium">N/A</div>
                )}
            </div>

            <Separator />

            {Object.entries(service.pricings)
                .filter(([key]) => !NON_ACCESSORIAL_PRICINGS.includes(key as RfqServicePricing))
                .map(([key, value], index) => {
                    const pricingName = key as RfqServicePricing
                    return (
                        <div key={index} className="flex gap-2 h-7 items-center">
                            <div className="w-[154px] text-sm leading-[18px] text-foreground-secondary font-medium">
                                {RfqServicePricingLabel[pricingName]}
                            </div>
                            <div>
                                {value.price ? (
                                    <div className="text-xl leading-6 text-primary font-semibold">
                                        ${Number(value.price).toLocaleString('en')}
                                    </div>
                                ) : (
                                    <div className="text-foreground-secondary font-medium">N/A</div>
                                )}
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
