import { OrderChatSlice } from '@/pages/Order/ui/OrderChat/model/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatMessagingMode } from '@/entities/chat/model/dto'

const initialState: OrderChatSlice = {
    chatMessages: [],
    availableChats: [],
    selectedChat: undefined,
    currentMessage: '',
    chatLoading: true,
    currentChat: null,
}

export const orderChatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        selectChat(state, action: PayloadAction<number | ChatMessagingMode | undefined>) {
            state.selectedChat = action.payload
        },
        setAvailableChats(state, action) {
            state.availableChats = action.payload ?? []
        },
        setChatMessages(state, action) {
            state.chatMessages = action.payload ?? []
        },
        addChatMessage(state, action) {
            state.chatMessages = [...state.chatMessages, action.payload]
        },
        setCurrentMessage(state, action) {
            state.currentMessage = action.payload
        },
        setChatLoading(state, action) {
            state.chatLoading = action.payload
        },
        setCurrentChat(state, action) {
            state.currentChat = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const orderChatActions = orderChatSlice.actions

export const orderChatReducer = orderChatSlice.reducer
