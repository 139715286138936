import React, { ReactNode, useRef, useEffect, useState } from 'react'
import { ReactComponent as CloseBtnIcon } from '@/assets/images/x.svg'

import './Modal.scss'

export interface ModalProps {
    title: string
    children: ReactNode
    onClose: () => void
    show: boolean
}

const Modal: React.FC<ModalProps> = ({ title, children, onClose, show }) => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [extraStyle, setExtraStyle] = useState<React.CSSProperties>({})

    useEffect(() => {
        if (show && contentRef.current) {
            if (window.innerWidth <= 1200) {
                contentRef.current.scrollIntoView({ behavior: 'smooth' })
                setExtraStyle({ paddingTop: '50px' })
            } else {
                setExtraStyle({})
            }
        }
    }, [show])

    if (!show) {
        return null
    }

    return (
        <div className="rfq__modal-wrapper" onClick={onClose}>
            <div className="rfq__modal" ref={contentRef} style={extraStyle}>
                <div className="rfq__modal-content" onClick={(e) => e.stopPropagation()}>
                    <h3 className="rfq__modal-title">{title}</h3>
                    {children}
                    <CloseBtnIcon className="rfq__modal-content__close-btn" onClick={onClose} />
                </div>
            </div>
        </div>
    )
}
export default Modal
