import React from 'react'
import { ServiceFormComponentProps } from '../index'
import { RfqServicePricing, RfqServicePricingLabel } from '@/shared/lib/rfq-services'
import { Input } from '@/shared/ui/Input'

export const TransloadingFormComponent = ({ order, isFloorLoaded, service, errors }: ServiceFormComponentProps) => {
    const onChange = (value: string, field: RfqServicePricing) => {
        if (service?.pricings[field]) {
            service!.pricings[field]!.price = value
        }
    }

    if (isFloorLoaded) {
        return (
            <div className="flex flex-col gap-5 w-full">
                <div className="font-medium">Floor Loaded</div>
                <div>
                    <div className="flex gap-2 items-center w-full">
                        <Input
                            label={RfqServicePricingLabel[RfqServicePricing.Unloading]}
                            wrapperClassName="w-full md:w-[200px]"
                            defaultValue={service.pricings.unloading?.price}
                            onChange={(e) => onChange(e.target.value, RfqServicePricing.Unloading)}
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                            {order.unitType ?? 'Unit'}
                        </span>
                    </div>
                    {errors?.[RfqServicePricing.Unloading] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.Unloading]}</div>
                    ) : null}
                </div>
                <div>
                    <div className="flex gap-2 items-center w-full">
                        <Input
                            label={RfqServicePricingLabel[RfqServicePricing.Palletization]}
                            wrapperClassName="w-full md:w-[200px]"
                            defaultValue={service.pricings.palletization?.price}
                            onChange={(e) => onChange(e.target.value, RfqServicePricing.Palletization)}
                        />
                        <span className="text-xl shrink-0 text-foreground-secondary">/</span>
                        <div className="text-foreground-secondary text-sm font-medium shrink-0 leading-4">
                            <span>Pallet</span>
                            <span className="hidden sm:inline">&nbsp;(including materials)</span>
                        </div>
                    </div>
                    {errors?.[RfqServicePricing.Palletization] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.Palletization]}</div>
                    ) : null}
                </div>
                <div>
                    <Input
                        label={RfqServicePricingLabel[RfqServicePricing.PalletOut]}
                        wrapperClassName="w-full md:w-[200px]"
                        defaultValue={service.pricings.palletOut?.price}
                        onChange={(e) => onChange(e.target.value, RfqServicePricing.PalletOut)}
                    />
                    {errors?.[RfqServicePricing.PalletOut] ? (
                        <div className="text-sm text-danger">{errors[RfqServicePricing.PalletOut]}</div>
                    ) : null}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="font-medium">Palletized</div>
            <div>
                <Input
                    label={RfqServicePricingLabel[RfqServicePricing.HandlingIn]}
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.handlingIn?.price}
                    onChange={(e) => onChange(e.target.value, RfqServicePricing.HandlingIn)}
                />
                {errors?.[RfqServicePricing.HandlingIn] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingIn]}</div>
                ) : null}
            </div>
            <div>
                <Input
                    label={RfqServicePricingLabel[RfqServicePricing.HandlingOut]}
                    wrapperClassName="w-full md:w-[200px]"
                    defaultValue={service.pricings.handlingOut?.price}
                    onChange={(e) => onChange(e.target.value, RfqServicePricing.HandlingOut)}
                />
                {errors?.[RfqServicePricing.HandlingOut] ? (
                    <div className="text-sm text-danger">{errors[RfqServicePricing.HandlingOut]}</div>
                ) : null}
            </div>
        </div>
    )
}
