import React, { useEffect } from 'react'
import { useUserState } from '@/store/user'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from '@/components/Button'
import rfqListPreviewLink from '@/pages/RFQPortal/assets/img/rfqListPreview.jpg'

import './ThankYou.scss'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
const CLASS_NAME = 'thank-you-page'

const ThankYou = () => {
    const { slug } = useMicrosite()
    const navigate = useNavigate()
    const location = useLocation()

    const { state } = useUserState()
    const { isLoggedIn } = state

    useEffect(() => {
        if (!location.state || !location.state.fromRfq) {
            navigate(slug ? `/ms/${slug}/rfq` : '/rfq')
        }
    }, [location, navigate])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    if (!location.state || !location.state.fromRfq) {
        return null
    }

    return (
        <div className={CLASS_NAME}>
            <h3 className={`${CLASS_NAME}__title`}>Thank you for your request!</h3>
            <p className={`${CLASS_NAME}__text`}>
                Our Partner Success Team will be in contact shortly.<br className="new-line"></br> For immediate
                assistance call us at <span className={`${CLASS_NAME}__phone`}>773-570-3481</span>.
            </p>
            <div className={`${CLASS_NAME}-btns`}>
                {isLoggedIn && (
                    <Button
                        types={['bordered', 'inverse']}
                        className={`${CLASS_NAME}_btn-bordered`}
                        onClick={() => {
                            navigate(slug ? `/ms/${slug}/rfq/list` : '/rfq/list')
                        }}
                    >
                        View Your Orders
                    </Button>
                )}
                <Button
                    types={['blue']}
                    onClick={() => {
                        navigate(slug ? `/ms/${slug}/rfq` : '/rfq')
                    }}
                >
                    New RFQ
                </Button>
            </div>
            {!isLoggedIn && (
                <div className={`${CLASS_NAME}_banner`}>
                    <div className={`${CLASS_NAME}_banner-header`}>
                        <h3 className={`${CLASS_NAME}_banner-label`}>
                            Sign in or create an account to see your order details and status
                        </h3>
                        <Button
                            types={['bordered', 'inverse']}
                            className={`${CLASS_NAME}_btn-bordered`}
                            onClick={() => {
                                navigate('/login?slug=' + slug, { replace: true })
                            }}
                        >
                            Sign in
                        </Button>
                    </div>
                    <img src={rfqListPreviewLink} alt="Your orders list" className={`${CLASS_NAME}_banner-img`} />
                </div>
            )}
            <div></div>
        </div>
    )
}

export default ThankYou
