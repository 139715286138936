import React, { FC, useRef, useEffect, useState } from 'react'

import TagsInput from 'react-tagsinput'
import cloneDeep from 'lodash/cloneDeep'

import { FormValues, FormValueTypes, SetErrorType } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'
import PhoneInput from '@/pages/RFQPortal/components/IntlTelInput'
import Input from '@/pages/RFQPortal/components/Input'
import { deleteSymbolsDotsSpacesHyphens } from '@/pages/RFQPortal/helpers/rfqHelper'
import { navigateToWpPage } from '@/pages/RFQPortal/helpers/rfqHelper'
import GoogleAutocompleteInput from '@/pages/RFQPortal/components/GoogleAutocompleteInput'
import useValidationRules from '@/pages/RFQPortal/hooks/useValidationRules'

import { sidebarClass } from '@/pages/RFQPortal/parts/Sidebar/SidebarHeader'
import { useUserState } from '@/store/user'

export const sidebarFormClass = sidebarClass + '__form'
const redirectClass = sidebarFormClass + '__redirect'

interface FormToRenderProps {
    formData: FormValues
    changeFormData: (path: string, value: FormValueTypes) => void
    children: FormChildrenProps
    errorMessages: any
    setError: SetErrorType
    sitesWithRegistration: string[] | null
    clientSlug: string
    publicSites: any
    clientName: string
    accessToken: string | null
    clientNamesWithRegistration: { [key: string]: string }
    setValidateSidebarFunc: any
}

export interface FormChildrenProps {
    header: React.ReactNode
    buttons: React.ReactNode
}

// eslint-disable-next-line complexity
const FormToRender: FC<FormToRenderProps> = ({
    formData,
    changeFormData,
    children,
    errorMessages,
    setError,
    clientSlug,
    publicSites,
    accessToken,
    clientNamesWithRegistration,
    setValidateSidebarFunc,
}) => {
    const { state } = useUserState()
    const formDataRef = useRef(formData)
    const teamMembersRef = useRef<TagsInput | null>(null)

    const companyWrapperRef = useRef<HTMLDivElement>(null)
    const agentIdWrapperRef = useRef<HTMLDivElement>(null)
    const firstNameWrapperRef = useRef<HTMLDivElement>(null)
    const lastNameWrapperRef = useRef<HTMLDivElement>(null)
    const titleWrapperRef = useRef<HTMLDivElement>(null)
    const clientLocationWrapperRef = useRef<HTMLDivElement>(null)
    const clientEmailWrapperRef = useRef<HTMLDivElement>(null)
    const clientPhoneWrapperRef = useRef<HTMLDivElement>(null)
    const clientPhoneExtWrapperRef = useRef<HTMLDivElement>(null)

    const {
        validateRequiredInput,
        validateEmail,
        validatePhone,
        validateMaxLength,
        validateGoogleLocation,
        validatePhoneExtention,
    } = useValidationRules()

    const [clientPhoneItiObject, setClientPhoneItiObject] = useState<any>(null)

    const [machedClient, setMachedClient] = useState<{ name: string; url: string }>({ name: '', url: '' })
    const [seconds, setSeconds] = useState<number>(5)

    const { header, buttons } = children

    useEffect(() => {
        formDataRef.current = formData
    }, [formData])

    const handleEmailsChange = (inputEmails: string[], newEmails: string[], newIndexes: number[]) => {
        //Removes any non-word characters (characters other than letters, digits, or underscores) from the beginning and end of each email
        const cleanedEmails = inputEmails.map((email) => email && email.replace(/^[^\w]+|[^\w]+$/g, '').trim())
        // Filter out duplicate emails
        const uniqueEmails = Array.from(new Set(cleanedEmails))
        changeFormData('clientData.teamMembers', uniqueEmails)
    }

    useEffect(() => {
        const generalRfqLink = document.getElementById('general-rfq-link')

        if (generalRfqLink) {
            generalRfqLink.addEventListener('click', () => navigateToWpPage('/rfq'))
        }

        return () => {
            if (generalRfqLink) {
                generalRfqLink.removeEventListener('click', () => navigateToWpPage('/rfq'))
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessages])

    const checkEmailAndRedirectToCustomSite = (email: string): boolean => {
        const emailDomain = email.split('@')[1]?.split('.')[0]

        let isRedirected = false

        if (emailDomain) {
            for (const siteUrl in clientNamesWithRegistration) {
                const cleanedEmailDomain = deleteSymbolsDotsSpacesHyphens(emailDomain)
                const cleanedClientName = deleteSymbolsDotsSpacesHyphens(clientNamesWithRegistration[siteUrl])
                const cleanedSiteUrl = deleteSymbolsDotsSpacesHyphens(siteUrl)
                const isFreightQuoteEmail = cleanedEmailDomain.toLocaleLowerCase() === 'freightquote'

                if (
                    cleanedEmailDomain.startsWith(cleanedClientName) ||
                    cleanedClientName.startsWith(cleanedEmailDomain) ||
                    cleanedSiteUrl.startsWith(cleanedEmailDomain) ||
                    cleanedEmailDomain.startsWith(cleanedSiteUrl) ||
                    isFreightQuoteEmail
                ) {
                    if (isFreightQuoteEmail) {
                        setMachedClient({ name: clientNamesWithRegistration.chr, url: 'chr' })
                        console.log('Found site:', 'chr')
                    } else {
                        setMachedClient({ name: clientNamesWithRegistration[siteUrl], url: siteUrl })
                        console.log('Found site:', siteUrl)
                    }

                    const formDataClone = cloneDeep(formData)
                    const opportunitiesClone = formDataClone.project.data

                    let isFile = false

                    opportunitiesClone.forEach((opportunity) => {
                        if (opportunity.files && opportunity.files.length > 0) {
                            opportunity.files = []
                            isFile = true
                        }
                    })

                    const body = {
                        redirectedFormData: { ...formDataClone },
                        isFile: isFile,
                    }

                    localStorage.setItem('redirectedFormData', JSON.stringify(body))

                    isRedirected = true
                    return isRedirected
                }
            }

            if (!isRedirected) {
                console.log('Nothing found!')
            }
        }

        return isRedirected
    }

    useEffect(() => {
        if (machedClient.url) {
            const intervalId: number = window.setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds <= 1) {
                        window.clearInterval(intervalId)
                        navigateToWpPage('/' + machedClient.url)

                        return 0
                    }
                    return prevSeconds - 1
                })
            }, 1000)

            return () => window.clearInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machedClient, setSeconds])

    const validateComapnyInput = (): boolean => {
        const filedLabel = 'Company*'
        const path = 'company'
        const value = formDataRef.current.clientData.company

        if (!validateRequiredInput(filedLabel, path, value, setError, companyWrapperRef.current as HTMLDivElement)) {
            return false
        }

        if (!validateMaxLength(filedLabel, path, value, setError, companyWrapperRef.current as HTMLDivElement)) {
            return false
        }

        return true
    }

    const validateAgentIdInput = (): boolean => {
        if (clientSlug === 'evans') {
            const fieldLabel = 'Agent ID*'
            const path = 'agentId'
            const value = formDataRef.current.clientData.agentId || ''

            if (
                !validateRequiredInput(fieldLabel, path, value, setError, agentIdWrapperRef.current as HTMLDivElement)
            ) {
                return false
            }

            if (!validateMaxLength(fieldLabel, path, value, setError, agentIdWrapperRef.current as HTMLDivElement)) {
                return false
            }
        }

        return true
    }

    const validateClientLocationInput = (): boolean => {
        const clientLocationObject = formDataRef.current.clientData.clientLocation

        if (clientLocationObject) {
            const filedLabel = 'Your Location*'
            const path = 'clientLocation'

            if (
                !validateGoogleLocation(
                    filedLabel,
                    path,
                    clientLocationObject,
                    setError,
                    clientLocationWrapperRef.current as HTMLDivElement,
                )
            ) {
                return false
            }
        } else {
            return false
        }

        return true
    }

    const validateClientEmail = async (): Promise<boolean> => {
        const fieldLabel = 'Email*'
        const path = 'email'
        const value = formDataRef.current.clientData.email

        const isValidEmail = await validateEmail(
            fieldLabel,
            path,
            value,
            setError,
            clientEmailWrapperRef.current as HTMLDivElement,
            undefined,
            true,
            true,
        )

        if (!isValidEmail) {
            return false
        }

        if (clientSlug === 'rfq') {
            const isRedirected = checkEmailAndRedirectToCustomSite(value)

            if (isRedirected) {
                setSeconds(5)
                return false
            }
        }

        return true
    }

    const validateFirstName = (): boolean => {
        const fieldLabel = 'First Name*'
        const path = 'firstName'
        const value = formDataRef.current.clientData.firstName

        if (!validateRequiredInput(fieldLabel, path, value, setError, firstNameWrapperRef.current as HTMLDivElement)) {
            return false
        }

        if (!validateMaxLength(fieldLabel, path, value, setError, firstNameWrapperRef.current as HTMLDivElement)) {
            return false
        }

        return true
    }

    const validateLastName = (): boolean => {
        const fieldLabel = 'Last Name*'
        const path = 'lastName'
        const value = formDataRef.current.clientData.lastName

        if (!validateRequiredInput(fieldLabel, path, value, setError, lastNameWrapperRef.current as HTMLDivElement)) {
            return false
        }

        if (!validateMaxLength(fieldLabel, path, value, setError, lastNameWrapperRef.current as HTMLDivElement)) {
            return false
        }

        return true
    }

    const validateTitle = (): boolean => {
        const fieldLabel = 'Title'
        const path = 'title'
        const value = formDataRef.current.clientData.title

        if (!validateMaxLength(fieldLabel, path, value, setError, titleWrapperRef.current as HTMLDivElement)) {
            return false
        }

        return true
    }

    const validateClientPhone = (): boolean => {
        const fieldLabel = 'Phone*'
        const path = 'phone'
        const value = formDataRef.current.clientData.phone

        // console.log('clientPhoneItiObject in validate function: ', clientPhoneItiObject)

        if (
            !validatePhone(
                fieldLabel,
                path,
                value,
                setError,
                clientPhoneItiObject,
                clientPhoneWrapperRef.current as HTMLDivElement,
                undefined,
                true,
            )
        ) {
            return false
        }

        return true
    }

    const validateClientPhoneExt = () => {
        const fieldLabel = 'Ext'
        const path = 'phoneExtention'
        const value = formDataRef.current.clientData.phoneExtention

        if (!validatePhoneExtention(fieldLabel, path, value, setError, titleWrapperRef.current as HTMLDivElement)) {
            return false
        }

        return true
    }

    const validateSidebarForm = async (): Promise<boolean> => {
        const emailValidation = await validateClientEmail()
        const comapnyInputValidation = validateComapnyInput()
        const agentIdInputValidation = validateAgentIdInput()
        const firstNameValidation = validateFirstName()
        const lastNameValidation = validateLastName()
        const titleValidation = validateTitle()
        const clientLocationInputValidation = validateClientLocationInput()
        const clientPhoneValidation = validateClientPhone()

        // console.log('emailValidation', emailValidation)
        // console.log('comapnyInputValidation', comapnyInputValidation)
        // console.log('agentIdInputValidation', agentIdInputValidation)
        // console.log('firstNameValidation', firstNameValidation)
        // console.log('lastNameValidation', lastNameValidation)
        // console.log('titleValidation', titleValidation)
        // console.log('clientLocationInputValidation', clientLocationInputValidation)
        // console.log('clientPhoneValidation', clientPhoneValidation)

        const sidebarValidationResults = [
            emailValidation,
            comapnyInputValidation,
            agentIdInputValidation,
            firstNameValidation,
            lastNameValidation,
            titleValidation,
            clientLocationInputValidation,
            clientPhoneValidation,
        ]

        return sidebarValidationResults.every((result) => result === true)
    }

    useEffect(() => {
        setValidateSidebarFunc(() => validateSidebarForm)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValidateSidebarFunc, clientPhoneItiObject, clientSlug])

    return (
        <div className={`${sidebarClass}-wrapper`}>
            <div className={sidebarClass}>
                {header}
                <div className={`${sidebarFormClass}`}>
                    <section className={`${sidebarFormClass}__section`}>
                        <h3 className={`${sidebarFormClass}__section-title`}>
                            {clientSlug === 'evans' || clientSlug === 'savealot' ? 'Carrier company' : 'Company'}
                        </h3>
                        <div className={`${sidebarFormClass}__container`}>
                            <div className={clientSlug === 'evans' ? 'flex-inputs' : ''}>
                                <Input
                                    label="Company*"
                                    id="company"
                                    type="text"
                                    name="company"
                                    value={formDataRef.current.clientData.company}
                                    onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                    errorMessage={errorMessages?.company?.message}
                                    onBlur={validateComapnyInput}
                                    disabled={state.isLoggedIn}
                                    wrapperRef={companyWrapperRef}
                                />
                                {clientSlug === 'evans' && (
                                    <Input
                                        label="Agent ID*"
                                        id="agentId"
                                        type="text"
                                        name="agentId"
                                        value={formDataRef.current.clientData.agentId}
                                        onChange={(e: any) =>
                                            changeFormData(`clientData.${e.target.name}`, e.target.value)
                                        }
                                        errorMessage={errorMessages?.agentId?.message}
                                        onBlur={validateAgentIdInput}
                                        wrapperRef={agentIdWrapperRef}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                    <section className={`${sidebarFormClass}__section`}>
                        <h3 className={`${sidebarFormClass}__section-title`}>Your Information</h3>
                        <div className={`${sidebarFormClass}__container`}>
                            <div className="flex-inputs">
                                <Input
                                    label="First Name*"
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    value={formDataRef.current.clientData.firstName}
                                    onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                    errorMessage={errorMessages?.firstName?.message}
                                    onBlur={validateFirstName}
                                    wrapperRef={firstNameWrapperRef}
                                />
                                <Input
                                    label="Last Name*"
                                    type="text"
                                    id="last-name"
                                    name="lastName"
                                    value={formDataRef.current.clientData.lastName}
                                    onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                    errorMessage={errorMessages?.lastName?.message}
                                    onBlur={validateLastName}
                                    wrapperRef={lastNameWrapperRef}
                                />
                            </div>
                            <Input
                                label="Title"
                                type="text"
                                id="title"
                                name="title"
                                value={formDataRef.current.clientData.title}
                                onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                errorMessage={errorMessages?.title?.message}
                                onBlur={validateTitle}
                                wrapperRef={titleWrapperRef}
                            />
                            <GoogleAutocompleteInput
                                type="text"
                                id="clientLocation"
                                name="clientLocation"
                                value={formDataRef.current.clientData.clientLocation.formattedAddress}
                                initialValue={formData.clientData.clientLocation.formattedAddress}
                                changePlaceFull={(path, value) => {
                                    changeFormData(path, value)
                                }}
                                onChangeText={changeFormData}
                                inputPath="clientData.clientLocation"
                                errorMessage={errorMessages?.clientLocation?.message}
                                label="Your Location*"
                                placeholder=""
                                wrapperRef={clientLocationWrapperRef}
                                autoComplete="off"
                                validate={validateClientLocationInput}
                            />
                            <div className={`${sidebarFormClass}__email-wrapper`}>
                                <div
                                    className={`${redirectClass}-wrapper ${
                                        machedClient.name ? `${redirectClass}-wrapper_active` : ''
                                    }`}
                                >
                                    <div className={`${redirectClass}-modal`}></div>
                                    <div className={`${redirectClass}`}>
                                        <h4 className={`${redirectClass}-title`}>Your Email Matches a Microsite!</h4>
                                        <p className={`${redirectClass}-text`}>
                                            Your company has a custom microsite for submitting a Request for Quote.
                                        </p>
                                        <h3 className={`${redirectClass}-name`}>{machedClient.name}</h3>
                                        <p className={`${redirectClass}-warning`}>
                                            You will be redirected in {seconds} second(s)
                                        </p>
                                    </div>
                                </div>
                                <Input
                                    label="Email*"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formDataRef.current.clientData.email}
                                    onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                    errorMessage={errorMessages?.email?.message}
                                    onBlur={validateClientEmail}
                                    disabled={accessToken != null}
                                    wrapperRef={clientEmailWrapperRef}
                                    // autoComplete="off"
                                />
                            </div>
                            <div className={`flex-inputs ${sidebarFormClass}__phone-info`}>
                                <PhoneInput
                                    label="Phone*"
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    placeholder="(999) 999-9999"
                                    formData={formData}
                                    changeFormData={changeFormData}
                                    errorMessage={errorMessages?.phone?.message}
                                    validatePhone={validateClientPhone}
                                    clientPhoneItiObject={clientPhoneItiObject}
                                    setClientPhoneItiObject={setClientPhoneItiObject}
                                    wrapperRef={clientPhoneWrapperRef}
                                />
                                <Input
                                    label="Ext #"
                                    type="text"
                                    id="phoneExtention"
                                    name="phoneExtention"
                                    value={formDataRef.current.clientData.phoneExtention}
                                    onChange={(e: any) => changeFormData(`clientData.${e.target.name}`, e.target.value)}
                                    errorMessage={errorMessages?.phoneExtention?.message}
                                    onBlur={validateClientPhoneExt}
                                    wrapperRef={clientPhoneExtWrapperRef}
                                />
                            </div>
                        </div>
                    </section>
                    <section className={`${sidebarFormClass}__section`}>
                        <h3
                            className={`${sidebarFormClass}__section-title ${sidebarFormClass}__section-title_memebers`}
                        >
                            Add Team Members
                        </h3>
                        <TagsInput
                            value={formData.clientData.teamMembers}
                            ref={teamMembersRef}
                            // value={emails}
                            onChange={handleEmailsChange}
                            inputProps={{
                                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === ' ' || e.key === ',' || e.key === 'Enter') {
                                        e.preventDefault()

                                        //Make accept of email on '', ',' and 'Enter'
                                        if (teamMembersRef.current) {
                                            teamMembersRef.current.accept()
                                        }
                                    }
                                },
                                className: 'react-tagsinput-input', //Default
                                placeholder: 'Add additional emails for visibility',
                            }}
                            tagProps={{
                                className: 'react-tagsinput-tag', //Default
                                classNameRemove: 'react-tagsinput-remove', //Default
                            }}
                            validationRegex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
                            // onValidationReject={(invalidEmails: string[]) => {
                            //     console.log('Invalid emails:', invalidEmails)
                            //     // You can perform additional actions here if needed
                            // }}
                            onlyUnique
                            addOnBlur
                            addOnPaste
                            pasteSplit={(data: string) => data.split(/[,\s]+/)} // Splits the pasted content using a regular expression that considers both commas and whitespace as delimiters.
                            renderTag={({
                                tag,
                                key,
                                disabled,
                                onRemove,
                                classNameRemove,
                                getTagDisplayValue,
                                ...other
                            }) => {
                                if (tag && getTagDisplayValue(tag).trim() !== '') {
                                    return (
                                        <div key={key} {...other}>
                                            <span className="tag-text">{getTagDisplayValue(tag)}</span>
                                            {!disabled && (
                                                <button
                                                    type="button"
                                                    className={classNameRemove}
                                                    onClick={(e) => onRemove(key)}
                                                >
                                                    X
                                                </button>
                                            )}
                                        </div>
                                    )
                                }
                                // If the tag is empty, you can return null or some fallback UI.
                                return null
                            }}
                        />
                    </section>
                </div>
            </div>
            {buttons}
        </div>
    )
}

export default FormToRender
