import React from 'react'
import { LoadboardOrder } from '@/entities/loadboard-orders/model'
import { Quote, QuoteStatus } from '@/entities/quotes/model'
import { OrderQuoteDemandList } from './OrderQuoteDemandList'
import { OrderQuoteBreakdown } from '../OrderQuoteBreakdown'
import { useAppSelector } from '@/app/store'

export const OrderQuoteDemand = () => {
    const order = useAppSelector((state) => state.orderPage.order) as LoadboardOrder

    const quote: Quote = order.bids[0]

    if (order.bids.length === 0) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quotes</span>
                <div className="border border-border bg-background-tertiary rounded-lg flex items-center justify-center py-3">
                    You have not received a quote
                </div>
            </div>
        )
    }

    if (order.bids.length === 1 && order.bids[0].status === QuoteStatus.ACCEPTED) {
        return (
            <div className="space-y-4">
                <span className="text-2xl font-semibold">Quote</span>
                <div className="border border-border rounded-xl bg-background-secondary p-3 mt-3">
                    <OrderQuoteBreakdown quote={quote} />
                </div>
            </div>
        )
    }

    return <OrderQuoteDemandList />
}
