import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from '@/shared/utils/cn'
import { cva, type VariantProps } from 'class-variance-authority'

const TabsRoot = TabsPrimitive.Root

const tabsListVariants = cva('inline-flex h-9 items-center justify-center', {
    variants: {
        variant: {
            default: 'gap-5',
            filled: 'gap-0',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

const tabsTriggerVariants = cva(
    'inline-flex h-full p-0 items-center justify-center !font-medium transition-colors duration-150 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'relative inline-flex items-center justify-center before:border-2 whitespace-nowrap text-foreground-secondary data-[state=active]:text-foreground-primary hover:text-foreground-primary rounded-none ' +
                    'before:border-solid before:border-transparent before:absolute before:bottom-0 before:h-1 before:w-full before:rounded-t-sm data-[state=active]:before:bg-primary before:transition-colors before:duration-150 hover:before:bg-accent-medium',
                filled: 'px-3 !text-sm rounded-none first:rounded-l-lg last:rounded-r-lg bg-transparent text-foreground-primary/75 hover:bg-background-tertiary border border-solid border-border data-[state=active]:bg-primary data-[state=active]:border-primary data-[state=active]:text-white',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

const TabsListContext = React.createContext<VariantProps<typeof tabsListVariants>>({
    variant: 'default',
})

export type TabsListProps = React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabsListVariants>

const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
    ({ className, children, variant, ...props }, ref) => (
        <TabsPrimitive.List ref={ref} className={cn(tabsListVariants({ variant, className }))} {...props}>
            <TabsListContext.Provider value={{ variant }}>{children}</TabsListContext.Provider>
        </TabsPrimitive.List>
    ),
)
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
    const { variant } = React.useContext(TabsListContext)

    return <TabsPrimitive.Trigger ref={ref} className={cn(tabsTriggerVariants({ variant, className }))} {...props} />
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            className,
        )}
        {...props}
    />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { TabsRoot, TabsList, TabsTrigger, TabsContent }
