import { useEffect, useState, useRef } from 'react'

const useWebSocket = (url: string) => {
    const [messages, setMessages] = useState<string[]>([])
    const [connected, setConnected] = useState(false)
    const socket = useRef<WebSocket>(null)

    useEffect(() => {
        // Create WebSocket connection.
        ;(socket as any).current = new WebSocket(url)
        if (!socket.current) {
            return
        }

        // Connection opened
        socket.current.addEventListener('open', (event) => {
            setConnected(true)
        })

        socket.current.addEventListener('error', (event) => {
            console.error('WebSocket error', event)
        })

        // Listen for messages
        socket.current.addEventListener('message', (event) => {
            const newMessage = JSON.parse(event.data)
            setMessages((prevMessages) => [...prevMessages, newMessage])
        })

        // Listen for possible close
        socket.current.addEventListener('close', () => {
            setConnected(false)
        })

        // Clean up on unmount
        return () => {
            if (socket.current) {
                socket.current.close()
            }
        }
    }, [url])

    // Function to send a message
    const sendMessage = (message: any) => {
        if (socket.current && connected) {
            socket.current.send(JSON.stringify(message))
        }
    }

    return {
        messages,
        sendMessage,
        connected,
    }
}

export default useWebSocket
