import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { WarehouseInfo } from '@/pages/Warehouses'
import WarehouseActions from '@/pages/Warehouses/WarehouseActions'

type UseWarehousesColumnsProps = {
    fetchWarehousesInfo?: any
    admin?: boolean
}

const columnHelper = createColumnHelper<WarehouseInfo>()

const useWarehousesColumns = ({
    fetchWarehousesInfo,
    admin,
}: UseWarehousesColumnsProps) => {
    const columns = [
        columnHelper.accessor('name', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Name</div>,
            enableSorting: true,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px' },
            },
        }),
        columnHelper.accessor('location', {
            cell: (info) => <div>{info.getValue() || '—'}</div>,
            header: () => <div>Location</div>,
            enableSorting: true,
            meta: {
                cellStyle: { width: '333px', maxWidth: '333px' },
            },
        }),
        columnHelper.accessor(
            (row) => {
                const firstName = row.contact?.firstName ?? ''
                const lastName = row.contact?.lastName ?? ''
                return `${firstName} ${lastName}`.trim()
            },
            {
                id: 'primaryContact',
                cell: (info) => <div>{info.getValue() || '—'}</div>,
                header: () => <div>Primary Contact</div>,
                enableSorting: true,
                meta: {
                    cellStyle: { width: '333px', maxWidth: '333px' },
                },
            },
        ),
        {
            id: 'actions',
            header: () => '',
            cell: (info: any) => (
                <WarehouseActions refreshWarehouses={fetchWarehousesInfo} warehouse={info.row.original} admin={admin} />
            ),
            meta: {
                cellStyle: { width: '165px', maxWidth: '165px' },
                cellClass: 'no-text-truncate actions-cell',
            },
        },
    ].filter(Boolean)

    return columns
}

export default useWarehousesColumns
