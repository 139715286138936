import { Avatar, AvatarFallback } from '@/shared/ui/Avatar'
import React, { FC } from 'react'

export type ChatInfoMessageProps = {
    message: string
}

export const ChatInfoMessage: FC<ChatInfoMessageProps> = ({ message }) => {
    return (
        <div className="mr-auto flex flex-col gap-2 w-full">
            <div className="flex flex-row-reverse gap-2 w-full">
                <div className="w-full text-center p-3 border border-border rounded-[20px_20px_20px_20px] m-auto bg-background-tertiary font-medium">
                    {message}
                </div>
            </div>
        </div>
    )
}
