import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InCheckmark2 = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.114 0.337953C10.5455 -0.112651 11.245 -0.112651 11.6764 0.337953C12.1026 0.783032 12.1078 1.50126 11.6921 1.95303L5.81144 9.63035C5.80296 9.64143 5.79389 9.65201 5.78428 9.66205C5.35284 10.1127 4.65333 10.1127 4.22189 9.66205L0.323582 5.59059C-0.107861 5.13999 -0.10786 4.40941 0.323581 3.95881C0.755023 3.5082 1.45453 3.5082 1.88597 3.95881L4.9698 7.1796L10.0847 0.372469C10.0938 0.360362 10.1036 0.348836 10.114 0.337953Z" />
            </svg>
        </IconBase>
    )
}
