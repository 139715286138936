import React, { FC, InputHTMLAttributes, Dispatch, SetStateAction } from 'react'
import cn from 'classnames'

import { ReactComponent as SearchLoop } from '@/assets/images/search-loop.svg'

import './Search.scss'

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    placeholder?: string
    onSearch: (searchEl: string) => void
    className?: string
    filterValue: string
    wrapperClassName?: string
}

const Search: FC<SearchProps> = ({
    placeholder = '',
    label,
    onSearch,
    className,
    filterValue,
    wrapperClassName,
    ...other
}) => {
    return (
        <div className={cn('search', wrapperClassName)}>
            {label && (
                <label className="search__label" htmlFor="searchInput">
                    {label}
                </label>
            )}
            <input
                id="searchInput"
                type="text"
                placeholder={placeholder}
                value={filterValue}
                onChange={(e) => onSearch(e.target.value)}
                className={cn(className, 'search__input')}
                onPaste={(e) => {
                    e.preventDefault()
                    const pastedText = e.clipboardData.getData('text').trim()
                    onSearch(pastedText)
                }}
                {...other}
            />
            <SearchLoop className="search__icon" />
        </div>
    )
}

export default Search
