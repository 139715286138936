import React from 'react'
import { ServiceBreakdownComponentProps } from '../index'
import { RfqServicePricing } from '@/shared/lib/rfq-services'

export const StorageBreakdownComponent = ({ order, service }: ServiceBreakdownComponentProps) => {
    return (
        <div className="flex items-center">
            {service.pricings[RfqServicePricing.Storage]?.price ? (
                <div className="flex flex-col sm:flex-row gap-1 sm:gap-2 sm:items-center w-full">
                    <span className="text-xl text-primary font-semibold">
                        ${Number(service.pricings[RfqServicePricing.Storage]!.price).toLocaleString('en')}
                    </span>

                    <div className="flex gap-1 sm:gap-2 items-center">
                        <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                            per
                        </span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            {service.type === 'unit' ? order.unitType ?? 'Unit' : 'Sqft'}
                        </span>
                    </div>
                    <div className="flex gap-1 sm:gap-2 items-center">
                        <span className="text-xl shrink-0 text-foreground-secondary hidden sm:inline">/</span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 sm:hidden">
                            per
                        </span>
                        <span className="text-foreground-secondary text-sm font-medium shrink-0 leading-4 capitalize">
                            {service.pricings[RfqServicePricing.Storage]!.modifier}
                        </span>
                    </div>
                </div>
            ) : (
                <span className="text-foreground-secondary font-medium">N/A</span>
            )}
        </div>
    )
}
