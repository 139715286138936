import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InCheckmark = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.3169 4.55806C17.561 4.80214 17.561 5.19786 17.3169 5.44194L8.56694 14.1919C8.44973 14.3092 8.29076 14.375 8.125 14.375C7.95924 14.375 7.80027 14.3092 7.68306 14.1919L3.30806 9.81694C3.06398 9.57286 3.06398 9.17714 3.30806 8.93306C3.55214 8.68898 3.94786 8.68898 4.19194 8.93306L8.125 12.8661L16.4331 4.55806C16.6771 4.31398 17.0729 4.31398 17.3169 4.55806Z" />
            </svg>
        </IconBase>
    )
}
