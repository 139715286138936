import { WarehouseScheduleItem } from './WarehouseSchedule'

export const removeIdFromNewObjects = (array: any[]): any[] => {
    return array.map((obj) => {
        if (obj?.id?.toString().length === 5) {
            const { id, ...objWithoutId } = obj
            return objWithoutId
        }
        return obj
    })
}

export function generateFiveDigitId() {
    return Math.floor(10000 + Math.random() * 90000)
}

export const isNameUnique = (name: string, newSchedule: WarehouseScheduleItem[]) => {
    return !newSchedule.some((item) => item.name === name)
}
