import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { LoadBoardPostDto } from '@shared/apiv2/load-board'
import dayjs from 'dayjs'
import { ReactComponent as LocationIcon } from '@/assets/images/location-dot2.svg'
import { getStatus, setServicesIcon } from './LoadBoardHelper'
import { stringPointToXY } from '@/utils/helper'
import NumberFormat from 'react-number-format'

interface LoadBoardColumnProps {
    showDistance?: boolean
    data?: LoadBoardPostDto[]
    tabValue?: string | number
    isMobile?: boolean
}

const columnHelper = createColumnHelper<
    LoadBoardPostDto & { unitType?: string; totalUnits?: number; isMobile?: boolean }
>()

export const loadBoardColumns = ({ showDistance, data, tabValue, isMobile }: LoadBoardColumnProps) => {
    const columns = [
        columnHelper.accessor('id', {
            cell: (info) => {
                const [color, text] = getStatus(info.row.original) as [string, string]
                const statusLabel = (
                    <span style={{ color }} className="load-board-table__status">
                        {text}
                    </span>
                )
                return (
                    <div className="load-board-table__id">
                        <span className="blue-link">{info.getValue()}</span>
                        {tabValue !== 'lost' ? statusLabel : null}
                    </div>
                )
            },
            header: () => <span>Order Number</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('startDate', {
            cell: (info) => <span>{dayjs.utc(info.getValue() as any).format('MM/DD/YYYY')}</span>,
            header: () => <span>Start Date</span>,
            enableSorting: true,
            meta: {
                cellStyle: { width: 130, maxWidth: 130 },
            },
        }),
        columnHelper.accessor('endDate', {
            cell: (info) => <span>{dayjs.utc(info.getValue() as any).format('MM/DD/YYYY')}</span>,
            header: () => <span>End Date</span>,
            enableSorting: true,
            meta: {
                cellStyle: { width: 110, maxWidth: 110 },
            },
        }),
        columnHelper.accessor('totalUnits', {
            cell: (info) => (
                <div className="load-board-table__units">
                    <NumberFormat value={info.getValue()} displayType={'text'} thousandSeparator />
                </div>
            ),
            header: () => <span>Units #</span>,
            enableSorting: true,
            meta: {
                cellStyle: { width: 100, maxWidth: 100 },
            },
        }),
        columnHelper.accessor('unitType', {
            cell: (info) =>
                info.getValue() ? <div className="load-board-table__unitType">{info.getValue()}</div> : <></>,
            header: () => <span>Unit Type</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('address', {
            enableSorting: false,
            cell: (info) => {
                const coordinate = stringPointToXY((info.row.original as any).coordinate)

                return <>{info.getValue()}</>
            },
            header: () => <span>Service Location</span>,
            meta: {
                cellStyle: { width: 300, maxWidth: 300 },
            },
        }),
        columnHelper.accessor('services', {
            enableSorting: false,
            cell: ({ getValue, row }) => <span className="service-icons">{setServicesIcon(getValue())}</span>,
            header: () => <span>Services</span>,
        }),
        showDistance &&
            (columnHelper.accessor('distance', {
                id: 'distance',
                enableSorting: true,
                cell: ({ getValue, row }) => <span>{getValue()} mls</span>,
                header: () => <span>Distance</span>,
                meta: {
                    cellStyle: { width: 120, maxWidth: 120 },
                },
            }) as any),
    ].filter(Boolean)

    const columnsMobile = [
        columnHelper.accessor('id', {
            cell: (info) => <span className="load-board-table__id">{info.getValue()}</span>,
            header: () => <span>Order Number</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('address', {
            enableSorting: false,
            cell: (info) => {
                return (
                    <div className="load-board-table__addressWrap">
                        <LocationIcon style={{ marginRight: 10 }} />
                        <div className="load-board-table__address">{info.getValue()}</div>
                    </div>
                )
            },
            header: () => <span>Service Location</span>,
        }),
        columnHelper.accessor(
            (row) => ({
                startDate: row.startDate,
                endDate: row.endDate,
            }),
            {
                id: 'startDate',
                header: () => <span>Dates</span>,
                cell: (info) => {
                    const { startDate, endDate } = info.getValue()
                    const formattedStartDate = dayjs.utc(startDate).format('MM/DD/YYYY')
                    const formattedEndDate = dayjs.utc(endDate).format('MM/DD/YYYY')
                    return (
                        <div className="load-board-table__dates">
                            <div className="load-board-table__cell">
                                <div className="load-board-table__label">Start Date</div>
                                <div>{formattedStartDate}</div>
                            </div>
                            <div className="load-board-table__cell">
                                <div className="load-board-table__label">End Date</div>
                                <div>{formattedEndDate}</div>
                            </div>
                        </div>
                    )
                },
                enableSorting: true,
            },
        ),
        columnHelper.accessor((row) => ({ totalUnits: row.totalUnits, unitType: row.unitType }), {
            id: 'totalUnits',
            cell: (info) => {
                const { totalUnits, unitType } = info.getValue()
                return (
                    <div className="load-board-table__totalUnits">
                        <div style={{ flex: 1 }}>
                            <div className="load-board-table__label"># of Unit</div>
                            <div>
                                <NumberFormat value={totalUnits} displayType={'text'} thousandSeparator />
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className="load-board-table__label">Unit Type</div>
                            <div>{unitType}</div>
                        </div>
                    </div>
                )
            },
            header: () => <span># Of Units</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('services', {
            cell: (info) => (
                <div style={{ flex: 0.5 }}>
                    <div className="load-board-table__label" style={{ paddingBottom: 4 }}>
                        Services
                    </div>
                    <div className="service-icons">{setServicesIcon(info.getValue())}</div>
                </div>
            ),
            header: () => <span>Unit Type</span>,
            enableSorting: false,
        }),
        showDistance &&
            (columnHelper.accessor('distance' as any, {
                enableSorting: false,
                cell: ({ getValue, row }) => <span>{getValue()} mls</span>,
                header: () => <span>Distance</span>,
            }) as any),
    ].filter(Boolean)

    const tableData = data?.map((elem: any) => {
        if (elem.notes) {
            elem.subRows = elem.notes
            elem.subRows = [{ notes: elem.notes }]
        }
        return elem
    })

    const expandedColumns = [
        {
            cellName: 'id',
            cellHeader: '',
            // cellStyle: { width: 200 },
            cell: (row: any) => {
                return (
                    <div
                        style={{ paddingRight: 20 }}
                        className="load-board_post-details_row ffBeVietnamPro"
                        title={row.id}
                    >
                        {row.notes}
                    </div>
                )
            },
        },
    ]

    return {
        columns: isMobile ? columnsMobile : columns,
        tableData,
        expandedColumns,
    }
}
