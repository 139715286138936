import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import { FormValues } from '@/pages/RFQPortal/RfqForm/index'

function useReferralLink(formik: FormikProps<FormValues>) {
    useEffect(() => {
        const referralFromLocalSt = localStorage.getItem('referral_link')
        const link = window.location.href

        if (referralFromLocalSt) {
            formik.setFieldValue('lead.referral', referralFromLocalSt.substring(0, 255))
            return
        }

        const linkParams = new URLSearchParams(new URL(link).search)
        const UtmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'gad_source', 'gbraid', 'gclid']
        const hasUTMParams = UtmParams.some((param) => linkParams.has(param))

        const paramsToBeRemoved = ['_gl', '_gcl_aw', '_ga']
        const cleanedLink = removeParams(link, paramsToBeRemoved).substring(0, 255)
        formik.setFieldValue('lead.referral', cleanedLink)

        if (hasUTMParams) {
            localStorage.setItem('referral_link', cleanedLink)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export function removeParams(urlString: string, paramPrefixes: string[]): string {
    const url = new URL(urlString)
    const params = new URLSearchParams(url.search)
    for (const [key] of params) {
        if (paramPrefixes.some((prefix) => key.startsWith(prefix))) {
            params.delete(key)
        }
    }
    url.search = params.toString()

    return url.toString()
}

export default useReferralLink
