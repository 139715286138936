import { ServiceMethods } from '../index'
import { YardStorageBreakdownComponent } from './BreakdownComponent'
import { YardStorageFormComponent } from './FormComponent'
import { RfqService, RfqServicePricing } from '@/shared/lib/rfq-services'

export const yardStorageService: ServiceMethods = {
    breakdownComponent: YardStorageBreakdownComponent,
    formComponent: YardStorageFormComponent,

    getDefaultService: () => {
        return {
            name: RfqService.YardStorage,
            type: null,
            pricings: {
                [RfqServicePricing.Storage]: { price: '', modifier: 'week' },
            },
        }
    },
}
