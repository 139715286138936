import React from 'react'

import { LoadBoardItem } from '../LoadBoardItem'

export const MainStep = ({
    formik,
    handleServicesChange,
    handleCertificationsChange,
    handleEquipmentChange,
    handleFeaturesChange,
    setDisabled,
    cancelClick,
}: {
    formik: any
    handleServicesChange: any
    handleCertificationsChange: any
    handleEquipmentChange: any
    handleFeaturesChange: any
    setDisabled: any
    cancelClick: () => void
}) => {
    return (
        <LoadBoardItem
            setDisabled={setDisabled}
            handleServicesChange={handleServicesChange}
            handleCertificationsChange={handleCertificationsChange}
            handleEquipmentChange={handleEquipmentChange}
            handleFeaturesChange={handleFeaturesChange}
            formik={formik}
        />
    )
}
