import React from 'react'

import { setMainStyle } from '../helper'
import Checkbox from '@/components/Checkbox'

export const FeaturesCheckboxes = ({
    handleFeaturesChange,
    isFeatureChecked,
}: {
    isFeatureChecked: (param: string) => any
    handleFeaturesChange: (param: any) => any
}) => {
    return (
        <div>
            <p className={setMainStyle('_label')}>Features</p>
            <div className={setMainStyle('_wrap, drayage_checkboxWrap')} style={{ flexWrap: 'wrap' }}>
                <div style={{ marginBottom: 12 }}>
                    <Checkbox
                        className={setMainStyle('_checkbox _checkbox_width')}
                        label={'Gated Yard'}
                        name={'gatedyard'}
                        value={isFeatureChecked('gatedyard')}
                        onChange={handleFeaturesChange}
                    />
                </div>

                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    label="Ramp"
                    name={'ramp'}
                    value={isFeatureChecked('ramp')}
                    onChange={handleFeaturesChange}
                />

                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    value={isFeatureChecked('security')}
                    label={'On-Site Security'}
                    name="security"
                    onChange={handleFeaturesChange}
                />

                <Checkbox
                    className={setMainStyle('_checkbox _checkbox_width')}
                    value={isFeatureChecked('sprinkler')}
                    name="sprinkler"
                    label="Sprinkler System"
                    onChange={handleFeaturesChange}
                />
            </div>
        </div>
    )
}
