import React, { FC, useEffect, Ref, useCallback, useState } from 'react'
import intlTelInput from 'intl-tel-input'
import Inputmask from 'inputmask'
import { generateRandomId } from '@/pages/RFQPortal/helpers/rfqHelper'
import cn from 'classnames'

import { FormValues, FormValueTypes } from '@/pages/RFQPortal/RfqOrder/rfqOrderTypes'

import 'intl-tel-input/build/css/intlTelInput.css'
import './IntlTelInput.scss'

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id?: string
    label: string
    formData?: FormValues //Rfq
    errorMessage?: string | false
    changeFormData?: (path: string, value: FormValueTypes) => void //Rfq
    validatePhone?: () => boolean
    validateOnChange?: boolean
    clientPhoneItiObject: any
    setClientPhoneItiObject: (itiObject: any) => void
    wrapperRef?: Ref<HTMLDivElement>
    wrapperClassName?: string
    value?: string
    changeValue?: (value: string) => void
    onBlur?: () => void
    placeholder?: string
}

const PhoneInput: FC<PhoneInputProps> = ({
    formData,
    changeFormData,
    errorMessage,
    label,
    id,
    validatePhone,
    clientPhoneItiObject,
    setClientPhoneItiObject,
    wrapperRef,
    value,
    changeValue,
    onBlur,
    placeholder = '(999) 999-9999',
    validateOnChange = true,
    wrapperClassName,
    ...rest
}) => {
    const [inputId] = useState(id || generateRandomId())

    const initializePhoneInput = useCallback(
        (node: HTMLInputElement | null) => {
            if (node && !node.classList.contains('intl-tel-input')) {
                const scriptURL = `${window.location.origin}/static/intl-tel-input-utils.min.js`
                const itiInstance = intlTelInput(node, {
                    utilsScript: scriptURL,
                    preferredCountries: ['us', 'ca', 'mx'],
                    nationalMode: false,
                    separateDialCode: true,
                })

                Inputmask({ mask: '(999) 999-9999', placeholder: '' }).mask(node)
                setClientPhoneItiObject(itiInstance)
            }
        },
        [setClientPhoneItiObject],
    )

    const inputRef = useCallback(
        (node) => {
            if (node !== null) {
                initializePhoneInput(node)
            }
        },
        [initializePhoneInput],
    )

    const changeNumber = () => {
        if (clientPhoneItiObject) {
            if (changeFormData) {
                changeFormData('clientData.phone', clientPhoneItiObject.getNumber())
            }

            if (changeValue) {
                changeValue(clientPhoneItiObject.getNumber())
            }
        }
    }

    useEffect(() => {
        if (clientPhoneItiObject) {
            if (formData?.clientData?.phone) {
                clientPhoneItiObject.setNumber(formData.clientData.phone)
            }

            if (value) {
                clientPhoneItiObject.setNumber(value)
            }
        }
    }, [clientPhoneItiObject, formData?.clientData?.phone, value])

    useEffect(() => {
        if (clientPhoneItiObject) {
            const handleCountryChange = () => {
                const phoneNumber = clientPhoneItiObject.getNumber()
                if (changeFormData) {
                    changeFormData('clientData.phone', phoneNumber)
                }
                if (changeValue) {
                    changeValue(phoneNumber)
                }
            }

            clientPhoneItiObject.telInput.addEventListener('countrychange', handleCountryChange)
            return () => {
                clientPhoneItiObject.telInput.removeEventListener('countrychange', handleCountryChange)
            }
        }
    }, [clientPhoneItiObject, changeFormData, changeValue])

    useEffect(() => {
        if (clientPhoneItiObject && value !== undefined) {
            clientPhoneItiObject.setNumber(value)
        }
    }, [value, clientPhoneItiObject])

    const checkIfError = errorMessage ? 'input-error' : ''

    return (
        <div className={cn(`${wrapperClassName} input-wrapper iti-wrapper`)} ref={wrapperRef}>
            <label htmlFor={inputId}>{label}</label>
            <input
                id={inputId}
                type="tel"
                name="phone"
                placeholder={placeholder}
                onChange={changeNumber}
                ref={inputRef}
                className={checkIfError}
                onBlur={() => {
                    if (validatePhone && validateOnChange) {
                        setTimeout(() => {
                            validatePhone()
                        }, 150)
                    }

                    if (onBlur) {
                        setTimeout(() => {
                            onBlur()
                        }, 150)
                    }
                }}
                {...rest}
            />
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
}

export default PhoneInput
