import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import logo from 'images/logoWide.svg'
import ROUTES from '@/router'
import './Logo.scss'

interface ILogo extends HTMLAttributes<HTMLAnchorElement> {
    isLoggedIn?: boolean
    hasAccount?: boolean
    navigateTo?: string
    onClick?: () => void
}

const CLASS_NAME = 'company-logo'

export const Logo: FC<ILogo> = ({ className, isLoggedIn, hasAccount, navigateTo = '/', onClick }) => {
    const isExternal =
        typeof navigateTo === 'string' && (navigateTo.startsWith('http://') || navigateTo.startsWith('https://'))

    if (isExternal) {
        return (
            <a href={navigateTo} className={cn(CLASS_NAME, className, { isLoggedIn, hasAccount })} onClick={onClick}>
                <img src={logo} alt="logo" />
            </a>
        )
    } else {
        return (
            <Link to={navigateTo} className={cn(CLASS_NAME, className, { isLoggedIn, hasAccount })} onClick={onClick}>
                <img src={logo} alt="logo" />
            </Link>
        )
    }
}
