import React, { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '@/parts/Navigation'
import { useUserState } from '@/store/user'
import MobilePopup from '@/parts/Partials/MobilePopup/MobilePopup'
import Button from '@/components/Button'
import './Header.scss'
import { Logo } from '@/parts/Partials/Header/Logo/Logo'
import { useMobileWidth } from '@shared/common/hooks'
import { ssoUrl } from '@/router'
import { useLocation } from 'react-router'
import { isDevEnv } from '@/common/consts'
import ConditionalImage from '@/components/ConditionalImage/ConditionalImage'
import { HttpV2 } from '@shared/apiv2/api'
import { useMicrosite } from '@/pages/RFQPortal/hooks/useMicrosite'
import { ReactComponent as LogInIcon } from '@/assets/images/log-in.svg'
import { getOLIMPLandingURL } from '@/utils/url'
import { useCustomAnalyticsEvent } from '@/hooks/useGoogleAnalytics'

const CLASS_NAME = 'olimp-header'

interface HeaderProps {
    shouldLogoReset: boolean
}

// eslint-disable-next-line complexity
const Header: FC<HeaderProps> = ({ shouldLogoReset }) => {
    const { sendCustomEvent } = useCustomAnalyticsEvent()
    const { data: micrositeData } = useMicrosite()
    const navigate = useNavigate()
    const location = useLocation()
    const headerRef = useRef<HTMLDivElement | null>(null)
    const isMobileLayout = useMobileWidth()

    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const {
        state: { isLoggedIn, userInfo },
    } = useUserState()

    const dev = isDevEnv

    const loginPage = location.pathname.indexOf('/login') >= 0
    const servicePage = location.pathname.indexOf('/504') >= 0

    const onDemandWarehousingRfq = location.pathname.includes('on-demand-warehousing')
    const rfqGeneral = !micrositeData?.site && location.pathname.includes('rfq')

    const landingBaseUrl = getOLIMPLandingURL()
    return (
        <header className={cn(CLASS_NAME, 'wrapper', { dev, tos: servicePage })}>
            <div ref={headerRef} className={cn(`${CLASS_NAME}__inner`)}>
                {/* <div className="flex"> */}
                <Logo
                    navigateTo={landingBaseUrl}
                    className={`${CLASS_NAME}__logo`}
                    isLoggedIn={isLoggedIn}
                    hasAccount={micrositeData?.accountId ?? userInfo?.sfParentAccountId}
                    onClick={() => {
                        sendCustomEvent('olimp_logo_clicked', {
                            logo_url: (micrositeData?.site || rfqGeneral) && landingBaseUrl,
                            place: 'header',
                            action: 'User clicked on Olimp logo in header',
                        })
                    }}
                />

                {(isLoggedIn || micrositeData) && (
                    <div className={`${CLASS_NAME}__entity-label inline-block shrink`}>
                        {/*<EntityStatus*/}
                        {/*    id={selectedEntity.entity.id}*/}
                        {/*    status={getFullEntityStatus(selectedEntity.entity)}*/}
                        {/*/>*/}

                        <ConditionalImage
                            resetTrigger={{
                                shouldLogoReset,
                            }}
                            src={`${HttpV2.defaults.baseURL}/accounts/${
                                micrositeData?.accountId ?? userInfo?.sfParentAccountId
                            }/logo`}
                            alt={'Logo'}
                            fallback={
                                <div
                                    className={`${CLASS_NAME}__entity-label-text max-w-xs w-full line-clamp-2`}
                                    title={micrositeData?.accountName ?? userInfo?.parentCompanyName}
                                >
                                    {micrositeData?.accountName ?? userInfo?.parentCompanyName}
                                </div>
                            }
                            onClick={() => {
                                sendCustomEvent('olimp_logo_clicked', {
                                    logo_url: (micrositeData?.site || rfqGeneral) && landingBaseUrl,
                                    place: 'header',
                                    action: 'User clicked on Olimp logo in header',
                                })
                            }}
                        />
                    </div>
                )}
                {/* </div> */}
                {/*<EntitiesMenu className={`${CLASS_NAME}__entities`} />*/}
                <Navigation
                    className={cn(`${CLASS_NAME}__nav`, 'media-desktop')}
                    isOpen={menuOpen}
                    onToggle={(val) => setMenuOpen(Boolean(val))}
                    shouldLogoReset={shouldLogoReset}
                />

                {!isLoggedIn && !onDemandWarehousingRfq && !loginPage && !isMobileLayout && (
                    <div className={`${CLASS_NAME}__auth`}>
                        <div className={`${CLASS_NAME}__logIn`}>
                            <Button
                                types={['plain']}
                                onClick={() => {
                                    const signUpLink = `${ssoUrl}/${
                                        micrositeData?.site || rfqGeneral ? `?slug=${micrositeData?.site}` : ''
                                    }`

                                    sendCustomEvent('signup_clicked', {
                                        signup_link: signUpLink,
                                        action: 'User clicked on signup button in header',
                                        device: 'desktop',
                                    })

                                    if (window.location.pathname.includes('/rfq')) {
                                        localStorage.setItem('rfqLinkForSSO', window.location.href)
                                    }
                                    window.location.href = signUpLink
                                }}
                            >
                                Sign up
                            </Button>
                            <Button
                                types={['plain']}
                                onClick={() => {
                                    const logInLink = `${ssoUrl}/login${
                                        micrositeData?.site || rfqGeneral ? `?slug=${micrositeData?.site}` : ''
                                    }`

                                    sendCustomEvent('login_clicked', {
                                        login_link: logInLink,
                                        action: 'User clicked on login button in header',
                                        device: 'desktop',
                                    })

                                    if (window.location.pathname.includes('/rfq')) {
                                        localStorage.setItem('rfqLinkForSSO', window.location.href)
                                    }
                                    window.location.href = logInLink
                                }}
                            >
                                Log in
                            </Button>
                            <LogInIcon />
                        </div>
                    </div>
                )}

                {(isLoggedIn || location.pathname.includes('/rfq')) && (
                    <div
                        className={cn(`${CLASS_NAME}__burger`, 'btn-menu media-mobile', {
                            opened: menuOpen,
                        })}
                        onClick={() => {
                            setMenuOpen((o) => {
                                if (!o) {
                                    sendCustomEvent('mobile_menu_opened', {
                                        action: 'User clicked on burger menu button',
                                    })
                                }
                                return !o
                            })
                        }}
                    />
                )}

                {isMobileLayout && (
                    <MobilePopup
                        visible={menuOpen}
                        onClose={() => setMenuOpen(false)}
                        headerRef={headerRef}
                        shouldLogoReset={shouldLogoReset}
                    />
                )}
            </div>
        </header>
    )
}

export default Header
