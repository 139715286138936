import React from 'react'
import { IconBase, IconProps } from '@/shared/ui/Icons'

export const InCrossDocking = (props: IconProps) => {
    return (
        <IconBase {...props}>
            <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.25 5H13.7461L13.7492 7.25C13.7492 7.54834 13.97 7.81844 14.3121 7.9375C14.654 8.05662 15.0515 8.00244 15.325 7.79981L19.7 4.54981C20.1 4.25293 20.1 3.747 19.7 3.45012L15.325 0.200119C15.0518 -0.00250618 14.6543 -0.0566624 14.3121 0.0623689C13.97 0.181619 13.7492 0.451744 13.7492 0.749869L13.7461 3H1.25C0.558984 3 0 3.44687 0 3.97187C0 4.49687 0.558984 5 1.25 5ZM18.75 11H6.25391L6.25 8.72187C6.25 8.42353 6.02922 8.15344 5.68711 8.03437C5.34766 7.94375 4.94922 7.99687 4.67578 8.2L0.300781 11.45C-0.0992187 11.7469 -0.0992187 12.2528 0.300781 12.5497L4.67578 15.7997C4.94891 16.0023 5.34648 16.0565 5.68867 15.9374C6.03125 15.8188 6.25 15.5469 6.25 15.25L6.25391 13H18.75C19.441 13 20 12.5528 20 12C20 11.4472 19.4414 11 18.75 11Z" />
            </svg>
        </IconBase>
    )
}
